import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import { InteractionType } from "@azure/msal-browser";
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import { MonitorService } from '../MonitorService'
import AlertMessage from "../../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import Spinner from '../../Spinner'
import './TaskScheduleMonitor.css'

//HM-397 - UI to monitor HIE scheduled tasks
class TaskScheduleMonitor extends Component {
    constructor() {
        super();
        this._isMirrorsMounted = false;
        this.state = {
            TaskScheduleList: [],
            SelectedTask: {},
            InstanceList: [],
            NamespaceList: [],
            StatusNameList: [],
            hideSpinner: false,
            pageErrorMessage: '',
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'Instance': { value: null, matchMode: FilterMatchMode.IN },
                'Namespace': { value: null, matchMode: FilterMatchMode.IN },
                'DisplayStatus': { value: ['ERROR'], matchMode: FilterMatchMode.IN },
                'Name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'TaskClass': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            },
            visibleRight: false,
            settingInHtml: null,
            visibleTaskDetails: false,
            selectedAutoRefresh: 0,
            intervalId: null,
            autoRefreshOptions: [
                { label: 'Auto-Refresh Off', value: 0 },
                { label: '15 minute', value: 900000 },
                { label: '30 minute', value: 1800000 },
                { label: '1 hour', value: 3600000 },
                { label: '2 hour', value: 7200000 }
            ]
        };

        this.baseAPIUrl = global.ENV_HIE_ADMIN_API_AZURE_URL;
        this.getCacheTaskSchedulerData = this.getCacheTaskSchedulerData.bind(this)
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.globalSearchFilter = this.globalSearchFilter.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.monitorService = new MonitorService();
    }

    componentDidMount() {
        this._isMirrorsMounted = true;
        this.getCacheTaskSchedulerData();
    }

    getCacheTaskSchedulerData() {
        this.setState({ hideSpinner: false })
        this.monitorService.getCacheTaskSchedulerData(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMirrorsMounted) {
                        this.setState({
                            TaskScheduleList: d.body,
                            InstanceList: [...new Set(d.body.map(x => x.Instance))],
                            NamespaceList: [...new Set(d.body.map(x => x.Namespace))].sort((a, b) => { return a > b ? 1 : -1 }),
                            StatusNameList: [...new Set(d.body.map(x => x.DisplayStatus))],
                            hideSpinner: true
                        });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    clearDataTableFilters = () => {
        this.setState({
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'Instance': { value: null, matchMode: FilterMatchMode.IN },
                'Namespace': { value: null, matchMode: FilterMatchMode.IN },
                'DisplayStatus': { value: null, matchMode: FilterMatchMode.IN },
                'Name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'TaskClass': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            }
        });
        sessionStorage.clear();
    }

    //HM-935 - change to save the filter
    onGlobalFilterChange(event, filtersKey) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter(filtersKey) {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)}
                            placeholder="Global Search" style={{ width: '100%' }} size="100" />
                    </span>
                    <span>
                        <Dropdown value={this.state.selectedAutoRefresh} options={this.state.autoRefreshOptions} onChange={this.onAutoRefresh} placeholder="Auto-Refresh"
                            tooltip='Auto-Refresh' tooltipOptions={{ position: 'top' }} className="ui-column-filter" />
                        <Button hidden={this.state.selectedAutoRefresh !== 0} tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.getCacheTaskSchedulerData} icon="pi pi-refresh" />
                    </span>
                </div>
            </div>
        )
    }

    instanceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.InstanceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="Instance"
        />;
    }

    namespaceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.NamespaceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="Namespace" className="multiselect-custom"
        />;
    }

    onAutoRefresh = (e) => {
        let interval = {}
        let selectedAutoRefresh = e.value;
        clearInterval(this.state.intervalId);

        if (e.value && e.value > 0) {
            interval = setInterval(() => {
                //console.log('Logs every - ' + selectedAutoRefresh + ' ms - ' + new Date());
                this.getCacheTaskSchedulerData();
            }, selectedAutoRefresh);
        }
        this.setState({ selectedAutoRefresh: e.value, intervalId: interval })
    }

    statusFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.StatusNameList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="Status" className="multiselect-custom"
        />;
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }


    onRowClick(e) {
        if (e.data.Settings) {
            try {
                var jsonSetting = JSON.parse(e.data.Settings)
                var convertSettingsToHtml = jsonSetting.map((Member) => {
                    var keys = Object.keys(Member)
                    var divs = keys.map(k => <><tr><td class="fluidcell">{k}</td><td class="fluidcell">{Member[k]}</td></tr></>)
                    return (
                        <>{divs}</>
                    )
                });
            } catch (error) { }
        }
        this.setState({ SelectedTask: e.data, visibleTaskDetails: true, settingInHtml: convertSettingsToHtml });
    }

    formatTaskStatus = (rowData, showErr) => {
        if (rowData.Status === null || rowData.Status === "" || rowData.Status === "1")
            return <span className={`task-status-badge status-ok`}>{rowData.DisplayStatus}</span>;
        else if (rowData.Status === "-1")
            return <span className={`task-status-badge status-warning`}>{rowData.DisplayStatus}</span>;
        else {
            if (showErr === true)
                return rowData.Status || "N.A"
            else
                return <span className={`task-status-badge status-error`}>{rowData.DisplayStatus}</span>;
        }
    }

    showNAIfNoData = (rowData, column) => {
        let value = rowData[column.field];

        if (value)
            return rowData[column.field];
        else
            return "N.A"
    }

    rowClassStyle = (rowData) => {
        return rowData.Suspended ? 'suspeneded-highlight' : ''
    }

    // formatLastStartedAndFinished(rowData, column) {
    //     let propname = column.field ? column.field : column;
    //     let value = rowData[propname];
    //     if (value === "12/31/1840 00:00:00 AM" && propname === "LastStarted")
    //         return "Never started";
    //     if (value === "12/31/1840 00:00:00 AM" && propname === "LastFinished")
    //         return "Did not finish";
    //     if (value === "12/31/1840 00:00:00 AM" && propname === "LastSchedule")
    //         return "Never run";
    //     if (rowData.TaskRunTime === 0 && propname === "DisplayTaskRunTime")
    //         return "N.A"
    //     return value ? value : "N.A"
    // }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="adminContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}

                    <div hidden={this.state.hideSpinner}><Spinner /></div>

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">SCHEDULED TASK MONITOR
                                &nbsp;<Tooltip target=".custom-target-icon" />
                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                    data-pr-tooltip="1.Below data get's refreshed in every hour. &#013; 2.Double click on a row to view task delails."
                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i></div>
                        </header>

                        <Dialog header={'Task - ' + this.state.SelectedTask.Name} style={{ width: '50vw' }}
                            visible={this.state.visibleTaskDetails} modal={true}
                            onHide={e => this.setState({ visibleTaskDetails: false })} maximizable={true} closeOnEscape={true}>
                            <div class="row">
                                <div class="column">
                                    <table class="fixedtablelayout" border={1}>
                                        <th colSpan={2} class="th-details">General Information</th>
                                        <tr><td>Description:</td><td class="fluidcell">{this.state.SelectedTask.Description || "N.A"}</td></tr>
                                        <tr><td>Task Class:</td><td class="fluidcell">{this.state.SelectedTask.TaskClass || "N.A"}</td></tr>
                                        <tr><td>Task Priority:</td><td>{this.state.SelectedTask.Priority || "N.A"}</td></tr>
                                        <tr><td>Type:</td><td>{this.state.SelectedTask.Type || "N.A"}</td></tr>
                                        <tr><td>Suspended:</td><td>{this.state.SelectedTask.Suspended || "N.A"}</td></tr>
                                        <tr><td>Last Status:</td><td class="fluidcell">{this.formatTaskStatus(this.state.SelectedTask, true)}</td></tr>
                                        <tr><td>Running JobNumber:</td><td>{this.state.SelectedTask.RunningJobNumber || "N.A"}</td></tr>
                                    </table>
                                </div>
                                <div class="column">
                                    <table class="fixedtablelayout" border={1}>
                                        <th colSpan={2} class="th-details">Execution Details</th>
                                        <tr><td>Run by User:</td><td>{this.state.SelectedTask.RunAsUser || "N.A"}</td></tr>
                                        <tr><td>Frequency:</td><td>{this.state.SelectedTask.DisplayRun || "N.A"}</td></tr>
                                        <tr><td>Last Schedule:</td><td>{this.state.SelectedTask.DisplayLastSchedule || "N.A"}</td></tr>
                                        <tr><td>Last Started:</td><td>{this.state.SelectedTask.DisplayLastStarted || "N.A"}</td></tr>
                                        <tr><td>Last Finished:</td><td>{this.state.SelectedTask.DisplayLastFinished || "N.A"}</td></tr>
                                        <tr><td>Next scheduled time:</td><td>{this.state.SelectedTask.DisplayNextScheduled || "N.A"}</td></tr>
                                        <tr><td>Interval between runs:</td><td>{this.state.SelectedTask.DisplayInterval || "N.A"}</td></tr>
                                    </table>
                                </div>
                            </div>
                            {this.state.settingInHtml ? <><br></br><table border={1}>
                                <th colSpan={2} class="th-details">Settings</th>{this.state.settingInHtml}</table></> : <></>}
                            <br></br>
                            <div>
                                <table class="fixedtablelayout" border={1}>
                                    <th colSpan={2} class="th-details">Other Details</th>
                                    <tr><td>Suspend task on error ?</td><td>{this.state.SelectedTask.SuspendOnError ? "Yes" : "No"}</td></tr>
                                    <tr><td>Reschedule task after system restart ?</td><td>{this.state.SelectedTask.RescheduleOnStart ? "Yes" : "No"}</td></tr>
                                    <tr><td>Send completion email notification to:</td><td>{this.state.SelectedTask.EmailOnCompletion || "N.A"}</td></tr>
                                    <tr><td>Send error email notification to:</td><td>{this.state.SelectedTask.EmailOnError || "N.A"}</td></tr>
                                </table>
                            </div>

                        </Dialog>

                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.TaskScheduleList}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            dataKey="Id"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            showGridlines removableSort
                            rowClassName={this.rowClassStyle}
                            emptyMessage="No Records found."
                            onFilter={(e) => this.setState({ filters: e.filters })} //HM-935 - change to save the filter
                            stateStorage="session" stateKey="dt-monitor-taskschedule-session"
                            onRowDoubleClick={this.onRowClick}
                        >
                            <Column field="Instance" header="Instance" filter filterElement={this.instanceFilterTemplate} showFilterMatchModes={false} filterPlaceholder='Instance' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            <Column field="Namespace" header="NameSpace" filter filterElement={this.namespaceFilterTemplate} showFilterMatchModes={false} filterPlaceholder='NameSpace' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            <Column field="Name" header="Task Name" filter filterPlaceholder='Task Name' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            <Column field="TaskClass" header="Task Class" filter filterPlaceholder='Task Class' showFilterOperator={false} style={{ textAlign: 'left' }} body={this.showNAIfNoData} />
                            <Column field="DisplayStatus" header="Status" filter filterElement={this.statusFilterTemplate} showFilterMatchModes={false} filterPlaceholder='Status' showFilterOperator={false} style={{ textAlign: 'left' }} body={this.formatTaskStatus} />
                            <Column field="RunAsUser" header="User" style={{ textAlign: 'left' }} body={this.showNAIfNoData} />
                            <Column field="DisplayRun" header="Frequency" style={{ textAlign: 'left' }} body={this.showNAIfNoData} sortable />
                            <Column field="DisplayTaskRunTime" header="Runtime" style={{ textAlign: 'left' }} body={this.showNAIfNoData} sortField="TaskRunTime" sortable />
                            <Column field="DisplayLastStarted" header="Last Started" style={{ textAlign: 'left' }} sortField="LastStartedActual" sortable />
                            <Column field="DisplayLastFinished" header="Last Finished" style={{ textAlign: 'left' }} sortField="LastFinishedActual" sortable />
                            <Column field="TimeCollected" header="Time Collected" style={{ textAlign: 'left' }} sortField="TimeCollectedActual" sortable />
                            <Column field="Suspended" header="Suspended" style={{ textAlign: 'left' }} body={this.showNAIfNoData} sortable />
                        </DataTable>
                    </div>
                </div >
            </MsalAuthenticationTemplate >
        );
    }
};
const WrappedTaskScheduleMonitorComponent = withMsal(TaskScheduleMonitor);
export default withAITracking(reactPlugin, WrappedTaskScheduleMonitorComponent);