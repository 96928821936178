import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "../sign-in-button/sign-in-button-component";
import { SignOutButton } from "../sign-out-button/sign-out-button";
import Northwelllogo from './../../../src/images/northwell-logo-horizontal.png';
import '../../../src/App.css'
/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;
    return (
        <>

            <Navbar bg="light" variant="light" className="Navbar-fixed" style={{ height: '65px' }}>
                <div className=" col-4">
                    <img src={Northwelllogo} alt="Northwell Monitoring Tool" width="280" height="30" />
                </div>
                <div className=" col-4">
                    <header className="">
                        <div className="app-header-title">MONITORING TOOL</div>
                    </header>
                </div>
                <div className="ml-auto">
                    {isAuthenticated ? <SignOutButton account={accounts[0]} /> : <SignInButton />}
                </div>
            </Navbar>
            {/* {isAuthenticated ? <ProfileContent name={name} /> : ""} */}
            {props.children}
        </>
    );
};
function getCurrentDate(separator = '') {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();
    let seconds = newDate.getSeconds();
    let time = `${hour < 10 ? `0${hour}` : `${hour}`}:${minutes < 10 ? `0${minutes}` : `${minutes}`}:${seconds < 10 ? `0${seconds}` : `${seconds}`}`
    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date} ${time}`
}

function ProfileContent(props) {
    return (
        <>
            {/* <h6 className="card-title" align="left">Welcome {props.name}</h6> */}
            <div>
                <p className="mx-3 left-align">
                    <b> Welcome {props.name}</b>
                    <span className="right-align">
                        <b>Login time : </b><br />{getCurrentDate(':')}
                    </span>
                </p>
            </div>
        </>
    );
}