import React, { useEffect, useState, useRef } from 'react';
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartBroken, faHeartbeat, faHeart, faBinoculars } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'primereact/dropdown';
import { HIEHealthService } from './HIEHealthService';
import Spinner from "../../Spinner"
import { Toast } from 'primereact/toast';
import { MessageSeverity } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tooltip } from 'primereact/tooltip';
import * as Yup from 'yup';
import "./LeadershipDashboard.css";

//HDO-6447 - PI 2024 Q3 - Monitoring tool: Leadership Dashboard Main Landing page
function LeadershipDashboard(props) {
    const alertMessage = useRef(null);
    const healthService = new HIEHealthService();
    const healthStatusList = [
        { label: 'Healthy', value: 'GREEN' },
        { label: 'Known Issue', value: 'PURPLE' },
        { label: 'Degraded', value: 'YELLOW' },
        { label: 'Unhealthy', value: 'RED' }
    ]
    const [pageErrorMessage, setpageErrorMessage] = useState(null)
    const [pageSuccessMessage, setpageSuccessMessage] = useState(null)
    const [hideSpinner, sethideSpinner] = useState(false);
    const [ShowhealthDataLogs, setShowhealthDataLogs] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [healthData, setHealthData] = useState([]);
    const [healthDataLogs, setHealthDataLogs] = useState([]);
    const [selectedPlatformhealthDataLogs, setSelectedPlatformhealthDataLogs] = useState(null);
    const [selectedHealthData, setSelectedHealthData] = useState({});
    const [updateHealthStatusDialogBoxVisible, setUpdateHealthStatusDialogBoxVisible] = useState(false);
    const [showCommentSection, setshowCommentSection] = useState(null);

    const loadHealthStatus = () => {
        healthService.loadHealthStatus(props.msalContext).then(response => {
            if (response && response.status === 200) {
                setHealthData(response.body)
                sethideSpinner(true)
            }
            else {
                sethideSpinner(true)
                setpageErrorMessage("Something went wrong !!!")
            }
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        loadHealthStatus()
    }, [])

    //HDO-5625 - Auto refresh option code - start
    let autoRefreshOptions = [
        { label: 'Auto-Refresh Off', value: 0 },
        { label: '1 minute', value: 60000 },
        { label: '5 minute', value: 300000 },
        { label: '15 minute', value: 900000 },
        { label: '30 minute', value: 1800000 }
    ]

    const onAutoRefresh = (e) => {
        setpageErrorMessage(null)
        setpageSuccessMessage(null)
        let interval = {}
        let selectedAutoRefresh = e.value;
        clearInterval(intervalId);
        setAutoRefresh(selectedAutoRefresh);

        if (e.value && e.value > 0) {
            interval = setInterval(() => {
                sethideSpinner(false)
                loadHealthStatus();
            }, selectedAutoRefresh);
            setIntervalId(interval)
        }
    }

    const autoRefreshOption = () => {
        return (
            <div className="flex justify-content-between">
                <span>
                    <h4>PLATFORM HEALTH</h4>
                </span>
                <span>
                    <Dropdown value={autoRefresh} options={autoRefreshOptions}
                        onChange={onAutoRefresh} placeholder="Auto-Refresh" tooltip='Auto-Refresh' tooltipOptions={{ position: 'top' }} className="ui-column-filter" style={{ left: '90%', position: 'sticky', top: '0' }} />
                </span>
            </div>
        )
    }
    //HDO-5625 - Auto refresh option code - end

    const getHealthStatusBody = (rowData) => {
        if (rowData && rowData.HealthStatus === "RED")
            return <span className={`health-status-badge status-${rowData.HealthStatus.toLowerCase()}`}>
                <FontAwesomeIcon icon={faHeartBroken} />&nbsp;&nbsp;Unhealthy</span>;
        else if (rowData && rowData.HealthStatus === "YELLOW")
            return <span className={`health-status-badge status-${rowData.HealthStatus.toLowerCase()}`}>
                <FontAwesomeIcon icon={faHeartbeat} />&nbsp;&nbsp;Degraded</span>;
        else if (rowData && rowData.HealthStatus === "PURPLE")
            return <span className={`health-status-badge status-${rowData.HealthStatus.toLowerCase()}`}>
                <FontAwesomeIcon icon={faBinoculars} />&nbsp;&nbsp;Known Issue</span>;
        else
            return <span className={`health-status-badge status-${rowData.HealthStatus.toLowerCase()}`}>
                <FontAwesomeIcon icon={faHeart} />&nbsp;&nbsp;Healthy</span>;
    }

    const platformBodyTemplate = (rowData) => {
        return <label title={rowData.Description}>{rowData.PlatformName}</label>;
    }

    const onHandleInputChange = (event) => {
        setpageErrorMessage(null); setpageSuccessMessage(null);
        setSelectedHealthData({
            ...selectedHealthData,
            [event.target.name]: event.target.value
        })
    }

    const showActionBtns = (rowData) => {
        setpageErrorMessage(null); setpageSuccessMessage(null);
        return (
            <React.Fragment>
                <Button icon="pi pi-list" className="p-button-rounded p-button-primary" tooltipOptions={{ position: 'top' }} tooltip="Open Issues" onClick={() => window.open('/issues?Platform=' + rowData.PlatformName, '_blank', 'noopener=true')} />
                <Button icon="pi pi-book" className="p-button-rounded p-button-primary" tooltipOptions={{ position: 'top' }} tooltip="Logs" onClick={() => getHealthStatusAuditLog(rowData)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" tooltipOptions={{ position: 'top' }} tooltip="Update Status"
                    onClick={(e) => { setUpdateHealthStatusDialogBoxVisible(true); setSelectedHealthData(rowData); }} />
            </React.Fragment>
        )
    }

    const updateStatus = async () => {
        sethideSpinner(false); setpageErrorMessage(null); setpageSuccessMessage(null);
        try {
            let healthDataSchema = Yup.object({
                Comments: Yup.string().required("Comments is required.")
                    .min(30, "Comments should be atleast of 30 characters.")
                    .matches(/^[^'"]*$/, "Comments should not contain single (') or double (\") quotes.")
            });
            await healthDataSchema.validate(selectedHealthData, { abortEarly: false })

            healthService.updateHealthStatus(selectedHealthData, props.msalContext).then(response => {
                if (response && response.status === 200) {
                    loadHealthStatus()
                    sethideSpinner(true); setpageSuccessMessage("Health status updated successfully.")
                    setUpdateHealthStatusDialogBoxVisible(false)
                }
                else {
                    sethideSpinner(true)
                    setpageErrorMessage("Something went wrong !!!")
                }
            }).catch(e => {
                console.log(e)
            })
        }
        catch (error) {
            setpageErrorMessage(error.inner[0].message)
            sethideSpinner(true)
        }
    }

    const showComments = (event) => {
        setShowhealthDataLogs(false)
        if (event.data && event.data.Comments) {
            setshowCommentSection(
                <div>
                    <Accordion activeIndex={0}>
                        <AccordionTab header="Comments" headerStyle={{ textAlign: 'left' }}>
                            <div className='update-comments' dangerouslySetInnerHTML={{ __html: event.data.Comments }}></div >
                        </AccordionTab>
                    </Accordion>
                    <br />
                </div>
            )
        }
        else {
            setshowCommentSection("")
        }
    }

    const saveFooter = (
        <div>
            <Button label='Save' onClick={() => updateStatus()} />
            <Button label='Cancel' onClick={(e) => { setpageErrorMessage(null); setpageSuccessMessage(null); setUpdateHealthStatusDialogBoxVisible(false); }} className='p-button-secondary' />
        </div>
    );

    const getHealthStatusAuditLog = (rowData) => {
        sethideSpinner(false)
        setshowCommentSection("")
        healthService.getHealthStatusAuditLog(rowData.Id, props.msalContext).then(response => {
            if (response && response.status === 200) {
                sethideSpinner(true)
                setHealthDataLogs(response.body)
                setSelectedPlatformhealthDataLogs(rowData.PlatformName)
                setShowhealthDataLogs(true)
            }
            else {
                sethideSpinner(true)
                setShowhealthDataLogs(false)
                setpageErrorMessage("Something went wrong !!!")
            }
        }).catch(e => {
            console.log(e)
        })
    }

    const showError = (severity, summary, msg) => {
        alertMessage.current.show({ severity: severity, summary: summary, detail: msg, life: global.ALERT_MSG_LIFE });
    }

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <div>
                <BreadCrumbComponent {...props} />
                <Toast ref={alertMessage} />
                {pageErrorMessage ? showError(MessageSeverity.ERROR, 'Error Message', pageErrorMessage) : <></>}
                {pageSuccessMessage ? showError(MessageSeverity.SUCCESS, 'Success Message', pageSuccessMessage) : <></>}

                <div hidden={hideSpinner}>
                    <Spinner />
                </div>

                <div>
                    <DataTable value={healthData}
                        dataKey="Id"
                        selectionMode="single"
                        responsiveLayout="scroll"
                        responsive={true}
                        header={(e) => autoRefreshOption()}
                        onRowClick={(e) => showComments(e)}
                        emptyMessage="No Records found."
                        showGridlines={true}
                        stateStorage="session" stateKey="dt-leaderdashboard-session"
                    >
                        <Column field="PlatformName" header="Platform" body={platformBodyTemplate} alignHeader='center' style={{ textAlign: 'center', width: '160px' }} />
                        <Column field="HealthStatus" header="Status" body={getHealthStatusBody} alignHeader='center' style={{ textAlign: 'center', width: '160px' }} />
                        <Column field="ModifiedDateDisplay" header="Last Updated" alignHeader='center' style={{ textAlign: 'center', width: '160px' }} />
                        <Column field="edit" header="Action" alignHeader='center' body={showActionBtns} style={{ whiteSpace: 'nowrap', width: '400px', textAlign: 'center' }} />
                    </DataTable> <br />
                    {showCommentSection}

                    {ShowhealthDataLogs ?
                        <DataTable value={healthDataLogs}
                            dataKey="Id"
                            paginator={true}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            selectionMode="single"
                            responsiveLayout="scroll"
                            responsive={true}
                            header="HEALTH STATUS CHANGE LOGS"
                            emptyMessage="No Records found."
                            showGridlines={true}
                        >
                            <Column field="PlatformName" header="Platform" body={selectedPlatformhealthDataLogs} alignHeader='center' style={{ textAlign: 'center', width: '160px' }} />
                            <Column field="HealthStatus" header="Status" body={getHealthStatusBody} alignHeader='center' style={{ textAlign: 'center', width: '160px' }} />
                            <Column field="ModifiedBy" header="Action By" alignHeader='center' style={{ textAlign: 'center', width: '160px' }} />
                            <Column field="DisplayTimestamp" header="Action Datetime" alignHeader='center' style={{ textAlign: 'center', width: '160px' }} />
                            <Column field="Comments" header="Comments" alignHeader='center' style={{ textAlign: 'center', width: '160px' }} />
                        </DataTable> : <></>}

                </div>

                <Dialog header='Update Health Status' footer={saveFooter}
                    visible={updateHealthStatusDialogBoxVisible} width='400px' modal={true}
                    onHide={(e) => { setUpdateHealthStatusDialogBoxVisible(false); setpageErrorMessage(null); setpageSuccessMessage(null); }} maximizable={false}>
                    <div>
                        <table className='w-100'>
                            <tr>
                                <td>
                                    <label><b>Platform</b></label>
                                    <label>{selectedHealthData.PlatformName || ''}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Health Status</label>
                                    <Dropdown value={selectedHealthData.HealthStatus} options={healthStatusList} placeholder="HealthStatus" className="ui-column-filter"
                                        onChange={(e) => { setSelectedHealthData({ ...selectedHealthData, HealthStatus: e.target.value }) }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className='required-field'><b>Comments</b></label>
                                    <InputTextarea type='text' name='Comments' rows="4" cols="100" value={selectedHealthData.Comments}
                                        onChange={(e) => onHandleInputChange(e)} />
                                </td>
                            </tr>
                        </table>
                    </div>
                </Dialog>

            </div>
        </MsalAuthenticationTemplate>
    )
}

const WrappedLeadershipDashboard = withMsal(LeadershipDashboard);
export default withAITracking(reactPlugin, WrappedLeadershipDashboard);

