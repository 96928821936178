import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Panel } from 'primereact/panel';
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import EventLogComponent from './event-log/EventLogComponent';
import MessageQueueComponent from './message-queue/MessageQueueComponent';
import DatabaseConnectionComponent from './database-connection/DatabaseConnectionComponent';
import MessageVolumesComponent from './message-volumes/MessageVolumesComponent';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';

import "./NamespaceComponent.css";

class NamespaceComponent extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this._isForbidden = false;
        this.state = {
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    render() {

        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="namespaceContainer">
                    <BreadCrumbComponent {...this.props} />
                    <div className="component">
                        <Panel header={'Namespace: ' + this.props.match.params.namespace}>
                            <MessageQueueComponent {...this.props} />
                            {/* <MessageVolumesComponent filter="Message Volumes" {...this.props} /> */}
                            <EventLogComponent {...this.props} />
                            {/* <DatabaseConnectionComponent {...this.props} /> */}
                        </Panel>
                    </div>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}

//export default NamespaceComponent;
const WrappedNamespaceComponent = withMsal(NamespaceComponent);
export default withAITracking(reactPlugin, WrappedNamespaceComponent);