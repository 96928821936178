import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import MessageVolumesComponent from '../message-volumes/MessageVolumesComponent';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';

import "./MessageVolumesDetailComponent.css";

class MessageVolumesDetailComponent extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this._isForbidden = false;
        this.state = {
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    render() {

        return (
            <div className="messageVolumesDetailContainer">
                <BreadCrumbComponent {...this.props} />
                <div className="component">
                    <Panel header={'Message Volumes Detail: ' + this.props.match.params.namespace}>
                        <MessageVolumesComponent filter="Services" {...this.props} />
                        <MessageVolumesComponent filter="Operations" {...this.props} />
                        <MessageVolumesComponent filter="Processes" {...this.props} />
                    </Panel>
                </div>
            </div>
        );
    }
}

//export default MessageVolumesDetailComponent;
export default withAITracking(reactPlugin, MessageVolumesDetailComponent);