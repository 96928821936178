import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { Message } from 'primereact/message';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { CustomHieAlertService } from './CustomHieAlertService';
import { ActionGroupService } from '../actionGroup/ActionGroupService';
import { AutoComplete } from 'primereact/autocomplete';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { InputSwitch } from 'primereact/inputswitch';
import Spinner from '../Spinner'
//import '../AdminComponent.css';

class EditCustomHieAlertComponent extends Component {
    constructor() {
        super();
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            selectedActionGroups: null,
            filteredActionGroups: null,
            autoCompleteReadOnly: false,
            disableNameSpaceDropDown: true,
            disableInterfaceDropDown: true,
            hideSpinner: false,
            customHieAlert: {
                AlertActionRuleId: 0
                , AlertType: 0
                , ConfigName: []
                , ServiceNowGroup: null
                , CreatedBy: null
                , CreatedDate: null
                , DeletedDate: null
                , DeveloperSupportSoptext: null
                , Id: 0
                , Instance: []
                , InvokeVoip: null
                , MatchFilter: null
                , MetricType: 0 //metric type id of event log is 9
                , ModifiedBy: null
                , ModifiedDate: null
                , Name: null
                , NameSpace: []
                , LogType: []
                , NotificationBody: null
                , NotificationSubject: null
                , Priority: 0
                , Status: false
                , GroupBy: false
                , PHI: false
                , TicketMetaDataId: null
                , TriggerEmail: false
                , TriggerReport: false
                , TriggerSms: null
                , TriggerTeamsMsg: null
                , LookBackTime: 60
                , ResendFrequency: 0
            },
            alertTypeList: [
                { label: 'Warning', value: 1 },
                { label: 'Error', value: 2 },
                { label: 'Information', value: 3 }
            ],
            priorityList: [
                { label: 'P1', value: 1 },
                { label: 'P2', value: 2 },
                { label: 'P3', value: 3 },
                { label: 'P4', value: 4 }
            ],
            eventLogType: [
                { label: 'Error', value: '2' },
                { label: 'Warning', value: '3' },
                { label: 'Info', value: '4' },
                { label: 'Alert', value: '6' },
            ],
            timePeriodOptions: [
                { label: '5 minutes', value: 5 },
                { label: '15 minutes', value: 15 },
                { label: '30 minutes', value: 30 },
                { label: '1 hour', value: 60 },
                { label: '6 hours', value: 360 },
                { label: '12 hours', value: 720 },
                { label: '1 day', value: 1440 },
                { label: '7 days', value: 10080 },
                { label: '15 days', value: 21600 },
                { label: '30 days', value: 43200 },
            ],
            actiongroups: [],
            servicenowgroups: [],
            hieInstances: [],
            productions: [],
            productionItems: [],
            confirmEditVisible: false,
        }
        this.INTERFACE_DROPDOWN_LIMIT = global.INTERFACE_DROPDOWN_LIMIT
        this.customAlertService = new CustomHieAlertService();
        this.actionGrpService = new ActionGroupService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateEditCustomAlertField = this.validateEditCustomAlertField.bind(this);
        this.searchActionGroup = this.searchActionGroup.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getActionGroupsData();
    }

    getCustomHieAlertRuleById() {
        this.customAlertService.getCustomHieAlertRuleById(this.props.match.params.id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted && d.status === 200) {
                        if (d.body.Id === 0) {
                            this.setState({ pageErrorMessage: 'Custom alert not found or had been deleted.', hideSpinner: true });
                            return;
                        }
                        this.setState({
                            customHieAlert: d.body,
                            selectedActionGroups: d.body.ActionGroupList
                        });
                        if (this.state.customHieAlert.LogType.some(x => x === 'ALL')) {
                            this.setState({ customHieAlert: { ...this.state.customHieAlert, LogType: this.state.eventLogType.map(x => x.value) } })
                        }
                        if (this.state.customHieAlert.NotificationSubject && this.state.customHieAlert.NotificationSubject.split(' - ').length > 1) {
                            let [first, ...rest] = this.state.customHieAlert.NotificationSubject.split(' - ')
                            let containsPHI = first === "PHI"
                            if (containsPHI)
                                this.setState({ customHieAlert: { ...this.state.customHieAlert, PHI: containsPHI, NotificationSubject: rest.join(' - ') } })
                        }
                        this.getHIEInstances();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getActionGroupsData() {
        this.setState({ hideSpinner: false, servicenowgroups: [{ label: 'NONE', value: '' }], pageErrorMessage: null, pageSuccessMessage: null })
        this.actionGrpService.getActionGroups(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else if (d.status === 200) {
                    if (this._isMounted) {
                        this.setState({ actiongroups: d.body.ActionGroups });
                        d.body.ServiceNowGroups.map((item) => (this.state.servicenowgroups.push({ label: item.Name, value: item.Name })))
                        this.getCustomHieAlertRuleById();
                    }
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong !!!', hideSpinner: true });
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getHIEInstances() {
        //this.setState({ hieInstances: [{ label: 'ALL', value: 'ALL' }], pageErrorMessage: null, pageSuccessMessage: null })
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.customAlertService.getHIEInstances("CACHE;GCP", this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.hieInstances.push({ label: item.ServerInstance, value: item.ServerInstance })));
                        if (this.state.customHieAlert.Instance && this.state.customHieAlert.Instance.length > 0) {

                            if (this.state.customHieAlert.Instance.some(x => x === 'ALL')) {
                                this.setState({ customHieAlert: { ...this.state.customHieAlert, Instance: this.state.hieInstances.map(x => x.value) } })
                                this.getProductions(['ALL']);
                            }
                            else
                                this.getProductions(this.state.customHieAlert.Instance);
                        }
                        else
                            this.setState({ hideSpinner: true })
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getProductions(instance) {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

        this.customAlertService.getProductions(instance, "CACHE;GCP", this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.productions.push({ label: item.Namespace, value: item.Namespace })));
                        if (this.state.customHieAlert.NameSpace && this.state.customHieAlert.NameSpace.length > 0) {
                            if (this.state.customHieAlert.NameSpace.some(x => x === 'ALL')) {
                                this.setState({ customHieAlert: { ...this.state.customHieAlert, NameSpace: this.state.productions.map(x => x.value) } })
                                this.getProductionItems(['ALL'], ['ALL'])
                            }
                            else
                                this.getProductionItems(this.state.customHieAlert.Instance, this.state.customHieAlert.NameSpace)
                        }
                        else
                            this.setState({ hideSpinner: true, disableNameSpaceDropDown: false });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getProductionItems(instance, nameSpace) {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })
        this.customAlertService.getProductionItems(instance, nameSpace, "CACHE;GCP", this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.productionItems.push({ label: item.Name, value: item.Name })));
                        this.setState({ hideSpinner: true, disableInterfaceDropDown: false, disableNameSpaceDropDown: false });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    arIntVals = ['AlertType', 'MetricType', 'Priority', 'ResendFrequency'];
    arBoolVals = ['Status', 'TriggerEmail'];

    handleInputChange(event) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null });
        let found = false;
        for (let i = 0; i < this.arBoolVals.length; i++) {
            if (event.target.name === this.arBoolVals[i]) {
                found = true;
                this.setState({
                    customHieAlert: {
                        ...this.state.customHieAlert, //using spread operator to initialize object with existing props
                        [event.target.name]: event.target.checked
                    }
                })
                break;
            }
        }
        for (let i = 0; i < this.arIntVals.length; i++) {
            if (event.target.name === this.arIntVals[i]) {
                found = true;
                this.setState({
                    customHieAlert: {
                        ...this.state.customHieAlert, //using spread operator to initialize object with existing props
                        [event.target.name]: parseInt(event.target.value, 10)
                    }
                })
                break;
            }
        }

        if (!found) {
            this.setState({
                customHieAlert: {
                    ...this.state.customHieAlert, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    async validateEditCustomAlertField(customHieAlert, onSave = false) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, confirmEditVisible: false })
        if (this.state.selectedActionGroups && this.state.selectedActionGroups.length > 0) {
            let alertGroupId = this.state.selectedActionGroups.map(a => a.Id)
            customHieAlert.ActionGroupIds = alertGroupId.join(",")
            this.setState({ customHieAlert: { ...this.state.customHieAlert, ActionGroupIds: alertGroupId.join(",") } })
        }
        else {
            customHieAlert.ActionGroupIds = ''
        }
        if (onSave) {
            var alertNameText = /^[^'"]*$/.test(customHieAlert.Name);
            var notificationSubjectText = /^[^'"]*$/.test(customHieAlert.NotificationSubject);
            var matchFilterText = /^[^"]*$/.test(customHieAlert.MatchFilter);

            if (customHieAlert.Name === null || customHieAlert.Name === '') {
                this.setState({ pageErrorMessage: 'Alert Rule Name is required.' });
                return false;
            }
            else if (customHieAlert.Name.trim().length > 100) {
                this.setState({ pageErrorMessage: 'Alert Rule Name cannot be more than 100 characters.' })
                return false;
            }
            else if (!alertNameText) {
                this.setState({ pageErrorMessage: "Alert Rule Name should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (customHieAlert.AlertType === null || customHieAlert.AlertType === 0) {
                this.setState({ pageErrorMessage: 'Alert Type is required' })
                return false;
            }
            else if ((customHieAlert.Priority === null || customHieAlert.Priority === 0)
                && customHieAlert.AlertType === 2) {
                this.setState({ pageErrorMessage: 'Priority is required' })
                return false;
            }
            else if (customHieAlert.NotificationSubject === null || customHieAlert.NotificationSubject === '') {
                this.setState({ pageErrorMessage: 'Notification Subject is required.' });
                return false;
            }
            else if (customHieAlert.NotificationSubject.trim().length > 100) {
                this.setState({ pageErrorMessage: 'Notification Subject cannot be more than 100 characters.' })
                return false;
            }
            else if (!notificationSubjectText) {
                this.setState({ pageErrorMessage: "Notification Subject should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (customHieAlert.MatchFilter === null || customHieAlert.MatchFilter === '') {
                this.setState({ pageErrorMessage: 'Match Filter is required.' });
                return false;
            }
            else if (!matchFilterText) {
                this.setState({ pageErrorMessage: "Match Filter should not contain double (\") quotes." })
                return false;
            }
            else if (customHieAlert.ActionGroupIds === null || customHieAlert.ActionGroupIds === '') {
                this.setState({ pageErrorMessage: 'Atleast one action group is required.' });
                return false;
            }
            else if (customHieAlert.ResendFrequency === null || customHieAlert.ResendFrequency <= 0) {
                this.setState({ pageErrorMessage: 'Alert Frequency is required.' });
                return false;
            }
            else if (customHieAlert.LookBackTime === null || customHieAlert.LookBackTime <= 0) {
                this.setState({ pageErrorMessage: 'LookBack Period is required.' });
                return false;
            }
            else if (customHieAlert.Status && !customHieAlert.TriggerEmail && !customHieAlert.TriggerReport) {
                this.setState({ pageErrorMessage: 'Trigger type is required.' });
                return false;
            }
            else {
                this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
                this.UpdateCustomHieAlertRule(customHieAlert)
            }
        }
        else {
            const propName = customHieAlert.target.name;
            const value = customHieAlert.target.value;
            switch (propName) {
                case 'Name':
                case 'NotificationSubject':
                case 'MatchFilter':
                    if (value === '' || value === null || value <= 0) {
                        this.setState({ pageErrorMessage: propName + ' is required.' });
                        return false;
                    }
                default:
                    break;
            }
        }
    }

    UpdateCustomHieAlertRule(customHieAlert) {
        this.setState({ hideSpinner: false, pageErrorMessage: '', pageSuccessMessage: '' })

        //set ['ALL'] if more than 1 & all instances and namespaces are selected 
        customHieAlert.LogType = customHieAlert && customHieAlert.LogType.length === this.state.eventLogType.length ? ['ALL'] : customHieAlert.LogType
        customHieAlert.Instance = customHieAlert && customHieAlert.Instance.length === this.state.hieInstances.length ? ['ALL'] : customHieAlert.Instance
        customHieAlert.NameSpace = customHieAlert && customHieAlert.NameSpace.length > 1 && customHieAlert.NameSpace.length === this.state.productions.length ? ['ALL'] : customHieAlert.NameSpace
        customHieAlert.NotificationSubject = customHieAlert.PHI ? `PHI - ${customHieAlert.NotificationSubject}` : customHieAlert.NotificationSubject
        let body = JSON.stringify(customHieAlert);

        this.customAlertService.editCustomHieAlertRule(this.props.match.params.id, body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else if (this._isMounted && d.status === 200) {
                    if (d.body.Id > 0) {
                        this.setState({ pageSuccessMessage: 'Changes saved successfully.', hideSpinner: true })
                    }
                    else {
                        this.setState({ pageErrorMessage: 'Rule save failed.', hideSpinner: true });
                    }
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
                }
            }
        ).catch(e => {
            this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
            console.log(e)
        });
    }

    onAlertTypeChange = (alertType) => {
        if (alertType !== null && alertType !== undefined) {
            this.setState({
                customHieAlert: {
                    ...this.state.customHieAlert, AlertType: alertType.value, Priority: 0, ServiceNowGroup: null,
                }, pageErrorMessage: null, pageSuccessMessage: null
            })
        }
    }

    onPriorityChange = (priority) => {
        if (priority !== null && priority !== undefined) {
            this.setState({ customHieAlert: { ...this.state.customHieAlert, Priority: priority.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    //HM-1093
    onLogTypeChange = (logType) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (logType !== null && logType !== undefined) {
            this.setState({
                customHieAlert: { ...this.state.customHieAlert, LogType: logType.value }
            })
        }
    }

    onInstanceChange = (instance) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (instance !== null && instance !== undefined) {

            let metrictype = 9 //HM-947 - this is set based upon the instance selection
            if (instance.value && instance.value.length === 1) {
                metrictype = instance.value[0].includes('GCP') ? 201 : 9
            }

            this.setState({
                customHieAlert: { ...this.state.customHieAlert, Instance: instance.value, NameSpace: [], ConfigName: [], MetricType: metrictype },
                productions: [], productionItems: [], disableNameSpaceDropDown: true, disableInterfaceDropDown: true
            })

            if (instance.value !== null && instance.value !== undefined && instance.value !== "" && instance.value.length > 0) {
                //get all namespaces if all instances are selected
                if (instance.value.length === this.state.hieInstances.length)
                    this.getProductions(['ALL'])
                else
                    this.getProductions(instance.value);
            }
            else
                this.setState({ productions: [], productionItems: [], disableNameSpaceDropDown: true, disableInterfaceDropDown: true });
        }
    }

    onNameSpaceChange = (nameSpace) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        if (nameSpace !== null && nameSpace !== undefined) {

            this.setState({
                customHieAlert: { ...this.state.customHieAlert, NameSpace: nameSpace.value, ConfigName: [] },
                productionItems: [], disableInterfaceDropDown: true
            })

            if (nameSpace.value !== null && nameSpace.value !== undefined && nameSpace.value !== "" && nameSpace.value.length > 0) {
                //get all interfaces if all instances and namespaces are selected
                if (this.state.customHieAlert.Instance.length === this.state.hieInstances.length && nameSpace.value.length === this.state.productions.length)
                    this.getProductionItems(['ALL'], ['ALL'])
                else
                    this.getProductionItems(this.state.customHieAlert.Instance, nameSpace.value);
            }
            else
                this.setState({ productionItems: [], disableInterfaceDropDown: true });
        }
    }

    onInterfaceNameChange = (interfaceName) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        if (interfaceName.value && interfaceName.value.length > this.INTERFACE_DROPDOWN_LIMIT) {
            this.setState({ pageErrorMessage: "Max " + this.INTERFACE_DROPDOWN_LIMIT + " can be selected" })
            return;
        }

        if (interfaceName !== null && interfaceName !== undefined) {
            this.setState({ customHieAlert: { ...this.state.customHieAlert, ConfigName: interfaceName.value } })
        }
    }

    onServiceNowGrpChange = (group) => {
        if (group !== null && group !== undefined) {
            this.setState({ customHieAlert: { ...this.state.customHieAlert, ServiceNowGroup: group.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    onResendFrequencyChange = (e) => {
        if (e !== null && e !== undefined) {

            if (e.value < this.state.customHieAlert.LookBackTime)
                this.setState({ pageErrorMessage: "The value for 'Alert Frequency' should be equal to or greater than the Lookback period. ", pageSuccessMessage: null })
            else
                this.setState({ customHieAlert: { ...this.state.customHieAlert, ResendFrequency: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    onLookBackPeriodChange = (lookBackPeriod) => {
        if (lookBackPeriod !== null && lookBackPeriod !== undefined) {
            if (this.state.customHieAlert.TriggerReport && lookBackPeriod.value < 60)
                this.setState({ customHieAlert: { ...this.state.customHieAlert, LookBackTime: 60, ResendFrequency: 60 }, pageErrorMessage: "The minimun value for 'LookBackTime' is 1 hour in case of 'Trigger Report'.", pageSuccessMessage: null })
            else
                this.setState({ customHieAlert: { ...this.state.customHieAlert, ResendFrequency: 0, LookBackTime: lookBackPeriod.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    onStatusSwitchChange = (e) => {
        this.setState({ customHieAlert: { ...this.state.customHieAlert, Status: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    onGroupBySwitchChange = (e) => {
        this.setState({ customHieAlert: { ...this.state.customHieAlert, GroupBy: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    onPHISwitchChange = (e) => {
        this.setState({ customHieAlert: { ...this.state.customHieAlert, PHI: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    onTriggerOptionChange = (e) => {
        if (e.value === "email")
            this.setState({ customHieAlert: { ...this.state.customHieAlert, TriggerEmail: e.checked, TriggerReport: false }, pageErrorMessage: null, pageSuccessMessage: null })
        else
            this.setState({ customHieAlert: { ...this.state.customHieAlert, Priority: 0, ResendFrequency: 0, AlertType: 3, ServiceNowGroup: null, TriggerEmail: false, TriggerReport: e.checked, LookBackTime: this.state.customHieAlert.LookBackTime < 60 ? 60 : this.state.customHieAlert.LookBackTime }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    searchActionGroup(event) {
        if (this.state.selectedActionGroups && this.state.selectedActionGroups.length >= global.ACTION_GROUP_LIMIT) {
            this.setState({ autoCompleteReadOnly: true, filteredActionGroups: null, pageErrorMessage: "You can add up to " + global.ACTION_GROUP_LIMIT + " action groups to each alert rule." })
        }
        else {
            setTimeout(() => {
                let filteredActionGroups;
                if (!event.query.trim().length) {
                    filteredActionGroups = [...this.state.actiongroups];
                }
                else {
                    filteredActionGroups = this.state.actiongroups.filter((actiongrp) => {
                        return actiongrp.Name.toLowerCase().match(event.query.toLowerCase());
                    });
                }
                this.setState({ filteredActionGroups });
            }, 250);
            this.setState({ pageErrorMessage: null, pageSuccessMessage: null, autoCompleteReadOnly: false })
        }
    }

    confirmEditFooter = (
        <div>
            <Button label='Ok' onClick={() => this.validateEditCustomAlertField(this.state.customHieAlert, true)} />
            <Button label='Cancel' onClick={e => this.setState({ confirmEditVisible: false, pageErrorMessage: null, pageSuccessMessage: null })} className='p-button-secondary' />
        </div>
    );

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="actiongroupContainer">

                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">EDIT CUSTOM ALERT RULE</div>
                        </header>
                        {this.state.customHieAlert.TriggerReport ?
                            <div><Message severity="info" text="The default value for 'Alert Type' is Information for 'Trigger Report' option." /><br /><br /></div> : <></>}
                        <div className="card">
                            <table className='w-100'>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Alert Rule Name</b></label>
                                        <label>{this.state.customHieAlert.Name || ''}</label>
                                    </td>
                                    <td>
                                        {this.state.customHieAlert.TriggerReport ?
                                            <div>
                                                <table border="0">
                                                    <tr>
                                                        <td style={{ width: '33%' }}>
                                                            <label><b>Enable</b></label>
                                                            <InputSwitch checked={this.state.customHieAlert.Status} onChange={this.onStatusSwitchChange}
                                                                tooltip={this.state.customHieAlert.Status ? 'YES' : 'NO'} />
                                                        </td>
                                                        <td style={{ width: '33%' }}>
                                                            <label><b>Contains PHI ?</b>
                                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                                    data-pr-tooltip={"Mark this as YES, if your error text contain any PHI data."} data-pr-position="top"
                                                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                                            <InputSwitch checked={this.state.customHieAlert.PHI} onChange={this.onPHISwitchChange}
                                                                tooltip={this.state.customHieAlert.PHI ? 'YES' : 'NO'} />
                                                        </td>
                                                        <td style={{ width: '33%' }}>
                                                            <label><b>Distinct Records</b>
                                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                                    data-pr-tooltip={"All the event logs will be grouped by Instance, Namespace & Session id to get distinct records."} data-pr-position="top"
                                                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                                            <InputSwitch checked={this.state.customHieAlert.GroupBy} onChange={this.onGroupBySwitchChange}
                                                                tooltip={this.state.customHieAlert.GroupBy ? 'YES' : 'NO'} />
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div> :
                                            <div>
                                                <table border="0">
                                                    <tr>
                                                        <td style={{ width: '50%' }}>
                                                            <label><b>Enable</b></label>
                                                            <InputSwitch checked={this.state.customHieAlert.Status} onChange={this.onStatusSwitchChange}
                                                                tooltip={this.state.customHieAlert.Status ? 'YES' : 'NO'} />
                                                        </td>
                                                        <td style={{ width: '50%' }}>
                                                            <label><b>Contains PHI ?</b>
                                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                                    data-pr-tooltip={"Mark this as YES, if your error text contain any PHI data."} data-pr-position="top"
                                                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                                            <InputSwitch checked={this.state.customHieAlert.PHI} onChange={this.onPHISwitchChange}
                                                                tooltip={this.state.customHieAlert.PHI ? 'YES' : 'NO'} />
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        <label><b>Trigger</b></label>
                                        <span className="flex flex-wrap gap-3">
                                            <label><RadioButton inputId="triggeremail" name="triggeremail" value="email" onChange={this.onTriggerOptionChange} checked={this.state.customHieAlert.TriggerEmail} /> Email</label>
                                            <label><RadioButton inputId="triggerreport" name="triggerreport" value="report" onChange={this.onTriggerOptionChange} checked={this.state.customHieAlert.TriggerReport} /> Report</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Alert Type</b></label>
                                        <Dropdown disabled={this.state.customHieAlert.TriggerReport} style={{ width: '100%' }} className="ui-column-filter" placeholder="Alert Type" value={this.state.customHieAlert.AlertType}
                                            options={this.state.alertTypeList} onChange={this.onAlertTypeChange} />
                                    </td>
                                    <td>
                                        <label className='required-field'><b>Priority</b></label>
                                        <Dropdown disabled={this.state.customHieAlert.AlertType !== 2 || this.state.customHieAlert.TriggerReport} style={{ width: '100%' }} className="ui-column-filter" placeholder="Priority" value={this.state.customHieAlert.Priority}
                                            options={this.state.priorityList} onChange={this.onPriorityChange} />
                                    </td>
                                    <td>
                                        <label><b>Log Type</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip={"If none selected, by default all options are considered."} data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" placeholder="Log Type" value={this.state.customHieAlert.LogType}
                                            options={this.state.eventLogType} onChange={this.onLogTypeChange} maxSelectedLabels={0} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label><b>Instance</b></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.customHieAlert.Instance}
                                            options={this.state.hieInstances} onChange={this.onInstanceChange} maxSelectedLabels={3} placeholder="Select Instance" />
                                    </td>
                                    <td>
                                        <label><b>NameSpace</b></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.customHieAlert.NameSpace}
                                            options={this.state.productions} onChange={this.onNameSpaceChange} disabled={this.state.disableNameSpaceDropDown}
                                            maxSelectedLabels={1} placeholder="Select NameSpace" />
                                    </td>
                                    <td>
                                        <label><b>Interface Name</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip={"Max " + this.INTERFACE_DROPDOWN_LIMIT + " can be selected"} data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.customHieAlert.ConfigName}
                                            options={this.state.productionItems} onChange={this.onInterfaceNameChange} disabled={this.state.disableInterfaceDropDown}
                                            maxSelectedLabels={1} placeholder="Select Interface" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Match Filter</b></label>
                                        <InputTextarea type='text' name='MatchFilter' value={this.state.customHieAlert.MatchFilter} rows={5} cols={30}
                                            onChange={this.handleInputChange} onBlur={this.validateEditCustomAlertField} />
                                    </td>
                                    <td>
                                        <label className='required-field'><b>Notification Subject</b></label>
                                        <InputText type='text' name='NotificationSubject' value={this.state.customHieAlert.NotificationSubject || ''}
                                            onChange={this.handleInputChange} onBlur={this.validateEditCustomAlertField} />
                                        <div><br /></div>
                                        <label className='required-field'><b>Alert Frequency</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip="Denotes the frequency(in minutes) of the reminder alert. Mentioned value is system default." data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                        </label>
                                        <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Select Frequency" value={this.state.customHieAlert.ResendFrequency}
                                            options={this.state.timePeriodOptions} onChange={this.onResendFrequencyChange} />
                                    </td>
                                    <td>
                                        <label htmlFor="ServiceNowGroup" name='ServiceNowGroup'><b>ServiceNow Group</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip="If provided, an incident will be created in servicenow & assigned to selected group. Use 'NONE' option to not create an incident in servicenow." data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                        </label>
                                        <Dropdown disabled={this.state.customHieAlert.AlertType !== 2 || this.state.customHieAlert.TriggerReport} style={{ width: '100%' }} className="ui-column-filter" placeholder="Select group" value={this.state.customHieAlert.ServiceNowGroup}
                                            options={this.state.servicenowgroups} onChange={this.onServiceNowGrpChange} />
                                        <div><br /></div>
                                        <label className='required-field'><b>Lookback period</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip="This is the lookback period, or the time period to look back at each time the data is checked. For example, every 5 minute you’ll be looking at the past 1 hour." data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                        </label>
                                        <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Select Timeperiod" value={this.state.customHieAlert.LookBackTime}
                                            options={this.state.timePeriodOptions} onChange={this.onLookBackPeriodChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='3'>
                                        <span className='required-field'><b>Action Groups</b></span>
                                        <Button label='' icon='pi pi-plus' tooltip='Create Action Group' onClick={() => window.open('/admin/alert/actiongroup', '_blank')} />
                                        <span className="p-fluid">
                                            <AutoComplete tooltip='Search for available action group' tooltipOptions={{ position: 'top' }} value={this.state.selectedActionGroups} suggestions={this.state.filteredActionGroups} completeMethod={this.searchActionGroup} field="Name" multiple
                                                onChange={(e) => this.setState({ pageErrorMessage: '', pageSuccessMessage: '', selectedActionGroups: e.value })} readonly={this.state.autoCompleteReadOnly} aria-label="actiongroups" />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Button label='Save' onClick={() => this.setState({ confirmEditVisible: true, pageErrorMessage: null, pageSuccessMessage: null })} /> &nbsp;
                                        <Button label='Cancel' onClick={() => this.props.history.push('/admin/custom/hie/alert/rules')} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <Dialog header='Edit custom alert rule' footer={this.confirmEditFooter}
                        visible={this.state.confirmEditVisible} width='400px' modal={true}
                        onHide={e => this.setState({ confirmEditVisible: false, pageErrorMessage: null, pageSuccessMessage: null })} maximizable={false}>
                        <div>
                            Are you sure you want to edit the rule <b>{this.state.customHieAlert.Name}</b>?
                        </div>
                    </Dialog>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}

const WrappedEditCustomHieAlertComponent = withMsal(EditCustomHieAlertComponent);
export default withAITracking(reactPlugin, WrappedEditCustomHieAlertComponent);