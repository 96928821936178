import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { ActionGroupService } from '../actionGroup/ActionGroupService';
import { AlertProcessingService } from './AlertProcessingService';
import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from 'primereact/dialog';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import Spinner from '../Spinner'

//HM-940 - UI to create notification blackout rule
class EditAlertProcessingRuleComponent extends Component {
    constructor() {
        super();
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            disableNameSpaceDropDown: true,
            disableInterfaceDropDown: true,
            disaableMetricTypeDropDown: true,
            disableServerInstanceDropDown: true,
            hideSpinner: true,
            customAlertProcessingRequest: {
                AlertActionRuleId: 0,
                Name: null,
                MetricType: [],
                SourceSystem: null,
                ServerInstance: [],
                NameSpace: [],
                Interface: [],
                Priority: 0,
                TriggerEmail: false,
                Description: '',
                ActionGroupIds: '',
            },
            serverInstances: [],
            productions: [],
            productionItems: [],
            metrictypes: [],
            sourcesystem: [
                { value: "CACHE", label: "CACHE" },
                { value: "GALILEO", label: "GALILEO" },
                { value: "AZURE", label: "AZURE" }
            ],
            priorityList: [
                { label: 'P1', value: 1 },
                { label: 'P2', value: 2 },
                { label: 'P3', value: 3 },
                { label: 'P4', value: 4 }
            ],
            selectedServerInstance: [],
            selectedProductions: [],
            selectedProductionItems: [],
            existingRuleFound: [],
            disableExistingRuleDialogVisible: false,
            existingRuleDialogFooter: null,
            existingRuleDialogBody: null,
            selectedActionGroups: null,
        }
        this.INTERFACE_DROPDOWN_LIMIT = global.INTERFACE_DROPDOWN_LIMIT
        this.alertProcessingService = new AlertProcessingService();
        this.actionGrpService = new ActionGroupService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateAlertProcessingRequest = this.validateAlertProcessingRequest.bind(this);

        //calender date intialization
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        this.minDate = new Date();
        //this.minDate.setHours(0);
        this.minDate.setMonth(month);
        this.minDate.setFullYear(year);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getAlertProcessingById();
        this.getActionGroupsData();
    }

    getActionGroupsData() {
        this.setState({ servicenowgroups: [{ label: 'NONE', value: '' }], pageErrorMessage: null, pageSuccessMessage: null })
        this.actionGrpService.getActionGroups(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({ actiongroups: d.body.ActionGroups });
                        d.body.ServiceNowGroups.map((item) => (this.state.servicenowgroups.push({ label: item.Name, value: item.Id })))
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getAlertProcessingById() {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, hideSpinner: false })
        this.alertProcessingService.getAlertProcessingById(this.props.match.params.id, true, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({
                            customAlertProcessingRequest: {
                                AlertActionRuleId: d.body.AlertActionRuleId,
                                Name: d.body.Name,
                                SourceSystem: d.body.SourceSystem,
                                MetricType: d.body.MetricType.map(item => item),
                                ServerInstance: d.body.ServerInstance.map(item => item),
                                NameSpace: d.body.NameSpace.map(item => item),
                                Interface: d.body.Interface.map(item => item),
                                TriggerEmail: d.body.TriggerEmail,
                                Description: d.body.Description,
                                Priority: d.body.Priority
                            },
                            selectedServerInstance: d.body.ServerInstance.map(item => item),
                            selectedProductions: d.body.NameSpace.map(item => item),
                            selectedProductionItems: d.body.Interface.map(item => item),
                            selectedActionGroups: d.body.ActionGroupList,
                            pageSuccessMessage: this.props.match.params.iscreate === 'true' ? 'Rule saved successfully.' : '',
                            pageErrorMessage: ''
                        });
                        this.props.match.params.iscreate = false; // set false to not show duplicate success message on Save() action
                        this.getMetricType(d.body.SourceSystem);
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getMetricType(sourceSystem) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.alertProcessingService.getMetricType(sourceSystem, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.metrictypes.push({ label: item.label, value: item.value })));
                        this.setState({ disaableMetricTypeDropDown: false });
                        this.getHIEInstances(sourceSystem);
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getHIEInstances(sourceSystem) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.alertProcessingService.getHIEInstances(sourceSystem, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.serverInstances.push({ label: item.ServerInstance, value: item.ServerInstance })));
                        this.setState({ hideSpinner: true, disableServerInstanceDropDown: false })
                        if (sourceSystem !== "GALILEO" && this.state.selectedServerInstance.length > 0) {
                            this.getProductions(this.state.selectedServerInstance);
                        }
                        else
                            this.setState({});
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getProductions(instance) {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

        this.alertProcessingService.getProductions(instance, this.state.customAlertProcessingRequest.SourceSystem, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.productions.push({ label: item.Namespace, value: item.Namespace })));
                        this.setState({ disableNameSpaceDropDown: false });
                        if (this.state.selectedProductions.length > 0) {
                            this.getProductionItems(this.state.selectedServerInstance, this.state.selectedProductions);
                        }
                        else
                            this.setState({ hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getProductionItems(instance, nameSpace) {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })
        this.alertProcessingService.getProductionItems(instance, nameSpace, this.state.customAlertProcessingRequest.SourceSystem, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.productionItems.push({ label: item.Name, value: item.Name })));
                        this.setState({ hideSpinner: true, disableInterfaceDropDown: false });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    arIntVals = ['MetricType', 'ServerInstance', 'NameSpace', 'Interface'];
    arBoolVals = ['TriggerEmail'];

    handleInputChange(event) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        let found = false;
        for (let i = 0; i < this.arBoolVals.length; i++) {
            if (event.target.name === this.arBoolVals[i]) {
                found = true;
                this.setState({
                    customAlertProcessingRequest: {
                        ...this.state.customAlertProcessingRequest, //using spread operator to initialize object with existing props
                        [event.target.name]: event.target.checked
                    }
                })
                break;
            }
        }
        for (let i = 0; i < this.arIntVals.length; i++) {
            if (event.target.name === this.arIntVals[i]) {
                found = true;
                this.setState({
                    customAlertProcessingRequest: {
                        ...this.state.customAlertProcessingRequest, //using spread operator to initialize object with existing props
                        [event.target.name]: parseInt(event.target.value, 10)
                    }
                })
                break;
            }
        }

        if (!found) {
            this.setState({
                customAlertProcessingRequest: {
                    ...this.state.customAlertProcessingRequest, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    async validateAlertProcessingRequest(customAlertProcessingRequest, onSave = false, isCreate = false) {
        this.setState({ pageErrorMessage: '', pageSuccessMessage: '' })

        if (this.state.selectedActionGroups && this.state.selectedActionGroups.length > 0) {
            let alertGroupId = this.state.selectedActionGroups.map(a => a.Id)
            customAlertProcessingRequest.ActionGroupIds = alertGroupId.join(",")
            this.setState({ customAlertProcessingRequest: { ...this.state.customAlertProcessingRequest, ActionGroupIds: alertGroupId.join(",") } })
        }
        else {
            customAlertProcessingRequest.ActionGroupIds = ''
        }

        if (onSave) {
            var DescriptionText = /^[^'"]*$/.test(customAlertProcessingRequest.Description);
            var RuleNameText = /^[^'"]*$/.test(customAlertProcessingRequest.Name);

            if (customAlertProcessingRequest.ServerInstance.length === 0) {
                this.setState({ pageErrorMessage: 'Server/Instance is required' })
                return false;
            }
            else if (customAlertProcessingRequest.MetricType.length === 0) {
                this.setState({ pageErrorMessage: 'Metric Type is required.' });
                return false;
            }
            else if (customAlertProcessingRequest.Priority === null || customAlertProcessingRequest.Priority === 0) {
                this.setState({ pageErrorMessage: 'Priority is required' })
                return false;
            }
            else if (this.state.customAlertProcessingRequest.SourceSystem === null || this.state.customAlertProcessingRequest.SourceSystem === '') {
                this.setState({ pageErrorMessage: 'Please select source system to load metric type & server/instance list' });
                return false;
            }
            else if (customAlertProcessingRequest.Name === null || customAlertProcessingRequest.Name === '') {
                this.setState({ pageErrorMessage: 'Rule Name is required.' });
                return false;
            }
            else if (customAlertProcessingRequest.Name.trim().length < global.COMMENT_BOX_MIN_LENGTH || customAlertProcessingRequest.Name.trim().length > 100) {
                this.setState({ pageErrorMessage: 'Rule Name should be between ' + global.COMMENT_BOX_MIN_LENGTH + ' & 100 characters.' })
                return false;
            }
            else if (!RuleNameText) {
                this.setState({ pageErrorMessage: "Rule Name should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (customAlertProcessingRequest.Description === null || customAlertProcessingRequest.Description === '') {
                this.setState({ pageErrorMessage: 'Description is required.' });
                return false;
            }
            else if (!DescriptionText) {
                this.setState({ pageErrorMessage: "Description should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (customAlertProcessingRequest.Description.trim().length < global.COMMENT_BOX_MIN_LENGTH) {
                this.setState({ pageErrorMessage: 'Description should be atleast of ' + global.COMMENT_BOX_MIN_LENGTH + ' characters.' })
                return false;
            }

            if (isCreate) {
                this.setState({ pageErrorMessage: "", pageSuccessMessage: "", hideSpinner: false })
                console.log(customAlertProcessingRequest)
                this.EditAlertProcessingRequest(customAlertProcessingRequest)
            }
        }
        else {
            const propName = customAlertProcessingRequest.target.name;
            const value = customAlertProcessingRequest.target.value;
            switch (propName) {
                case 'Name':
                    if (value === '' || value === null || value <= 0) {
                        this.setState({ pageErrorMessage: 'Rule Name is required.' });
                        return false;
                    }
                case 'Description':
                    if (value === '' || value === null || value <= 0) {
                        this.setState({ pageErrorMessage: propName + ' is required.' });
                        return false;
                    }
                default:
                    break;
            }
        }
    }

    EditAlertProcessingRequest(customAlertProcessingRequest) {
        this.setState({ pageErrorMessage: '', pageSuccessMessage: '' })
        let body = JSON.stringify(customAlertProcessingRequest);
        this.alertProcessingService.editAlertProcessingRequest(this.state.customAlertProcessingRequest.AlertActionRuleId, body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                }
                else if (this._isMounted) {
                    if (d.status === 200 && d.body > 0) {
                        this.setState({ hideSpinner: true, pageSuccessMessage: 'Rule saved successfully.'})
                        this.clearCustomAlertProcessingRequest()
                        this.getAlertProcessingById()
                    }
                    else if (d.status === 400 && d.body.error) {
                        this.setState({ pageErrorMessage: d.body.error, hideSpinner: true });
                    }
                    else if (d.status === 400 && d.body.length > 0) {
                        this.setState({ existingRuleFound: d.body, hideSpinner: true });
                        this.showExistingRuleCombination(d.body);
                    }
                    else {
                        this.setState({ pageErrorMessage: 'Rule creation failed.', hideSpinner: true });
                    }
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
                }
            }
        ).catch(e => {
            this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
            console.log(e)
        });
    }

    clearCustomAlertProcessingRequest() {
        this.setState({
            customAlertProcessingRequest: {
                Name: '',
                MetricType: [],
                ServerInstance: [],
                NameSpace: [],
                Interface: [],
                Priority: 0,
                TriggerEmail: false,
                Description: '',
                ActionGroupIds: '',
                SourceSystem: 'CACHE'
            },
            metrictypes: [],
            serverInstances: [],
            productions: [],
            productionItems: [],
            disaableMetricTypeDropDown: true,
            disableServerInstanceDropDown: true,
            disableInterfaceDropDown: true,
            disableNameSpaceDropDown: true,
            pageErrorMessage: null,
            pageSuccessMessage: null,
        });
    }

    showExistingRuleCombination(existingRuleFound) {
        var filterExistingRuleFound = existingRuleFound.filter((x) => x.AlertActionRuleId !== this.state.customAlertProcessingRequest.AlertActionRuleId)

        if (existingRuleFound && existingRuleFound.length > 0) {
            this.setState({
                disableExistingRuleDialogVisible: true,
                existingRuleDialogFooter: <><Button label='OK' onClick={() => window.open('/admin/alert/processing/rule/' + existingRuleFound[0].AlertActionRuleId, "_blank")} />
                    <Button label='Cancel' onClick={() => this.setState({ disableExistingRuleDialogVisible: false })} className='p-button-secondary' /></>,
                existingRuleDialogBody: <>We have found an existing rule <b>{filterExistingRuleFound[0].Name}</b> with similar combination.<br />Similar combination rule cannot be re-create.<br />Click <b>OK</b> to view/edit.</>
            })
        }
    }

    onMetricTypeChange = (event) => {
        this.setState({
            pageSuccessMessage: null, pageErrorMessage: null,
            customAlertProcessingRequest: { ...this.state.customAlertProcessingRequest, MetricType: event.value }
        });
    }

    onSourceSystemChange = (event) => {
        this.setState({
            serverInstances: [], metrictypes: [], hideSpinner: false,
            customAlertProcessingRequest: {
                ...this.state.customAlertProcessingRequest, SourceSystem: event.value, MetricType: [], ServerInstance: [], NameSpace: [], Interface: [], Priority: 0
            }, disableNameSpaceDropDown: true, disableInterfaceDropDown: true
        })
        this.getMetricType(event.value);
    }

    onInstanceChange = (instance) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (instance !== null && instance !== undefined) {

            let serverInstancesList = instance.value.map((id) => this.state.serverInstances.find((el) => el.value == id).label);

            this.setState({
                customAlertProcessingRequest: { ...this.state.customAlertProcessingRequest, ServerInstance: instance.value, NameSpace: [], Interface: [] },
                productions: [], productionItems: [], disableNameSpaceDropDown: true, disableInterfaceDropDown: true,
                selectedServerInstance: serverInstancesList
            })


            if (instance.value !== null && instance.value !== undefined && instance.value !== "" && instance.value.length > 0
                && this.state.customAlertProcessingRequest.SourceSystem !== "GALILEO") {
                //get all namespaces if all instances are selected
                if (instance.value.length > 1 && instance.value.length === this.state.serverInstances.length)
                    this.getProductions(['ALL'])
                else
                    this.getProductions(serverInstancesList);
            }
            else
                this.setState({ productions: [], productionItems: [], disableNameSpaceDropDown: true, disableInterfaceDropDown: true });
        }
    }


    onNameSpaceChange = (nameSpace) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        if (nameSpace !== null && nameSpace !== undefined) {

            let nameSpaceList = nameSpace.value.map((id) => this.state.productions.find((el) => el.value == id).label);

            this.setState({
                customAlertProcessingRequest: { ...this.state.customAlertProcessingRequest, NameSpace: nameSpace.value, Interface: [] },
                productionItems: [], disableInterfaceDropDown: true, selectedProductions: nameSpaceList
            })
            if (nameSpace.value !== null && nameSpace.value !== undefined && nameSpace.value !== "" && nameSpace.value.length > 0) {
                //get all interfaces if all instances and namespaces are selected
                if (nameSpace.value.length > 1 && this.state.customAlertProcessingRequest.ServerInstance.length === this.state.serverInstances.length && nameSpace.value.length === this.state.productions.length)
                    this.getProductionItems(['ALL'], ['ALL'])
                else
                    this.getProductionItems(this.state.selectedServerInstance, nameSpaceList);
            }
            else
                this.setState({ productionItems: [], disableInterfaceDropDown: true });
        }
    }

    onInterfaceNameChange = (interfaceName) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (interfaceName.value && interfaceName.value.length > this.INTERFACE_DROPDOWN_LIMIT) {
            this.setState({ pageErrorMessage: "Max " + this.INTERFACE_DROPDOWN_LIMIT + " can be selected" })
            return;
        }

        if (interfaceName !== null && interfaceName !== undefined) {
            this.setState({ customAlertProcessingRequest: { ...this.state.customAlertProcessingRequest, Interface: interfaceName.value } })
        }
    }

    onPriorityChange = (priority) => {
        if (priority !== null && priority !== undefined) {
            this.setState({ customAlertProcessingRequest: { ...this.state.customAlertProcessingRequest, Priority: priority.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    onInputSwitchChange = (e) => {
        this.setState({ customAlertProcessingRequest: { ...this.state.customAlertProcessingRequest, TriggerEmail: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    searchActionGroup = (event) => {
        if (this.state.selectedActionGroups && this.state.selectedActionGroups.length >= global.ACTION_GROUP_LIMIT) {
            this.setState({ autoCompleteReadOnly: true, filteredActionGroups: null, pageErrorMessage: "You can add up to " + global.ACTION_GROUP_LIMIT + " action groups to each alert rule." })
        }
        else {
            setTimeout(() => {
                let filteredActionGroups;
                if (!event.query.trim().length) {
                    filteredActionGroups = [...this.state.actiongroups];
                }
                else {
                    filteredActionGroups = this.state.actiongroups.filter((actiongrp) => {
                        return actiongrp.Name.toLowerCase().match(event.query.toLowerCase());
                    });
                }
                this.setState({ filteredActionGroups });
            }, 250);
            this.setState({ pageErrorMessage: null, pageSuccessMessage: null, autoCompleteReadOnly: false })
        }
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="alterProcessingContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}


                    <Dialog header="MATCH FOUND" footer={this.state.existingRuleDialogFooter}
                        visible={this.state.disableExistingRuleDialogVisible} width='400px' modal={true}
                        onHide={e => this.setState({ disableExistingRuleDialogVisible: false })} maximizable={false}>
                        <div>
                            {this.state.existingRuleDialogBody}
                        </div>
                    </Dialog>

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">EDIT ALERT PROCESSING RULE
                                &nbsp;<Tooltip target=".custom-target-icon" />
                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                    data-pr-tooltip="These rule is applied above the base rules (threshold rule, custom alert & azure alert rule)."
                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                            </div>
                        </header>
                        <div className="card">
                            <table style={{ tablelayout: 'fixed', width: '100%' }}>
                                <colgroup>
                                    <col style={{ width: '50%' }} />
                                    <col style={{ width: '50%' }} />
                                </colgroup>
                                <tr>
                                    <td colSpan={2}>
                                        <label><b>Enable</b></label>
                                        <InputSwitch checked={this.state.customAlertProcessingRequest.TriggerEmail} onChange={this.onInputSwitchChange}
                                            tooltip={this.state.customAlertProcessingRequest.TriggerEmail ? 'YES' : 'NO'} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>{this.state.customAlertProcessingRequest.SourceSystem === "AZURE" ? "Resource Group" : "Server/Instance"}</b></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.customAlertProcessingRequest.ServerInstance}
                                            options={this.state.serverInstances} onChange={this.onInstanceChange} maxSelectedLabels={3} disabled={this.state.disableServerInstanceDropDown}
                                            placeholder="Select Server/Instance" />
                                    </td>
                                    <td>
                                        <label><b>{this.state.customAlertProcessingRequest.SourceSystem === "AZURE" ? "Resources" : "Namespace"}</b></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.customAlertProcessingRequest.NameSpace}
                                            options={this.state.productions} onChange={this.onNameSpaceChange} disabled={this.state.disableNameSpaceDropDown}
                                            maxSelectedLabels={1} placeholder="Select NameSpace" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label><b>{this.state.customAlertProcessingRequest.SourceSystem === "AZURE" ? "Resource Items" : "Interface Name"}</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip={"Max " + this.INTERFACE_DROPDOWN_LIMIT + " can be selected"} data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.customAlertProcessingRequest.Interface}
                                            options={this.state.productionItems} onChange={this.onInterfaceNameChange} disabled={this.state.disableInterfaceDropDown}
                                            maxSelectedLabels={1} placeholder={this.state.customAlertProcessingRequest.SourceSystem === "AZURE" ? "Select resource items" : "Select interface name"} />
                                    </td>
                                    <td>
                                        <label className='required-field'><b>Priority</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip={"This value will replace the base rule priority value."} data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                        <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Priority" value={this.state.customAlertProcessingRequest.Priority}
                                            options={this.state.priorityList} onChange={this.onPriorityChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Metric Type</b></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.customAlertProcessingRequest.MetricType}
                                            options={this.state.metrictypes} onChange={this.onMetricTypeChange}
                                            maxSelectedLabels={1} placeholder="Select Metric Type" />
                                    </td>
                                    <td>
                                        <label className='required-field'><b>Source</b></label>
                                        <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Source System" Id={this.state.customAlertProcessingRequest.SourceSystem}
                                            value={this.state.customAlertProcessingRequest.SourceSystem} options={this.state.sourcesystem} onChange={this.onSourceSystemChange} filter />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Rule Name</b></label>
                                        <InputText style={{ width: '100%' }} type='text' name='Name' value={this.state.customAlertProcessingRequest.Name}
                                            onChange={this.handleInputChange} onBlur={this.validateAlertProcessingRequest} />
                                    </td>
                                    <td>
                                        <label className='required-field'><b>Description</b></label>
                                        <InputTextarea rows="3" style={{ width: '100%' }} type='text' name='Description' value={this.state.customAlertProcessingRequest.Description}
                                            onChange={this.handleInputChange} onBlur={this.validateAlertProcessingRequest} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='2'>
                                        <span className='required-field'><b>Action Groups</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip={"This will be get append with the base rule action groups."} data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i></span>
                                        <Button label='' icon='pi pi-plus' tooltip='Create Action Group' onClick={() => window.open('/admin/alert/actiongroup', '_blank')} />
                                        <span className="p-fluid">
                                            <AutoComplete tooltip='Search for available action group' tooltipOptions={{ position: 'top' }} value={this.state.selectedActionGroups} suggestions={this.state.filteredActionGroups} completeMethod={this.searchActionGroup} field="Name" multiple
                                                onChange={(e) => this.setState({ pageErrorMessage: '', pageSuccessMessage: '', selectedActionGroups: e.value })} readonly={this.state.autoCompleteReadOnly} aria-label="actiongroups" />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Button label='Save' onClick={() => this.validateAlertProcessingRequest(this.state.customAlertProcessingRequest, true, true)} /> &nbsp;
                                        <Button label='Cancel' onClick={() => this.props.history.push('/admin/alert/processing/rules')} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedEditAlertProcessingRuleComponent = withMsal(EditAlertProcessingRuleComponent);
export default withAITracking(reactPlugin, WrappedEditAlertProcessingRuleComponent);