import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import Spinner from '../Spinner'
import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { IACLService } from './IACLService';
import { ProdItemSettingService } from '../prodItemSettings/ProdItemSettingSerivce'
import WrappedIACLComponentDetail from "./IACLComponentDetail";
import { uuid } from 'uuidv4';
import "./IACL.css"

//HDO-5469 - PI 2024 Q3 - Integrate IACL UI features into the Monitoring tool
class IACLComponent extends Component {
    constructor() {
        super();
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            hideSpinner: false,
            IACLInterfaceList: [],
            PlatformList: [],
            NamespaceList: [],
            InterfaceTypeList: [],
            SiteNameList: [],
            ApplicationNameList: [],
            Priority: [1, 2, 3, 4],
            IACLSearchSelectedNamespace: JSON.parse(sessionStorage.getItem("IACLSearchSelectedNamespace")) ?? '',
            IACLSearchSelectedPlatform: JSON.parse(sessionStorage.getItem("IACLSearchSelectedPlatform")) ?? '',
            IACLSearchSelectedSitename: JSON.parse(sessionStorage.getItem("IACLSearchSelectedSitename")) ?? '',
            IACLSearchSelectedPriority: JSON.parse(sessionStorage.getItem("IACLSearchSelectedPriority")) ?? '',
            IACLSearchSelectedType: JSON.parse(sessionStorage.getItem("IACLSearchSelectedType")) ?? '',
            IACLSearchSelectedApplicationname: JSON.parse(sessionStorage.getItem("IACLSearchSelectedApplicationname")) ?? '',
            IACLSearchInterfacename: JSON.parse(sessionStorage.getItem("IACLSearchInterfacename")) ?? '',
            // add affected search applicationName
            IACLSearchAffectedApplicationName: sessionStorage.getItem("IACLSearchAffectedApplicationName") ?? '',
            IACLSelectedRecord: null,
            IACLRecordByIdData: null,
            IACLRecordByIdSiteName: [],
            IACLRecordByIdMessageType: [],
            DataTableColumn: 'NameSpace',
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
        }
        sessionStorage.removeItem('dt-iaclhome-session')
        this.IACLService = new IACLService();
        this.prodItemSettingService = new ProdItemSettingService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getProductions(['ALL'])
    }

    getProductions = (instance) => {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

        this.prodItemSettingService.getProductions(instance, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.NamespaceList.push({ label: item.Namespace, value: item.Namespace })));
                        this.getIaclSearchParameters();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    getIaclSearchParameters = () => {
        this.IACLService.getIaclSearchParameters(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.PlatformList.map((item) => (this.state.PlatformList.push({ label: item.Name, value: item.Id })));
                        d.body.SiteNameList.map((item) => (this.state.SiteNameList.push({ label: item.Name, value: item.Id })));
                        d.body.ApplicationNameList.map((item) => (this.state.ApplicationNameList.push({ label: item.Name, value: item.Id })));
                        d.body.InterfaceTypeList.map((item) => (this.state.InterfaceTypeList.push({ label: item.Name, value: item.Id })));
                        this.setState({ pageSuccessMessage: '', pageErrorMessage: '' });
                        this.getIACLHomeData();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getIACLHomeData = () => {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '', IACLRecordByIdData: null, IACLRecordByIdMessageType: null, IACLRecordByIdSiteName: null });
        let requestPayload = {
            Platform: '' + this.state.IACLSearchSelectedPlatform + '',
            ApplicationId: '' + this.state.IACLSearchSelectedApplicationname + '',
            Namespace: '' + this.state.IACLSearchSelectedNamespace + '',
            InterfaceName: this.state.IACLSearchInterfacename,
            Type: '' + this.state.IACLSearchSelectedType + '',
            Priority: '' + this.state.IACLSearchSelectedPriority + '',
            SiteName: '' + this.state.IACLSearchSelectedSitename + '',
            AffectedApps: '' + this.state.IACLSearchAffectedApplicationName + ''
        }
        this.IACLService.getIACLHomeData(JSON.stringify(requestPayload), this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({ IACLInterfaceList: d.body, hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    exportIACLHomeData = () => {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '', IACLRecordByIdData: null, IACLRecordByIdMessageType: null, IACLRecordByIdSiteName: null });
        let requestPayload = {
            Platform: '' + this.state.IACLSearchSelectedPlatform + '',
            ApplicationId: '' + this.state.IACLSearchSelectedApplicationname + '',
            Namespace: '' + this.state.IACLSearchSelectedNamespace + '',
            InterfaceName: this.state.IACLSearchInterfacename,
            Type: '' + this.state.IACLSearchSelectedType + '',
            Priority: '' + this.state.IACLSearchSelectedPriority + '',
            SiteName: '' + this.state.IACLSearchSelectedSitename + '',
            AffectedApps: '' + this.state.IACLSearchAffectedApplicationName + ''
        }

        this.IACLService.exportIACLHomeData(JSON.stringify(requestPayload), this.props.msalContext)
            .then((response) => response.blob())
            .then(
                (d) => {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(new Blob([d]));
                    link.download = uuid() + ".xlsx";
                    document.body.appendChild(link);
                    link.click(); //Force download
                    link.parentNode.removeChild(link);
                    this.setState({ hideSpinner: true, pageSuccessMessage: "File exported successfully !!!" });
                }
            ).catch(e => {
                console.log(e)
            });
    }

    getIaclRecordById = (Id) => {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

        this.IACLService.getIaclRecordById(Id, false, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({
                            IACLRecordByIdData: d.body,
                            MessageTypes: d.body.MessageType ? d.body.MessageType.map((item) => (this.state.IACLRecordByIdMessageType.push({ label: item.MessageType, value: item.MessageType }))) : [],
                            SiteNames: d.body.SiteName ? d.body.SiteName.map((item) => (this.state.IACLRecordByIdSiteName.push({ label: item.Description, value: item.Description }))) : [],
                            hideSpinner: true
                        })
                    }
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    onGlobalFilterChange = (event, filtersKey) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter = (filtersKey) => {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)}
                            placeholder="Global Search" style={{ width: '100%' }} size={100} />
                    </span>
                </div>
            </div>
        )
    }

    handleDropDownInputChange = (event) => {
        sessionStorage.setItem([event.target.name], event.value);
        this.setState({ [event.target.name]: event.value, pageErrorMessage: null, pageSuccessMessage: null })
    }

    handleMultiDropDownInputChange = (event) => {
        sessionStorage.setItem([event.target.name], JSON.stringify(event.value));
        this.setState({ [event.target.name]: event.value, pageErrorMessage: null, pageSuccessMessage: null })
    }
    handleInputChange = (event) => {
        sessionStorage.setItem([event.target.name], event.target.value);
        this.setState({ [event.target.name]: event.target.value, pageErrorMessage: null, pageSuccessMessage: null })
    }
    onPlatformDropDownChange = (event) => {
        if ((event.value.length === 1 && event.value[0] === 5)) {
            //specific to OPENLINK Platform selection only
            this.setState({
                DataTableColumn: 'ParentInterface',
                IACLInterfaceList: [],
            }, () => this.handleMultiDropDownInputChange(event))
        }
        else {
            this.setState({
                DataTableColumn: 'NameSpace',
            }, () => this.handleMultiDropDownInputChange(event))
        }
    }

    onRowSelectionChange = (event) => {
        this.setState({
            IACLRecordByIdData: null,
            IACLRecordByIdSiteName: [],
            IACLRecordByIdMessageType: [],
            pageErrorMessage: null,
            pageErrorMessage: null,
            IACLSelectedRecord: event.value
        })

        if (event !== null && event.value !== null && event.value.Id > 0) {
            this.getIaclRecordById(event.value.Id)
        }
    }

    onRowDoubleClick = (event) => {
        let redirectUrl = '/iacl/component/detail/' + event.data.Id
        this.props.history.push(redirectUrl)
    }

    clearSearchParams = async () => {
        sessionStorage.clear();
        this.setState({
            pageErrorMessage: '',
            pageSuccessMessage: '',
            IACLSearchSelectedNamespace: '',
            IACLSearchSelectedPlatform: '',
            IACLSearchSelectedSitename: '',
            IACLSearchSelectedPriority: '',
            IACLSearchSelectedType: '',
            IACLSearchSelectedApplicationname: '',
            IACLSearchAffectedApplicationName: '',
            IACLSearchInterfacename: '',
            IACLSelectedRecord: null,
            IACLRecordByIdData: null,
            IACLRecordByIdSiteName: [],
            IACLRecordByIdMessageType: [],
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            }
        }, () => this.getIACLHomeData());
    }

    documentOpenAction = () => {
        return (
            <React.Fragment>
                <Button icon="pi pi-folder-open" className="p-button-rounded p-button-primary p-mr-2" tooltip="Open" />
            </React.Fragment>
        );
    }

    panelHeaderTemplate = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
                <span className={titleClassName}>
                    {options.props.id}
                </span>
            </div>
        )
    }

    //this is invoked from the child component - IACLComponentDetail.js by a handler
    onDeleteIACLRecord = (IACLRecordId) => {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

        this.IACLService.deleteIACLRecord(IACLRecordId, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (d.status === 200) {
                        this.setState({
                            pageSuccessMessage: 'IACL record deleted successfully !',
                            hideSpinner: true
                        })
                        this.getIACLHomeData()
                    }
                    else {
                        this.setState({
                            pageErrorMessage: 'Failed to delete IACL record !',
                            hideSpinner: true
                        })
                    }
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="iaclcontainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div className="grid">
                        <div className="col-5 align-items-center justify-content-center">
                            <Panel id='Search' toggleable headerTemplate={this.panelHeaderTemplate} collapsed={true}>
                                <div className="card" style={{ alignItems: 'center' }}>
                                    <div className="float-container">
                                        <div className="float-child">
                                            <MultiSelect options={this.state.PlatformList} value={this.state.IACLSearchSelectedPlatform} name='IACLSearchSelectedPlatform'
                                                style={{ width: '200px' }} onChange={(e) => this.onPlatformDropDownChange(e)} maxSelectedLabels={0} placeholder="Platform" className="multiselect-custom"
                                            />
                                        </div>
                                        <div className="float-child">
                                            <MultiSelect options={this.state.NamespaceList} value={this.state.IACLSearchSelectedNamespace} name='IACLSearchSelectedNamespace'
                                                style={{ width: '200px' }} filter onChange={(e) => this.handleMultiDropDownInputChange(e)} maxSelectedLabels={0} placeholder="NameSpace" className="multiselect-custom"
                                            />
                                        </div>
                                        <div className="float-child">
                                            <MultiSelect options={this.state.SiteNameList} value={this.state.IACLSearchSelectedSitename} name='IACLSearchSelectedSitename'
                                                style={{ width: '200px' }} filter onChange={(e) => this.handleMultiDropDownInputChange(e)} maxSelectedLabels={0} placeholder="Sitename" className="multiselect-custom"
                                            />
                                        </div>
                                    </div>

                                    <div className="float-container">
                                        <div className="float-child">
                                            <MultiSelect options={this.state.ApplicationNameList} value={this.state.IACLSearchSelectedApplicationname} name='IACLSearchSelectedApplicationname'
                                                style={{ width: '200px' }} filter onChange={(e) => this.handleMultiDropDownInputChange(e)} maxSelectedLabels={0} placeholder="Application Name" className="multiselect-custom"
                                            />
                                        </div>
                                        <div className="float-child">
                                            <MultiSelect options={this.state.InterfaceTypeList} value={this.state.IACLSearchSelectedType} name='IACLSearchSelectedType'
                                                style={{ width: '200px' }} onChange={(e) => this.handleMultiDropDownInputChange(e)} maxSelectedLabels={0} placeholder="Type" className="multiselect-custom"
                                            />
                                        </div>
                                        <div className="float-child">
                                            <MultiSelect options={this.state.Priority} value={this.state.IACLSearchSelectedPriority} name='IACLSearchSelectedPriority'
                                                style={{ width: '200px' }} onChange={(e) => this.handleMultiDropDownInputChange(e)} maxSelectedLabels={0} placeholder="Priority" className="multiselect-custom"
                                            />
                                        </div>
                                        
                                    </div>
                                    <div>
                                    <InputText type="search" name='IACLSearchAffectedApplicationName' value={this.state.IACLSearchAffectedApplicationName} placeholder='Affected Apps' onChange={(e) => this.handleInputChange(e)}/>
                                    </div>
                                    <div className="float-container">
                                        <div className="float-child">
                                            <Button label='Search' onClick={() => this.getIACLHomeData()} />
                                        </div>
                                        <div className="float-child">
                                            <Button label='Export' onClick={() => this.exportIACLHomeData()} />
                                        </div>
                                        <div className="float-child">
                                            <Button label='Clear' onClick={this.clearSearchParams} />
                                        </div>
                                        <div className="float-child">
                                            <Button type="button" icon="pi pi-plus" tooltip='New Interface' tooltipOptions={{ position: 'top' }} label="New" className="p-button-help" onClick={() => window.open('/iacl/component/create', '_blank')} /> &nbsp;
                                        </div>
                                    </div>
                                </div>
                            </Panel> <br />
                            <div className="p-fluid">
                                <DataTable
                                    dataKey="Id"
                                    value={this.state.IACLInterfaceList}
                                    paginator={true}
                                    rows={15}
                                    rowsPerPageOptions={[10, 15, 20, 35, 50]}
                                    filters={this.state.filters}
                                    filterDisplay="menu"
                                    responsiveLayout="scroll"
                                    header={this.globalSearchFilter('filters')}
                                    emptyMessage="No Records found."
                                    selection={this.state.IACLSelectedRecord}
                                    onSelectionChange={this.onRowSelectionChange}
                                    //onRowDoubleClick={this.onRowDoubleClick}
                                    selectionPageOnly showGridlines removableSort
                                    onFilter={(e) => this.setState({ filters: e.filters, pageErrorMessage: null, pageSuccessMessage: null })}
                                    stateStorage="session" stateKey="dt-iaclhome-session"
                                >
                                    <Column selectionMode="single" headerStyle={{ width: '3em' }} frozen></Column>
                                    <Column field="Id" header="#ID" style={{ textAlign: 'left', width: '60px' }} hidden />
                                    <Column field="PlatformName" header="Platform" style={{ textAlign: 'left' }} />
                                    <Column field="NameSpace" header={this.state.DataTableColumn} style={{ textAlign: 'left' }} />
                                    <Column field="Interface" header="Interface" style={{ textAlign: 'left' }} />
                                    <Column field="Priority" sortable header="Biz-hrs Priority" style={{ textAlign: 'left', width: '80px' }} />
                                    <Column field="AfterHoursPriority" sortable header="Off-hrs Priority" style={{ textAlign: 'left', width: '80px' }} />
                                </DataTable>
                            </div>
                        </div>

                        <div className="col-1 flex align-items-center justify-content-center">
                            <Divider layout="vertical">
                                <i className="pi pi-arrow-circle-right" style={{ 'fontSize': '2em' }}></i>
                            </Divider>
                        </div>

                        <div className="col-6 align-items-center justify-content-center">
                            {this.state.IACLRecordByIdData && this.state.IACLRecordByIdData.Id > 0 ?
                                <WrappedIACLComponentDetail IACLRecord={this.state.IACLRecordByIdData}
                                    IACLRecordMessageTypes={this.state.IACLRecordByIdMessageType}
                                    IACLRecordSiteNames={this.state.IACLRecordByIdSiteName}
                                    onDeleteIACLRecordHandler={this.onDeleteIACLRecord} /> : <></>}
                        </div>
                    </div>
                </div>
            </MsalAuthenticationTemplate >
        )
    }
}

const WrappedIACLComponent = withMsal(IACLComponent);
export default withAITracking(reactPlugin, WrappedIACLComponent);