import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { msalInstance } from '../../App';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { AdminService } from './AdminService';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import Spinner from '../Spinner';
import { AutoComplete } from 'primereact/autocomplete';

// TODO: LOGIN - GET TOKEN - CHECK TOKEN WHEN USING GetData
import './AdminComponent.css';

class EditThresholdComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            selectedActionGroups: null,
            filteredActionGroups: null,
            autoCompleteReadOnly: false,
            thresholdRule: {
                ActionGroupList: [],
                ActionGroups: null,
                AlertActionRuleId: 0,
                AlertDescription: null,
                AlertType: 0,
                AlertTypeName: null,
                EmailList: null,
                HighThresholdValue: null,
                ID: null,
                LowThresholdValue: null,
                MetricName: null,
                MetricType: 0,
                Name: null,
                NotificationBody: null,
                NotificationSubject: null,
                Priority: 0,
                ScenarioType: 0,
                Status: false,
                TriggerEmail: false,
                CreateJIRA: false,
                CreateServiceNow: false,
                ServiceNowGroup: null,
                JiraUserName: null,
                ResendFrequency: 0
            },
            adminRoles: [],
            actiongroups: [],
            servicenowgroups: [],
            hideSpinner: false,
            pageErrorMessage: null,
            pageSuccessMessage: null,
            hasReadRights: true,
            showMetricTypeInfo: true,
            alertTypeList: [
                { label: 'Warning', value: 1 },
                { label: 'Error', value: 2 },
                // { label: 'Information', value: 3 }
            ],
            priorityList: [
                { label: 'P1', value: 1 },
                { label: 'P2', value: 2 },
                { label: 'P3', value: 3 },
                { label: 'P4', value: 4 }
            ]
        };
        this.adminService = new AdminService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateThresholdRuleEditField = this.validateThresholdRuleEditField.bind(this);
        this.searchActionGroup = this.searchActionGroup.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getAdminRoles();
        this.getActionGroupsData();
    }

    getAdminRoles() {
        this.adminService.getAdminRoles(this.props.msalContext).then(
            (d) => {
                this.setState({ adminRoles: d });
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getRoleId() {
        var adminRoles = this.state.adminRoles;
        if (adminRoles && adminRoles.length > 0) {
            if (adminRoles.includes('Admin.Write'))
                return 2;
            if (adminRoles.includes('Admin.Read'))
                return 1;
        }
        return 0;
    }

    getActionGroupsData() {
        this.setState({ hideSpinner: false, servicenowgroups: [{ label: 'NONE', value: '' }], pageErrorMessage: null, pageSuccessMessage: null })
        this.adminService.getActionGroups(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else if (d.status === 200) {
                    if (this._isMounted) {
                        this.setState({ actiongroups: d.body.ActionGroups });
                        d.body.ServiceNowGroups.map((item) => (this.state.servicenowgroups.push({ label: item.Name, value: item.Name })))
                        this.getThresholdRulesById();
                    }
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong !!!', hideSpinner: true });
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getThresholdRulesById() {
        this.adminService.getThresholdRulesById(this.props.match.params.id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else if (d.status === 200) {
                    if (this._isMounted && d.status === 200) {
                        this.setState({ thresholdRule: d.body, selectedActionGroups: d.body.ActionGroupList, hideSpinner: true });
                    }
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong !!!', hideSpinner: true });
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    arIntVals = ['AlertType', 'MetricType', 'ScenarioType', 'HighThresholdValue', 'LowThresholdValue', 'Priority', 'ResendFrequency'];
    arBoolVals = ['Status', 'TriggerEmail', 'CreateJIRA', 'CreateServiceNow'];

    handleInputChange(event) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        let found = false;
        for (let i = 0; i < this.arBoolVals.length; i++) {
            if (event.target.name === this.arBoolVals[i]) {
                found = true;
                this.setState({
                    thresholdRule: {
                        ...this.state.thresholdRule, //using spread operator to initialize object with existing props
                        [event.target.name]: event.target.checked
                    }
                })
                break;
            }
        }
        for (let i = 0; i < this.arIntVals.length; i++) {
            if (event.target.name === this.arIntVals[i]) {
                found = true;
                this.setState({
                    thresholdRule: {
                        ...this.state.thresholdRule, //using spread operator to initialize object with existing props
                        [event.target.name]: parseInt(event.target.value, 10)
                    }
                })
                break;
            }
        }

        if (!found) {
            this.setState({
                thresholdRule: {
                    ...this.state.thresholdRule, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    async validateThresholdRuleEditField(thresholdRule, onSave = false) {
        this.setState({ pageErrorMessage: '', pageSuccessMessage: '' });
        if (this.state.selectedActionGroups && this.state.selectedActionGroups.length > 0) {
            let alertGroupId = this.state.selectedActionGroups.map(a => a.Id)
            thresholdRule.ActionGroupIds = alertGroupId.join(",")
            this.setState({ thresholdRule: { ...this.state.thresholdRule, ActionGroupIds: alertGroupId.join(",") } })
        }
        else {
            thresholdRule.ActionGroupIds = ''
        }
        if (onSave) {
            var notificationSubjectText = /^[^'"]*$/.test(thresholdRule.NotificationSubject);
            var alertDescription = /^[^'"]*$/.test(thresholdRule.AlertDescription);
            var validateServiceNowGrp = /[^a-zA-Z0-9 ]/.test(thresholdRule.ServiceNowGroup);
            var validateJiraUser = /[^a-zA-Z0-9]/.test(thresholdRule.JiraUserName);

            if (thresholdRule.LowThresholdValue === null || thresholdRule.LowThresholdValue === '' || thresholdRule.LowThresholdValue < 0) {
                this.setState({ pageErrorMessage: 'LowThresholdValue is required' });
                return false;
            }
            else if (thresholdRule.HighThresholdValue === null || thresholdRule.HighThresholdValue === '' || thresholdRule.HighThresholdValue < 0) {
                this.setState({ pageErrorMessage: 'HighThresholdValue is required' });
                return false;
            }
            else if ((thresholdRule.Priority === null || thresholdRule.Priority === '' || thresholdRule.Priority <= 0) && thresholdRule.AlertType === 2) {
                this.setState({ pageErrorMessage: 'Priority is required' });
                return false;
            }
            else if (thresholdRule.AlertDescription === null || thresholdRule.AlertDescription === '') {
                this.setState({ pageErrorMessage: 'AlertDescription is required' });
                return false;
            }
            else if (thresholdRule.AlertDescription.trim().length > 100) {
                this.setState({ pageErrorMessage: 'Alert Description cannot be more than 100 characters.' })
                return false;
            }
            else if (!alertDescription) {
                this.setState({ pageErrorMessage: "Alert Description should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (thresholdRule.NotificationSubject === null || thresholdRule.NotificationSubject === '') {
                this.setState({ pageErrorMessage: 'NotificationSubject is required' });
                return false;
            }
            else if (thresholdRule.NotificationSubject.trim().length > 100) {
                this.setState({ pageErrorMessage: 'Notification Subject cannot be more than 100 characters.' })
                return false;
            }
            else if (!notificationSubjectText) {
                this.setState({ pageErrorMessage: "Notification Subject should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (thresholdRule.ActionGroupIds === null || thresholdRule.ActionGroupIds === '') {
                this.setState({ pageErrorMessage: 'Atleast one action group is required.' });
                return false;
            }
            else if (validateServiceNowGrp) {
                this.setState({ pageErrorMessage: "Service Now group should not contain any special characters." })
                return false;
            }
            else if (validateJiraUser) {
                this.setState({ pageErrorMessage: "Jira assignee should not contain any space/special characters." })
                return false;
            }
            else if (!thresholdRule.ResendFrequency || thresholdRule.ResendFrequency === ""
                || thresholdRule.ResendFrequency === 0 || thresholdRule.ResendFrequency <= 4) {
                this.setState({ pageErrorMessage: "Alert Frequency should be greater than 4 minutes" })
                return false;
            }
            else {
                this.setState({ pageErrorMessage: '' });
                this.editThresholdRule(thresholdRule);
            }
        }
        else {
            const propName = thresholdRule.target.name;
            const value = thresholdRule.target.value;
            switch (propName) {
                case 'Priority':
                case 'AlertDescription':
                case 'NotificationSubject':
                    if (value === '' || value === null || value <= 0) {
                        this.setState({ pageErrorMessage: propName + ' is required' });
                        return false;
                    }
                case 'Name':
                    if (value === '' || value === null || value <= 0) {
                        this.setState({ pageErrorMessage: 'Alert Name is required' });
                        return false;
                    }
                case 'LowThresholdValue':
                case 'HighThresholdValue':
                    if (value === '' || value === null || value < 0) {
                        this.setState({ pageErrorMessage: propName + ' is required' });
                        return false;
                    }
                default:
                    break;
            }
        }
    }

    editThresholdRule(thresholdRule) {
        console.log(this._isMounted)
        this.setState({ hideSpinner: false, pageErrorMessage: '', pageSuccessMessage: '' })
        let body = JSON.stringify(thresholdRule);
        this.adminService.editThresholdRule(this.props.match.params.id, body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else if (this._isMounted && d.status === 200) {
                    if (d.body.ID) {
                        this.setState({ pageSuccessMessage: 'Changes saved successfully.', hideSpinner: true })
                    }
                    else {
                        this.setState({ pageErrorMessage: 'Rule save failed.', hideSpinner: true });
                    }
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
                }
            }
        ).catch(e => {
            this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
            console.log(e)
        });
    }

    searchActionGroup(event) {
        if (this.state.selectedActionGroups && this.state.selectedActionGroups.length >= global.ACTION_GROUP_LIMIT) {
            this.setState({ autoCompleteReadOnly: true, filteredActionGroups: null, pageErrorMessage: "You can add up to " + global.ACTION_GROUP_LIMIT + " action groups to each alert rule." })
        }
        else {
            setTimeout(() => {
                let filteredActionGroups;
                if (!event.query.trim().length) {
                    filteredActionGroups = [...this.state.actiongroups];
                }
                else {
                    filteredActionGroups = this.state.actiongroups.filter((actiongrp) => {
                        return actiongrp.Name.toLowerCase().match(event.query.toLowerCase());
                    });
                }
                this.setState({ filteredActionGroups });
            }, 250);
            this.setState({ pageErrorMessage: null, pageSuccessMessage: null, autoCompleteReadOnly: false })
        }
    }

    onAlertTypeChange = (alertType) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (alertType !== null && alertType !== undefined) {
            if (alertType.value === 1 || alertType.value === 3)
                this.setState({ thresholdRule: { ...this.state.thresholdRule, AlertType: alertType.value, Priority: 0 } })
            else
                this.setState({ thresholdRule: { ...this.state.thresholdRule, AlertType: alertType.value } })
        }
    }

    onPriorityChange = (priority) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (priority !== null && priority !== undefined) {
            this.setState({ thresholdRule: { ...this.state.thresholdRule, Priority: priority.value } })
        }
    }

    onServiceNowGrpChange = (group) => {
        if (group !== null && group !== undefined) {
            this.setState({ thresholdRule: { ...this.state.thresholdRule, ServiceNowGroup: group.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="editThresholdRuleContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div hidden={(this.getRoleId() == 0)}>
                        <header className="page-header">
                            <div className="page-header-title">EDIT THRESHOLD RULE</div>
                        </header>

                        <div className="card">
                            <table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td colSpan={1}>
                                            <Checkbox name='Status' inputId="Status" value="Active" onChange={this.handleInputChange} checked={this.state.thresholdRule.Status}></Checkbox>&nbsp;
                                            <label htmlFor="Status" className="p-checkbox-label d-inline"><b>Active</b></label>
                                        </td>
                                        <td>
                                            <Checkbox name='TriggerEmail' inputId="cb2" value="TriggerEmail" onChange={this.handleInputChange} checked={this.state.thresholdRule.TriggerEmail}></Checkbox>&nbsp;
                                            <label htmlFor="cb2" className="p-checkbox-label d-inline" name='TriggerEmail'><b>Trigger Email</b></label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label><b>Metric type</b>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip='Type of metric from source system.'
                                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                            </label>
                                            <span>{this.state.thresholdRule.MetricType}</span>
                                        </td>
                                        <td>
                                            <label><b>Metric name</b></label>
                                            <span>{this.state.thresholdRule.MetricName || 'N.A.'}</span>
                                        </td>
                                        <td>
                                            <label><b>Scenario Type</b>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip='If multiple alerts are needed, use an scenario type.'
                                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                            </label>
                                            <span>{this.state.thresholdRule.ScenarioType}</span>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td colSpan={1}>
                                            <label className='required-field'><b>Alert Description</b></label>
                                            <InputText type='text' name='AlertDescription' value={this.state.thresholdRule.AlertDescription}
                                                onChange={this.handleInputChange} onBlur={this.validateThresholdRuleEditField} />
                                        </td>
                                        <td>
                                            <label><b>Alert Name</b></label>
                                            <span>{this.state.thresholdRule.Name}</span>
                                        </td>
                                        <td>
                                            <label><b>Alert type</b> &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip='Warning - 1, Error - 2, Info - 3'
                                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                            </label>
                                            {//HM-779 - bug fix
                                                this.state.thresholdRule.AlertType === 3 ? <>{this.state.thresholdRule.AlertTypeName}</> :
                                                    <><Dropdown disabled={this.state.thresholdRule.AlertType === 3 ? true : false} style={{ width: '100%' }} className="ui-column-filter" placeholder="Alert Type" value={this.state.thresholdRule.AlertType}
                                                        options={this.state.alertTypeList} onChange={this.onAlertTypeChange} /></>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='1'>
                                            <label className='required-field'><b>Notification Subject</b></label>
                                            <InputText type='text' name='NotificationSubject' value={this.state.thresholdRule.NotificationSubject || ''} required
                                                onChange={this.handleInputChange} onBlur={this.validateThresholdRuleEditField} />
                                        </td>
                                        <td colSpan='1'>
                                            <label><b>Low Threshold Value</b></label>
                                            <InputText type='text' name='LowThresholdValue' value={this.state.thresholdRule.LowThresholdValue || 0} required
                                                onChange={this.handleInputChange} onBlur={this.validateThresholdRuleEditField} />
                                        </td>
                                        <td colSpan='1'>
                                            <label><b>High Threshold Value</b></label>
                                            <InputText type='text' name='HighThresholdValue' value={this.state.thresholdRule.HighThresholdValue || 0} required
                                                onChange={this.handleInputChange} onBlur={this.validateThresholdRuleEditField} />
                                        </td>
                                    </tr>
                                    {this.state.thresholdRule.AlertType === 2 ?
                                        <tr>
                                            <td>
                                                <label className='required-field'><b>Priority</b></label>
                                                <Dropdown disabled={this.state.thresholdRule.AlertType === 2 ? false : true} style={{ width: '100%' }} className="ui-column-filter" placeholder="Priority" value={this.state.thresholdRule.Priority}
                                                    options={this.state.priorityList} onChange={this.onPriorityChange} />
                                            </td>
                                            <td>
                                                <label htmlFor="ServiceNowGroup" name='ServiceNowGroup'><b>ServiceNow Group</b>
                                                    &nbsp;<Tooltip target=".custom-target-icon" />
                                                    <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                        data-pr-tooltip="If provided, an incident will be created in servicenow & assigned to selected group. Use 'NONE' option to not create an incident in servicenow." data-pr-position="right" data-pr-at="right+5 top"
                                                        data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                                </label>
                                                <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Select group" value={this.state.thresholdRule.ServiceNowGroup}
                                                    options={this.state.servicenowgroups} onChange={this.onServiceNowGrpChange} />
                                            </td>
                                            <td>
                                                <label htmlFor="JiraUserName" name='JiraUserName'><b>Jira Assignee</b>
                                                    &nbsp;<Tooltip target=".custom-target-icon" />
                                                    <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                        data-pr-tooltip="If provided, an issue will be created in jira & assigned to provided username." data-pr-position="right" data-pr-at="right+5 top"
                                                        data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                                </label>
                                                <InputText disabled={this.state.thresholdRule.AlertType === 2 ? false : true} type='text' name='JiraUserName'
                                                    value={this.state.thresholdRule.JiraUserName || ''} onChange={this.handleInputChange} onBlur={this.validateThresholdRuleEditField} />
                                            </td>
                                        </tr> : false}
                                    <tr>
                                        <td colSpan='2'>
                                            <span className='required-field'><b>Action Groups</b></span>
                                            <Button label='' icon='pi pi-plus' tooltip='Create Action Group' onClick={() => window.open('/admin/alert/actiongroup', '_blank')} />
                                            <span className="p-fluid">
                                                <AutoComplete tooltip='Search for available action group' tooltipOptions={{ position: 'top' }} value={this.state.selectedActionGroups} suggestions={this.state.filteredActionGroups}
                                                    completeMethod={this.searchActionGroup} field="Name" multiple onChange={(e) => {
                                                        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
                                                        this.setState({ selectedActionGroups: e.value })
                                                    }}
                                                    readonly={this.state.autoCompleteReadOnly} aria-label="actiongroups" />
                                            </span>
                                        </td>
                                        <td>
                                            <label className='required-field'><b>Alert Frequency</b>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip="Denotes the frequency(in minutes) of the reminder alert." data-pr-position="right" data-pr-at="right+5 top"
                                                    data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                            </label>
                                            <InputText type='text' name='ResendFrequency' value={this.state.thresholdRule.ResendFrequency || 0}
                                                onChange={this.handleInputChange} onBlur={this.validateThresholdRuleEditField} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Button label='Save' onClick={() => this.validateThresholdRuleEditField(this.state.thresholdRule, true)} /> &nbsp;
                                            <Button label='Cancel' onClick={() => this.props.history.push('/admin/threshold/rules')} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}
const WrappedEditThresholdComponent = withMsal(EditThresholdComponent);
export default withAITracking(reactPlugin, WrappedEditThresholdComponent);