import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { NotificationBlackoutService } from './NotificationBlackoutService';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import Spinner from "../Spinner";
import AlertMessage from "../alertmessage/AlertMessage"
import { MessageSeverity, FilterMatchMode, FilterOperator } from 'primereact/api';
import { NavLink } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import "./NotificationBlackout.css"

//HM-940 - UI to show all notification blackout rules
class NotificationBlackoutComponent extends Component {
    constructor() {
        super();
        this.WrappedActionGroupComponent = React.createRef();
        this._isMounted = false;
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            editActionGrpErrorMessage: '',
            notificationBlackoutRules: [],
            notificationBlackoutRulesCollasped: [],
            InstanceList: [],
            NamespaceList: [],
            DNMStatusList: ["ACTIVE", "INACTIVE", "EXPIRED"],
            hideSpinner: false,
            showpagination: false,
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'ServerInstance': { value: null, matchMode: FilterMatchMode.IN },
                'NameSpace': { value: null, matchMode: FilterMatchMode.IN },
                'Name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'InterfaceName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'Status': { value: ["ACTIVE"], matchMode: FilterMatchMode.IN }
            },
            globalFilterValue: '',
            showCollaspedView: true
        }
        this.notificationBlackoutService = new NotificationBlackoutService();
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.globalSearchFilter = this.globalSearchFilter.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getNotificationBlackoutRules();
    }

    getNotificationBlackoutRules() {
        this.notificationBlackoutService.getNotificationBlackoutRules(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({
                            notificationBlackoutRules: d.body,
                            InstanceList: [...new Set(d.body.map(x => x.ServerInstance).filter((item) => item !== null))].sort((a, b) => { return a > b ? 1 : -1 }),
                            NamespaceList: [...new Set(d.body.map(x => x.NameSpace).filter((item) => item !== null))].sort((a, b) => { return a > b ? 1 : -1 }),
                            hideSpinner: true, pageSuccessMessage: '', pageErrorMessage: ''
                        });
                        if (this.state.notificationBlackoutRules.length > 10) {
                            this.setState({ showpagination: true })
                        }
                        this.createCollaspedList(d.body)
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    createCollaspedList(array) {
        var result = array.filter(function (a) {
            var key = a.RuleId + '|' + a.Name + '|' + a.SourceSystem;
            if (!this[key]) {
                this[key] = true;
                return true;
            }
        }, Object.create(null));
        this.setState({ notificationBlackoutRulesCollasped: result })
    }

    //HM-935 - change to save the filter
    onGlobalFilterChange(event, filtersKey) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter(filtersKey) {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left" >
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                    <div>
                        <InputSwitch checked={this.state.showCollaspedView} onChange={(e) => { this.setState({ showCollaspedView: e.value }) }}
                            tooltip={this.state.showCollaspedView ? 'EXPAND VIEW' : 'COLLASPE VIEW'} tooltipOptions={{ position: 'top' }} />
                    </div>
                </div>
            </div>
        )
    }

    clearDataTableFilters = () => {
        this.setState({
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'ServerInstance': { value: null, matchMode: FilterMatchMode.IN },
                'NameSpace': { value: null, matchMode: FilterMatchMode.IN },
                'Name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'InterfaceName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'Status': { value: null, matchMode: FilterMatchMode.IN }
            }
        });
        sessionStorage.clear();
    }

    ruleNameTemplate = (rowData) => {
        let redirectUrl = '/notification/blackout/rule/detail/' + rowData.RuleId
        return <><NavLink key={rowData.RuleId} to={redirectUrl}><strong>{rowData.Name}</strong></NavLink></>
    }

    instanceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.InstanceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="ServerInstance"
        />;
    }

    namespaceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.NamespaceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="NameSpace" className="multiselect-custom"
        />;
    }

    statusFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.DNMStatusList}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            placeholder="Status" className="multiselect-custom"
        />;
    }

    statusBodyTemplate = (rowData) => {
        return <span className={`dnm-status-badge status-${rowData.Status.toLowerCase()}`}>{rowData.Status}</span>;
    }

    showNAIfNoData = (rowData, column) => {
        let value = rowData[column.field];

        if (value)
            return rowData[column.field];
        else
            return "N.A"
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="notificationBlackoutContainer">
                    <BreadCrumbComponent {...this.props} />

                    <AlertMessage ref={instance => { this.alertMsg = instance }} />
                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <div className='flex justify-content-between' style={{ marginBottom: '10px' }}>
                            <div className="page-header-title">DO NOT MONITOR RULES</div>
                            <Button label='' icon='pi pi-plus' tooltip='Create DNM Rule' tooltipOptions={{ position: 'left' }} onClick={() => window.open('/notification/blackout/rule/create', '_blank')} />
                        </div>
                        {this.state.showCollaspedView ? <>
                            <DataTable ref={(el) => this.dt = el}
                                value={this.state.notificationBlackoutRulesCollasped}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                dataKey="RuleId"
                                filters={this.state.filters}
                                filterDisplay="menu"
                                responsiveLayout="scroll"
                                header={this.globalSearchFilter('filters')}
                                showGridlines removableSort
                                emptyMessage="No Records found."
                                onFilter={(e) => this.setState({ filters: e.filters })} //HM-935 - change to save the filter
                                stateStorage="session" stateKey="dt-notificationblackout-session"
                            >
                                <Column hidden field="RuleId" header="RuleId" style={{ textAlign: 'left', minWidth: '80px' }} />
                                <Column field="Name" header="Rule Name" style={{ textAlign: 'left' }} filter showFilterOperator={false} body={this.ruleNameTemplate} />
                                <Column field="SourceSystem" header="Source System" style={{ textAlign: 'left' }} />
                                <Column field="DisplayExpiryDate" header="Expiry (EST)" style={{ textAlign: 'left' }} sortField="ExpiryDate" sortable />
                                <Column field="Status" header="Status" style={{ textAlign: 'left' }} filter filterPlaceholder='Status' filterElement={this.statusFilterTemplate} showFilterMatchModes={false} body={this.statusBodyTemplate} />
                                <Column field="DisplayCreatedDate" header="Created On" style={{ textAlign: 'left' }} sortField="CreatedDate" sortable />
                                <Column field="CreatedBy" header="Created By" style={{ textAlign: 'left' }} />
                                <Column field="DisplayModifiedDate" header="Modified On" style={{ textAlign: 'left' }} body={this.showNAIfNoData} sortField="ModifiedDate" sortable />
                                <Column field="ModifiedBy" header="Modified By" style={{ textAlign: 'left' }} body={this.showNAIfNoData} />
                            </DataTable></> :
                            <><DataTable ref={(el) => this.dt = el}
                                value={this.state.notificationBlackoutRules}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                dataKey="RuleId"
                                filters={this.state.filters}
                                filterDisplay="menu"
                                responsiveLayout="scroll"
                                header={this.globalSearchFilter('filters')}
                                showGridlines removableSort
                                emptyMessage="No Records found."
                                onFilter={(e) => this.setState({ filters: e.filters })} //HM-935 - change to save the filter
                                stateStorage="session" stateKey="dt-notificationblackout-session"
                            >
                                <Column hidden field="RuleId" header="RuleId" style={{ textAlign: 'left', minWidth: '80px' }} />
                                <Column field="Name" header="Rule Name" style={{ textAlign: 'left' }} filter showFilterOperator={false} body={this.ruleNameTemplate} />
                                <Column field="MetricName" header="Metric Name" style={{ textAlign: 'left' }} />
                                <Column field="ServerInstance" header="Server/Instance" filter filterElement={this.instanceFilterTemplate} showFilterMatchModes={false} filterPlaceholder='Server/Instance' showFilterOperator={false} style={{ textAlign: 'left' }} />
                                <Column field="NameSpace" header="NameSpace" filter filterElement={this.namespaceFilterTemplate} showFilterMatchModes={false} filterPlaceholder='NameSpace' showFilterOperator={false} style={{ textAlign: 'left' }} body={this.showNAIfNoData} />
                                <Column field="InterfaceName" header="Interface Name" style={{ textAlign: 'left' }} body={this.showNAIfNoData} filter showFilterOperator={false} />
                                <Column field="SourceSystem" header="Source System" style={{ textAlign: 'left' }} />
                                <Column field="DisplayExpiryDate" header="Expiry (EST)" style={{ textAlign: 'left' }} sortField="ExpiryDate" sortable />
                                <Column field="Status" header="Status" style={{ textAlign: 'left' }} filter filterPlaceholder='Status' filterElement={this.statusFilterTemplate} showFilterMatchModes={false} body={this.statusBodyTemplate} />
                            </DataTable></>}

                    </div>
                </div>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedNotificationBlackoutComponent = withMsal(NotificationBlackoutComponent);
export default withAITracking(reactPlugin, WrappedNotificationBlackoutComponent);