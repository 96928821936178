import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import { Dropdown } from 'primereact/dropdown';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import './Galileo.css';
import Spinner from '../../Spinner'

class GalileoComponent extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.reportSelectItems = [
            { label: 'CPU Utilization', value: 'HostCpu' },
            { label: 'Memory Use Percent', value: 'HostMemoryUse' },
            { label: 'Host Filesystem Percent Used', value: 'HostFsUsedMbPct' },
        ];

        this.state = {
            frame: 'HostCpu',
            selectedServer: null,
            serverSelectItems: [],
            hideSpinner: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getGalileoData();
    }

    getGalileoData() {
        console.log(this.props.msalContext)

        var url = global.ENV_APIM_BASE_URL + "/hiemonitoring/galileo/tags/" + global.ENV_GALILEO_TAG + "/related?t=" + global.ENV_GALILEO_TOKEN;

        var serverItems = [];

        var results = this.getData(url).then(
            d => {
                for (let result of d.data.related.assets) {
                    serverItems.push({ label: result.name + ' - ' + result.alias, value: result.id });
                }
                this.setState({ selectedServer: serverItems[0].value, serverSelectItems: serverItems, hideSpinner: true });
            }
        );
    }

    async getData(url) {
        const headers = new Headers();
        headers.append('Accept', '*/*');
        headers.append('Content-Type', 'application/json');
        headers.append('x-northwell-version', '1.0');
        headers.append('Ocp-Apim-Subscription-Key', global.ENV_APIM_SUBSCRIPTION_KEY);

        const options = {
            method: "GET",
            headers: headers
        };

        let response = await fetch(url, options);

        return await response.json();
    }

    updateView(e) {
        this.setState({ frame: e.value, hideSpinner: true });
    }

    updateServer(e) {
        this.setState({ selectedServer: e.value, hideSpinner: true });
    }

    render() {
        if (this.state.selectedServer === null) {
            return (
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                    <div className="galileoContainer">
                        <BreadCrumbComponent {...this.props} />
                        <div hidden={this.state.hideSpinner}><Spinner /></div>
                        <div className='row justify-content-between px-4'>
                            <div className='col'>
                                <Dropdown filter={true} value={this.state.selectedServer} options={this.state.serverSelectItems} onChange={(e) => { this.updateServer(e) }} placeholder="Select a server" />
                            </div>
                            <div className='col text-right'>
                                <Dropdown value={this.state.frame} options={this.reportSelectItems} onChange={(e) => { this.updateView(e) }} placeholder="Select a report" />
                            </div>
                        </div>
                    </div>
                </MsalAuthenticationTemplate>
            )
        }
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="galileoContainer">
                    <BreadCrumbComponent {...this.props} />
                    <div hidden={this.state.hideSpinner}><Spinner /></div>
                    <header className="page-header">
                        <div className="page-header-title">GALILEO</div>
                    </header>
                    <div className='row justify-content-between px-4'>
                        <div className='col'>
                            <Dropdown filter={true} value={this.state.selectedServer} options={this.state.serverSelectItems} onChange={(e) => { this.updateServer(e) }} placeholder="Select a server" />
                        </div>
                        <div className='col text-right'>
                            <Dropdown value={this.state.frame} options={this.reportSelectItems} onChange={(e) => { this.updateView(e) }} placeholder="Select a report" />
                        </div>
                    </div>
                    <iframe className='text-left' style={{ width: '100%', height: '100%', border: '0px', }} src={`${global.ENV_GALILEO_DOMAIN_URL}/${this.state.frame}?timezone=EST5EDT&range_type=last_1440&filters=top_10&asset=${this.state.selectedServer}&t=${global.ENV_GALILEO_TOKEN}`}></iframe>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
};

const WrappedGalileoComponent = withMsal(GalileoComponent);
export default withAITracking(reactPlugin, WrappedGalileoComponent);
