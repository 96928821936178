import React, { Component } from 'react';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import HIEMetricService from '../../../../services/HIEMetricService';
import BreadCrumbComponent from '../../../bread-crumb/BreadCrumbComponent';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../../AppInsights';
import './MemoryComponent.css';

class MemoryComponent extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this._isForbidden = false;
        this.state = {
            data: [],
            lastUpdate: null,
            loading: true,
            frequency: 5
        };
        this.chartRef = React.createRef();
        this.hieMetricService = new HIEMetricService();
        this.myChart = null;
        this.timerId = null;
        this.fullscreen = props.match.url.includes('memory');
        this.getChartData = this.getChartData.bind(this);
        this.updateFrequency = this.updateFrequency.bind(this);

        this.freqSelectItems = [
            { label: '5 Minutes', value: 5 },
            { label: '30 Minutes', value: 30 },
            { label: '1 Hour', value: 60 },
        ];
    }

    componentDidMount() {
        // console.log('componentDidMount(): ');
        this._isMounted = true;
        this.getChartData();
        this.timerId = setInterval(this.getChartData, 10000);
    }

    componentDidUpdate() {
        // console.log('componentDidUpdate(): ');
        this.buildChart();
    }

    componentWillUnmount() {
        // console.log('componentWillUnmount(): ');
        this._isMounted = false;
        clearTimeout(this.timerId);
    }

    getChartData() {
        this.hieMetricService.getMemory(this.props.match.params.server + ':' + this.props.match.params.server.slice(0, -1).toUpperCase(), this.state.frequency).then(memLog => {
            const data = [];
            if (Array.isArray(memLog) && memLog.length > 0) {
                memLog.forEach(mem => data.push(Math.round(mem.MemoryInUse / mem.MemorySize * 100)));
                if (this._isMounted) this.setState({ data, lastUpdate: memLog[0].TimeCollected, loading: false });
            }
        });
    }

    buildChart() {
        // console.log('buildChart(): ');
        if (!this.state.loading) {
            let titleFontSize = 16;
            let axisFontSize = 10;
            let titlePosition = 'bottom';
            let xAxisScaleLabel = null;
            let yAxisScaleLabel = null;

            if (this.fullscreen) {
                titleFontSize *= 2;
                axisFontSize *= 2;
                titlePosition = 'top';
                yAxisScaleLabel = 'Percentage';
                if (this.state.frequency < 60) {
                    xAxisScaleLabel = `Last ${this.state.frequency} minutes`;
                } else {
                    //                    xAxisScaleLabel = `Last ${this.state.frequency/60} hour(s)`;
                    xAxisScaleLabel = 'Last hour';
                }
            }

            const labelList = [];

            let min = 0;
            for (let i = 0; i < this.state.frequency * 6 + 1; i++) {
                if (this.state.frequency <= 60 && i % 6 === 0) {
                    labelList.push(min);
                    min++;
                } else {
                    labelList.push('');
                }
            }

            const myChartRef = this.chartRef.current.getContext("2d");
            new Chart(myChartRef, {
                type: 'line',
                data: {
                    labels: labelList,
                    datasets: [{
                        fill: 'origin',
                        borderColor: 'black',
                        data: this.state.data,
                        // showLine: false,
                        pointRadius: 0,
                        borderWidth: '1px',
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: 'Memory',
                        fontSize: titleFontSize,
                        position: titlePosition,
                        padding: 0,
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            display: this.fullscreen,
                            scaleLabel: {
                                display: this.fullscreen,
                                labelString: yAxisScaleLabel,
                                fontSize: axisFontSize,
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100,
                                fontSize: axisFontSize,
                            }
                        }],
                        xAxes: [{
                            display: this.fullscreen,
                            scaleLabel: {
                                display: this.fullscreen,
                                labelString: xAxisScaleLabel,
                                fontSize: axisFontSize,
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 6,
                                fontSize: axisFontSize,
                            }
                        }],
                    },
                    animation: {
                        duration: 0
                    },
                    events: []
                },
                plugins: [{
                    beforeRender: (x, options) => {
                        var c = x.chart
                        var dataset = x.data.datasets[0];
                        var gradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);
                        gradientFill.addColorStop(0, 'red');
                        gradientFill.addColorStop(0.25 - 0.0001, 'red');
                        gradientFill.addColorStop(0.25 + 0.0001, 'green');
                        gradientFill.addColorStop(1, 'green');

                        var model = x.data.datasets[0]._meta[Object.keys(dataset._meta)[0]].dataset._model;
                        model.backgroundColor = gradientFill;
                    }
                }],
            });
        }
    }

    updateFrequency(e) {
        clearTimeout(this.timerId);
        this.setState({ frequency: e.value, loading: true }, () => {
            this.getChartData();
            this.timerId = setInterval(this.getChartData, 10000);
        });
    }

    render() {

        let breadCrumb = null;
        let lastUpdateBody = null;
        let updateFreqBody = null;
        let containerClassName = 'memContainer';
        let bodyClassName = 'memBody';
        if (this.fullscreen) {
            containerClassName = 'memFullScreenContainer';
            bodyClassName = 'memFullScreenBody';
            breadCrumb = <BreadCrumbComponent {...this.props} />
            if (this.state.data.length > 0) {
                if (moment(this.state.lastUpdate).isSame(moment().utc(), 'hour')) {
                    lastUpdateBody = <div className="lastUpdate">Last Update: {this.state.lastUpdate}</div>;
                } else {
                    lastUpdateBody = <div className="lastUpdate outOfDate">Last Update: {this.state.lastUpdate}</div>;
                }
            }
            updateFreqBody = <Dropdown value={this.state.frequency} options={this.freqSelectItems} onChange={this.updateFrequency} className="freqDropdown" placeholder="Update frequency" />
        }
        let memBody = null;
        if (this.state.loading) {
            memBody = <ProgressSpinner className="spinner" />;
        } else {
            memBody = <canvas id="myChart" className="chart" ref={this.chartRef} />;
        }
        return (
            <div className={containerClassName}>
                {breadCrumb}
                <div className={bodyClassName}>
                    {updateFreqBody}
                    {memBody}
                    {lastUpdateBody}
                </div>
            </div>
        );
    }
}

//export default MemoryComponent;
export default withAITracking(reactPlugin, MemoryComponent);