import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { msalInstance } from '../../App';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { AdminService } from './AdminService';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity, FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import Spinner from '../Spinner';
// TODO: LOGIN - GET TOKEN - CHECK TOKEN WHEN USING GetData
import './AdminComponent.css';

class AdminComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            thresholdRule: {
                id: "",
                alertActionRuleId: 0,
                alertType: null,
                alertTypeName: null,
                alertDescription: null,
                developerSupportSOPText: null,
                emailList: null,
                highThresholdValue: null,
                lowThresholdValue: null,
                metricType: null,
                metricName: null,
                name: null,
                notificationBody: null,
                notificationSubject: null,
                priority: null,
                scenarioType: null,
                status: null,
                triggerEmail: null,
                adminRoles: []
            },
            ThresholdRules: [],
            hideSpinner: false,
            createRuleErrorMessage: '',
            pageErrorMessage: '',
            pageUnAuthorizedErrorMsg: '',
            hasReadRights: true,
            createThresholdRule: false,
            showMetricTypeInfo: true,
            jwtToken: '',
            alertTypeList: [
                { label: 'Warning', value: 1 },
                { label: 'Error', value: 2 },
                { label: 'Information', value: 3 }
            ],
            metricTypeList: [],
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'MetricName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'Priority': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'Name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            },
            globalFilterValue: ''
        };
        this.adminService = new AdminService();
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.globalSearchFilter = this.globalSearchFilter.bind(this);
        this.clearDataTableFilters = this.clearDataTableFilters.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getTableData();
        this.getAdminRoles();
    }

    getAdminRoles() {
        this.adminService.getAdminRoles(this.props.msalContext).then(
            (d) => {
                this.setState({ adminRoles: d, pageErrorMessage: null });
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getRoleId() {
        var adminRoles = this.state.adminRoles;
        if (adminRoles && adminRoles.length > 0) {
            if (adminRoles.includes('Admin.Write'))
                return 2;
            if (adminRoles.includes('Admin.Read'))
                return 1;
        }
        return 0;
    }

    getTableData() {
        this.adminService.getThresholdRules(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({
                        pageErrorMessage: 'You do not have permission to view this page.',
                        pageUnAuthorizedErrorMsg: 'You do not have permission to view this page.', hideSpinner: true, hasReadRights: false
                    });
                } else {
                    if (this._isMounted) {
                        this.setState({ ThresholdRules: d.body, hideSpinner: true, pageErrorMessage: null, pageUnAuthorizedErrorMsg: null });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    updateAndDeleteButtonTemplate = (rowData) => {
        if (this.getRoleId() == 2) {
            return (
                <React.Fragment>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" tooltip="Edit"
                        onClick={() => this.props.history.push('/admin/threshold/rule/edit/' + rowData.ID)} />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    booleanChecker = (rowData, item) => {
        if (rowData[item.field]) {
            return "Active"
        } else {
            return "Not Active"
        }
    };

    globalSearchFilter(filtersKey) {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span><span></span>
                </div>
            </div>
        )
    }

    onGlobalFilterChange(event, filtersKey) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    clearDataTableFilters() {
        this.setState({
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'MetricName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'Priority': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'Name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            },
            globalFilterValue: '',
        })
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="adminContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div className='text-danger text-center'>{this.state.pageUnAuthorizedErrorMsg}</div>
                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}

                    <div hidden={!this.state.hasReadRights}>
                        <header className="page-header">
                            <div className="page-header-title">THRESHOLD RULES</div>
                        </header>
                        <DataTable
                            value={this.state.ThresholdRules}
                            isHidden={!this.state.hasReadRights}
                            paginator={true}
                            selectionMode="single"
                            rows={10}
                            rowsPerPageOptions={[5, 10, 20, 50]}
                            dataKey="Id"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            emptyMessage="No Records found."
                            onRowSelect={this.rowSelection}
                            showGridlines={true}
                            onFilter={(e) => this.setState({ filters: e.filters })}
                            stateStorage="session" stateKey="dt-adminthresholdrule-session"
                        >
                            <Column field="MetricType" header="Metric Type" style={{ textAlign: 'left', width: '50px' }} />
                            <Column field="MetricName" header='Metric Name' style={{ textAlign: 'left' }} filter filterPlaceholder="Metric Name" showFilterOperator={false} />
                            <Column field="Priority" header='Priority' style={{ textAlign: 'left', width: '100px' }} filter filterPlaceholder="Priority" showFilterOperator={false} />
                            <Column field="ScenarioType" header="Scenario Type" style={{ textAlign: 'left' }} />
                            <Column field="AlertTypeName" header="Alert Type" style={{ textAlign: 'left' }} />
                            <Column field="Name" header='Alert Name' style={{ textAlign: 'left' }} filter filterPlaceholder="Alert Name" showFilterOperator={false} />
                            <Column field="AlertDescription" header="Alert Description" style={{ textAlign: 'left' }} />
                            <Column field="HighThresholdValue" header="High Threshold Value" style={{ textAlign: 'left' }} />
                            <Column field="LowThresholdValue" header="Low Threshold Value" style={{ textAlign: 'left' }} />
                            <Column field="Status" header="Status" style={{ textAlign: 'left' }} body={this.booleanChecker} />
                            <Column field="TriggerEmail" header="TriggerEmail" style={{ textAlign: 'left' }} body={this.booleanChecker} />
                            <Column field="edit" header="Action" body={this.updateAndDeleteButtonTemplate} style={{ whiteSpace: 'nowrap', minWidth: '100px' }} />
                        </DataTable>
                    </div>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
};
const WrappedAdminComponent = withMsal(AdminComponent);
//export default WrappedAdminComponent;
export default withAITracking(reactPlugin, WrappedAdminComponent);