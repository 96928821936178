import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { NotificationBlackoutService } from './NotificationBlackoutService';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import Spinner from "../Spinner";
import AlertMessage from "../alertmessage/AlertMessage"
import { MessageSeverity, FilterMatchMode, FilterOperator } from 'primereact/api';
import { NavLink } from 'react-router-dom';

//HM-940 - UI to show notification blackout rule details
class NotificationBlackoutRuleComponent extends Component {
    constructor() {
        super();
        this.WrappedActionGroupComponent = React.createRef();
        this._isMounted = false;
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            editActionGrpErrorMessage: '',
            notificationBlackoutRules: [],
            notificationBlackoutRule: [],
            ServerList: [],
            InstanceList: [],
            NamespaceList: [],
            hideSpinner: false,
            showpagination: false,
            hideInstanceColumn: false,
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'ServerName': { value: null, matchMode: FilterMatchMode.IN },
                'ServerInstance': { value: null, matchMode: FilterMatchMode.IN },
                'NameSpace': { value: null, matchMode: FilterMatchMode.IN },
                'Name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'InterfaceName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            },
            globalFilterValue: ''
        }
        this.notificationBlackoutService = new NotificationBlackoutService();
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.globalSearchFilter = this.globalSearchFilter.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getNotificationBlackoutRules();
    }

    getNotificationBlackoutRules() {
        this.notificationBlackoutService.getNotificationBlackoutRule(this.props.match.params.id, true, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({
                            notificationBlackoutRules: d.body,
                            notificationBlackoutRule: [d.body[0]],
                            ServerList: [...new Set(d.body.map(x => x.ServerName))],
                            InstanceList: [...new Set(d.body.map(x => x.ServerInstance))],
                            NamespaceList: [...new Set(d.body.map(x => x.NameSpace))].sort((a, b) => { return a > b ? 1 : -1 }),
                            hideInstanceColumn: d.body[0].SourceSystem === "CACHE" ? false : true,
                            hideSpinner: true,
                            pageSuccessMessage: this.props.match.params.iscreate === 'true' && sessionStorage.getItem(this.props.match.params.id) !== 'true' ? 'Rule created successfully.' : '',
                            pageErrorMessage: ''
                        });
                        sessionStorage.setItem(this.props.match.params.id, this.props.match.params.iscreate); //HDO-5447 - bug fix
                        if (this.state.notificationBlackoutRules.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }


    //HM-935 - change to save the filter
    onGlobalFilterChange(event, filtersKey) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter(filtersKey) {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left" >
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                    <div></div>
                </div>
            </div>
        )
    }

    clearDataTableFilters = () => {
        this.setState({
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'ServerName': { value: null, matchMode: FilterMatchMode.IN },
                'ServerInstance': { value: null, matchMode: FilterMatchMode.IN },
                'NameSpace': { value: null, matchMode: FilterMatchMode.IN },
                'Name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'InterfaceName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            }
        });
        sessionStorage.clear();
    }

    ruleNameTemplate = (rowData) => {
        let redirectUrl = '/notification/blackout/rule/detail/' + rowData.RuleId
        return <><NavLink key={rowData.RuleId} to={redirectUrl}><strong>{rowData.Name}</strong></NavLink></>
    }

    serverFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.ServerList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="Server"
        />;
    }

    instanceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.InstanceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="Instance"
        />;
    }

    namespaceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.NamespaceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="NameSpace" className="multiselect-custom"
        />;
    }

    showNAIfNoData = (rowData, column) => {
        let value = rowData[column.field];

        if (value)
            return rowData[column.field];
        else
            return "N.A"
    }

    showBoolValue = (rowData, column) => {
        let value = rowData[column.field];

        if (value)
            return 'Yes';
        else
            return "No"
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
        this.setState({ pageSuccessMessage: '' })
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="notificationBlackoutRuleContainer">
                    <BreadCrumbComponent {...this.props} />

                    <AlertMessage ref={instance => { this.alertMsg = instance }} />
                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <div className='flex justify-content-between' style={{ marginBottom: '10px' }}>
                            <div className="page-header-title">NOTIFICATION BLACKOUT RULE</div>
                            <Button label='' icon='pi pi-plus' tooltip='Create DNM Rule' tooltipOptions={{ position: 'left' }} onClick={() => window.open('/notification/blackout/rule/create', '_blank')} />
                        </div>

                        <DataTable ref={(el) => this.dt = el} value={this.state.notificationBlackoutRule} responsiveLayout="scroll" selectionMode="single">
                            <Column hidden field="RuleId" header="RuleId" style={{ textAlign: 'left', minWidth: '80px' }} />
                            <Column field="Name" header="Rule Name" style={{ textAlign: 'left' }} body={this.ruleNameTemplate} />
                            <Column field="TriggerNotification" header="Trigger Notification" style={{ textAlign: 'left' }} body={this.showBoolValue} />
                            <Column field="Decommissioned" header="Decommissioned" style={{ textAlign: 'left' }} body={this.showBoolValue} />
                            <Column field="Reason" header="Reason" style={{ textAlign: 'left' }} />
                            <Column field="DisplayExpiryDate" header="Expiry (EST)" style={{ textAlign: 'left' }} />
                            <Column field="DisplayCreatedDate" header="Created On" style={{ textAlign: 'left' }} sortField="CreatedDate" />
                            <Column field="CreatedBy" header="Created By" style={{ textAlign: 'left' }} />
                            <Column field="DisplayModifiedDate" header="Modified On" style={{ textAlign: 'left' }} body={this.showNAIfNoData} sortField="ModifiedDate" />
                            <Column field="ModifiedBy" header="Modified By" style={{ textAlign: 'left' }} body={this.showNAIfNoData} />

                        </DataTable>
                        <br /><center>
                            <Button label='Edit' onClick={() => window.open('/notification/blackout/rule/edit/' + this.props.match.params.id, '_blank')} /> &nbsp;
                            <Button label='Cancel' onClick={() => this.props.history.push('/notification/blackout/rules')} /></center>
                        <div className='flex justify-content-between' style={{ marginBottom: '5px' }}>
                            <div className="page-header-title">MAPPINGS</div>
                        </div>
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.notificationBlackoutRules}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            dataKey="RuleId"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            showGridlines removableSort
                            emptyMessage="No Records found."
                            onFilter={(e) => this.setState({ filters: e.filters })} //HM-935 - change to save the filter
                            stateStorage="session" stateKey="dt-notificationblackoutrule-session"
                        >
                            <Column hidden field="RuleId" header="RuleId" style={{ textAlign: 'left', minWidth: '80px' }} />
                            <Column field="MetricName" header="Metric Name" style={{ textAlign: 'left' }} />
                            <Column field="ServerName" header="Server" filter filterElement={this.serverFilterTemplate} showFilterMatchModes={false} filterPlaceholder='Server' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            <Column hidden={this.state.hideInstanceColumn} field="ServerInstance" header="Instance" filter filterElement={this.instanceFilterTemplate} showFilterMatchModes={false} filterPlaceholder='Instance' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            <Column field="NameSpace" header="NameSpace" filter filterElement={this.namespaceFilterTemplate} showFilterMatchModes={false} filterPlaceholder='NameSpace' showFilterOperator={false} style={{ textAlign: 'left' }} body={this.showNAIfNoData} />
                            <Column field="InterfaceName" header="Interface Name" style={{ textAlign: 'left' }} body={this.showNAIfNoData} filter showFilterOperator={false} />
                            <Column field="SourceSystem" header="Source System" style={{ textAlign: 'left' }} />
                        </DataTable>
                    </div>
                </div>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedNotificationBlackoutRuleComponent = withMsal(NotificationBlackoutRuleComponent);
export default withAITracking(reactPlugin, WrappedNotificationBlackoutRuleComponent);