import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import MessageVolumesComponent from '../message-volumes/MessageVolumesComponent';
import HIEMetricService from '../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';

import "./MessageVolumeTypesDetailComponent.css";

class MessageVolumeTypesDetailComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this._isForbidden = false;
        this.state = {
            hostNames: [],
            loading: true
        };
        this.hieMetricService = new HIEMetricService();
        this.msgVolTypes = ['', 'Services', 'Interfaces', 'Operations', 'Processes'];
    }

    componentDidMount() {
        const hostType = this.msgVolTypes.indexOf(this.props.match.params.type)
        this._isMounted = true;
        this.hieMetricService.getMessageVolumeNames(this.props.match.params.server, this.props.match.params.instance, this.props.match.params.namespace, hostType).then(data => {
            let hostNames = data.map(item => item.HostName);
            if (this._isMounted) this.setState({ hostNames, loading: false });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        let body = [];
        this.state.hostNames.forEach((hostName, index) => {
            body.push(<MessageVolumesComponent key={index} filter={hostName} closed='1' {...this.props} />);
        });

        return (
            <div className="messageVolumesDetailContainer">
                <BreadCrumbComponent {...this.props} />
                <div className="component">
                    <Panel header={'Message Volumes Detail: ' + this.props.match.params.namespace + ':' + this.props.match.params.type}>
                        {body}
                    </Panel>
                </div>
            </div>
        );
    }
}

//export default MessageVolumeTypesDetailComponent;
export default withAITracking(reactPlugin, MessageVolumeTypesDetailComponent);