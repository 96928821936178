import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { reactPlugin, appInsights } from '../../AppInsights';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import Spinner from '../Spinner'
import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Dialog } from 'primereact/dialog';
import { IACLService } from './IACLService'
import "./IACL.css"

//HDO-5469 - PI 2024 Q3 - Integrate IACL UI features into the Monitoring tool
class IACLComponentDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            hideSpinner: true,
            IACLAuditHistory: [],
            confirmDeleteIACLRecordDialogBoxVisible: false
        }
        this.IACLService = new IACLService();
    }

    panelHeaderTemplate = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
                <span className={titleClassName}>
                    {options.props.id}
                </span>
            </div>
        )
    }

    createIACLDocLink = (rowData) => {
        let redirectUrl = rowData.Url
        return (
            <React.Fragment>
                <a href={redirectUrl} target="_blank"><strong>{rowData.Name}</strong></a>
            </React.Fragment>
        );
    }

    getIACLAuditHistory = () => {

        if (this.state.IACLAuditHistory && this.state.IACLAuditHistory.length == 0) {
            this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

            this.IACLService.getIACLAuditHistory(this.props.IACLRecord.Id, this.props.msalContext).then(
                (d) => {
                    if (d.status === 403) {
                        this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                    } else {
                        if (d.status === 200) {
                            this.setState({
                                IACLAuditHistory: d.body, hideSpinner: true, pageSuccessMessage: null, pageErrorMessage: null
                            })
                        }
                        else {
                            this.setState({
                                pageErrorMessage: 'Something went wrong !',
                                hideSpinner: true
                            })
                        }
                    }
                }
            ).catch(e => {
                console.log(e)
                this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
            });
        }
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    confirmDeleteFooter = (
        <div>
            <Button label='Ok' onClick={() => this.props.onDeleteIACLRecordHandler(this.props.IACLRecord.Id)} />
            <Button label='Cancel' onClick={e => this.setState({ confirmDeleteIACLRecordDialogBoxVisible: false })} className='p-button-secondary' />
        </div>
    );

    getIACLAuditHistory = () => {

        if (this.state.IACLAuditHistory && this.state.IACLAuditHistory.length == 0) {
            this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

            this.IACLService.getIACLAuditHistory(this.props.IACLRecord.Id, this.props.msalContext).then(
                (d) => {
                    if (d.status === 403) {
                        this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                    } else {
                        if (d.status === 200) {
                            this.setState({
                                IACLAuditHistory: d.body, hideSpinner: true, pageSuccessMessage: null, pageErrorMessage: null
                            })
                        }
                        else {
                            this.setState({
                                pageErrorMessage: 'Something went wrong !',
                                hideSpinner: true
                            })
                        }
                    }
                }
            ).catch(e => {
                console.log(e)
                this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
            });
        }
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="iacldetailscontainer">

                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    {this.props.IACLRecord === null || this.props.IACLRecord === undefined ?
                        <div style={{ padding: '25em 10em', textAlign: 'center' }}>
                            <h3>Select an interface from the table on the left.</h3>
                        </div> :
                        <div>
                            <div style={{ textAlign: 'end', padding: '5px 0px' }}>
                                <Button type="button" icon="pi pi-plus" tooltip='New Interface' tooltipOptions={{ position: 'top' }} label="New" className="p-button-help" onClick={() => window.open('/iacl/component/create', '_blank')} /> &nbsp;
                                <Button type="button" icon="pi pi-pencil" label="Edit" tooltip='Edit Interface' tooltipOptions={{ position: 'top' }} className="p-button-primary" onClick={() => window.open('/iacl/component/edit/' + this.props.IACLRecord.Id, '_blank')} /> &nbsp;
                                <Button type="button" icon="pi pi-trash" label="Delete" tooltip='Delete Interface' onClick={e => this.setState({ confirmDeleteIACLRecordDialogBoxVisible: true })} tooltipOptions={{ position: 'top' }} className="p-button-danger" />
                            </div>
                            <div id='Support Information'>
                                <Panel id='Support Information' toggleable headerTemplate={this.panelHeaderTemplate}>
                                    <h4>{this.props.IACLRecord.ApplicationName ? this.props.IACLRecord.ApplicationName.ApplicationName : ""}</h4><hr />
                                    <table className='iacl-table'>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Email Group:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>CA Group:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Secondary Contact Info:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.props.IACLRecord.ApplicationName ? this.props.IACLRecord.ApplicationName.EmailGroup || 'N.A' : 'N.A'}</td>
                                            <td className='iacl-td'>{this.props.IACLRecord.CAGroupName || 'N.A'}</td>
                                            <td className='iacl-td'>{this.props.IACLRecord.ApplicationName ? this.props.IACLRecord.ApplicationName.SecondaryContact || 'N.A' : 'N.A'}</td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Manager Contact:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Manager Phone:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Tertiary Contact Info::</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.props.IACLRecord.ApplicationName ? this.props.IACLRecord.ApplicationName.ManagerContact || 'N.A' : 'N.A'}</td>
                                            <td className='iacl-td'>{this.props.IACLRecord.ApplicationName ? this.props.IACLRecord.ApplicationName.ManagerPhone || 'N.A' : 'N.A'}</td>
                                            <td className='iacl-td'>{this.props.IACLRecord.ApplicationName ? this.props.IACLRecord.ApplicationName.TertiaryContact || 'N.A' : 'N.A'}</td>
                                        </tr>
                                    </table>
                                </Panel> <Divider layout="horizontal"></Divider>
                            </div>

                            <div id='Interface Properties'>
                                <Panel id='Interface Properties' toggleable headerTemplate={this.panelHeaderTemplate}>
                                    <table className='iacl-table'>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Interface Type:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>{this.props.IACLRecord.Platform === "OPENLINK" ? 'Design Name:' : 'Namespace:'}</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Connection Type:</span></td>
                                            {this.props.IACLRecord.ConnectionType === "SFTP" ? <td className='iacl-td'><span className='iacl-label'>GOA JobName:</span></td> : <></>}
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.props.IACLRecord.InterfaceType || 'N.A'}</td>
                                            <td className='iacl-td'>{this.props.IACLRecord.Namespace || 'N.A'}</td>
                                            <td className='iacl-td'>{this.props.IACLRecord.ConnectionType || 'N.A'}</td>
                                            {this.props.IACLRecord.ConnectionType === "SFTP" ? <td className='iacl-td'>{this.props.IACLRecord.GOAJobName || 'N.A'}</td> : <></>}
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Message Content:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Message Type:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Site Name:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.props.IACLRecord.MessageContent || 'N.A'}</td>
                                            {this.props.IACLRecordMessageTypes.length > 1 ?
                                                <td className='iacl-td'>{this.props.IACLRecordMessageTypes.length > 0 ? <Dropdown style={{ width: '98px' }} readOnly={true} value={this.props.IACLRecordMessageTypes[0].value} options={this.props.IACLRecordMessageTypes} /> : <>N.A</>} </td>
                                                : this.props.IACLRecordMessageTypes.length > 0 ? <td className='iacl-td'>{this.props.IACLRecordMessageTypes[0].value}</td> : <td>N.A</td>}
                                            {this.props.IACLRecordSiteNames.length > 1 ?
                                                <td className='iacl-td'>{this.props.IACLRecordSiteNames.length > 0 ? <Dropdown style={{ width: '-webkit-fill-available', color: '#333333' }} readOnly={true} valueTemplate={''} value={this.props.IACLRecordSiteNames[0].value} options={this.props.IACLRecordSiteNames} /> : <>N.A</>} </td>
                                                : this.props.IACLRecordSiteNames.length > 0 ? <td className='iacl-td'>{this.props.IACLRecordSiteNames[0].value}</td> : <td>N.A</td>}
                                        </tr>
                                    </table>
                                </Panel>
                            </div>

                            {this.props.IACLRecord.ImpactAnalysis === null ? <></> :
                                <div id='Impact Analysis'>
                                    <Divider layout="horizontal"></Divider>
                                    <Panel id='Impact Analysis' toggleable headerTemplate={this.panelHeaderTemplate} collapsed={true}>
                                        <table>
                                            <tr>
                                                <td className='iacl-td'><span className='iacl-label'>Affected Application:</span></td>
                                                <td className='iacl-td'><span className='iacl-label'>Notify:</span></td>
                                                <td className='iacl-td'><span className='iacl-label'>Comments:</span></td>
                                            </tr>
                                            <tr>
                                                <td className='iacl-td'>{this.props.IACLRecord.ImpactAnalysis.AffectedApps || 'N.A'}</td>
                                                <td className='iacl-td'>{this.props.IACLRecord.ImpactAnalysis.Notify || 'N.A'}</td>
                                                <td className='iacl-td'>{this.props.IACLRecord.ImpactAnalysis.Comments || 'N.A'}</td>
                                            </tr>
                                        </table>
                                    </Panel>
                                </div>
                            }

                            {this.props.IACLRecord.Documents && this.props.IACLRecord.Documents.length > 0 ?
                                <div id='Documents'>
                                    <Divider layout="horizontal"></Divider>
                                    <Panel id='Documents' toggleable headerTemplate={this.panelHeaderTemplate}>
                                        <DataTable
                                            value={this.props.IACLRecord.Documents}
                                            selectionMode="single"
                                            paginator={true}
                                            rows={3}
                                            filterDisplay="menu"
                                            responsiveLayout="scroll"
                                            showGridlines removableSort
                                            emptyMessage="No Records found."
                                            stateStorage="session" stateKey="dt-iaclcomponent-detail-session"
                                        >
                                            <Column field="Name" header="Name" style={{ textAlign: 'left' }} body={this.createIACLDocLink} />
                                            <Column field="DocType" header="Type" style={{ textAlign: 'left' }} />
                                        </DataTable>
                                    </Panel>
                                </div> : <></>}

                            {this.props.IACLRecord.RoutineMaintenanceFrequency === null && this.props.IACLRecord.RoutineMaintenanceTimeStart === null && this.props.IACLRecord.RoutineMaintenanceTimeEnd === null &&
                                this.props.IACLRecord.RoutineMaintenanceWeekDays === null && this.props.IACLRecord.RoutineMaintenanceMonthDays === null && this.props.IACLRecord.RoutineMaintenanceComments === null &&
                                this.props.IACLRecord.OneTimeMaintenaceStart === null && this.props.IACLRecord.OneTimeMaintenaceEnd === null && this.props.IACLRecord.OneTimeComments === null &&
                                this.props.IACLRecord.Comments === null ? <></> :
                                <div id='Maintenance Activity'>
                                    <Divider layout="horizontal"></Divider>
                                    <Panel id='Maintenance Activity' toggleable headerTemplate={this.panelHeaderTemplate} collapsed={true}>
                                        {this.props.IACLRecord.RoutineMaintenanceFrequency === null && this.props.IACLRecord.RoutineMaintenanceTimeStart === null && this.props.IACLRecord.RoutineMaintenanceTimeEnd === null &&
                                            this.props.IACLRecord.RoutineMaintenanceWeekDays === null && this.props.IACLRecord.RoutineMaintenanceMonthDays === null && this.props.IACLRecord.RoutineMaintenanceComments === null ? <></> :
                                            <>
                                                <h4>Recurring Maintenance Window:</h4><hr />
                                                <table className='iacl-table'>
                                                    <tr>
                                                        <td className='iacl-td'><span className='iacl-label'>Frequency:</span></td>
                                                        <td className='iacl-td'><span className='iacl-label'>Start Time(hh:mm) / 24h:</span></td>
                                                        <td className='iacl-td'><span className='iacl-label'>End Time(hh:mm) / 24h:</span></td>
                                                        <td className='iacl-td'><span className='iacl-label'>Days:</span></td>
                                                        <td className='iacl-td'><span className='iacl-label'>Day of the Month:</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='iacl-td'>{this.props.IACLRecord.RoutineMaintenanceFrequency || 'N.A'}</td>
                                                        <td className='iacl-td'>{this.props.IACLRecord.RoutineMaintenanceTimeStart || 'N.A'}</td>
                                                        <td className='iacl-td'>{this.props.IACLRecord.RoutineMaintenanceTimeEnd || 'N.A'}</td>
                                                        <td className='iacl-td'>{this.props.IACLRecord.RoutineMaintenanceWeekDays || 'N.A'}</td>
                                                        <td className='iacl-td'>{this.props.IACLRecord.RoutineMaintenanceMonthDays || 'N.A'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='iacl-td'><span className='iacl-label'>Comments:</span></td>
                                                    </tr>
                                                </table>
                                                <div>{this.props.IACLRecord.RoutineMaintenanceComments || 'N.A'}</div>
                                            </>}

                                        {this.props.IACLRecord.OneTimeMaintenaceStart === null && this.props.IACLRecord.OneTimeMaintenaceEnd === null && this.props.IACLRecord.OneTimeComments === null ? <></> : <>
                                            <h4>One Time Maintenance Window:</h4><hr />
                                            <table>
                                                <tr>
                                                    <td className='iacl-td'><span className='iacl-label'>Start Date/Time:</span></td>
                                                    <td className='iacl-td'><span className='iacl-label'>End Date/Time:</span></td>
                                                </tr>
                                                <tr>
                                                    <td className='iacl-td'>{this.props.IACLRecord.OneTimeMaintenaceStart || 'N.A'}</td>
                                                    <td className='iacl-td'>{this.props.IACLRecord.OneTimeMaintenaceEnd || 'N.A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='iacl-td'><span className='iacl-label'>Comments:</span></td>
                                                </tr>
                                            </table>
                                            <div>{this.props.IACLRecord.OneTimeComments || 'N.A'}</div><br />
                                        </>}

                                        {this.props.IACLRecord.Comments === null ? <></> :
                                            <><hr />
                                                <table>
                                                    <tr>
                                                        <td className='iacl-td'><span className='iacl-label'>Support Notes:</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='iacl-td'>{this.props.IACLRecord.Comments || 'N.A'}</td>
                                                    </tr>
                                                </table>
                                            </>}

                                    </Panel>
                                </div>}

                            <div id='AuditHistory'>
                                <Divider layout="horizontal"></Divider>
                                <Panel id='Audit History' toggleable headerTemplate={this.panelHeaderTemplate} collapsed={true} onExpand={this.getIACLAuditHistory}>
                                    <DataTable
                                        value={this.state.IACLAuditHistory}
                                        selectionMode="single"
                                        paginator={true}
                                        rows={3}
                                        filterDisplay="menu"
                                        responsiveLayout="scroll"
                                        showGridlines removableSort
                                        emptyMessage="No Records found."
                                        stateStorage="session" stateKey="dt-iaclcomponent-detail-session"
                                    >
                                        <Column field="DisplayTimestamp" header="DateTime (EST)" style={{ textAlign: 'left' }} />
                                        <Column field="ActionBy" header="Action By" style={{ textAlign: 'left' }} />
                                        <Column field="ActionType" header="Action Type" style={{ textAlign: 'left' }} />
                                    </DataTable>
                                </Panel>
                            </div>

                            <Dialog header='Delete IACL Record' footer={this.confirmDeleteFooter}
                                visible={this.state.confirmDeleteIACLRecordDialogBoxVisible} width='400px' modal={true}
                                onHide={e => this.setState({ confirmDeleteIACLRecordDialogBoxVisible: false })} maximizable={false}>
                                <div>
                                    Are you sure you want to delete the IACL record - <b>{this.props.IACLRecord.InterfaceName}</b> ?
                                </div>
                            </Dialog>
                        </div>
                    }
                </div>
            </MsalAuthenticationTemplate >
        )
    }
}

const WrappedIACLComponentDetail = withMsal(IACLComponentDetail);
export default withAITracking(reactPlugin, WrappedIACLComponentDetail);