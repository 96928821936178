import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { Dropdown } from 'primereact/dropdown';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import Spinner from '../Spinner'
import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { IACLService } from './IACLService'
import "./IACL.css"

//HDO-5469 - PI 2024 Q3 - Integrate IACL UI features into the Monitoring tool
class IACLComponentDetailViewOnly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            hideSpinner: false,
            IACLRecord: null,
            IACLRecordMessageTypes: [],
            IACLRecordSiteNames: [],
            IACLAuditHistory: []
        }
        this.IACLService = new IACLService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getIaclRecordById(this.props.match.params.id)
    }

    getIaclRecordById = (Id) => {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

        this.IACLService.getIaclRecordById(Id, false, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else if (d.status === 200) {
                    if (this._isMounted) {
                        this.setState({
                            IACLRecord: d.body,
                            MessageTypes: d.body.MessageType ? d.body.MessageType.map((item) => (this.state.IACLRecordMessageTypes.push({ label: item.MessageType, value: item.MessageType }))) : [],
                            SiteNames: d.body.SiteName ? d.body.SiteName.map((item) => (this.state.IACLRecordSiteNames.push({ label: item.Description, value: item.Description }))) : [],
                            hideSpinner: true
                        })
                    }
                }
                else {
                    console.log(d.body.error)
                    this.setState({ pageErrorMessage: d.body.error, hideSpinner: true });
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    getIACLAuditHistory = () => {

        if (this.state.IACLAuditHistory && this.state.IACLAuditHistory.length == 0) {
            this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

            this.IACLService.getIACLAuditHistory(this.props.match.params.id, this.props.msalContext).then(
                (d) => {
                    if (d.status === 403) {
                        this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                    } else {
                        if (d.status === 200) {
                            this.setState({
                                IACLAuditHistory: d.body, hideSpinner: true, pageSuccessMessage: null, pageErrorMessage: null
                            })
                        }
                        else {
                            this.setState({
                                pageErrorMessage: 'Something went wrong !',
                                hideSpinner: true
                            })
                        }
                    }
                }
            ).catch(e => {
                console.log(e)
                this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
            });
        }
    }

    panelHeaderTemplate = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
                <span className={titleClassName}>
                    {options.props.id}
                </span>
            </div>
        )
    }

    createIACLDocLink = (rowData) => {
        let redirectUrl = rowData.Url
        return (
            <React.Fragment>
                <a href={redirectUrl}><strong>{rowData.Name}</strong></a>
            </React.Fragment>
        );
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="iacldetailscontainer">
                    <BreadCrumbComponent {...this.props} />

                    <AlertMessage ref={instance => { this.alertMsg = instance }} />
                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">VIEW INTERFACE</div>
                        </header>

                        {this.state.IACLRecord === null || this.state.IACLRecord === undefined ? <></> : <div>
                            <div id='Basic Information'>
                                <Panel id='Basic Information' toggleable headerTemplate={this.panelHeaderTemplate}>
                                    <table className='iacl-table'>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Platform:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Interface Name:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Priority:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>After Hours Priority:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.state.IACLRecord.Platform || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.InterfaceName || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.Priority || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.AfterHoursPriority || 'N.A'}</td>
                                        </tr>
                                    </table>
                                </Panel> <Divider layout="horizontal"></Divider>
                            </div>
                            <div id='Support Information'>
                                <Panel id='Support Information' toggleable headerTemplate={this.panelHeaderTemplate}>
                                    <h4>{this.state.IACLRecord.ApplicationName.ApplicationName}</h4><hr />
                                    <table className='iacl-table'>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Email Group:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>CA Group:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Secondary Contact Info:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.state.IACLRecord.ApplicationName ? this.state.IACLRecord.ApplicationName.EmailGroup || 'N.A' : 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.CAGroupName || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.ApplicationName ? this.state.IACLRecord.ApplicationName.SecondaryContact || 'N.A' : 'N.A'}</td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Manager Contact:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Manager Phone:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Tertiary Contact Info::</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.state.IACLRecord.ApplicationName ? this.state.IACLRecord.ApplicationName.ManagerContact || 'N.A' : 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.ApplicationName ? this.state.IACLRecord.ApplicationName.ManagerPhone || 'N.A' : 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.ApplicationName ? this.state.IACLRecord.ApplicationName.TertiaryContact || 'N.A' : 'N.A'}</td>
                                        </tr>
                                    </table>
                                </Panel> <Divider layout="horizontal"></Divider>
                            </div>
                            <div id='Interface Properties'>
                                <Panel id='Interface Properties' toggleable headerTemplate={this.panelHeaderTemplate}>
                                    <table className='iacl-table'>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Interface Type:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>{this.state.IACLRecord.Platform === "OPENLINK" ? 'Design Name:' : 'Namespace:'}</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Connection Type:</span></td>
                                            {this.state.IACLRecord.ConnectionType === "SFTP" ? <td className='iacl-td'><span className='iacl-label'>GOA JobName:</span></td> : <></>}
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.state.IACLRecord.InterfaceType || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.Namespace || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.ConnectionType || 'N.A'}</td>
                                            {this.state.IACLRecord.ConnectionType === "SFTP" ? <td className='iacl-td'>{this.state.IACLRecord.GOAJobName || 'N.A'}</td> : <></>}
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Message Content:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Message Type:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Site Name:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.state.IACLRecord.MessageContent || 'N.A'}</td>
                                            {this.state.IACLRecordMessageTypes.length > 1 ?
                                                <td className='iacl-td'>{this.state.IACLRecordMessageTypes.length > 0 ? <Dropdown style={{ width: '98px' }} readOnly={true} value={this.state.IACLRecordMessageTypes[0].value} options={this.state.IACLRecordMessageTypes} /> : <>N.A</>} </td>
                                                : this.state.IACLRecordMessageTypes.length > 0 ? <td className='iacl-td'>{this.state.IACLRecordMessageTypes[0].value}</td> : <td>N.A</td>}
                                            {this.state.IACLRecordSiteNames.length > 1 ?
                                                <td className='iacl-td'>{this.state.IACLRecordSiteNames.length > 0 ? <Dropdown style={{ width: '-webkit-fill-available', color: '#333333' }} readOnly={true} valueTemplate={''} value={this.state.IACLRecordSiteNames[0].value} options={this.state.IACLRecordSiteNames} /> : <>N.A</>} </td>
                                                : this.state.IACLRecordSiteNames.length > 0 ? <td className='iacl-td'>{this.state.IACLRecordSiteNames[0].value}</td> : <td>N.A</td>}
                                        </tr>
                                    </table>
                                </Panel>
                            </div>


                            <div id='Impact Analysis'>
                                <Divider layout="horizontal"></Divider>
                                <Panel id='Impact Analysis' toggleable headerTemplate={this.panelHeaderTemplate} collapsed={true}>
                                    <table>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Affected Application:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Notify:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Comments:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.state.IACLRecord.ImpactAnalysis && this.state.IACLRecord.ImpactAnalysis.AffectedApps || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.ImpactAnalysis && this.state.IACLRecord.ImpactAnalysis.Notify || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.ImpactAnalysis && this.state.IACLRecord.ImpactAnalysis.Comments || 'N.A'}</td>
                                        </tr>
                                    </table>
                                </Panel>
                            </div>

                            <div id='Documents'>
                                <Divider layout="horizontal"></Divider>
                                <Panel id='Documents' toggleable headerTemplate={this.panelHeaderTemplate} collapsed={this.state.IACLRecord.Documents && this.state.IACLRecord.Documents.length > 0 ? false : true}>
                                    <DataTable
                                        value={this.state.IACLRecord.Documents}
                                        selectionMode="single"
                                        paginator={true}
                                        rows={3}
                                        filterDisplay="menu"
                                        responsiveLayout="scroll"
                                        showGridlines removableSort
                                        emptyMessage="No Records found."
                                        stateStorage="session" stateKey="dt-iaclcomponent-detail-session"
                                    >
                                        <Column field="Name" header="Name" style={{ textAlign: 'left' }} body={this.createIACLDocLink} />
                                        <Column field="DocType" header="Type" style={{ textAlign: 'left' }} />
                                    </DataTable>
                                </Panel>
                            </div>

                            <div id='AuditHistory'>
                                <Divider layout="horizontal"></Divider>
                                <Panel id='Audit History' toggleable headerTemplate={this.panelHeaderTemplate} collapsed={true} onExpand={this.getIACLAuditHistory}>
                                    <DataTable
                                        value={this.state.IACLAuditHistory}
                                        selectionMode="single"
                                        paginator={true}
                                        rows={3}
                                        filterDisplay="menu"
                                        responsiveLayout="scroll"
                                        showGridlines removableSort
                                        emptyMessage="No Records found."
                                        stateStorage="session" stateKey="dt-iaclcomponent-detail-session"
                                    >
                                        <Column field="DisplayTimestamp" header="DateTime (EST)" style={{ textAlign: 'left' }} />
                                        <Column field="ActionBy" header="Action By" style={{ textAlign: 'left' }} />
                                        <Column field="ActionType" header="Action Type" style={{ textAlign: 'left' }} />
                                    </DataTable>
                                </Panel>
                            </div>

                            <div id='Maintenance Activity'>
                                <Divider layout="horizontal"></Divider>
                                <Panel id='Maintenance Activity' toggleable headerTemplate={this.panelHeaderTemplate} collapsed={true}>

                                    <h4>Recurring Maintenance Window:</h4><hr />
                                    <table className='iacl-table'>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Frequency:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Start Time(hh:mm) / 24h:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>End Time(hh:mm) / 24h:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Days:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Day of the Month:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.state.IACLRecord.RoutineMaintenanceFrequency || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.RoutineMaintenanceTimeStart || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.RoutineMaintenanceTimeEnd || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.RoutineMaintenanceWeekDays || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.RoutineMaintenanceMonthDays || 'N.A'}</td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Comments:</span></td>
                                        </tr>
                                    </table>
                                    <div>{this.state.IACLRecord.RoutineMaintenanceComments || 'N.A'}</div>

                                    <h4>One Time Maintenance Window:</h4><hr />
                                    <table>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Start Date/Time:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>End Date/Time:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.state.IACLRecord.OneTimeMaintenaceStart || 'N.A'}</td>
                                            <td className='iacl-td'>{this.state.IACLRecord.OneTimeMaintenaceEnd || 'N.A'}</td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Comments:</span></td>
                                        </tr>
                                    </table>
                                    <div>{this.state.IACLRecord.OneTimeComments || 'N.A'}</div><br />
                                    <hr />
                                    <table>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Support Notes:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'>{this.state.IACLRecord.Comments || 'N.A'}</td>
                                        </tr>
                                    </table>
                                </Panel>
                            </div>
                        </div>}
                    </div>
                </div>
            </MsalAuthenticationTemplate >
        )
    }
}

const WrappedIACLComponentDetailViewOnly = withMsal(IACLComponentDetailViewOnly);
export default withAITracking(reactPlugin, WrappedIACLComponentDetailViewOnly);