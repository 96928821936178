import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { ProgressSpinner } from 'primereact/progressspinner';
import { NavLink } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCube } from '@fortawesome/free-solid-svg-icons'
import NoDataFoundImg from '../../../../images/NoDataFound.png';
import HIEMetricService from '../../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../../AppInsights';
import "./InstancesComponent.css";

class InstancesComponent extends Component {
    constructor() {
        super();
        this.state = {
            instances: [],
            loading: true,
        };
        this.hieMetricService = new HIEMetricService();
    }

    componentDidMount() {
        this.hieMetricService.getInstances(this.props.match.params.server).then(instances => {
            this.setState({ instances, loading: false });
        });
    }

    render() {
        let instanceList;
        let instaceListClassName;

        if (this.state.loading) {
            instanceList = <ProgressSpinner className="spinner" />;
            instaceListClassName = 'instanceList spinner';
        } else if (this.state.instances.length === 0) {
            instanceList = <div className="noDataFoundContainer"><img src={NoDataFoundImg} alt="No Data Found" /></div>;
            instaceListClassName = 'instanceList spinner';
        } else {
            instaceListClassName = 'instanceList';
            instanceList = this.state.instances.map((instance, index) => {
                return (
                    <NavLink key={index} to={this.props.match.params.server + '/' + instance.ServerInstance}>
                        {/* <div className={"instanceEntry " + (index===0 ? 'pulseAlert' : '') }> */}
                        <div className="instanceEntry">
                            <FontAwesomeIcon className="instanceIcon" size="3x" icon={faCube} />
                            <div className="instanceName">{instance.ServerInstance}</div>
                        </div>
                    </NavLink>
                );
            });
        }

        let body =
            <div className={instaceListClassName}>
                {instanceList}
            </div>;

        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="instancesContainer">
                    <Accordion activeIndex={0}>
                        <AccordionTab header="Instances" headerStyle={{ textAlign: 'left' }}>
                            {body}
                        </AccordionTab>
                    </Accordion>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}

//export default InstancesComponent;
const WrappedInstancesComponent = withMsal(InstancesComponent);
export default withAITracking(reactPlugin, WrappedInstancesComponent);