import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Dropdown } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import { logoutRequest } from "../../authConfig";
import { Cookies } from 'react-cookie';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

//JIRA - HM-425 & HM-459 Bug Fix without Redirect login flow
function removeMsalInteractionFromCookies(cookies) {
    let cookieExits = cookies.get('msal.interaction.status', { path: '/' });
    if (cookieExits !== undefined && cookieExits !== null) {
        //console.log(cookieExits);
        cookies.remove('msal.interaction.status', { path: '/' });
        //console.log(cookies.getAll());
    }
}

function handleLogout(instance, cookies) {

    //console.log("handleLogout")
    removeMsalInteractionFromCookies(cookies);

    instance.logoutRedirect(logoutRequest).catch(e => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        console.error(e);
    });
}
/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = (props) => {
    const { instance } = useMsal();
    const [cookies] = useState(() => new Cookies());
    const [switchOptionDialog, setswitchOptionDialogFlag] = useState(false);
    const dialogFuncMap = {
        'switchOptionDialog': setswitchOptionDialogFlag,
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const onClick = (name, position) => {
        console.log("clicked")
        dialogFuncMap[`${name}`](true);
    }

    return (
        <>
            <div className="ml-auto">
                <div class="btn-group">
                    <button type="button" class="btn btn-primary"><b><i className="fa fa-user-circle"></i> &nbsp; {props.account.name}</b></button>
                    <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item">{props.account.username}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#" onClick={() => onClick('switchOptionDialog')}>Switch Environment <i className="fa fa-random"></i></a>
                        <div class="dropdown-divider"></div>
                        <Dropdown.Item onClick={() => handleLogout(instance, cookies)}><b> Sign Out </b> <i className="fa fa-sign-out"></i></Dropdown.Item>
                    </div>

                    <Dialog header='Switch Environment'
                        visible={switchOptionDialog} modal={true}
                        onHide={() => onHide('switchOptionDialog')} maximizable={false}>
                        <div>
                            <table>
                                <tr>
                                    <td><Button label="DEV/iDEV" className="p-button-raised" onClick={() => window.open('https://monitoringtool.dev.northwell.io/', "_self")} /></td>
                                    <td><Button label="QA" className="p-button-raised" onClick={() => window.open('https://monitoringtool.qa.northwell.io/', "_self")} /></td>
                                    <td><Button label="STAGE" className="p-button-raised" onClick={() => window.open('https://monitoringtool.stg.northwell.io/', "_self")} /></td>
                                    <td><Button label="PROD" className="p-button-raised" onClick={() => window.open('https://monitoringtool.northwell.io/', "_self")} /></td>
                                </tr>
                            </table>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    );
}