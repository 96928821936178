import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { NavLink } from 'react-router-dom';
import CPUComponent from './cpu/CPUComponent';
import MemoryComponent from './memory/MemoryComponent';
import SwapComponent from './swap/SwapComponent';
import InstancesComponent from './instances/InstancesComponent';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';

import './ServerStatsComponent.css';

class ServerStatsComponent extends Component {
    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="serverStatsContainer">
                    {/* <NavLink key="0" to={`${this.props.match.params.server}/cpu`}><CPUComponent {...this.props} /></NavLink>
                <NavLink key="1" to={`${this.props.match.params.server}/memory`}><MemoryComponent {...this.props} /></NavLink>
                <NavLink key="2" to={`${this.props.match.params.server}/swap`}><SwapComponent {...this.props} /></NavLink> */}
                    <InstancesComponent key="3" {...this.props} />
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}

//export default ServerStatsComponent;
const WrappedServerStatsComponent = withMsal(ServerStatsComponent);
export default withAITracking(reactPlugin, WrappedServerStatsComponent);