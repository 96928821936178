import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import HIEMetricService from '../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import './EventLogComponent.css';

class EventLogComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            EventLogs: [],
            loading: true,
        };
        this.hieMetricService = new HIEMetricService();
        this.getTableData = this.getTableData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getTableData();
    }

    getTableData() {
        this.hieMetricService.getEventLogs(this.props.match.params.server + ':' + this.props.match.params.instance).then(EventLogs => {
            if (this._isMounted) this.setState({ EventLogs, loading: false });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    formatTimestring(rowData, column) {
        return rowData.TimeLogged.replace('T', ' ');
    }

    getUrlSessionId(rowData, column) {
        let server = rowData.SystemName.match(/[a-z]/i) ? rowData.SystemName.slice(0, -1) : rowData.SystemName;
        let url = `http://${server}.nshs.edu/csp/healthshare/${rowData.Namespace}/EnsPortal.VisualTrace.zen?SESSIONID=${rowData.SessionId}`;
        return <a href={url} target="_blank">{rowData.SessionId}</a>;
    }


    render() {
        return (
            <div className="EventLogContainer component">
                <Accordion activeIndex={0}>
                    <AccordionTab header="Event Log" headerStyle={{ textAlign: 'left' }}>
                        <DataTable value={this.state.EventLogs} selectionMode="single" autoLayout={true} loading={this.state.loading} paginator={true} rows={10} rowsPerPageOptions={[10, 20, 50, 100]} showGridlines={true}>
                            <Column field="Namespace" header="Namespace" style={{ textAlign: 'left' }} sortable />
                            <Column field="TimeLogged" header="Time Logged" body={this.formatTimestring} sortable />
                            <Column field="ConfigName" header="Config Name" style={{ textAlign: 'left' }} sortable />
                            <Column field="SessionId" header="Session Id" body={this.getUrlSessionId} sortable />
                            <Column field="Text" header="Text" style={{ textAlign: 'left' }} sortable />
                            <Column field="SourceClass" header="Source Class" style={{ textAlign: 'left' }} sortable />
                            <Column field="SourceMethod" header="Source Method" style={{ textAlign: 'left' }} sortable />
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </div>
        );
    }
}

//export default EventLogComponent;
export default withAITracking(reactPlugin, EventLogComponent);