import React, { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../AppInsights';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { msalInstance } from '../../App';
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InboundActivityService } from './InboundActivityService';
import { Checkbox } from 'primereact/checkbox';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import "./InboundActivityComponent.css";
import Spinner from '../Spinner'

class InboundDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this._isIssueDetailsMounted = false;
        this.state = {
            hideSpinner: false,
            inboundMetric: {
                Id: 0,
                System: null,
                Instance: null,
                Namespace: null,
                MetricType: 0,
                TimeCollected: null,
                InterfaceName: null,
                NumMessages: 0,
                LastInboundTimeStamp: null,
                CurrentFrequencyInSeconds: 0,
                CurrentFrequency: null,
                InactivityTimeout: 0,
                ExpectedFrequency: null,
                IsBackloadService: null,
                IsAnomaly: null,
                Decommissioned: 0,
                Comments: null,
                ModifiedBy: null,
                ModifiedDateTime: null,
                IsReviewed: null,
                EditReason: null
            },
            inboundMetricDetails: [],
            inboundMetricUpdateLogs: [],
            showInboundMetricUpdateLogs: false,
            hasReadRights: true,
            EditInboundMetricErrorMessage: '',
            actionCommentPanelCollasped: true,
            showpagination: false,
            isAdminWrite: false,
            statusData: null
        };
        this.loadInboundMetricDetails = this.loadInboundMetricDetails.bind(this);
        this.validateEditInboundFormField = this.validateEditInboundFormField.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.inboundActivityService = new InboundActivityService();
    }

    componentDidMount() {
        this._isIssueDetailsMounted = true;
        this.loadInboundMetricDetails()
    }

    loadInboundMetricDetails() {
        this.setState({ pageErrorMessage: '', EditInboundMetricErrorMessage: null })
        this.inboundActivityService.getInboundDataById(this.props.match.params.id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                    this.setState({ hasReadRights: false });
                } else if (d.status === 200) {
                    if (this._isIssueDetailsMounted) {
                        this.setState({ inboundMetricDetails: d.body, hideSpinner: true });
                        this.handleInboundDetailsEdit()
                    }
                }
            }
        ).catch(e => {
            this.setState({ hideSpinner: true, pageErrorMessage: 'Something went wrong !!!' })
            console.log(e)
        });
    }

    handleViewHistoryChange() {
        this.setState({ hideSpinner: false, pageErrorMessage: '', EditInboundMetricErrorMessage: null })
        this.inboundActivityService.getInboundMetricUpdatelogs(this.props.match.params.id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                    this.setState({ hasReadRights: false });
                } else if (d.status === 200) {
                    if (this._isIssueDetailsMounted) {
                        this.setState({ inboundMetricUpdateLogs: d.body, hideSpinner: true, showInboundMetricUpdateLogs: true });
                        if (this.state.inboundMetricUpdateLogs.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            this.setState({ hideSpinner: true, pageErrorMessage: 'Something went wrong !!!' })
            console.log(e)
        });
    }

    handleInboundDetailsEdit(IsEdit = false) {
        this.setState({
            inboundMetric: {
                EditReason: '',
                Id: this.state.inboundMetricDetails[0].Id,
                System: this.state.inboundMetricDetails[0].System,
                Instance: this.state.inboundMetricDetails[0].Instance,
                Namespace: this.state.inboundMetricDetails[0].Namespace,
                MetricType: this.state.inboundMetricDetails[0].MetricType,
                TimeCollected: this.state.inboundMetricDetails[0].TimeCollected,
                InterfaceName: this.state.inboundMetricDetails[0].InterfaceName,
                NumMessages: this.state.inboundMetricDetails[0].NumMessages,
                LastInboundTimeStamp: this.state.inboundMetricDetails[0].LastInboundTimeStamp,
                CurrentFrequencyInSeconds: this.state.inboundMetricDetails[0].CurrentFrequencyInSeconds,
                CurrentFrequency: this.state.inboundMetricDetails[0].CurrentFrequency,
                InactivityTimeout: this.state.inboundMetricDetails[0].InactivityTimeout,
                ExpectedFrequency: this.state.inboundMetricDetails[0].ExpectedFrequency,
                IsBackloadService: this.state.inboundMetricDetails[0].IsBackloadService,
                IsAnomaly: this.state.inboundMetricDetails[0].IsAnomaly,
                Comments: this.state.inboundMetricDetails[0].Comments,
                ModifiedBy: this.state.inboundMetricDetails[0].ModifiedBy,
                ModifiedDateTime: this.state.inboundMetricDetails[0].ModifiedDateTime,
                IsReviewed: this.state.inboundMetricDetails[0].IsReviewed,
                Decommissioned: this.state.inboundMetricDetails[0].Decommissioned,
            },
            EditInboundVisible: IsEdit
        });
    }

    handleInputChange(event) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, EditInboundMetricErrorMessage: null })
        if (event.target.name === "InactivityTimeout") {
            this.setState({
                inboundMetric: {
                    ...this.state.inboundMetric, //using spread operator to initialize object with existing props
                    [event.target.name]: parseInt(event.target.value, 10),
                    ["ExpectedFrequency"]: this.formatSeconds(event.target.value)
                }
            })
        }
        else if (event.target.name === "IsBackloadService" || event.target.name === "IsReviewed"
            || event.target.name === "Decommissioned" || event.target.name === "IsAnomaly") {
            this.setState({
                inboundMetric: {
                    ...this.state.inboundMetric, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.checked
                }
            })
        }
        else {
            this.setState({
                inboundMetric: {
                    ...this.state.inboundMetric, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value,
                }
            })
        }
    }

    async validateEditInboundFormField(event, onSave = false) {
        this.setState({ EditInboundMetricErrorMessage: '' });
        if (onSave) {

            if (event.EditReason === null || event.EditReason === '') {
                this.setState({ EditInboundMetricErrorMessage: 'Edit Reason is required' });
                return false;
            }
            else if (event.InactivityTimeout === null || event.InactivityTimeout === '' || event.InactivityTimeout <= 0) {
                this.setState({ EditInboundMetricErrorMessage: 'Inbound Threshold Frequency is required' });
                return false;
            } else if (event.EditReason.trim().length < global.COMMENT_BOX_MIN_LENGTH) {
                this.setState({ EditInboundMetricErrorMessage: 'Edit Reason should be atleast of ' + global.COMMENT_BOX_MIN_LENGTH + ' characters.', disableNotificationVisible: false })
            }
            else if (event.EditReason.trim().length > 0 || event.Comments.length > 0) {
                //HM-708 bug fix
                var onlyValidEditReasonCharacters = /^[^'"]*$/.test(event.EditReason.trim());
                var onlyCommentsCharacters = /^[^'"]*$/.test(event.Comments);
                if (!onlyValidEditReasonCharacters || !onlyCommentsCharacters) {
                    this.setState({ EditInboundMetricErrorMessage: "Action Comments/Edit Reason should not contain single (') or double (\") quotes.", disableNotificationVisible: false })
                }
                else {
                    this.setState({ EditInboundMetricErrorMessage: '' });
                    this.onSaveInboundDetails();
                }
            }
        }
    }

    formatSeconds(totalseconds) {
        if (totalseconds >= 0) {
            var day = 86400;
            var hour = 3600;
            var minute = 60;
            var daysout = Math.floor(totalseconds / day);
            var hoursout = Math.floor((totalseconds - daysout * day) / hour);
            var minutesout = Math.floor((totalseconds - daysout * day - hoursout * hour) / minute);
            var secondsout = Math.floor(totalseconds - daysout * day - hoursout * hour - minutesout * minute);
            return daysout + "d " + hoursout + "h " + minutesout + "m " + secondsout + "s"
        }
        else
            return "0d 0h 0m 0s"
    }

    onSaveInboundDetails() {
        this.setState({ hideSpinner: false, actionCommentPanelCollasped: false })
        let requestBody = JSON.stringify(this.state.inboundMetric)
        this.inboundActivityService.editInboundDataById(this.props.match.params.id, this.props.msalContext, requestBody).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                    this.setState({ hasReadRights: false });
                } else if (d.status === 200) {
                    this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
                    if (this._isIssueDetailsMounted) {
                        this.setState({ inboundMetricDetails: d.body, hideSpinner: true, showInboundMetricUpdateLogs: false, pageSuccessMessage: 'Changes saved successfully.' });
                        this.clearInboundMetricFields();
                    }
                }
            }
        ).catch(e => {
            this.setState({ hideSpinner: true, pageErrorMessage: 'Something went wrong !!!' })
            console.log(e)
        });
    }

    clearInboundMetricFields() {
        this.setState({
            inboundMetric: {
                Id: 0,
                System: null,
                Instance: null,
                Namespace: null,
                MetricType: 0,
                TimeCollected: null,
                InterfaceName: null,
                NumMessages: 0,
                LastInboundTimeStamp: null,
                CurrentFrequencyInSeconds: 0,
                CurrentFrequency: null,
                InactivityTimeout: 0,
                ExpectedFrequency: null,
                IsBackloadService: null,
                Decommissioned: 0,
                Comments: null,
                ModifiedBy: null,
                ModifiedDateTime: null,
                IsReviewed: null,
                EditReason: ''
            },
            EditInboundVisible: false,
            pageSuccessMessage: null,
            pageErrorMessage: null,
            EditInboundMetricErrorMessage: null
        });
    }

    saveFooter = (
        <div>
            <Button label='Save' onClick={() => this.validateEditInboundFormField(this.state.inboundMetric, true)} />
        </div>
    );

    reviewedBodyTemplate(rowData) {
        let body = ""
        if (rowData.IsReviewed) {
            body = <i className='pi pi-check-circle'></i>;
        } else if (rowData.CurrentFrequencyInSeconds > rowData.InactivityTimeout) {
            body = <i className='pi pi-times-circle'></i>;
        }
        return body;
    }

    backloadBodyTemplate(rowData) {
        let body = ""
        if (rowData.IsBackloadService) {
            body = <i className='pi pi-check-circle'></i>;
        } else {
            body = <i className='pi pi-times-circle'></i>;
        }
        return body;
    }

    isAnomalyTemplate(rowData) {
        //HDO-5544 - Message Volume Anomaly UI
        let body = ""
        if (rowData.IsAnomaly) {
            body = <i className='pi pi-exclamation-triangle'></i>;
        }
        return body;
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        let showButtons, actionCommentsText;
        if (this.state.hideSpinner) {
            if (this.state.inboundMetricDetails.length > 0) {
                if (this.state.inboundMetricDetails[0].Comments) {
                    actionCommentsText = this.state.inboundMetricDetails[0].Comments
                }
                else {
                    actionCommentsText = "<i>Nothing has been specified....</i>"
                }
            }
            showButtons = <div>
                <Button label='Back' icon='pi pi-arrow-left' onClick={() => this.props.history.push('/monitor/inboundmetric')} /> &nbsp;
                <Button label='Edit' icon='pi pi-user-edit' onClick={() => this.handleInboundDetailsEdit(true)} /> &nbsp;
                <Button label='History' icon='pi pi-history' onClick={() => this.handleViewHistoryChange()} />
            </div>
        }
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className='InboundMetricDetailscontainer'>

                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <header className="page-header">
                        <div className="page-header-title">INBOUND COMPONENT DETAILS</div>
                    </header>

                    <DataTable value={this.state.inboundMetricDetails}
                        selectionMode="single"
                        autoLayout={true}
                        isHidden={!this.state.hasReadRights}
                        editable="true"
                        editMode="cell"
                        showGridlines={true}
                    >
                        {/* <Column field="Id" header="#ID" style={{ textAlign: 'left', width: '60px' }} /> */}
                        <Column field="IsReviewed" header="Reviewed" dataType="boolean" style={{ textAlign: 'center', width: '4rem' }} body={this.reviewedBodyTemplate} />
                        <Column field="System" header='System' style={{ textAlign: 'left' }} />
                        <Column field="Instance" header='Instance' style={{ textAlign: 'left', width: '100px' }} />
                        <Column field="Namespace" header='Namespace' style={{ textAlign: 'left', width: '100px' }} />
                        <Column field="InterfaceName" header='Interface Name' style={{ textAlign: 'left', width: '100px' }} />
                        <Column field="TimeCollected" header="Time Collected (EST)" style={{ textAlign: 'left', minwidth: '250px' }} />
                        <Column field="LastInboundTimeStamp" header="Last Inbound (EST)" style={{ textAlign: 'left', minwidth: '250px' }} />
                        <Column field="CurrentFrequency" header="No Activity Since" style={{ textAlign: 'left', width: '130px' }} />
                        <Column field="ExpectedFrequency" header="Expected Frequency" style={{ textAlign: 'left', width: '100px' }} />
                        <Column field="IsBackloadService" header="Backload" dataType="boolean" style={{ textAlign: 'center', width: '4rem' }} body={this.backloadBodyTemplate} />
                        <Column field="IsAnomaly" header="Anomaly Alert" dataType="boolean" style={{ textAlign: 'center', width: '111px' }} body={this.isAnomalyTemplate} />
                    </DataTable> <br />

                    <Accordion activeIndex={0}>
                        <AccordionTab header="Action Comments" headerStyle={{ textAlign: 'left' }}>
                            < div className='action-comments' dangerouslySetInnerHTML={{ __html: actionCommentsText }}></div >
                        </AccordionTab>
                    </Accordion> <br />

                    <center>{showButtons}</center><br />

                    <div className="inboundMetricsUpdateLogs" style={{ display: this.state.showInboundMetricUpdateLogs && this.state.inboundMetricUpdateLogs.length ? "block" : "none" }}>
                        <DataTable value={this.state.inboundMetricUpdateLogs}
                            selectionMode="single"
                            autoLayout={true}
                            //loading={this.state.loadingdata}
                            rowClassName={this.rowMirrorStyle}
                            isHidden={!this.state.hasReadRights}
                            paginator={this.state.showpagination}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            editable="true"
                            editMode="cell"
                            showGridlines={true}
                        >
                            <Column
                                field="IsReviewed"
                                header="Reviewed"
                                dataType="boolean" style={{ textAlign: 'center', width: '4rem' }} body={this.reviewedBodyTemplate} />
                            <Column
                                field="DisplayTimestamp"
                                header="DateTime (EST)"
                                style={{ textAlign: 'left' }} />
                            <Column
                                field="UpdatedBy"
                                header="Updated By"
                                style={{ textAlign: 'left' }} />
                            <Column
                                field="ActionComments"
                                header="Action Comments"
                                style={{ textAlign: 'left' }} />
                            <Column
                                field="Comments"
                                header="Edit Reason"
                                style={{ textAlign: 'left' }} />
                        </DataTable>
                        <br />
                    </div>

                    <Dialog header='Edit Inbound Details' footer={this.saveFooter} width='750px'
                        visible={this.state.EditInboundVisible} modal={true}
                        onHide={() => this.setState({ EditInboundVisible: false })} maximizable={false}>
                        <div>
                            <table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td colSpan={1}>
                                            <Checkbox name='IsReviewed' inputId="cb1" value="Active" onChange={this.handleInputChange} checked={this.state.inboundMetric.IsReviewed}></Checkbox>&nbsp;
                                            <label htmlFor="cb1" className="p-checkbox-label d-inline">Reviewed</label>
                                        </td>
                                        <td colSpan={1}>
                                            <Checkbox name='IsBackloadService' inputId="cb1" value="Active" onChange={this.handleInputChange} checked={this.state.inboundMetric.IsBackloadService}></Checkbox>&nbsp;
                                            <label htmlFor="cb1" className="p-checkbox-label d-inline">Backload Service</label>
                                        </td>
                                        <td colSpan={1}>
                                            <Checkbox name='Decommissioned' inputId="cb1" value="Active" onChange={this.handleInputChange} checked={this.state.inboundMetric.Decommissioned}></Checkbox>&nbsp;
                                            <label htmlFor="cb1" className="p-checkbox-label d-inline">Decommissioned</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <label className='required-field'>Inbound Threshold Frequency (Seconds)</label>
                                            <InputText type='text' name='InactivityTimeout' value={this.state.inboundMetric.InactivityTimeout || 0}
                                                onChange={this.handleInputChange} onBlur={this.validateEditInboundFormField} />
                                        </td>
                                        <td colSpan={2}>
                                            <label>Inbound Threshold Frequency</label>
                                            <label>{this.state.inboundMetric.ExpectedFrequency}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <label>Action Comments
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge" data-pr-tooltip="Use HTML syntax for decorated text" data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                            </label>
                                            <InputTextarea type='text' name='Comments' rows="5" cols="160" value={this.state.inboundMetric.Comments}
                                                onChange={this.handleInputChange} onBlur={this.validateEditInboundFormField} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <label className='required-field'>Edit Reason
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge" data-pr-tooltip={'Edit Reason should be atleast of ' + global.COMMENT_BOX_MIN_LENGTH + ' characters'} data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                            </label>
                                            <InputTextarea type='text' name='EditReason' rows="5" cols="160" value={this.state.inboundMetric.EditReason}
                                                onChange={this.handleInputChange} onBlur={this.validateEditInboundFormField} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* {this.state.EditInboundMetricErrorMessage ? <div className='text-danger text-center'>{this.state.EditInboundMetricErrorMessage}</div> : <></>} */}
                            {this.state.EditInboundMetricErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.EditInboundMetricErrorMessage) : <></>}
                        </div>
                    </Dialog>
                </div>
            </MsalAuthenticationTemplate >
        )
    }
}

const WrappedInboundDetailsComponent = withMsal(InboundDetailsComponent);
export default withAITracking(reactPlugin, WrappedInboundDetailsComponent);
