import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import { InteractionType } from "@azure/msal-browser";
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import { MonitorService } from '../MonitorService'
import AlertMessage from "../../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import Spinner from '../../Spinner'
import './MirrorsMonitor.css';

//HM-891 - Latest Queue Count GUI
class MirrorsMonitorComponent extends Component {
    constructor() {
        super();
        this._isMirrorsMounted = false;
        this.state = {
            MirrorList: [],
            MappingList: [],
            InstanceList: [],
            hideSpinner: false,
            pageErrorMessage: '',
            hasReadRights: true,
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'SystemName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'Instance': { value: null, matchMode: FilterMatchMode.IN },
                'MemberName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            },
            selectedAutoRefresh: 0,
            intervalId: null,
            autoRefreshOptions: [
                { label: 'Auto-Refresh Off', value: 0 },
                { label: '5 minute', value: 300000 },
                { label: '10 minute', value: 600000 },
                { label: '15 minute', value: 900000 },
                { label: '30 minute', value: 1800000 }
            ]
        };

        this.baseAPIUrl = global.ENV_HIE_ADMIN_API_AZURE_URL;
        this.getMirrorStatusData = this.getMirrorStatusData.bind(this)
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.globalSearchFilter = this.globalSearchFilter.bind(this);
        this.clearDataTableFilters = this.clearDataTableFilters.bind(this);
        this.mirrorStatusBodyTemplate = this.mirrorStatusBodyTemplate.bind(this);
        this.monitorService = new MonitorService();
    }

    componentDidMount() {
        this._isMirrorsMounted = true;
        this.getMirrorStatusData();
    }

    getMirrorStatusData() {
        this.setState({ hideSpinner: false })
        this.monitorService.getMirrorStatusData(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                    this.setState({ hasReadRights: false });
                } else {
                    if (this._isMirrorsMounted) {
                        this.setState({
                            MirrorList: d.body,
                            InstanceList: [...new Set(d.body.map(x => x.Instance))],
                            hideSpinner: true
                        });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    clearDataTableFilters() {
        this.setState({
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'SystemName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'Instance': { value: null, matchMode: FilterMatchMode.IN },
                'MemberName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            }
        });
        sessionStorage.clear();
    }

    //HM-935 - change to save the filter
    onGlobalFilterChange(event, filtersKey) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter(filtersKey) {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                    <span>
                        <Dropdown value={this.state.selectedAutoRefresh} options={this.state.autoRefreshOptions} onChange={this.onAutoRefresh} placeholder="Auto-Refresh"
                            tooltip='Auto-Refresh' tooltipOptions={{ position: 'top' }} className="ui-column-filter" />
                        <Button hidden={this.state.selectedAutoRefresh !== 0} tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.getMirrorStatusData} icon="pi pi-refresh" />
                    </span>
                </div>
            </div>
        )
    }

    onAutoRefresh = (e) => {
        let interval = {}
        let selectedAutoRefresh = e.value;
        clearInterval(this.state.intervalId);

        if (e.value && e.value > 0) {
            interval = setInterval(() => {
                //console.log('Logs every - ' + selectedAutoRefresh + ' ms - ' + new Date());
                this.getMirrorStatusData();
            }, selectedAutoRefresh);
        }
        this.setState({ selectedAutoRefresh: e.value, intervalId: interval })
    }

    instanceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.InstanceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="Instance" className="multiselect-custom"
        />;
    }

    formatBooleanValue(rowData, column) {
        return (
            <div>
                {String(Boolean(rowData[column.field]))}
            </div>
        );
    }

    mirrorStatusBodyTemplate(rowData) {
        return <span className={`mirror-status-badge status-${rowData.CurrentRole.toLowerCase()}`}>{rowData.CurrentRole}</span>;
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="adminContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}

                    <div hidden={this.state.hideSpinner}><Spinner /></div>

                    <div hidden={!this.state.hasReadRights}>
                        <header className="page-header">
                            <div className="page-header-title">MIRROR STATUS</div>
                        </header>
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.MirrorList}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            dataKey="Id"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            showGridlines removableSort
                            emptyMessage="No Records found."
                            onFilter={(e) => this.setState({ filters: e.filters })} //HM-935 - change to save the filter
                            stateStorage="session" stateKey="dt-mirror-status-session"
                        >
                            <Column field="CurrentRole" header="Mirror Status" body={this.mirrorStatusBodyTemplate} style={{ textAlign: 'left', minWidth: '140px' }} sortable />
                            <Column field="SystemName" header="HostName" filter filterPlaceholder='Host Name' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            <Column field="Instance" header="Instance" filter filterPlaceholder='Instance' filterElement={this.instanceFilterTemplate} showFilterMatchModes={false} style={{ textAlign: 'left' }} />
                            <Column field="MemberName" header="Member Name" filter filterPlaceholder='Member Name' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            <Column field="DisplayType" header="Member Type" style={{ textAlign: 'left' }} />
                            <Column field="JournalTimeLatency" header="Journal Transfer" style={{ textAlign: 'left' }} sortable />
                            <Column field="DatabaseTimeLatency" header="Dejournaling" style={{ textAlign: 'left' }} sortable />
                            <Column field="IscagentStatus" header="ISC Agent Status" style={{ textAlign: 'left' }} body={this.formatBooleanValue} />
                            <Column field="ArbiterNode" header="Arbiter Node" style={{ textAlign: 'left' }} sortable />
                            <Column field="MirrorFailoverMode" header="Arbiter Failover Mode" style={{ textAlign: 'left' }} />
                            <Column field="ArbiterConnectionStatus" header="Arbiter Conn Status" style={{ textAlign: 'left' }} body={this.formatBooleanValue} />
                            <Column field="TimeCollected" header="Last Updated" style={{ textAlign: 'left' }} sortable />
                        </DataTable>
                    </div>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
};
const WrappedMirrorsMonitorComponent = withMsal(MirrorsMonitorComponent);
//export default WrappedMirrorsMonitorComponent;
export default withAITracking(reactPlugin, WrappedMirrorsMonitorComponent);