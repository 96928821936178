import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { CustomAzureAlertService } from './CustomAzAlertService';
import { ActionGroupService } from '../actionGroup/ActionGroupService';
import { AutoComplete } from 'primereact/autocomplete';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { InputSwitch } from 'primereact/inputswitch';
import Spinner from '../Spinner'
//import '../AdminComponent.css';

class EditCustomAzAlertComponent extends Component {
    constructor() {
        super();
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            selectedActionGroups: null,
            filteredActionGroups: null,
            autoCompleteReadOnly: false,
            disableNameSpaceDropDown: true,
            disableInterfaceDropDown: true,
            hideSpinner: false,
            customAzureAlertRequest: {
                Subscription: '',
                ResourceGroup: '',
                ResourceName: '',
                Name: '',
                Status: false,
                ResourceType: '',
                AzMonitorAlert: false,
                Priority: 0,
                NotificationBody: '',
                TriggerEmail: false,
                NotificationSubject: '',
                ActionGroupIds: '',
                ServiceNowGroup: null,
                JiraUserName: null,
                AlertRule: '', // same as Alert rule name
                ResendFrequency: 0
            },
            resourceTypeList: [],
            priorityList: [
                { label: 'P1', value: 1 },
                { label: 'P2', value: 2 },
                { label: 'P3', value: 3 },
                { label: 'P4', value: 4 }
            ],
            actiongroups: [],
            servicenowgroups: [],
            confirmEditVisible: false,
        }
        this.INTERFACE_DROPDOWN_LIMIT = global.INTERFACE_DROPDOWN_LIMIT
        this.customAzureAlertService = new CustomAzureAlertService();
        this.actionGrpService = new ActionGroupService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateEditCustomAlertField = this.validateEditCustomAlertField.bind(this);
        this.searchActionGroup = this.searchActionGroup.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getActionGroupsData();
    }

    getActionGroupsData() {
        this.setState({ hideSpinner: false, servicenowgroups: [{ label: 'NONE', value: '' }], pageErrorMessage: null, pageSuccessMessage: null })
        this.actionGrpService.getActionGroups(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else if (d.status === 200) {
                    if (this._isMounted) {
                        this.setState({ actiongroups: d.body.ActionGroups });
                        d.body.ServiceNowGroups.map((item) => (this.state.servicenowgroups.push({ label: item.Name, value: item.Name })))
                        this.getCustomHieAlertRuleById();
                    }
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong !!!', hideSpinner: true });
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getCustomHieAlertRuleById() {
        this.customAzureAlertService.getCustomAzureAlertRuleById(this.props.match.params.id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted && d.status === 200) {
                        this.setState({ customAzureAlertRequest: d.body, selectedActionGroups: d.body.ActionGroupList });
                        this.getAzureResourceGroup();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getAzureResourceGroup() {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.customAzureAlertService.getAzureResourceGroup(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.resourceTypeList.push({ label: item.Name, value: item.Name })));
                        this.setState({ hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    arIntVals = ['Priority', 'ResendFrequency'];
    arBoolVals = ['Status', 'AzMonitorAlert', 'TriggerEmail'];

    handleInputChange(event) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null });
        let found = false;
        for (let i = 0; i < this.arBoolVals.length; i++) {
            if (event.target.name === this.arBoolVals[i]) {
                found = true;
                this.setState({
                    customAzureAlertRequest: {
                        ...this.state.customAzureAlertRequest, //using spread operator to initialize object with existing props
                        [event.target.name]: event.target.checked
                    }
                })
                break;
            }
        }
        for (let i = 0; i < this.arIntVals.length; i++) {
            if (event.target.name === this.arIntVals[i]) {
                found = true;
                this.setState({
                    customAzureAlertRequest: {
                        ...this.state.customAzureAlertRequest, //using spread operator to initialize object with existing props
                        [event.target.name]: parseInt(event.target.value, 10)
                    }
                })
                break;
            }
        }

        if (!found) {
            this.setState({
                customAzureAlertRequest: {
                    ...this.state.customAzureAlertRequest, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    async validateEditCustomAlertField(customAzureAlertRequest, onSave = false) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, confirmEditVisible: false })
        if (this.state.selectedActionGroups && this.state.selectedActionGroups.length > 0) {
            let alertGroupId = this.state.selectedActionGroups.map(a => a.Id)
            customAzureAlertRequest.ActionGroupIds = alertGroupId.join(",")
            this.setState({ customAzureAlertRequest: { ...this.state.customAzureAlertRequest, ActionGroupIds: alertGroupId.join(",") } })
        }
        else {
            customAzureAlertRequest.ActionGroupIds = ''
        }
        if (onSave) {
            var alertNameText = /^[^'"]*$/.test(customAzureAlertRequest.Name);
            var notificationSubjectText = /^[^'"]*$/.test(customAzureAlertRequest.NotificationSubject);
            var resourceGroup = /^[^'"]*$/.test(customAzureAlertRequest.ResourceGroup);
            var resourceName = /^[^'"]*$/.test(customAzureAlertRequest.ResourceName);
            var subscription = /^[^'"]*$/.test(customAzureAlertRequest.Subscription);
            var validateServiceNowGrp = /[^a-zA-Z0-9 ]/.test(customAzureAlertRequest.ServiceNowGroup);
            var validateJiraUser = /[^a-zA-Z0-9]/.test(customAzureAlertRequest.JiraUserName);

            if (customAzureAlertRequest.Name === null || customAzureAlertRequest.Name === '') {
                this.setState({ pageErrorMessage: 'Alert Rule Name is required.' });
                return false;
            }
            else if (customAzureAlertRequest.Name.trim().length > 50) {
                this.setState({ pageErrorMessage: 'Alert Rule Name cannot be more than 50 characters.' })
                return false;
            }
            else if (!alertNameText) {
                this.setState({ pageErrorMessage: "Alert Rule Name should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (customAzureAlertRequest.Subscription === null || customAzureAlertRequest.Subscription === '') {
                this.setState({ pageErrorMessage: 'Subscription is required.' });
                return false;
            }
            else if (customAzureAlertRequest.Subscription.trim().length > 100) {
                this.setState({ pageErrorMessage: 'Subscription cannot be more than 100 characters.' })
                return false;
            }
            else if (!subscription) {
                this.setState({ pageErrorMessage: "Subscription should not contain single (') or double (\") quotes." })
                return false;
            }
            if (customAzureAlertRequest.ResourceGroup === null || customAzureAlertRequest.ResourceGroup === '') {
                this.setState({ pageErrorMessage: 'Resource Group is required.' });
                return false;
            }
            else if (customAzureAlertRequest.ResourceGroup.trim().length > 50) {
                this.setState({ pageErrorMessage: 'Resource Group cannot be more than 50 characters.' })
                return false;
            }
            else if (!resourceGroup) {
                this.setState({ pageErrorMessage: "Resource Group should not contain single (') or double (\") quotes." })
                return false;
            }
            if (customAzureAlertRequest.ResourceName === null || customAzureAlertRequest.ResourceName === '') {
                this.setState({ pageErrorMessage: 'Resource Name is required.' });
                return false;
            }
            else if (customAzureAlertRequest.ResourceName.trim().length > 500) {
                this.setState({ pageErrorMessage: 'Resource Name cannot be more than 500 characters.' })
                return false;
            }
            else if (!resourceName) {
                this.setState({ pageErrorMessage: "Resource Name should not contain single (') or double (\") quotes." })
                return false;
            }
            if (customAzureAlertRequest.ResourceType === null || customAzureAlertRequest.ResourceType === '') {
                this.setState({ pageErrorMessage: 'Resource Type is required.' });
                return false;
            }
            else if ((customAzureAlertRequest.Priority === null || customAzureAlertRequest.Priority === 0) && !customAzureAlertRequest.AzMonitorAlert) {
                this.setState({ pageErrorMessage: 'Priority is required' })
                return false;
            }
            else if (customAzureAlertRequest.NotificationSubject === null || customAzureAlertRequest.NotificationSubject === '') {
                this.setState({ pageErrorMessage: 'Notification Subject is required.' });
                return false;
            }
            else if (customAzureAlertRequest.NotificationSubject.trim().length > 50) {
                this.setState({ pageErrorMessage: 'Notification Subject cannot be more than 50 characters.' })
                return false;
            }
            else if (!notificationSubjectText) {
                this.setState({ pageErrorMessage: "Notification Subject should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (customAzureAlertRequest.ActionGroupIds === null || customAzureAlertRequest.ActionGroupIds === '') {
                this.setState({ pageErrorMessage: 'Atleast one action group is required.' });
                return false;
            }
            else if (validateServiceNowGrp) {
                this.setState({ pageErrorMessage: "Service Now group should not contain any special characters." })
                return false;
            }
            else if (validateJiraUser) {
                this.setState({ pageErrorMessage: "Jira assignee should not contain any space/special characters." })
                return false;
            }
            else if (!customAzureAlertRequest.ResendFrequency || customAzureAlertRequest.ResendFrequency === ""
                || customAzureAlertRequest.ResendFrequency === 0 || customAzureAlertRequest.ResendFrequency <= 5) {
                this.setState({ pageErrorMessage: "Resend Frequency should be greater than 5 minutes" })
                return false;
            }
            else {
                this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
                this.UpdateCustomHieAlertRule(customAzureAlertRequest)
            }
        }
        else {
            const propName = customAzureAlertRequest.target.name;
            const value = customAzureAlertRequest.target.value;
            switch (propName) {
                case 'Name':
                case 'NotificationSubject':
                    if (value === '' || value === null || value <= 0) {
                        this.setState({ pageErrorMessage: propName + ' is required.' });
                        return false;
                    }
                default:
                    break;
            }
        }
    }

    UpdateCustomHieAlertRule(customAzureAlertRequest) {
        this.setState({ hideSpinner: false, pageErrorMessage: '', pageSuccessMessage: '' })
        customAzureAlertRequest.AlertRule = customAzureAlertRequest.Name;
        customAzureAlertRequest.Priority = customAzureAlertRequest.AzMonitorAlert ? 0 : customAzureAlertRequest.Priority;
        let body = JSON.stringify(customAzureAlertRequest);
        this.customAzureAlertService.editCustomAzureAlertRule(this.props.match.params.id, body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to perform this operation.', hideSpinner: true });
                } else if (this._isMounted && d.status === 200) {
                    if (d.body.Id > 0) {
                        this.setState({ pageSuccessMessage: 'Changes saved successfully.', hideSpinner: true })
                    }
                    else {
                        this.setState({ pageErrorMessage: 'Rule save failed.', hideSpinner: true });
                    }
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
                }
            }
        ).catch(e => {
            this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
            console.log(e)
        });
    }

    onResourceTypeChange = (resourceType) => {
        if (resourceType !== null && resourceType !== undefined) {
            this.setState({
                customAzureAlertRequest: {
                    ...this.state.customAzureAlertRequest, ResourceType: resourceType.value
                }, pageErrorMessage: null, pageSuccessMessage: null
            })
        }
    }

    onPriorityChange = (priority) => {
        if (priority !== null && priority !== undefined) {
            this.setState({ customAzureAlertRequest: { ...this.state.customAzureAlertRequest, Priority: priority.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    onServiceNowGrpChange = (group) => {
        if (group !== null && group !== undefined) {
            this.setState({ customAzureAlertRequest: { ...this.state.customAzureAlertRequest, ServiceNowGroup: group.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    onStatusSwitchChange = (e) => {
        this.setState({ customAzureAlertRequest: { ...this.state.customAzureAlertRequest, Status: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    onTriggerEmailSwitchChange = (e) => {
        this.setState({ customAzureAlertRequest: { ...this.state.customAzureAlertRequest, TriggerEmail: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    onAzMonitorAlertSwitchChange = (e) => {
        this.setState({ customAzureAlertRequest: { ...this.state.customAzureAlertRequest, AzMonitorAlert: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    searchActionGroup(event) {
        if (this.state.selectedActionGroups && this.state.selectedActionGroups.length >= global.ACTION_GROUP_LIMIT) {
            this.setState({ autoCompleteReadOnly: true, filteredActionGroups: null, pageErrorMessage: "You can add up to " + global.ACTION_GROUP_LIMIT + " action groups to each alert rule." })
        }
        else {
            setTimeout(() => {
                let filteredActionGroups;
                if (!event.query.trim().length) {
                    filteredActionGroups = [...this.state.actiongroups];
                }
                else {
                    filteredActionGroups = this.state.actiongroups.filter((actiongrp) => {
                        return actiongrp.Name.toLowerCase().match(event.query.toLowerCase());
                    });
                }
                this.setState({ filteredActionGroups });
            }, 250);
            this.setState({ pageErrorMessage: null, pageSuccessMessage: null, autoCompleteReadOnly: false })
        }
    }

    confirmEditFooter = (
        <div>
            <Button label='Ok' onClick={() => this.validateEditCustomAlertField(this.state.customAzureAlertRequest, true)} />
            <Button label='Cancel' onClick={e => this.setState({ confirmEditVisible: false, pageErrorMessage: null, pageSuccessMessage: null })} className='p-button-secondary' />
        </div>
    );

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="actiongroupContainer">

                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">EDIT AZURE ALERT RULE</div>
                        </header>
                        {this.state.customAzureAlertRequest.AzMonitorAlert ?
                            <div><Message severity="info" text="Alert Rule Name should be same as mentioned while creating the alert rule in Azure." /><br /><br /></div> : <></>}
                        <div className="card">
                            <table className='w-100'>
                                <tr>
                                    <td colSpan={1}>
                                        <label><b>Enable</b></label>
                                        <InputSwitch checked={this.state.customAzureAlertRequest.Status} onChange={this.onStatusSwitchChange}
                                            tooltip={this.state.customAzureAlertRequest.Status ? 'YES' : 'NO'} />
                                    </td>
                                    <td colSpan={1}>
                                        <label><b>Trigger Email</b></label>
                                        <InputSwitch checked={this.state.customAzureAlertRequest.TriggerEmail} onChange={this.onTriggerEmailSwitchChange}
                                            tooltip={this.state.customAzureAlertRequest.TriggerEmail ? 'YES' : 'NO'} />
                                    </td>
                                    <td colSpan={1}>
                                        <label><b>Azure Monitor Alert</b></label>
                                        <InputSwitch checked={this.state.customAzureAlertRequest.AzMonitorAlert} onChange={this.onAzMonitorAlertSwitchChange}
                                            tooltip={this.state.customAzureAlertRequest.AzMonitorAlert ? 'YES' : 'NO'} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Alert Rule Name</b></label>
                                        <label>{this.state.customAzureAlertRequest.Name || ''}</label>
                                    </td>
                                    <td>
                                        <label className='required-field'><b>Subscription</b></label>
                                        <InputText type='text' name='Subscription' value={this.state.customAzureAlertRequest.Subscription || ''}
                                            onChange={this.handleInputChange} onBlur={this.validateEditCustomAlertField} />
                                    </td>
                                    <td>
                                        <label className='required-field'><b>Resource Group</b></label>
                                        <InputText type='text' name='ResourceGroup' value={this.state.customAzureAlertRequest.ResourceGroup || ''}
                                            onChange={this.handleInputChange} onBlur={this.validateEditCustomAlertField} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Resource Name</b></label>
                                        <InputText type='text' name='ResourceName' value={this.state.customAzureAlertRequest.ResourceName || ''}
                                            onChange={this.handleInputChange} onBlur={this.validateCreateCustomAzAlertField} />
                                    </td>
                                    <td>
                                        <label className='required-field'><b>Resource Type</b></label>
                                        <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Resource Type" value={this.state.customAzureAlertRequest.ResourceType}
                                            options={this.state.resourceTypeList} onChange={this.onResourceTypeChange} />
                                    </td>
                                    <td>
                                        <label className='required-field'><b>Priority</b></label>
                                        <Dropdown disabled={this.state.customAzureAlertRequest.AzMonitorAlert} style={{ width: '100%' }} className="ui-column-filter" placeholder="Priority" value={this.state.customAzureAlertRequest.Priority}
                                            options={this.state.priorityList} onChange={this.onPriorityChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Notification Subject</b></label>
                                        <InputText type='text' name='NotificationSubject' value={this.state.customAzureAlertRequest.NotificationSubject || ''}
                                            onChange={this.handleInputChange} onBlur={this.validateEditCustomAlertField} />
                                    </td>
                                    <td>
                                        <label htmlFor="ServiceNowGroup" name='ServiceNowGroup'><b>ServiceNow Group</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip="If provided, an incident will be created in servicenow & assigned to selected group. Use 'NONE' option to not create an incident in servicenow." data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                        </label>
                                        <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Select group" value={this.state.customAzureAlertRequest.ServiceNowGroup}
                                            options={this.state.servicenowgroups} onChange={this.onServiceNowGrpChange} />
                                        <div><br /></div>
                                    </td>
                                    <td>
                                        <label htmlFor="JiraUserName" name='JiraUserName'><b>Jira Assignee</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip="If provided, an issue will be created in jira & assigned to provided username." data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                        </label>
                                        <InputText type='text' name='JiraUserName' value={this.state.customAzureAlertRequest.JiraUserName || null}
                                            onChange={this.handleInputChange} onBlur={this.validateEditCustomAlertField} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='2'>
                                        <span className='required-field'><b>Action Groups</b></span>
                                        <Button label='' icon='pi pi-plus' tooltip='Create Action Group' onClick={() => window.open('/admin/alert/actiongroup', '_blank')} />
                                        <span className="p-fluid">
                                            <AutoComplete tooltip='Search for available action group' tooltipOptions={{ position: 'top' }} value={this.state.selectedActionGroups} suggestions={this.state.filteredActionGroups} completeMethod={this.searchActionGroup} field="Name" multiple
                                                onChange={(e) => this.setState({ pageErrorMessage: '', pageSuccessMessage: '', selectedActionGroups: e.value })} readonly={this.state.autoCompleteReadOnly} aria-label="actiongroups" />
                                        </span>
                                    </td>
                                    <td>
                                        <label className='required-field'><b>Resend Frequency</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip="Denotes the frequency(in minutes) of the reminder alert." data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                        </label>
                                        <InputText type='text' name='ResendFrequency' value={this.state.customAzureAlertRequest.ResendFrequency || 0}
                                            onChange={this.handleInputChange} onBlur={this.validateEditCustomAlertField} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Button label='Save' onClick={() => this.setState({ confirmEditVisible: true, pageErrorMessage: null, pageSuccessMessage: null })} /> &nbsp;
                                        <Button label='Cancel' onClick={() => this.props.history.push('/admin/custom/azure/alert/rules')} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <Dialog header='Edit custom alert rule' footer={this.confirmEditFooter}
                        visible={this.state.confirmEditVisible} width='400px' modal={true}
                        onHide={e => this.setState({ confirmEditVisible: false, pageErrorMessage: null, pageSuccessMessage: null })} maximizable={false}>
                        <div>
                            Are you sure you want to edit the rule <b>{this.state.customAzureAlertRequest.Name}</b>?
                        </div>
                    </Dialog>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}

const WrappedEditCustomAzAlertComponent = withMsal(EditCustomAzAlertComponent);
export default withAITracking(reactPlugin, WrappedEditCustomAzAlertComponent);