import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { NotificationBlackoutService } from './NotificationBlackoutService';
import { Dialog } from 'primereact/dialog';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { InputSwitch } from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import Spinner from '../Spinner'

//HM-940 - UI to create notification blackout rule
class CreateNotificationBlackoutComponent extends Component {
    constructor() {
        super();
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            disableNameSpaceDropDown: true,
            disableInterfaceDropDown: true,
            disaableMetricTypeDropDown: true,
            disableServerInstanceDropDown: true,
            hideSpinner: true,
            notificationBlackoutRule: {
                RuleName: null,
                SourceSystem: "CACHE",
                MetricType: [],
                ServerInstance: [],
                NameSpace: [],
                Interface: [],
                TriggerNotification: false,
                Decommissioned: false,
                ExpiryDate: null,
                Reason: ''
            },
            serverInstances: [],
            productions: [],
            productionItems: [],
            metrictypes: [],
            sourcesystem: [
                { value: "CACHE", label: "CACHE" },
                { value: "GALILEO", label: "GALILEO" },
                { value: "AZURE", label: "AZURE" }
            ],
            selectedServerInstance: [],
            selectedProductions: [],
            selectedProductionItems: [],
            existingRuleFound: [],
            disableExistingRuleDialogVisible: false,
            existingRuleDialogFooter: null,
            existingRuleDialogBody: null,
            disabledExpiryDate: null,
            disabledExpiryTime: null,
        }
        this.INTERFACE_DROPDOWN_LIMIT = global.INTERFACE_DROPDOWN_LIMIT
        this.notificationBlackoutService = new NotificationBlackoutService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateCreateNotificationBlackoutField = this.validateCreateNotificationBlackoutField.bind(this);

        //calender date intialization
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        this.minDate = new Date();
        //this.minDate.setHours(0);
        this.minDate.setMonth(month);
        this.minDate.setFullYear(year);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ hideSpinner: false })
        this.setInitialExpiryDateTime();
        this.getMetricType(this.state.notificationBlackoutRule.SourceSystem);
    }

    setInitialExpiryDateTime() {
        //show default expiry datetime as 1 day in future
        let toDateLocal = new Date();
        let futureDate = new Date(new Date().setDate(toDateLocal.getDate() + 1));
        futureDate.setHours(0);
        futureDate.setMinutes(0);
        futureDate.setSeconds(0);
        this.setState({ disabledExpiryDate: futureDate, disabledExpiryTime: futureDate })
    }

    getMetricType(sourceSystem) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.notificationBlackoutService.getMetricType(sourceSystem, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.metrictypes.push({ label: item.label, value: item.value })));
                        this.setState({ disaableMetricTypeDropDown: false });
                        this.getHIEInstances();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getHIEInstances() {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.notificationBlackoutService.getHIEInstances(this.state.notificationBlackoutRule.SourceSystem, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.serverInstances.push({ label: item.ServerInstance, value: item.ServerInstance })));
                        this.setState({ hideSpinner: true, disableServerInstanceDropDown: false });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getProductions(instance) {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

        this.notificationBlackoutService.getProductions(instance, this.state.notificationBlackoutRule.SourceSystem, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.productions.push({ label: item.Namespace, value: item.Namespace })));
                        this.setState({ hideSpinner: true, disableNameSpaceDropDown: false });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getProductionItems(instance, nameSpace) {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })
        this.notificationBlackoutService.getProductionItems(instance, nameSpace, this.state.notificationBlackoutRule.SourceSystem, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.productionItems.push({ label: item.Name, value: item.Name })));
                        this.setState({ hideSpinner: true, disableInterfaceDropDown: false });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    arIntVals = ['MetricType', 'ServerInstance', 'NameSpace', 'Interface'];
    arBoolVals = ['TriggerNotification', 'Decommissioned'];

    handleInputChange(event) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        let found = false;
        for (let i = 0; i < this.arBoolVals.length; i++) {
            if (event.target.name === this.arBoolVals[i]) {
                found = true;
                this.setState({
                    notificationBlackoutRule: {
                        ...this.state.notificationBlackoutRule, //using spread operator to initialize object with existing props
                        [event.target.name]: event.target.checked
                    }
                })
                break;
            }
        }
        for (let i = 0; i < this.arIntVals.length; i++) {
            if (event.target.name === this.arIntVals[i]) {
                found = true;
                this.setState({
                    notificationBlackoutRule: {
                        ...this.state.notificationBlackoutRule, //using spread operator to initialize object with existing props
                        [event.target.name]: parseInt(event.target.value, 10)
                    }
                })
                break;
            }
        }

        if (!found) {
            this.setState({
                notificationBlackoutRule: {
                    ...this.state.notificationBlackoutRule, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    clearCusterAlertRequest() {
        this.setState({
            notificationBlackoutRule: {
                RuleName: '',
                SourceSystem: null,
                MetricType: [],
                ServerInstance: [],
                NameSpace: [],
                Interface: [],
                TriggerNotification: false,
                Decommissioned: false,
                ExpiryDate: null,
                Reason: ''
            },
            metrictypes: [],
            serverInstances: [],
            productions: [],
            productionItems: [],
            disaableMetricTypeDropDown: true,
            disableServerInstanceDropDown: true,
            disableInterfaceDropDown: true,
            disableNameSpaceDropDown: true,
            pageErrorMessage: null,
            pageSuccessMessage: null,
            disabledExpiryDate: null,
            disabledExpiryTime: null
        });
    }

    async validateCreateNotificationBlackoutField(notificationBlackoutRule, onSave = false, isCreate = false) {
        this.setState({ pageErrorMessage: '', pageSuccessMessage: '' })
        if (onSave) {
            var ReasonText = /^[^'"]*$/.test(notificationBlackoutRule.Reason);
            var RuleNameText = /^[^'"]*$/.test(notificationBlackoutRule.RuleName);

            if (notificationBlackoutRule.ServerInstance.length === 0) {
                this.setState({ pageErrorMessage: 'Server/Instance is required' })
                return false;
            }
            else if (notificationBlackoutRule.MetricType.length === 0) {
                this.setState({ pageErrorMessage: 'Metric Type is required.' });
                return false;
            }
            else if (this.state.notificationBlackoutRule.SourceSystem === null || this.state.notificationBlackoutRule.SourceSystem === '') {
                this.setState({ pageErrorMessage: 'Please select source system to load metric type & server/instance list' });
                return false;
            }
            else if (notificationBlackoutRule.RuleName === null || notificationBlackoutRule.RuleName === '') {
                this.setState({ pageErrorMessage: 'Rule Name is required.' });
                return false;
            }
            else if (notificationBlackoutRule.RuleName.trim().length < global.COMMENT_BOX_MIN_LENGTH || notificationBlackoutRule.RuleName.trim().length > 100) {
                this.setState({ pageErrorMessage: 'Rule Name should be between ' + global.COMMENT_BOX_MIN_LENGTH + ' & 100 characters.' })
                return false;
            }
            else if (!RuleNameText) {
                this.setState({ pageErrorMessage: "Rule Name should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (notificationBlackoutRule.Reason === null || notificationBlackoutRule.Reason === '') {
                this.setState({ pageErrorMessage: 'Reason is required.' });
                return false;
            }
            else if (!ReasonText) {
                this.setState({ pageErrorMessage: "Reason should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (notificationBlackoutRule.Reason.trim().length < global.COMMENT_BOX_MIN_LENGTH) {
                this.setState({ pageErrorMessage: 'Reason should be atleast of ' + global.COMMENT_BOX_MIN_LENGTH + ' characters.' })
                return false;
            }

            //set & validate expiry date & time
            if (!notificationBlackoutRule.TriggerNotification) {
                if (this.state.disabledExpiryDate && this.state.disabledExpiryTime)
                    notificationBlackoutRule.ExpiryDate = this.state.disabledExpiryDate.toLocaleDateString() + ' ' + this.state.disabledExpiryTime.toLocaleTimeString();
                else {
                    this.setState({ pageErrorMessage: 'Expiry datetime need to be specified or Enable Notification.' });
                    return false;
                }
            }

            let currentEstTime = new Date().toLocaleString('en-US', { timeZone: 'America/New_York', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
            if (notificationBlackoutRule.ExpiryDate) {
                if (new Date(notificationBlackoutRule.ExpiryDate) < new Date(currentEstTime)) {
                    this.setState({ pageErrorMessage: 'Expiry date should be greater than current EST detetime.' });
                    return false;
                }
            }

            if (isCreate) {
                this.setState({ pageErrorMessage: "", pageSuccessMessage: "", hideSpinner: false })
                if (this.state.notificationBlackoutRule.TriggerNotification || this.state.notificationBlackoutRule.Decommissioned) {
                    notificationBlackoutRule.ExpiryDate = null
                    this.setState({ disabledExpiryDate: null, disabledExpiryTime: null })
                }
                this.CreateNotificationBlackoutRule(notificationBlackoutRule)
            }
        }
        else {
            const propName = notificationBlackoutRule.target.name;
            const value = notificationBlackoutRule.target.value;
            switch (propName) {
                case 'RuleName':
                    if (value === '' || value === null || value <= 0) {
                        this.setState({ pageErrorMessage: 'Rule Name is required.' });
                        return false;
                    }
                case 'Reason':
                    if (value === '' || value === null || value <= 0) {
                        this.setState({ pageErrorMessage: propName + ' is required.' });
                        return false;
                    }
                default:
                    break;
            }
        }
    }

    CreateNotificationBlackoutRule(notificationBlackoutRule) {
        this.setState({ pageErrorMessage: '', pageSuccessMessage: '' })
        notificationBlackoutRule.ExpiryDate = notificationBlackoutRule.ExpiryDate ? notificationBlackoutRule.ExpiryDate.toLocaleString('sv-SE', { timeZone: 'America/New_York', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : null
        let body = JSON.stringify(notificationBlackoutRule);

        this.notificationBlackoutService.createNotificationBlackoutRule(body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                }
                else if (this._isMounted) {
                    if (d.status === 200 && d.body > 0) {
                        this.setState({ hideSpinner: true })
                        this.props.history.push('/notification/blackout/rule/detail/' + d.body + '/true')
                    }
                    else if (d.status === 400 && d.body.error) {
                        this.setState({ pageErrorMessage: d.body.error, hideSpinner: true });
                    }
                    else if (d.status === 400 && d.body.length > 0) {
                        this.setState({ existingRuleFound: d.body, hideSpinner: true });
                        this.showExistingRuleCombination(d.body);
                    }
                    else {
                        this.setState({ pageErrorMessage: 'Rule creation failed.', hideSpinner: true });
                    }
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
                }
            }
        ).catch(e => {
            this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
            console.log(e)
        });
    }

    showExistingRuleCombination(existingRuleFound) {
        if (existingRuleFound && existingRuleFound.length > 0) {
            this.setState({
                disableExistingRuleDialogVisible: true,
                existingRuleDialogFooter: <><Button label='OK' onClick={() => this.props.history.push('/notification/blackout/rule/detail/' + existingRuleFound[0].RuleId)} />
                    <Button label='Cancel' onClick={e => this.setState({ disableExistingRuleDialogVisible: false })} className='p-button-secondary' /></>,
                existingRuleDialogBody: <>We have found an existing rule <b>{existingRuleFound[0].Name}</b> with similar combination.<br />Similar combination rule cannot be re-create.<br />Click <b>OK</b> to view/edit.</>
            })
        }
    }

    onMetricTypeChange = (event) => {
        this.setState({
            pageSuccessMessage: null, pageErrorMessage: null,
            notificationBlackoutRule: { ...this.state.notificationBlackoutRule, MetricType: event.value }
        })
    }

    onSourceSystemChange = (event) => {
        this.setState({
            serverInstances: [], metrictypes: [], hideSpinner: false,
            notificationBlackoutRule: {
                ...this.state.notificationBlackoutRule, SourceSystem: event.value, MetricType: [], ServerInstance: [], NameSpace: [], Interface: []
            }, disableNameSpaceDropDown: true, disableInterfaceDropDown: true
        })
        this.getMetricType(event.value);
    }

    onInstanceChange = (instance) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (instance !== null && instance !== undefined) {

            let serverInstancesList = instance.value.map((id) => this.state.serverInstances.find((el) => el.value == id).label);

            this.setState({
                notificationBlackoutRule: { ...this.state.notificationBlackoutRule, ServerInstance: instance.value, NameSpace: [], Interface: [] },
                productions: [], productionItems: [], disableNameSpaceDropDown: true, disableInterfaceDropDown: true,
                selectedServerInstance: serverInstancesList
            })


            if (instance.value !== null && instance.value !== undefined && instance.value !== "" && instance.value.length > 0
                && this.state.notificationBlackoutRule.SourceSystem !== "GALILEO") {
                //get all namespaces if all instances are selected
                if (instance.value.length > 1 && instance.value.length === this.state.serverInstances.length)
                    this.getProductions(['ALL'])
                else
                    this.getProductions(serverInstancesList)
            }
            else
                this.setState({ productions: [], productionItems: [], disableNameSpaceDropDown: true, disableInterfaceDropDown: true })
        }
    }


    onNameSpaceChange = (nameSpace) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        if (nameSpace !== null && nameSpace !== undefined) {

            let nameSpaceList = nameSpace.value.map((id) => this.state.productions.find((el) => el.value == id).label);

            this.setState({
                notificationBlackoutRule: { ...this.state.notificationBlackoutRule, NameSpace: nameSpace.value, Interface: [] },
                productionItems: [], disableInterfaceDropDown: true, selectedProductions: nameSpaceList
            })
            if (nameSpace.value !== null && nameSpace.value !== undefined && nameSpace.value !== "" && nameSpace.value.length > 0) {
                //get all interfaces if all instances and namespaces are selected
                if (nameSpace.value.length > 1 && this.state.notificationBlackoutRule.ServerInstance.length === this.state.serverInstances.length && nameSpace.value.length === this.state.productions.length)
                    this.getProductionItems(['ALL'], ['ALL'])
                else
                    this.getProductionItems(this.state.selectedServerInstance, nameSpaceList);
            }
            else
                this.setState({ productionItems: [], disableInterfaceDropDown: true });
        }
    }

    onInterfaceNameChange = (interfaceName) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (interfaceName.value && interfaceName.value.length > this.INTERFACE_DROPDOWN_LIMIT) {
            this.setState({ pageErrorMessage: "Max " + this.INTERFACE_DROPDOWN_LIMIT + " can be selected" })
            return;
        }

        if (interfaceName !== null && interfaceName !== undefined) {
            this.setState({ notificationBlackoutRule: { ...this.state.notificationBlackoutRule, Interface: interfaceName.value } })
        }
    }

    onInputSwitchChange = (e) => {
        this.setState({ notificationBlackoutRule: { ...this.state.notificationBlackoutRule, TriggerNotification: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    //calender functions
    setDisableExpiryDate = (expiryDate) => {
        this.setState({ pageErrorMessage: "", pageSuccessMessage: "", disabledExpiryDate: expiryDate.value })
    }

    setDisableExpiryTime = (time) => {
        this.setState({ pageErrorMessage: "", pageSuccessMessage: "", disabledExpiryTime: time })
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="notificaitonBlackoutContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}


                    <Dialog header="MATCH FOUND" footer={this.state.existingRuleDialogFooter}
                        visible={this.state.disableExistingRuleDialogVisible} width='400px' modal={true}
                        onHide={e => this.setState({ disableExistingRuleDialogVisible: false })} maximizable={false}>
                        <div>
                            {this.state.existingRuleDialogBody}
                        </div>
                    </Dialog>

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">CREATE DO-NOT-MONITOR RULE</div>
                        </header>
                        {this.state.notificationBlackoutRule.TriggerNotification ?
                            <div><Message severity="warn" text="Enable notification will make the do-not-monitor rule inactive." /><br /><br /></div> : <></>}
                        <div className="card">
                            <table style={{ tablelayout: 'fixed', width: '100%' }}>
                                <colgroup>
                                    <col style={{ width: '50%' }} />
                                    <col style={{ width: '50%' }} />
                                </colgroup>
                                <tr>
                                    <td colSpan={2}>
                                        <label><b>Enable Notification</b></label>
                                        <InputSwitch checked={this.state.notificationBlackoutRule.TriggerNotification} onChange={this.onInputSwitchChange}
                                            tooltip={this.state.notificationBlackoutRule.TriggerNotification ? 'YES' : 'NO'} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>{this.state.notificationBlackoutRule.SourceSystem === "AZURE" ? "Resource Group" : "Server/Instance"}</b></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.notificationBlackoutRule.ServerInstance}
                                            options={this.state.serverInstances} onChange={this.onInstanceChange} maxSelectedLabels={3} disabled={this.state.disableServerInstanceDropDown}
                                            placeholder={this.state.notificationBlackoutRule.SourceSystem === "AZURE" ? "Select resource group" : "Select server/instance"} />
                                    </td>
                                    <td>
                                        <label><b>{this.state.notificationBlackoutRule.SourceSystem === "AZURE" ? "Resources" : "Namespace"}</b></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.notificationBlackoutRule.NameSpace}
                                            options={this.state.productions} onChange={this.onNameSpaceChange} disabled={this.state.disableNameSpaceDropDown}
                                            maxSelectedLabels={1} placeholder={this.state.notificationBlackoutRule.SourceSystem === "AZURE" ? "Select resources" : "Select namespace"} />
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <label><b>{this.state.notificationBlackoutRule.SourceSystem === "AZURE" ? "Resource Items" : "Interface Name"}</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip={"Max " + this.INTERFACE_DROPDOWN_LIMIT + " can be selected"} data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.notificationBlackoutRule.Interface}
                                            options={this.state.productionItems} onChange={this.onInterfaceNameChange} disabled={this.state.disableInterfaceDropDown}
                                            maxSelectedLabels={1} placeholder={this.state.notificationBlackoutRule.SourceSystem === "AZURE" ? "Select resource items" : "Select interface name"} />
                                    </td>
                                    <td>
                                        <label><b>Expiry Date (EST)</b>  &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip={"System will use the given datetime in EST timezone."} data-pr-position="right" data-pr-at="right+5 top"
                                                data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i> </label>
                                        <Calendar disabled={this.state.notificationBlackoutRule.TriggerNotification || this.state.notificationBlackoutRule.Decommissioned}
                                            style={{ width: '50%' }} id="ExpiryDate" value={this.state.disabledExpiryDate} onChange={this.setDisableExpiryDate} minDate={this.minDate} placeholder="Date" readOnlyInput /> &nbsp;
                                        <Calendar disabled={this.state.notificationBlackoutRule.TriggerNotification || this.state.notificationBlackoutRule.Decommissioned}
                                            style={{ width: '48%' }} id="ExpiryTime" value={this.state.disabledExpiryTime} onChange={(e) => this.setDisableExpiryTime(e.value)} timeOnly showSeconds hourFormat="12" placeholder="Time" readOnlyInput />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Metric Type</b></label>
                                        <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.notificationBlackoutRule.MetricType}
                                            options={this.state.metrictypes} onChange={this.onMetricTypeChange}
                                            maxSelectedLabels={0} placeholder="Select Metric Type" />
                                        {/* <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Metric Type" Id={this.state.notificationBlackoutRule.MetricType} disabled={this.state.disaableMetricTypeDropDown}
                                            value={this.state.notificationBlackoutRule.MetricType} options={this.state.metrictypes} onChange={this.onMetricTypeChange} filter /> */}
                                    </td>
                                    <td>
                                        <label className='required-field'><b>Source</b></label>
                                        <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Source System" Id={this.state.notificationBlackoutRule.SourceSystem}
                                            value={this.state.notificationBlackoutRule.SourceSystem} options={this.state.sourcesystem} onChange={this.onSourceSystemChange} filter />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Rule Name</b></label>
                                        <InputText style={{ width: '100%' }} type='text' name='RuleName' value={this.state.notificationBlackoutRule.RuleName}
                                            onChange={this.handleInputChange} onBlur={this.validateCreateNotificationBlackoutField} />
                                    </td>
                                    {/* <span style={{ marginLeft: '3rem' }} >
                                            <Checkbox name='Decommissioned' inputId="cb1" value="Active" onChange={this.handleInputChange} checked={this.state.notificationBlackoutRule.Decommissioned}></Checkbox>&nbsp;
                                            <label htmlFor="cb1" className="p-checkbox-label d-inline"><b>Decommissioned</b></label>
                                        </span> */}
                                    <td>
                                        <label className='required-field'><b>Reason</b></label>
                                        <InputTextarea rows="3" style={{ width: '100%' }} type='text' name='Reason' value={this.state.notificationBlackoutRule.Reason}
                                            onChange={this.handleInputChange} onBlur={this.validateCreateNotificationBlackoutField} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Button label='Create' onClick={() => this.validateCreateNotificationBlackoutField(this.state.notificationBlackoutRule, true, true)} /> &nbsp;
                                        <Button label='Clear' onClick={() => this.clearCusterAlertRequest()} /> &nbsp;
                                        <Button label='Cancel' onClick={() => this.props.history.push('/notification/blackout/rules')} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
            </MsalAuthenticationTemplate >
        )
    }
}

const WrappedCreateNotificationBlackoutComponent = withMsal(CreateNotificationBlackoutComponent);
export default withAITracking(reactPlugin, WrappedCreateNotificationBlackoutComponent);