import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { ActionGroupService } from './ActionGroupService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Spinner from "../Spinner";
import AlertMessage from "../alertmessage/AlertMessage"
import { MessageSeverity, FilterMatchMode, FilterOperator } from 'primereact/api';
import * as Yup from 'yup';

class ActionGroupComponent extends Component {
    constructor() {
        super();
        this.WrappedActionGroupComponent = React.createRef();
        this._isMounted = false;
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            editActionGrpErrorMessage: '',
            actiongroups: [],
            actiongroup: {
                Id: 0,
                Name: null,
                Description: null,
                CreatedDate: null,
                CreatedBy: null,
                ModifiedDate: null,
                DeletedDate: null,
                ModifiedBy: null,
                EmailList: null,
                Users: null
            },
            hideSpinner: false,
            showpagination: false,
            hasReadRights: true,
            editActionGroupDialogBoxVisible: false,
            createActionGroupDialogBoxVisible: false,
            confirmDeleteActionGroupDialogBoxVisible: false,
            confirmDeleteVisible: false,
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            globalFilterValue: '',
        }
        this.adminService = new ActionGroupService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getActionGroupsData();
    }

    getActionGroupsData = () => {
        this.adminService.getActionGroups(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({ actiongroups: d.body.ActionGroups, hideSpinner: true, editActionGrpErrorMessage: '', pageSuccessMessage: '', pageErrorMessage: '' });
                        if (this.state.actiongroups.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    editActionGroup = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" tooltip="Edit" onClick={() => this.showEditActionGroupDialog(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" tooltip="Delete" onClick={e => this.setState({ confirmDeleteActionGroupDialogBoxVisible: true, actiongroup: { ...rowData } })} />
            </React.Fragment>
        );
    }

    showEditActionGroupDialog = (rowData) => {
        this.setState({ actiongroup: { ...rowData }, editActionGroupDialogBoxVisible: true, editActionGrpErrorMessage: '', pageSuccessMessage: '', pageErrorMessage: '' })
    }

    showActionGroupDialogBoxForCreate = () => {
        this.clearRowSelection();
        this.setState({ createActionGroupDialogBoxVisible: true, editActionGrpErrorMessage: '', pageSuccessMessage: '', pageErrorMessage: '' })
    }

    clearRowSelection() {
        this.setState({
            actiongroup: {
                Id: 0,
                Name: '',
                Description: '',
                CreatedDate: null,
                CreatedBy: null,
                ModifiedDate: null,
                DeletedDate: null,
                ModifiedBy: null,
                EmailList: '',
                Users: 0
            },
        })
    }

    handleInputChange = (event) => {
        this.setState({
            actiongroup: {
                ...this.state.actiongroup, //using spread operator to initialize object with existing props
                [event.target.name]: event.target.value
            },
            editActionGrpErrorMessage: null
        });
    }

    validateEditActionGrpDialogFormField = async (actiongroup, onSave = false, isCreate = false) => {

        if (onSave || isCreate) {
            try {
                let actionGroupSchema = Yup.object({
                    Name: Yup.string().required("Action Group Name is required.")
                        .max(50, "Action Group Name cannot be more than 50 characters.")
                        .matches(/^[^'"]*$/, "Action Group Name should not contain single (') or double (\") quotes."),
                    Description: Yup.string().required("Description is required.")
                        .max(global.COMMENT_BOX_MAX_LENGTH, "Description cannot be more than " + global.COMMENT_BOX_MAX_LENGTH + " characters.")
                        .matches(/^[^\'"]*$/, "Description should not contain single (') or double (\") quotes."),
                    EmailList: Yup.string().required('Email list is required.')
                        .matches(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(;[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/, 'Email list is not valid. Refer info icon.')
                });
                await actionGroupSchema.validate(actiongroup, { abortEarly: false })

                if (isCreate) {
                    this.setState({ editActionGrpErrorMessage: '' });
                    this.IsActionGroupNameExist(isCreate);
                }
                else {
                    this.setState({ editActionGrpErrorMessage: '' });
                    this.updateActionGroup();
                }
            }
            catch (error) {
                this.setState({ editActionGrpErrorMessage: error.inner[0].message });
            }
        }
    }

    IsActionGroupNameExist(isCreate = false) {
        this.adminService.isActionGroupNameExist(this.state.actiongroup.Name, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                } else {
                    if (d.status === 200 && d.body) {
                        this.setState({ editActionGrpErrorMessage: 'The action group name provided already exists. Please use a different one.' });
                    }
                    else {
                        if (isCreate)
                            this.createActionGroup();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    createActionGroup() {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' })
        let body = JSON.stringify(this.state.actiongroup);
        this.adminService.createActionGroup(body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                } else {
                    if (this._isMounted && d.status === 200) {
                        this.getActionGroupsData();
                        this.setState({ pageSuccessMessage: 'Action group created successfully', createActionGroupDialogBoxVisible: false, hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    updateActionGroup() {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' })
        let body = JSON.stringify(this.state.actiongroup);
        this.adminService.updateActionGroup(this.state.actiongroup.Id, body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                } else {
                    if (this._isMounted && d.status === 200) {
                        this.getActionGroupsData();
                        this.setState({ pageSuccessMessage: 'Changes saved successfully.', editActionGroupDialogBoxVisible: false, hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    saveFooter = (
        <div>
            <Button label='Save' onClick={() => this.validateEditActionGrpDialogFormField(this.state.actiongroup, true)} />
            <Button label='Cancel' onClick={e => this.setState({ editActionGroupDialogBoxVisible: false, editActionGrpErrorMessage: null })} className='p-button-secondary' />
        </div>
    );

    createFooter = (
        <div>
            <Button label='Create' onClick={() => this.validateEditActionGrpDialogFormField(this.state.actiongroup, true, true)} />
            <Button label='Cancel' onClick={e => this.setState({ createActionGroupDialogBoxVisible: false, editActionGrpErrorMessage: null })} className='p-button-secondary' />
        </div>
    );

    isActionGroupMappingExists() {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' })
        this.adminService.isActionGroupMappingExists(this.state.actiongroup.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to perform this operation.', confirmDeleteActionGroupDialogBoxVisible: false, hideSpinner: true });
                } else if (d.status === 200 && d.body) {
                    this.setState({ pageErrorMessage: 'Deleted failed. The action group is linked with alert rule.', confirmDeleteActionGroupDialogBoxVisible: false, hideSpinner: true });
                } else {
                    this.deleteActionGroup();
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    deleteActionGroup() {
        this.adminService.deleteActionGroup(this.state.actiongroup.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to perform this operation.', confirmDeleteActionGroupDialogBoxVisible: false, hideSpinner: true });
                } else if (d.status === 200 && d.body) {
                    this.setState({ pageSuccessMessage: 'Action Group deleted successfully.', confirmDeleteActionGroupDialogBoxVisible: false, hideSpinner: true });
                    this.getActionGroupsData();
                } else {
                    this.setState({ pageErrorMessage: 'Something went wrong', confirmDeleteActionGroupDialogBoxVisible: false, hideSpinner: true });
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    confirmDeleteFooter = (
        <div>
            <Button label='Ok' onClick={() => this.isActionGroupMappingExists()} />
            <Button label='Cancel' onClick={e => this.setState({ confirmDeleteActionGroupDialogBoxVisible: false })}
                className='p-button-secondary' />
        </div>
    );

    onGlobalFilterChange = (event, filtersKey) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter = (filtersKey) => {
        return (
            <div>
                <div style={{ 'textAlign': 'center' }} >
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                </div>
            </div>
        )
    }

    showAlertMessage = (severity, alertMsg) => {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="actiongroupContainer">
                    <BreadCrumbComponent {...this.props} />

                    <AlertMessage ref={instance => { this.alertMsg = instance }} />
                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <div className='flex justify-content-between' style={{ marginBottom: '10px' }}>
                            <div className="page-header-title">ACTION GROUPS</div>
                            <Button label='' icon='pi pi-plus' tooltip='Create Action Group' tooltipOptions={{ position: 'left' }} onClick={this.showActionGroupDialogBoxForCreate} />
                        </div>

                        <DataTable ref={(el) => this.dt = el} value={this.state.actiongroups}
                            selectionMode="single"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            paginator={this.state.showpagination}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            emptyMessage="No Records found."
                            showGridlines={true}
                            onFilter={(e) => this.setState({ filters: e.filters })}
                            stateStorage="session" stateKey="dt-actiongroup-session"
                        >
                            <Column field="Name" header="Name" style={{ textAlign: 'left', width: '500px' }} />
                            <Column field="Description" header="Description" style={{ textAlign: 'left' }} />
                            <Column field="Users" header="Users" style={{ textAlign: 'left' }} sortable sortField="Users" />
                            <Column field="edit" header="Action" body={this.editActionGroup} style={{ whiteSpace: 'nowrap', width: '150px', textAlign: 'center' }} />
                        </DataTable>
                    </div>

                    <Dialog header='Edit Action Group' footer={this.saveFooter}
                        visible={this.state.editActionGroupDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ editActionGroupDialogBoxVisible: false, editActionGrpErrorMessage: null })} maximizable={false}>
                        <div>
                            {this.state.editActionGrpErrorMessage ?
                                this.showAlertMessage(MessageSeverity.ERROR, this.state.editActionGrpErrorMessage) : <></>
                            /* {this.state.editActionGrpErrorMessage ? <div className='text-danger text-center'>{this.state.editActionGrpErrorMessage}</div> : <></>} */}
                            <table className='w-100'>
                                <tr>
                                    <td>
                                        <label><b>Name</b></label>
                                        <label>{this.state.actiongroup.Name || ''}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label><b>Description</b></label>
                                        <InputText type='text' name='Description' value={this.state.actiongroup.Description || ''}
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label><b>Email List</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip='Semicolon (;) delimited field.'
                                                data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                                style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                        </label>
                                        <InputTextarea type='text' name='EmailList' value={this.state.actiongroup.EmailList || ''} rows={5} cols={160}
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Dialog>

                    <Dialog header='Create Action Group' footer={this.createFooter}
                        visible={this.state.createActionGroupDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ createActionGroupDialogBoxVisible: false, editActionGrpErrorMessage: null })} maximizable={false}>
                        <div>
                            {this.state.editActionGrpErrorMessage ?
                                this.showAlertMessage(MessageSeverity.ERROR, this.state.editActionGrpErrorMessage) : <></>
                                /* {this.state.editActionGrpErrorMessage ? <div className='text-danger text-center'>{this.state.editActionGrpErrorMessage}</div> : <></>} */}
                            <table className='w-100'>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Name</b></label>
                                        <InputText type='text' name='Name' value={this.state.actiongroup.Name || ''} required
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Description</b></label>
                                        <InputText type='text' name='Description' value={this.state.actiongroup.Description || ''}
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Email List</b>
                                            &nbsp;<Tooltip target=".custom-target-icon" />
                                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                data-pr-tooltip='Email not in correct format or if multiple emails not seperated by semicolon ( ; ) as a delimiter. e.g. abc@email.com;xyz@email.com'
                                                data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                                style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                        </label>
                                        <InputTextarea type='text' name='EmailList' value={this.state.actiongroup.EmailList} rows={5} cols={160}
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Dialog>

                    <Dialog header='Delete Action Group' footer={this.confirmDeleteFooter}
                        visible={this.state.confirmDeleteActionGroupDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ confirmDeleteActionGroupDialogBoxVisible: false })} maximizable={false}>
                        <div>
                            Are you sure you want to delete the action group <b>{this.state.actiongroup.Name}</b>?
                        </div>
                    </Dialog>

                </div>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedActionGroupComponent = withMsal(ActionGroupComponent);
export default withAITracking(reactPlugin, WrappedActionGroupComponent);