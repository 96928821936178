import { bool } from 'yup';
import { msalInstance } from '../../App';
//HDO-6805 - Create a new screen that will be used to "front" the server reconnection script.
export class RecoveryService {

    async invokeWebAPI(url, httpMethod, msalContext, body = "") {

        // check if msalContext exists
        if (msalContext.accounts.length > 0) {
            const tokenRequest = {
                scopes: [global.ENV_MSAL_LOGIN_REQUEST_SCOPES],
                account: msalContext.accounts[0],
            };

            // acquire Bearer token to include in GET request
            const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
            const token = tokenResponse.accessToken;
            const bearer = `Bearer ${token}`;
            const headers = new Headers();
            headers.append('Accept', '*/*');
            headers.append('Content-Type', 'application/json');
            headers.append('x-northwell-version', '1.0');
            headers.append("Authorization", bearer);
            headers.append('User', msalContext.accounts[0].username)

            var options = {}
            if (body) {
                options = {
                    method: httpMethod,
                    headers: headers,
                    body: body
                };
            } else {
                options = {
                    method: httpMethod,
                    headers: headers
                };
            }

            let response = await fetch(url, options);

            return {
                status: response.status,
                body: await response.json()
            };

        } else {
            console.log('user not logged in')
        }
    }

    async getHIEInstances(environment, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/metricsread/HIEInstances?environment=" + environment
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getProductions(instance, environment, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/metricsread/ProductionStatus?instance=" + instance + "&environment=" + environment
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getProductionItems(instance, nameSpace, environment, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem?instance=" + instance + "&nameSpace=" + nameSpace + "&environment=" + environment
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getCacheProductionItemsHybridStatusV2(instance, nameSpace, type, prefix, suffix, msalContext) {

        let url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem/v2/hybrid/status?id=0"
        if (instance)
            url = url + "&instance=" + instance
        if (nameSpace)
            url = url + "&nameSpace=" + nameSpace
        if (type)
            url = url + "&type=" + type
        if (prefix)
            url = url + "&prefix=" + prefix
        if (type)
            url = url + "&suffix=" + suffix
        url = url + "&lookbackTime=7"

        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async recoverComponent(request, msalContext) {
        try {
            let url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem/recoverytool/recovercomponent"
            const response = await this.invokeWebAPI(url, 'POST', msalContext, request);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async recoverComponentLogs(Instance, StartDate, EndDate, timeZone, msalContext) {
        try {
            let url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem/recoverytool/recovercomponent/logs?instance=" + Instance + "&startDate=" + StartDate + "&endDate=" + EndDate + "&timeZone=" + timeZone
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }
}