import React, { useEffect, useState, useRef } from 'react';
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { HIEHealthService } from './HIEHealthService';
import Spinner from "../../Spinner"
import { Toast } from 'primereact/toast';
import { MessageSeverity } from 'primereact/api';
import "./HIEHealthOverviewComponent.css";

function HIEHealthOverviewComponent(props) {
    const healthService = new HIEHealthService();
    const alertMessage = useRef(null);
    const [data, setData] = useState([]);
    const [hideSpinner, sethideSpinner] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [settings, setSettings] = useState({ PageNo: 1, Category: "", PageSize: 10, First: 0, IsNew: false })
    const [pageErrorMessage, setpageErrorMessage] = useState(null)
    const history = useHistory();

    //#region Initialization
    let warnings = "0 Active";
    let P1 = "0 Active ";
    let P2 = "0 Active ";
    let P3 = "0 Active ";
    let P4 = "0 Active ";
    let errors = "0 Active ";
    let newErrors = "0 New ";
    let newWarnings = "0 New ";
    let newP1 = "0 New";
    let newP2 = "0 New ";
    let newP3 = "0 New ";
    let newP4 = "0 New ";
    if (data.count) {
        P1 = data.count.P1 + " Active ";
        P2 = data.count.P2 + " Active ";
        P3 = data.count.P3 + " Active ";
        P4 = data.count.P4 + " Active ";
        errors = data.count.Error + " Active ";
        newErrors = data.count.IsStillOpenError + " New ";
        newP1 = data.count.IsStillOpenP1 + " New ";
        newP2 = data.count.IsStillOpenP2 + " New ";
        newP3 = data.count.IsStillOpenP3 + " New ";
        newP4 = data.count.IsStillOpenP4 + " New ";
    }

    let totalRecords = 0
    if (settings.IsNew === false) {
        if (settings.Category === 1) {
            totalRecords = data?.count?.P1
        } else if (settings.Category === 2) {
            totalRecords = data?.count?.P2
        } else if (settings.Category === 3) {
            totalRecords = data?.count?.P3
        } else if (settings.Category === 4) {
            totalRecords = data?.count?.P4
        } else if (settings.Category === 0) {
            totalRecords = 0
        } else {
            totalRecords = (data?.count?.P1 + data?.count?.P2 + data?.count?.P3 + data?.count?.P4)
        }
    }
    else {
        if (settings.Category === 1) {
            totalRecords = data?.count?.IsStillOpenP1
        } else if (settings.Category === 2) {
            totalRecords = data?.count?.IsStillOpenP2
        } else if (settings.Category === 3) {
            totalRecords = data?.count?.IsStillOpenP3
        } else if (settings.Category === 4) {
            totalRecords = data?.count?.IsStillOpenP4
        } else if (settings.Category === 0) {
            totalRecords = 0
        } else {
            totalRecords = (data?.count?.IsStillOpenP1 + data?.count?.IsStillOpenP2 + data?.count?.IsStillOpenP3 + data?.count?.IsStillOpenP4)
        }
    }
    //#endregion

    const loadDashboardData = () => {
        healthService.loadDashboardData(settings.PageNo, settings.IsNew, settings.PageSize, settings.Category, props.msalContext)
            .then(response => {
                if (response && response.status === 200) {
                    setData(response.body)
                    sethideSpinner(true)
                }
                else {
                    sethideSpinner(true)
                    setpageErrorMessage("Something went wrong !!!")
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        loadDashboardData()
    }, [settings])

    const loadTableBasedOnCategory = (Category, IsNew) => {
        sethideSpinner(false)
        setpageErrorMessage(null)
        totalRecords = 0
        setSettings(prevState => ({ PageNo: 1, Category: Category, PageSize: prevState.PageSize, First: 0, IsNew: IsNew }))
    }

    //HDO-5625 - Auto refresh option code - start
    let autoRefreshOptions = [
        { label: 'Auto-Refresh Off', value: 0 },
        { label: '1 minute', value: 60000 },
        { label: '5 minute', value: 300000 },
        { label: '15 minute', value: 900000 },
        { label: '30 minute', value: 1800000 }
    ]

    const onAutoRefresh = (e) => {
        setpageErrorMessage(null)
        let interval = {}
        let selectedAutoRefresh = e.value;
        clearInterval(intervalId);
        setAutoRefresh(selectedAutoRefresh);

        if (e.value && e.value > 0) {
            interval = setInterval(() => {
                loadDashboardData();
            }, selectedAutoRefresh);
            setIntervalId(interval)
        }
    }

    const autoRefreshOption = () => {
        return (
            <div>
                <span>
                    <Dropdown value={autoRefresh} options={autoRefreshOptions}
                        onChange={onAutoRefresh} placeholder="Auto-Refresh" tooltip='Auto-Refresh' tooltipOptions={{ position: 'top' }} className="ui-column-filter" style={{ left: '90%', position: 'sticky', top: '0' }} />
                </span>
            </div>
        )
    }
    //HDO-5625 - Auto refresh option code - end

    const onPageChange = (event) => {
        sethideSpinner(false)
        setSettings(prevState => ({ PageNo: event.page + 1, Category: prevState.Category, PageSize: event.rows, First: event.first, IsNew: prevState.IsNew }))
    }

    const statusBodyTemplate = (rowData) => {
        return <span className={`issue-status-badge status-${rowData.StatusName.toLowerCase()}`}>{rowData.StatusName}</span>;
    }

    const getNamespace = (rowData) => {
        //HDO-5626 - Add a column for Namespace in the home page.
        switch (rowData.MetricType) {
            case 6:
            case 106:
                return <span>{rowData.IssueLocation.split('_')[0]}</span>;
            case 4:
            case 7:
            case 36:
                return <span>{rowData.IssueLocation.split('_')[1]}</span>;
            case 9:
            case 105:
                return <span>{rowData.IssueLocation.split('/')[1]}</span>;
            default:
                return <span>N.A</span>;
        }
    }
    const getItemName = (rowData) => {
        //HDO-5626 - Add a column for Interface name in the home page.
        switch (rowData.MetricType) {
            case 6:
            case 106:
                return <span>{rowData.IssueLocation.split('_')[1]}</span>;
            case 9:
                return <span>{rowData.IssueLocation.split('/')[2]}</span>;
            default:
                return <span>N.A</span>;

        }
    }

    const showError = (severity, msg) => {
        alertMessage.current.show({ severity: severity, summary: 'Error Message', detail: msg, life: global.ALERT_MSG_LIFE });
    }

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <div>
                <BreadCrumbComponent {...props} />
                <Toast ref={alertMessage} />
                {pageErrorMessage ? showError(MessageSeverity.ERROR, pageErrorMessage) : <></>}

                <div hidden={hideSpinner}>
                    <Spinner />
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", textAlign: "center" }}>
                    <Card type="button" title="Warnings" style={warningCardStyle}>
                        <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "20px" }}>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory(0, false)}>{warnings}</button>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory(0, true)}>{newWarnings}</button>
                        </div>
                    </Card>
                    <Card type="button" title="Errors" style={errorCardStyle}>
                        <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "20px" }}>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory("", false)}>{errors}</button>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory("", true)}>{newErrors}</button>
                        </div>
                    </Card>
                    <Card type="button" title="P1" style={pCardStyle}>
                        <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "20px" }}>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory(1, false)}>{P1}</button>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory(1, true)}>{newP1}</button>
                        </div>
                    </Card>
                    <Card type="button" title="P2" style={pCardStyle}>
                        <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "20px" }}>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory(2, false)}>{P2}</button>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory(2, true)}>{newP2}</button>
                        </div>
                    </Card>
                    <Card type="button" title="P3" style={pCardStyle}>
                        <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "20px" }}>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory(3, false)}>{P3}</button>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory(3, true)}>{newP3}</button>
                        </div>
                    </Card>
                    <Card type="button" title="P4" style={pCardStyle}>
                        <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "20px" }}>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory(4, false)}>{P4}</button>
                            <button style={buttonStyle} onClick={() => loadTableBasedOnCategory(4, true)}>{newP4}</button>
                        </div>
                    </Card>
                </div>
                <div>
                    <DataTable
                        lazy
                        selectionMode="single"
                        first={settings.First}
                        value={data.results}
                        responsiveLayout="scroll"
                        header={(e) => autoRefreshOption()}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        onPage={(event) => onPageChange(event)}
                        totalRecords={totalRecords || 0}
                        paginator={true}
                        rows={settings.PageSize}
                        onRowDoubleClick={(event) => history.push("/issue/" + event.data.Id)}
                        showGridlines={true} emptyMessage="No Records found."
                    >
                        <Column field='Platform' header="Platform" style={{ textAlign: 'left', minWidth: '20px' }} />
                        <Column field='MetricName' header="Alert Name" style={{ textAlign: 'left', minWidth: '50px' }} />
                        <Column field='ServerName' header="Server Name" style={{ textAlign: 'left', minWidth: '100px' }} />
                        <Column field='NameSpace' header="Namespace" style={{ textAlign: 'left', minWidth: '100px' }} body={getNamespace} />
                        <Column field='Name' header="Interface" style={{ textAlign: 'left', minWidth: '100px' }} body={getItemName} />
                        <Column field='PriorityId' header="Priority" style={{ textAlign: 'left', minWidth: '20px' }} />
                        <Column field='StatusName' header="Status" style={{ textAlign: 'left', minWidth: '40px' }} body={statusBodyTemplate} />
                        <Column field="CreatedDatetime" header="Created Date" style={{ textAlign: 'left', minWidth: '50px' }} />
                    </DataTable>
                </div>
            </div>
        </MsalAuthenticationTemplate>
    )
}

const buttonStyle = {
    width: "100px",
    height: "60px"
}

const pCardStyle = {
    width: '15rem',
    marginBottom: '1em',
    backgroundColor: "#F8F8F8",
    height: '10rem'
}

const errorCardStyle = {
    width: '15rem',
    marginBottom: '1em',
    backgroundColor: "red",
    height: '10rem'
}

const warningCardStyle = {
    width: '15rem',
    marginBottom: '1em',
    backgroundColor: "yellow",
    height: '10rem'
}

const WrappedHIEHealthOverviewComponent = withMsal(HIEHealthOverviewComponent);
export default withAITracking(reactPlugin, WrappedHIEHealthOverviewComponent);

