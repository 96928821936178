import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { loginRequest } from "../../authConfig";
import { Cookies } from 'react-cookie';


function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

//JIRA - HM-425 Bug Fix without Redirect login flow
function removeMsalInteractionFromCookies(cookies){
    let cookieExits = cookies.get('msal.interaction.status', { path: '/' });
    if(cookieExits !== undefined && cookieExits !== null){
        //console.log(cookieExits);
        cookies.remove('msal.interaction.status', { path: '/' });
        //console.log(cookies.getAll());
    }
}
/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    window.localStorage.clear();
    const [cookies] = useState(()=>new Cookies());
    removeMsalInteractionFromCookies(cookies);

    const { instance } = useMsal();

    return (
        <Button variant="primary" className="ml-auto" onClick={() => handleLogin(instance)}><b>Sign in</b></Button>
    );
}