import React, { useEffect, useState, useRef } from "react";
import BreadCrumbComponent from "../bread-crumb/BreadCrumbComponent";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../AppInsights";
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { NavLink } from 'react-router-dom';
import { RecoveryService } from "./RecoveryService"
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import Spinner from "../Spinner"
import { Toast } from "primereact/toast";
import { MessageSeverity } from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';
import * as Yup from "yup";
import "./Recovery.css"

//HDO-6805 - Create a new screen that will be used to "front" the server reconnection script.
function RestoreConnection(props) {
    const alertMessage = useRef(null);
    const recoveryService = new RecoveryService();
    const RESTORE_CONN_RESTART_THRESHOLD = global.RESTORE_CONN_RESTART_THRESHOLD
    const bizHostCategoty = ["Service", "Process", "Operation"]
    const action = ["Start", "Stop", "Restart"]
    const itemStatusList = ["DISABLED", "ENABLED", "ERROR", "INACTIVE", "RETRY", "UNCONFIGURED"]
    const [pageErrorMessage, setpageErrorMessage] = useState(null)
    const [pageSuccessMessage, setpageSuccessMessage] = useState(null)
    const [hideSpinner, sethideSpinner] = useState(false);
    const [hieInstance, setHieInstance] = useState([]);
    const [namespace, setNamespace] = useState([]);
    const [productionItems, setProductionItems] = useState([]);
    const [disableNameSpaceDropDown, setdisableNameSpaceDropDown] = useState(true);
    const [showPreviewData, setShowPreviewData] = useState(false);
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [showJobbingOffMsg, setShowJobbingOffMsg] = useState(false);
    const [filters, setfilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'ItemStatus': { value: [], matchMode: FilterMatchMode.IN }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedOptions, setSelectedOptions] = useState({
        Instance: null,
        Namespace: [],
        ComponentType: null,
        Action: "",
        Prefix: "",
        Suffix: "",
        IncidentNumber: "",
        Comments: ""
    });

    const getHIEInstances = () => {
        setpageSuccessMessage(null); setpageErrorMessage(null)
        recoveryService.getHIEInstances("CACHE", props.msalContext).then(response => {
            if (response && response.status === 200) {
                let hieInstanceList = []
                response.body.map((item) => (hieInstanceList.push({ label: item.ServerInstance, value: item.ServerInstance })))
                setHieInstance(hieInstanceList)
                sethideSpinner(true)
            }
            else {
                sethideSpinner(true); setpageErrorMessage("Something went wrong !!!")
            }
        }).catch(e => {
            console.log(e)
        });
    }

    const getProductions = (instance) => {
        setpageSuccessMessage(null); setpageErrorMessage(null); sethideSpinner(false);
        recoveryService.getProductions(instance, "CACHE", props.msalContext).then(response => {
            if (response && response.status === 200) {
                let productionList = []
                response.body.map((item) => (productionList.push({ label: item.Namespace, value: item.Namespace })))
                setNamespace(productionList)
                sethideSpinner(true); setdisableNameSpaceDropDown(false)
            }
            else {
                sethideSpinner(true); setpageErrorMessage("Something went wrong !!!")
            }
        }).catch(e => {
            console.log(e)
        });
    }

    useEffect(() => {
        getHIEInstances()
    }, [])

    const handleInputChange = (event) => {
        setpageErrorMessage(null); setpageSuccessMessage(null);
        setSelectedOptions({
            ...selectedOptions,
            [event.target.name]: event.target.value
        })
    }

    const handleDropDownInputChange = (event) => {
        setpageErrorMessage(null); setpageSuccessMessage(null);
        setSelectedOptions({
            ...selectedOptions,
            [event.target.name]: event.target.value
        })
    }

    const handleMultiDropDownInputChange = (event) => {
        setpageErrorMessage(null); setpageSuccessMessage(null);
        setSelectedOptions({
            ...selectedOptions,
            [event.target.name]: event.target.value
        })
    }

    const onInstanceChange = (event) => {
        setpageSuccessMessage(null); setpageErrorMessage(null);
        let instance = event.target.value
        if (instance !== null && instance !== undefined) {
            setSelectedOptions({
                ...selectedOptions,
                [event.target.name]: event.target.value,
                Namespace: []
            })
            getProductions(instance)
        }
    }

    const validateSearchInputs = () => {
        try {
            let searchSchema = Yup.object({
                Instance: Yup.string().required(),
                Namespace: Yup.array(Yup.string().required(),).min(1),
                ComponentType: Yup.string().required("Component type is a required field."),
                Action: Yup.string().required(),
                Prefix: Yup.string().required("Business hostname prefix is a required field.")
                    .matches(/^[^'"]*$/, "Business hostname prefix should not contain single (') or double (\") quotes."),
                Suffix: Yup.string().required("Business hostname suffix is a required field.")
                    .matches(/^[^'"]*$/, "Business hostname suffix should not contain single (') or double (\") quotes."),
                IncidentNumber: Yup.string().required("Incident number is a required field.")
                    .min(10, "Incident number must be atleast 10 characters.")
                    .matches(/^[^'"]*$/, "Incident number should not contain single (') or double (\") quotes."),
                Comments: Yup.string().required()
                    .min(30, "Comments must be atleast 30 characters.")
                    .matches(/^[^'"]*$/, "Comments should not contain single (') or double (\") quotes.")
            });
            searchSchema.validateSync(selectedOptions, { abortEarly: false })
            return true
        }
        catch (error) {
            setpageErrorMessage(error.inner[0].message); sethideSpinner(true)
            return false
        }
    }

    const onSearchRequest = () => {
        setpageErrorMessage(null); setpageSuccessMessage(null); setSelectedRows([]); sethideSpinner(false);
        if (validateSearchInputs()) {
            //invoke search api to get the production items
            recoveryService.getCacheProductionItemsHybridStatusV2(selectedOptions.Instance, selectedOptions.Namespace, selectedOptions.ComponentType, selectedOptions.Prefix, selectedOptions.Suffix, props.msalContext).then(response => {
                if (response && response.status === 200) {
                    setProductionItems(response.body)
                    sethideSpinner(true); setShowPreviewData(true);
                }
                else {
                    sethideSpinner(true); setpageErrorMessage("Something went wrong !!!")
                }
            }).catch(e => {
                console.log(e)
            });
        }
    }

    const onEditSearch = () => {
        setpageErrorMessage(null); setpageSuccessMessage(null); setSelectedRows([]); setShowPreviewData(false);
    }

    const viewAuditLogs = () => {
        setpageErrorMessage(null); setpageSuccessMessage(null); setSelectedRows([]); setShowPreviewData(false);
        window.open('/restore/connection/logs', '_new')
    }

    const restoreConnectionRequest = () => {
        setpageErrorMessage(null); setpageSuccessMessage(null); sethideSpinner(false);
        if (validateSearchInputs()) {
            let request = {
                Instance: selectedOptions.Instance,
                Namespace: selectedOptions && selectedOptions.Namespace.length > 1 && selectedOptions.Namespace.length === namespace.length ? "ALL" : selectedOptions.Namespace.join(","),
                ComponentName: selectedRows && selectedRows.length > 0 ? selectedRows.map(item => item.Name).join(",") : "",
                ComponentType: selectedOptions.ComponentType,
                Action: selectedOptions.Action,
                Prefix: selectedOptions.Prefix,
                Suffix: selectedOptions.Suffix,
                Comments: selectedOptions.IncidentNumber + " - " + selectedOptions.Comments,
                ComponentCountThresholdForRestartAsJob: RESTORE_CONN_RESTART_THRESHOLD
            }
            setConfirmDialogVisible(false);
            recoveryService.recoverComponent(JSON.stringify(request), props.msalContext).then(response => {
                if (response && response.status === 200) {
                    sethideSpinner(true);
                    let updatedPordItems = productionItems.map(item => {
                        let currentstate = response.body.find(x => x.Namespace === item.NameSpace && x.Itemname === item.Name)
                        if (currentstate) {
                            return {
                                Id: item.Id,
                                Instance: item.Instance,
                                NameSpace: item.NameSpace,
                                Name: item.Name,
                                Enabled: selectedRows.length > RESTORE_CONN_RESTART_THRESHOLD && selectedOptions.Action === "Restart" ? item.Enabled : currentstate.Status,
                                ItemStatus: selectedRows.length > RESTORE_CONN_RESTART_THRESHOLD && selectedOptions.Action === "Restart" ? getProdItemStatus(item) : currentstate.Status === 1 ? "ENABLED" : "DISABLED",
                                OSVersion: item.OSVersion,
                                Platform: item.Platform,
                                ProductionName: item.ProductionName,
                                ComponentType: item.ComponentType,
                                IsLatest: item.IsLatest,
                                LastMessageDelay: item.LastMessageDelay,
                                Qcount: item.Qcount,
                                MetricType: item.MetricType,
                                OSVersion: item.OSVersion,
                                Platform: item.Platform,
                                ProductionName: item.ProductionName,
                                Server: item.Server
                            }
                        }
                        return item;
                    })

                    //in case, if selected component exxceeds max threshold for restart.
                    if (selectedRows && selectedRows.length > RESTORE_CONN_RESTART_THRESHOLD && selectedOptions.Action === "Restart")
                        setShowJobbingOffMsg(true);

                    setProductionItems(updatedPordItems); setSelectedRows([]); setpageSuccessMessage("Restore action performed successfully !!!");
                }
                else {
                    sethideSpinner(true);
                    setpageErrorMessage("Something went wrong !!!");
                }
            }).catch(e => {
                console.log(e); sethideSpinner(true); setpageErrorMessage("Something went wrong !!!");
            });
        }
    }

    const onGlobalFilterChange = (event) => {
        setpageErrorMessage(null); setpageSuccessMessage(null);

        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setfilters(_filters);
        setGlobalFilterValue(value);
    }

    const globalSearchFilter = () => {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearDataTableFilters} />
                    <h4>PREVIEW</h4>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={globalFilterValue} onChange={onGlobalFilterChange}
                            placeholder="Global Search" size={35} />
                    </span>
                </div>
            </div>
        )
    }

    const itemStatusFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={itemStatusList}
            onChange={(e) => options.filterCallback(e.value, options.index)} maxSelectedLabels={1}
            placeholder="ItemStatus" className="multiselect-custom"
        />;
    }

    const clearDataTableFilters = () => {
        setpageErrorMessage(null); setpageSuccessMessage(null); setGlobalFilterValue('');
        setfilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'ItemStatus': { value: [], matchMode: FilterMatchMode.IN }
        })
    }

    const clearSelectedOptions = () => {
        setpageErrorMessage(null); setpageSuccessMessage(null); setGlobalFilterValue('');
        setdisableNameSpaceDropDown(true); setNamespace([]); setShowPreviewData(false); setSelectedRows([]); setProductionItems([]);
        setSelectedOptions({
            Instance: null,
            Namespace: [],
            ComponentType: null,
            Action: "",
            Prefix: "",
            Suffix: "",
            IncidentNumber: "",
            Comments: ""
        });
    }

    const rowClassStyle = (rowData) => {
        return rowData.ItemStatus === "ERROR" ? 'error-highlight' : ''
    }


    const getProdItemStatus = (rowData) => {
        if (rowData.Enabled && rowData.ItemStatus === "ENABLED") {
            return <span className={`issue-status-badge status-enabled`}>{rowData.ItemStatus}</span>;
        }
        else if (!rowData.Enabled) {
            return <span className={`issue-status-badge status-disabled`}>{rowData.ItemStatus}</span>;
        }
        else if (rowData.ItemStatus === "RETRY") {
            return <span className={`issue-status-badge status-retry`}>{rowData.ItemStatus}</span>;
        }
        else if (rowData.ItemStatus)
            return <span className={`issue-status-badge status-disabled`}>{rowData.ItemStatus}</span>;
        else
            return "N.A"
    }

    const getCacheProductionUrl = (rowData) => {
        let url = null
        if (rowData.OSVersion === "AIX") {
            if (rowData.Platform === "ENSEMBLE" || rowData.Platform === "HEALTHSHARE")
                url = `https://${rowData.Instance.toLocaleLowerCase()}.nshs.edu/csp/healthshare/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
            else
                url = `https://${rowData.Server.toLocaleLowerCase()}.nshs.edu/${rowData.Instance.toLocaleLowerCase()}/csp/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
        }
        else if (rowData.OSVersion === "LINUX") { //HM-1149 - fix
            url = `https://${rowData.Instance.toLocaleLowerCase()}.mid.northwell.edu/csp/healthshare/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
        }
        return <a href={url} target="_blank">{rowData.NameSpace}</a>;
    }


    const onSelectionChange = (event) => {
        setpageErrorMessage(null); setpageSuccessMessage(null); setSelectedRows(event.value);
    }

    const onRestoreConnBtnClick = () => {
        setpageErrorMessage(null); setpageSuccessMessage(null);
        if (selectedRows && selectedRows.length > 0) {
            setConfirmDialogVisible(true)
        }
        else {
            setpageErrorMessage("No items are selected.");
        }
    }

    const confirmDialogFooter = (
        <div>
            <Button label='YES' onClick={restoreConnectionRequest} />
            <Button label='NO' onClick={(e) => { setConfirmDialogVisible(false); setpageErrorMessage(null); setpageSuccessMessage(null); }} className='p-button-secondary' />
        </div>
    );

    const confirmJobOffDialogFooter = (
        <div>
            <Button label='OK' onClick={(e) => { setShowJobbingOffMsg(false); setpageErrorMessage(null); setpageSuccessMessage(null); }} />
        </div>
    );

    const showError = (severity, summary, msg) => {
        alertMessage.current.show({ severity: severity, summary: summary, detail: msg, life: global.ALERT_MSG_LIFE });
    }

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <div>
                <BreadCrumbComponent {...props} />
                <Toast ref={alertMessage} />
                {pageErrorMessage ? showError(MessageSeverity.ERROR, "Error Message", pageErrorMessage) : <></>}
                {pageSuccessMessage ? showError(MessageSeverity.SUCCESS, "Success Message", pageSuccessMessage) : <></>}

                <div hidden={hideSpinner}>
                    <Spinner />
                </div>

                <div className="restoreConnection">
                    <header className="page-header">
                        <div className="page-header-title">RESTORE CONNECTION
                            &nbsp;<Tooltip target=".custom-target-icon" />
                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                data-pr-tooltip="UI to restore hie components connections"
                                data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                style={{ fontSize: "1rem", cursor: "pointer" }}></i>
                        </div>
                    </header>
                    <div className="card">
                        <table className="w-100" border="0">
                            <tr>
                                <td>
                                    <Dropdown disabled={showPreviewData && productionItems && productionItems.length > 0} style={{ width: "100%" }} className="ui-column-filter" name="Instance" placeholder="Select Instance" value={selectedOptions.Instance}
                                        filter options={hieInstance} onChange={(e) => onInstanceChange(e)} tooltip="Required" />
                                </td>
                                <td>
                                    <MultiSelect style={{ width: "100%" }} className="ui-column-filter" name="Namespace" filter value={selectedOptions.Namespace}
                                        options={namespace} onChange={(e) => handleMultiDropDownInputChange(e)} disabled={disableNameSpaceDropDown || (showPreviewData && productionItems && productionItems.length > 0)}
                                        maxSelectedLabels={2} placeholder="Select Namespace" tooltip="Required" />
                                </td>
                                <td>
                                    <Dropdown disabled={showPreviewData && productionItems && productionItems.length > 0} style={{ width: "100%" }} className="ui-column-filter" name="ComponentType" placeholder="Component Type" value={selectedOptions.ComponentType}
                                        options={bizHostCategoty} onChange={(e) => handleDropDownInputChange(e)} tooltip="Required" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Dropdown disabled={showPreviewData && productionItems && productionItems.length > 0} style={{ width: "100%" }} className="ui-column-filter" name="Action" placeholder="Action" value={selectedOptions.Action}
                                        options={action} onChange={(e) => handleDropDownInputChange(e)} tooltip="Required" tooltipOptions={{ position: "top" }} />
                                </td>
                                <td>
                                    <InputText disabled={showPreviewData && productionItems && productionItems.length > 0} type="text" name="Prefix" value={selectedOptions.Prefix} placeholder="Business Hostname Prefix" onChange={(e) => handleInputChange(e)} tooltip="Required" />
                                </td>
                                <td>
                                    <InputText disabled={showPreviewData && productionItems && productionItems.length > 0} type="text" name="Suffix" value={selectedOptions.Suffix} placeholder="Business Hostname Suffix" onChange={(e) => handleInputChange(e)} tooltip="Required" />
                                </td>
                                <td>
                                    <InputText disabled={showPreviewData && productionItems && productionItems.length > 0} type="text" name="IncidentNumber" value={selectedOptions.IncidentNumber} placeholder="Incident Number" onChange={(e) => handleInputChange(e)} tooltip="Required" tooltipOptions={{ position: "top" }} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <InputTextarea disabled={showPreviewData && productionItems && productionItems.length > 0} type="text" name="Comments" value={selectedOptions.Comments} placeholder="Comments" onChange={(e) => handleInputChange(e)} rows={4} tooltip="Required" tooltipOptions={{ position: "bottom" }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {showPreviewData && productionItems && productionItems.length > 0 ? <><Button label="Edit Search" onClick={() => onEditSearch()} /> &nbsp;</> :
                                        <><Button label="Search" onClick={() => onSearchRequest()} /> &nbsp;</>}
                                    <Button label="View logs" onClick={() => viewAuditLogs()} tooltip="View Audit Logs" tooltipOptions={{ position: "top" }} /> &nbsp;
                                    <Button label="Clear" onClick={() => clearSelectedOptions()} tooltip="Clear search" tooltipOptions={{ position: "top" }} /> &nbsp;
                                    {showPreviewData && productionItems && productionItems.length > 0 ? <Button label="Restore Connection" className="p-button-help" onClick={onRestoreConnBtnClick} /> : <></>}
                                </td>
                            </tr>
                        </table>
                    </div> <br />
                    {showPreviewData ?
                        <div>
                            {productionItems && productionItems.length > 1 ? <><Message severity="warn" text="Please review the below list, before submitting the request to restore connection." /><br /><br /></> : <></>}
                            <DataTable
                                value={productionItems}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                dataKey="Id"
                                filters={filters}
                                filterDisplay="menu"
                                responsiveLayout="scroll"
                                header={globalSearchFilter}
                                rowClassName={rowClassStyle}
                                showGridlines selectionPageOnly
                                selection={selectedRows}
                                onSelectionChange={onSelectionChange}
                                emptyMessage="No Records found."
                            >
                                <Column selectionMode="multiple" headerStyle={{ width: '3em' }} frozen></Column>
                                <Column field="Instance" header="Instance" style={{ textAlign: 'left' }} />
                                <Column field="NameSpace" header="Namespace" style={{ textAlign: 'left' }} body={getCacheProductionUrl} />
                                <Column field="Name" header="Interface Name" style={{ textAlign: 'left' }} />
                                <Column field="ItemStatus" header="Current Status" body={getProdItemStatus} filter filterPlaceholder='Status' filterElement={itemStatusFilterTemplate} showFilterMatchModes={false} />
                            </DataTable></div> : <></>}
                </div>
            </div>

            {/* confirmation dialog box*/}
            <Dialog header={"Restore connection - " + selectedOptions.Action.toLocaleLowerCase() + " confirm"} footer={confirmDialogFooter}
                visible={confirmDialogVisible} width='400px' modal={true}
                onHide={(e) => { setConfirmDialogVisible(false); setpageErrorMessage(null); setpageSuccessMessage(null); }} maximizable={false}>
                <div>
                    {'Would you like to ' + selectedOptions.Action.toLocaleLowerCase() + ' the selected ' + selectedRows.length + ' components? This activity will be logged and audited!'}
                </div>
            </Dialog>

            {/* jobbing off message dialog box*/}
            <Dialog header="Information" footer={confirmJobOffDialogFooter}
                visible={showJobbingOffMsg} width='400px' modal={true}
                onHide={(e) => { setShowJobbingOffMsg(false); setpageErrorMessage(null); setpageSuccessMessage(null); }} maximizable={false}>
                <div>
                    <>
                        More than {RESTORE_CONN_RESTART_THRESHOLD} records are selected to restart. Request is submitted to a backend job, which will take care to restart all the selected interfaces.
                        Please visit <NavLink key='link' to='/monitor/production/items' target="_blank"><strong>link</strong></NavLink> ,to track the current interface status.
                    </>
                </div>
            </Dialog>
        </MsalAuthenticationTemplate>
    )
}

const WrappedRestoreConnection = withMsal(RestoreConnection);
export default withAITracking(reactPlugin, WrappedRestoreConnection);