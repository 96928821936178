import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { AlertProcessingService } from './AlertProcessingService';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import Spinner from "../Spinner";
import AlertMessage from "../alertmessage/AlertMessage"
import { MessageSeverity, FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';

//HM-1145 - UI to list alert processing scope rules
class AlertProcessingRulesComponent extends Component {
    constructor() {
        super();
        this.WrappedActionGroupComponent = React.createRef();
        this._isMounted = false;
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            alertProcessingRules: [],
            selectedAlertProcessingRuleView: {
                AlertActionRuleId: 0,
                Name: null,
                Description: null,
                Priority: 0,
                TriggerEmail: false,
                ActionGroupList: [],
                MetricType: [],
                MetricName: [],
                SystemId: [],
                ServerInstance: [],
                NameSpaceId: [],
                NameSpace: [],
                InterfaceId: [],
                Interface: [],
                CreatedBy: null,
                DisplayCreatedDate: null,
                ModifiedBy: null,
                DisplayModifiedDate: null
            },
            showAlertProcessingRuleView: false,
            hideSpinner: false,
            showpagination: false,
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            globalFilterValue: ''
        }
        this.alertProcessingService = new AlertProcessingService();
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.globalSearchFilter = this.globalSearchFilter.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getAlertProcessingScopes();
    }

    getAlertProcessingScopes() {
        this.alertProcessingService.getAlertProcessingScopes(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({
                            alertProcessingRules: d.body,
                            hideSpinner: true, pageSuccessMessage: '', pageErrorMessage: ''
                        });
                        if (this.state.alertProcessingRules.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getAlertProcessingById = (Id) => {
        this.setState({ hideSpinner: false })
        this.alertProcessingService.getAlertProcessingById(Id, true, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted && d.status === 200) {
                        this.setState({
                            selectedAlertProcessingRuleView: d.body,
                            showAlertProcessingRuleView: true,
                            hideSpinner: true
                        });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    //HM-935 - change to save the filter
    onGlobalFilterChange(event, filtersKey) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter(filtersKey) {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left" >
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                    <div></div>
                </div>
            </div>
        )
    }

    clearDataTableFilters = () => {
        this.setState({
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            }
        });
        sessionStorage.clear();
    }

    alertProcessingRuleAction = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-eye" className="p-button-rounded p-button-secondary p-mr-2" tooltip="View" tooltipOptions={{ position: 'top' }} onClick={() => this.getAlertProcessingById(rowData.AlertActionRuleId)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" tooltip="Edit" tooltipOptions={{ position: 'top' }} onClick={() => window.open('/admin/alert/processing/rule/' + rowData.AlertActionRuleId, "_blank")} />
            </React.Fragment>
        );
    }

    booleanChecker = (rowData, item) => {
        if (rowData[item.field]) {
            return "Active"
        } else {
            return "Not Active"
        }
    };

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="alterProcessingContainer">
                    <BreadCrumbComponent {...this.props} />

                    <AlertMessage ref={instance => { this.alertMsg = instance }} />
                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <div className='flex justify-content-between' style={{ marginBottom: '10px' }}>
                            <div className="page-header-title">ALERT PROCESSING RULES
                                &nbsp;<Tooltip target=".custom-target-icon" />
                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                    data-pr-tooltip="These rules are applied above the base rules (threshold rule, custom alert & azure alert rule)."
                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                            </div>
                            <Button label='' icon='pi pi-plus' tooltip='Create Alert processing Rule' tooltipOptions={{ position: 'left' }} onClick={() => window.open('/admin/alert/processing/rule/create', '_blank')} />
                        </div>

                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.alertProcessingRules}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            dataKey="AlertActionRuleId"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            showGridlines removableSort
                            emptyMessage="No Records found."
                            onFilter={(e) => this.setState({ filters: e.filters })} //HM-935 - change to save the filter
                            stateStorage="session" stateKey="dt-alertprocessingrules-session"
                        >
                            <Column hidden field="AlertActionRuleId" header="AlertActionRuleId" style={{ textAlign: 'left', minWidth: '80px' }} />
                            <Column field="Name" header="Rule Name" style={{ textAlign: 'left' }} />
                            <Column field="DeveloperSupportSoptext" header="Description" style={{ textAlign: 'left' }} />
                            <Column field="Priority" header="Priority" showFilterMatchModes={false} style={{ textAlign: 'left' }} />
                            <Column field="TriggerEmail" header="Status" style={{ textAlign: 'left' }} body={this.booleanChecker} />
                            <Column field="edit" header="Action" alignHeader='center' body={this.alertProcessingRuleAction} style={{ whiteSpace: 'nowrap', width: '150px', textAlign: 'center' }} />
                        </DataTable>
                    </div>
                </div>

                <Dialog header={this.state.selectedAlertProcessingRuleView.Name} style={{ width: '50vw' }}
                    visible={this.state.showAlertProcessingRuleView} modal={true}
                    onHide={e => this.setState({ showAlertProcessingRuleView: false })} maximizable={true} closeOnEscape={true}>
                    <div>
                        <table class="fixedtablelayout" border={1}>
                            <th colSpan={2} class="th-details">General Information</th>
                            <tr><td>Description:</td><td class="fluidcell">{this.state.selectedAlertProcessingRuleView.Description}</td></tr>
                            <tr><td>Status:</td><td class="fluidcell">{this.state.selectedAlertProcessingRuleView.TriggerEmail ? 'Active' : 'Not Active'}</td></tr>
                            <tr><td>Priority:</td><td>{this.state.selectedAlertProcessingRuleView.Priority || "N.A"}</td></tr>
                        </table>
                    </div>
                    <div>
                        <br />
                        <table class="fixedtablelayout" border={1}>
                            <th colSpan={2} class="th-details">Alert Configuration</th>
                            <tr><td>Metric Type:</td><td class="fluidcell">{this.state.selectedAlertProcessingRuleView.MetricName ? this.state.selectedAlertProcessingRuleView.MetricName.map(item => item).join(', ') : ''}</td></tr>
                            <tr><td>Instance:</td><td class="fluidcell">{this.state.selectedAlertProcessingRuleView.ServerInstance ? this.state.selectedAlertProcessingRuleView.ServerInstance.map(item => item).join(', ') : ''}</td></tr>
                            <tr><td>NameSpace:</td><td class="fluidcell">{this.state.selectedAlertProcessingRuleView.NameSpace.length > 0 ? this.state.selectedAlertProcessingRuleView.NameSpace.map(item => item).join(', ') : 'N.A'}</td></tr>
                            <tr><td>Interface:</td><td class="fluidcell">{this.state.selectedAlertProcessingRuleView.Interface.length > 0 ? this.state.selectedAlertProcessingRuleView.Interface.map(item => item).join(', ') : 'N.A'}</td></tr>
                            <tr><td>Action group's:</td><td class="fluidcell">{this.state.selectedAlertProcessingRuleView.ActionGroupList ? this.state.selectedAlertProcessingRuleView.ActionGroupList.map(item => item.Name).join(', ') : ''}</td></tr>
                        </table>
                    </div>
                    <div>
                        <br />
                        <table class="fixedtablelayout" border={1}>
                            <th colSpan={2} class="th-details">Actions</th>
                            <tr><td>Created by:</td><td>{this.state.selectedAlertProcessingRuleView.CreatedBy || "N.A"}</td></tr>
                            <tr><td>Created date:</td><td class="fluidcell">{this.state.selectedAlertProcessingRuleView.DisplayCreatedDate ? this.state.selectedAlertProcessingRuleView.DisplayCreatedDate : "N.A"}</td></tr>
                            <tr><td>Modified by:</td><td class="fluidcell">{this.state.selectedAlertProcessingRuleView.ModifiedBy || "N.A"}</td></tr>
                            <tr><td>Modified date:</td><td class="fluidcell">{this.state.selectedAlertProcessingRuleView.DisplayModifiedDate ? this.state.selectedAlertProcessingRuleView.DisplayModifiedDate : "N.A"}</td></tr>
                        </table>
                    </div>
                </Dialog>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedAlertProcessingRulesComponent = withMsal(AlertProcessingRulesComponent);
export default withAITracking(reactPlugin, WrappedAlertProcessingRulesComponent);