import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import HIEMetricService from '../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import './MessageQueueComponent.css';

class MessageQueueComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            messageQueues: [],
            loading: true,
        };
        this.hieMetricService = new HIEMetricService();
        this.getTableData = this.getTableData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getTableData();
    }

    getTableData() {
        this.hieMetricService.getMessageQueues(this.props.match.params.server + ':' + this.props.match.params.instance).then(messageQueues => {
            if (this._isMounted) this.setState({ messageQueues, loading: false });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    rowStyle(rowData) {
        // removed per HM-35
        // return {alert: (rowData.LastMessageDelay>10)?true:false};        
    }

    formatSeconds(rowData, column) {

        let delayInS = rowData[column.field];

        let tVal = 0, d = 0, h = 0, m = 0, s = 0;
        let dOut = "", hOut = "", mOut = "", sOut = "";

        d = ~~(((delayInS / 60) / 60) / 24);
        tVal = (delayInS - (((d * 24) * 60)) * 60);
        dOut = d + "d ";

        h = ~~((tVal / 60) / 60);
        tVal = (tVal - ((h * 60) * 60));
        hOut = h + "h ";

        m = ~~(tVal / 60);
        mOut = m + "m ";

        s = tVal - (m * 60);
        // Per phone call, if seconds are less than 1, make it zero.
        // Example, 0.59 = 0
        //          0.001 = 0
        if (s < 1) { s = 0 }

        // this cleans up 26.2659999995 situations.
        s = ~~(s);

        sOut = s + "s ";

        return dOut + " " + hOut + " " + mOut + " " + sOut;

    }

    render() {
        return (
            <div className="messageQueueContainer component">
                <Accordion activeIndex={0}>
                    <AccordionTab header="Message Queues" headerStyle={{ textAlign: 'left' }}>
                        <DataTable value={this.state.messageQueues} selectionMode="single" autoLayout={true} loading={this.state.loading} paginator={true} rows={10} rowsPerPageOptions={[10, 20, 50, 100]} rowClassName={this.rowStyle} showGridlines={true}>
                            <Column field="Namespace" header="Namespace" style={{ textAlign: 'left' }} />
                            <Column field="Name" header="Name" style={{ textAlign: 'left' }} sortable />
                            <Column field="QCount" header="Count" sortable />
                            <Column field="QCountAlertValue" header="Count Alert Value" sortable />
                            <Column field="LastMessageDelay" header="Delay" body={this.formatSeconds} sortable />
                            <Column field="LastMessageDelayAlertValue" header="Delay Alert Value" body={this.formatSeconds} sortable />
                            <Column field="TimeCollected" header="Time Collected" sortable />
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </div>
        );
    }
}

//export default MessageQueueComponent;
export default withAITracking(reactPlugin, MessageQueueComponent);