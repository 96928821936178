import React, { useEffect, useState, useRef } from "react";
import BreadCrumbComponent from "../bread-crumb/BreadCrumbComponent";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../AppInsights";
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { RecoveryService } from "./RecoveryService"
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import Spinner from "../Spinner"
import { Toast } from "primereact/toast";
import { MessageSeverity } from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import * as Yup from "yup";
import "./Recovery.css"
import { Dialog } from "primereact/dialog";

//HDO-6805 - Create a new screen that will be used to "front" the server reconnection script.
function RestoreConnectionLogs(props) {
    const alertMessage = useRef(null);
    const recoveryService = new RecoveryService();
    const [restoreConnLogsData, setrestoreConnLogsData] = useState([]);
    const [hideSpinner, sethideSpinner] = useState(false);
    const [pageErrorMessage, setpageErrorMessage] = useState(null)
    const [pageSuccessMessage, setpageSuccessMessage] = useState(null)
    const [filters, setfilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [hieInstance, setHieInstance] = useState([]);
    const [disableFetchBtn, setdisableFetchBtn] = useState(true);
    const [showLogsDialog, setshowLogsDialog] = useState(false)
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectLogItem, setselectLogItem] = useState({});
    const [searchLogReq, setSearchlogReq] = useState({
        Instance: '',
        FromDate: null, //fromDate
        ToDate: null, //toDate
        Timezone: null
    });

    useEffect(() => {
        getHIEInstances()
    }, [])

    const getClientTimeZone = () => {
        var clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        switch (clientTimeZone) {
            case "Asia/Calcutta":
                return "India Standard Time";
            case "America/New_York":
                return "Eastern Standard Time";
        }
    }

    const getHIEInstances = () => {
        setpageSuccessMessage(null); setpageErrorMessage(null)
        recoveryService.getHIEInstances("CACHE", props.msalContext).then(response => {
            if (response && response.status === 200) {
                let hieInstanceList = ['ALL']
                response.body.map((item) => (hieInstanceList.push({ label: item.ServerInstance, value: item.ServerInstance })))
                setHieInstance(hieInstanceList)
                sethideSpinner(true)
            }
            else {
                sethideSpinner(true); setpageErrorMessage("Something went wrong !!!")
            }
        }).catch(e => {
            console.log(e)
        });
    }

    const getResotoreConnLogs = () => {
        sethideSpinner(false); setpageSuccessMessage(null); setpageErrorMessage(null);
        if (validateSearchInputs()) {
            let fromDateLocal = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(searchLogReq.FromDate)
            let toDateLocal = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(searchLogReq.ToDate)

            recoveryService.recoverComponentLogs(searchLogReq.Instance, fromDateLocal, toDateLocal, searchLogReq.Timezone, props.msalContext).then(response => {
                if (response && response.status === 200) {
                    setrestoreConnLogsData(response.body)
                    sethideSpinner(true)
                }
                else {
                    sethideSpinner(true); setpageErrorMessage("Something went wrong !!!")
                }
            }).catch(e => {
                console.log(e)
            });
        }
    }

    const validateSearchInputs = () => {
        try {
            let searchSchema = Yup.object({
                //Instance: Yup.string().required(),
                FromDate: Yup.date().required(),
                ToDate: Yup.date().required(),
            });
            searchSchema.validateSync(searchLogReq, { abortEarly: false })
            return true
        }
        catch (error) {
            setpageErrorMessage(error.inner[0].message); sethideSpinner(true)
            return false
        }
    }

    const onInstanceChange = (event) => {
        setpageSuccessMessage(null); setpageErrorMessage(null)
        let instance = event.target.value
        if (instance !== null && instance !== undefined) {
            setSearchlogReq({
                ...searchLogReq,
                [event.target.name]: event.target.value,
                Timezone: getClientTimeZone()
            })
        }
    }

    const setToDateChange = (event) => {
        setpageSuccessMessage(null); setpageErrorMessage(null)
        let date = event.target.value
        if (date.getDate() === new Date().getDate()) {
            setSearchlogReq({
                ...searchLogReq,
                [event.target.name]: new Date(event.target.value.setHours(23, 59, 59, 999)),
                Timezone: getClientTimeZone()
            })
            validateFilterDates(searchLogReq.FromDate, new Date(date.setHours(23, 59, 59, 999)))
        } else {
            setSearchlogReq({
                ...searchLogReq,
                [event.target.name]: new Date(event.target.value.setHours(23, 59, 59, 999)),
                Timezone: getClientTimeZone()
            })
            validateFilterDates(searchLogReq.FromDate, new Date(date.setHours(23, 59, 59, 999)))
        }


    }

    const setFromDateChange = (event) => {
        setpageSuccessMessage(null); setpageErrorMessage(null)
        setSearchlogReq({
            ...searchLogReq,
            [event.target.name]: new Date(event.target.value),
        })
        validateFilterDates(new Date(event.target.value), searchLogReq.ToDate)
    }


    const getDifferenceInDays = (date1, date2) => {
        console.log(date1 + " " + date2)
        const diffInMs = Math.abs(date2.getTime() - date1.getTime());
        return Math.floor(diffInMs / (1000 * 3600 * 24));
    }

    const validateFilterDates = (fromDate, toDate) => {
        var currentDate = new Date().setHours(23, 59, 59, 999);
        if (fromDate === null || toDate === null) {
            setdisableFetchBtn(true); return false;
        }
        else if (fromDate > toDate) {
            setpageErrorMessage("From Date should be less than To Date")
            setdisableFetchBtn(true); return false;
        }
        else if (fromDate > currentDate) {
            setpageErrorMessage("From Date should be less than current date")
            setdisableFetchBtn(true); return false;
        }
        else if (toDate > currentDate) {
            setpageErrorMessage("To Date should be less than current date")
            setdisableFetchBtn(true); return false;
        }
        else if (fromDate > toDate) {
            setpageErrorMessage("From Date should be less than To Date")
            setdisableFetchBtn(true); return false;
        }
        else if (getDifferenceInDays(fromDate, toDate) > global.PROD_ITEM_MAX_DATE_RANGE - 1) { //-1 - to exclude the extra day, after diff - HM-693 fix
            setpageErrorMessage("Date range cannot exceed more than " + global.PROD_ITEM_MAX_DATE_RANGE + " days")
            setdisableFetchBtn(true); return false;
        }
        setpageSuccessMessage(null); setpageErrorMessage(null); setdisableFetchBtn(false);
        return true;
    }

    const onGlobalFilterChange = (event) => {
        setpageErrorMessage(null); setpageSuccessMessage(null);

        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setfilters(_filters);
        setGlobalFilterValue(value);
    }

    const globalSearchFilter = () => {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearDataTableFilters} />
                    <h4>AUDIT LOGS</h4>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={globalFilterValue} onChange={onGlobalFilterChange}
                            placeholder="Global Search" size={35} />
                    </span>
                </div>
            </div>
        )
    }

    const onRowDoubleClick = (event) => {
        console.log(event)
        setselectLogItem(event.data)
        setshowLogsDialog(true)
    }

    const clearDataTableFilters = () => {
        setpageErrorMessage(null); setpageSuccessMessage(null); setGlobalFilterValue('');
        setfilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
        })
    }

    const clearSearchReqInput = () => {
        setpageErrorMessage(null); setpageSuccessMessage(null); setdisableFetchBtn(true);
        setSearchlogReq({
            Instance: '',
            FromDate: null, //fromDate
            ToDate: null, //toDate
            Timezone: null
        });
    }

    const showError = (severity, summary, msg) => {
        alertMessage.current.show({ severity: severity, summary: summary, detail: msg, life: global.ALERT_MSG_LIFE });
    }

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <div>
                <BreadCrumbComponent {...props} />
                <Toast ref={alertMessage} />
                {pageErrorMessage ? showError(MessageSeverity.ERROR, "Error Message", pageErrorMessage) : <></>}
                {pageSuccessMessage ? showError(MessageSeverity.SUCCESS, "Success Message", pageSuccessMessage) : <></>}

                <div hidden={hideSpinner}>
                    <Spinner />
                </div>

                <div className="restoreConnectionLogs">
                    <header className="page-header">
                        <div className="page-header-title">RESTORE CONNECTION LOGS
                            &nbsp;<Tooltip target=".custom-target-icon" />
                            <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                data-pr-tooltip="UI to view hie restore components connections audit logs."
                                data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                style={{ fontSize: "1rem", cursor: "pointer" }}></i>
                        </div>
                    </header>
                    <div>
                        <div className="card">
                            <div className="float-container">
                                <div className="float-child">
                                    <Dropdown style={{ width: "100%" }} className="ui-column-filter" name="Instance" placeholder="Select Instance" value={searchLogReq.Instance}
                                        options={hieInstance} onChange={(e) => onInstanceChange(e)} />
                                </div>
                                <div className="float-child">
                                    <Calendar id="basic" name="FromDate" value={searchLogReq.FromDate} onChange={setFromDateChange} tooltip="Required" placeholder="From Date" ></Calendar>
                                </div>
                                <div className="float-child">
                                    <Calendar id="basic" name="ToDate" value={searchLogReq.ToDate} onChange={setToDateChange} tooltip="Required" placeholder="To Date" ></Calendar>
                                </div>
                                <div className="float-child">
                                    <Button disabled={disableFetchBtn} label='Fetch' icon='pi pi-arrow-down' onClick={getResotoreConnLogs} />
                                </div>
                                <div className="float-child">
                                    <Button label='Clear' onClick={clearSearchReqInput} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable value={restoreConnLogsData}
                        selectionMode="single"
                        paginator={true}
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        dataKey="Id"
                        filters={filters}
                        filterDisplay="menu"
                        responsiveLayout="scroll"
                        header={globalSearchFilter}
                        onRowDoubleClick={onRowDoubleClick}
                        showGridlines
                        sortField='Timestamp' sortOrder="-1"
                        emptyMessage="No Records found."
                    >
                        <Column field="PartitionKey" header="User" style={{ textAlign: 'left' }} />
                        <Column field="TimestampValue" header="Timestamp" style={{ textAlign: 'left' }} sortField="Timestamp" sortable />
                        <Column field="Instance" header="Instance" style={{ textAlign: 'left' }} />
                        <Column field="Namespace" header="Namespace" style={{ textAlign: 'left' }} />
                        <Column field="ComponentType" header="Component Type" style={{ textAlign: 'left' }} />
                        <Column field="Action" header="Action" style={{ textAlign: 'left' }} />
                        <Column field="Prefix" header="Prefix" style={{ textAlign: 'left' }} />
                        <Column field="Suffix" header="Suffix" style={{ textAlign: 'left' }} />
                    </DataTable>
                </div>
            </div>

            <Dialog header="Details" style={{ width: '70vw' }}
                visible={showLogsDialog} modal={true} maximizable={true} onHide={() => { setshowLogsDialog(false) }}
                closeOnEscape={true}>
                <div>
                    <table class="fixedtablelayout" border={1}>
                        <tr><td>Component Name's:</td><td class="fluidcell">{selectLogItem.ComponentName || "N.A"}</td></tr>
                        <tr><td>Comment:</td><td class="fluidcell">{selectLogItem.Comments || "N.A"}</td></tr>
                        <tr><td>Response:</td><td class="fluidcell">{selectLogItem.APIResponse || "N.A"}</td></tr>
                    </table>
                </div>
            </Dialog>
        </MsalAuthenticationTemplate>
    );
}

const WrappedRestoreConnectionLogs = withMsal(RestoreConnectionLogs);
export default withAITracking(reactPlugin, WrappedRestoreConnectionLogs);