import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { NavLink } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer } from '@fortawesome/free-solid-svg-icons'
import { ProgressSpinner } from 'primereact/progressspinner';
import NoDataFoundImg from '../../../images/NoDataFound.png';
import HIEMetricService from '../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import "./ServersComponent.css";

class ServersComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            servers: [],
            loading: true,
        };
        this.hieMetricService = new HIEMetricService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.hieMetricService.getSystems().then(servers => {
            if (this._isMounted) this.setState({ servers, loading: false });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let body;
        if (this.state.loading) {
            body = <ProgressSpinner className="spinner" />;
        } else if (this.state.servers.length === 0) {
            body = <div className="noDataFoundContainer"><img src={NoDataFoundImg} alt="No Data Found" /></div>;
        } else {
            body =
                <div className="serverList">
                    {
                        this.state.servers.map((server, index) => {
                            return (
                                <NavLink key={index} to={server.ServerName}>
                                    {/* <div className={"serverEntry " + (index === 4 ? 'pulseAlert' : '')}> */}
                                    <div className="serverEntry">
                                        <div className="serverName">{server.ServerName.split(".")[0]}</div>
                                        <FontAwesomeIcon className="serverIcon" size="3x" icon={faServer} />
                                        <div className="serverInstance">{server.ServerInstance}</div>
                                    </div>
                                </NavLink>
                            );
                        })
                    }
                </div>;
        }

        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="serversContainer">
                    <Accordion activeIndex={0}>
                        <AccordionTab header="SERVERS" headerStyle={{ textAlign: 'left' }}>
                            {body}
                        </AccordionTab>
                    </Accordion>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}

//export default ServersComponent;
const WrappedServersComponent = withMsal(ServersComponent);
export default withAITracking(reactPlugin, WrappedServersComponent);
