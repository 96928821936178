import React, { Component } from 'react';
import "./IssuessComponent.css";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../AppInsights';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { msalInstance } from '../../App';
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { IssuesDetailsService } from './IssuesDetailsService';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import Spinner from '../Spinner'

class IssuesDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this._isIssueDetailsMounted = false;
        this.state = {
            IssueDetails: [],
            IssueHistoryDetails: [],
            hideSpinner: false,
            pageErrorMessage: null,
            pageSuccessMessage: null,
            statusButtonLabelText: 'OK',
            dropdownStatusValue: 0,
            ackbuttonIcon: '',
            viewMode: false,
            commentBoxText: '',
            showHistoryDetails: false,
            jwtToken: '',
            showpagination: false,
            availableStatusToUpdate: [],
            updateBtnDisabled: true,

            disableNotificationVisible: false,
            dialogNotificationDialogText: "",
            dialogNotificationDialogHeaderText: "",

            disableEmailTextVisible: false,
            dialoagEmailTextDialogText: "",
            dialogEmailTextHeaderText: "",
            loadBreadCrumbComponent: true,
        };
        this.issuesDetailsService = new IssuesDetailsService();
        this.jiraBodyTemplate = this.jiraBodyTemplate.bind(this);
    }

    componentDidMount() {
        this._isIssueDetailsMounted = true;
        this.getIssueDetailsById()
    }

    getIssueDetailsById(showUpdateMsg) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        let incidentStatus = [
            { label: 'ON-HOLD', value: '4' },
            { label: 'ESCALATE', value: '5' },
            { label: 'RESOLVED', value: '3' }
        ];
        this.issuesDetailsService.getIssueDetailsById(this.props.match.params.id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                } else if (d.status === 200) {
                    if (this._isIssueDetailsMounted) {
                        this.setState({ IssueDetails: d.body, hideSpinner: true, loadBreadCrumbComponent: true });
                        if (showUpdateMsg)
                            this.setState({
                                pageSuccessMessage: 'Issue status changed to ' + this.state.IssueDetails[0].StatusName.toUpperCase() + '.'
                                , pageErrorMessage: null
                            })
                        if (this.state.IssueDetails[0] !== null && this.state.IssueDetails[0].AlertType === 2) {
                            switch (this.state.IssueDetails[0].StatusName) {
                                case 'NEW':
                                    this.setState({
                                        statusButtonLabelText: 'Acknowledge', ackbuttonIcon: 'pi pi-thumbs-up',
                                        pageSuccessMessage: null, pageErrorMessage: null
                                    })
                                    break
                                case 'ACKNOWLEDGED':
                                    this.setState({
                                        availableStatusToUpdate: incidentStatus,
                                        ackbuttonIcon: 'pi pi-check-square', pageSuccessMessage: null, pageErrorMessage: null
                                    })
                                    break
                                case 'RESOLVED':
                                    this.setState({ viewMode: true, pageSuccessMessage: null, pageErrorMessage: null })
                                    break;
                                default:
                                    this.setState({
                                        availableStatusToUpdate: incidentStatus.filter(s => s.value != this.state.IssueDetails[0].StatusId),
                                        ackbuttonIcon: 'pi pi-check-square', pageSuccessMessage: null, pageErrorMessage: null
                                    })
                            }
                        }
                        else
                            this.setState({ viewMode: true, pageSuccessMessage: null, pageErrorMessage: null })
                    }
                }
            }
        ).catch(e => {
            this.setState({ hideSpinner: true, pageErrorMessage: 'Something went wrong !!!' })
            console.log(e)
        });
    }

    updateNotificationStatus() {
        var onlyValidCharacters = /^[^\'"]*$/.test(this.state.commentBoxText); //HM-708 bug fix

        //removed this validation as a part of HM-731
        // if (this.state.commentBoxText === '' || this.state.commentBoxText.trim().length == 0) {
        //     this.setState({ pageErrorMessage: 'Please provide your comments.', disableNotificationVisible: false })
        // }
        // else if (this.state.commentBoxText.trim().length < global.COMMENT_BOX_MIN_LENGTH) {
        //     this.setState({ pageErrorMessage: 'Comments should be atleast of ' + global.COMMENT_BOX_MIN_LENGTH + ' characters.', disableNotificationVisible: false })
        // }
        //Commented commentBoxText length validation as a part of HDO-5628 - Increase the Char limit for adding comments
        // if (this.state.commentBoxText.trim().length > global.COMMENT_BOX_MAX_LENGTH) {
        //     this.setState({ pageErrorMessage: 'Please provide your comments within ' + global.COMMENT_BOX_MAX_LENGTH + ' characters.', disableNotificationVisible: false })
        // }
        // else 
        if (!onlyValidCharacters) { //HM-708 bug fix
            this.setState({ pageErrorMessage: "Comments should not contain single (') or double (\") quotes.", disableNotificationVisible: false })
        }
        else {
            this.setState({ disableNotificationVisible: false, hideSpinner: false, loadBreadCrumbComponent: false })
            let userId = this.props.msalContext.accounts[0].username
            let comments = this.state.commentBoxText.trim()
            let status = this.state.IssueDetails[0].StatusName === 'NEW' ? 2 : this.state.dropdownStatusValue
            let requestBody = {
                UserID: userId,
                Comments: comments,
                Status: parseInt(status)
            }
            this.issuesDetailsService.updateNotificationStatus(this.props.match.params.id, JSON.stringify(requestBody), this.props.msalContext).then(
                (d) => {
                    if (d.status === 403) {
                        this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                    } else if (d.status === 400) {
                        console.log(d);
                        this.setState({ hideSpinner: true, pageErrorMessage: d.body.error })
                    } else if (d.status === 200) {
                        this.setState({
                            pageErrorMessage: null, pageSuccessMessage: null, dropdownStatusValue: 0, pageErrorMessage: null,
                            updateBtnDisabled: true, commentBoxText: "", showHistoryDetails: false
                        })
                        //this.props.history.push('/issue/' + this.props.match.params.id)
                        this.getIssueDetailsById(true) //updating the UI with latest update details
                    }
                }
            ).catch(e => {
                this.setState({ hideSpinner: true, pageErrorMessage: 'Something went wrong !!!' })
                console.log(e)
            });
        }
    }

    handleServerNotificationInputChange() {
        if (this.state.dropdownStatusValue === 0 && this.state.IssueDetails[0].StatusName != 'NEW') {
            //HM-681 
            this.setState({ pageErrorMessage: 'Please select appropriate status from drop-down', disableNotificationVisible: false })
        } else {
            let theText = "Are you sure you wish to " + this.state.statusButtonLabelText + " the issue ?"
            this.setState({ dialogNotificationDialogText: theText });
            this.setState({ dialogNotificationDialogHeaderText: this.state.statusButtonLabelText });
            this.setState({ disableNotificationVisible: true });
        }
    }

    handleViewHistoryChange() {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, hideSpinner: false, showHistoryDetails: false })
        this.issuesDetailsService.getStatusHistory(this.props.match.params.id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                } else if (d.status === 200) {
                    this.setState({ IssueHistoryDetails: d.body, hideSpinner: true, showHistoryDetails: true, pageErrorMessage: '' })
                    if (this.state.IssueHistoryDetails.length > 10) {
                        this.setState({ showpagination: true })
                    }
                }
            }
        ).catch(e => {
            this.setState({ hideSpinner: true, pageErrorMessage: 'Something went wrong !!!' })
            console.log(e)
        });
    }

    handleEmailTextChange() {
        let theText = <React.Fragment><div dangerouslySetInnerHTML={{ __html: this.state.IssueDetails[0].EmailContent }}></div></React.Fragment>
        this.setState({ dialoagEmailTextDialogText: theText });
        this.setState({ dialogEmailTextHeaderText: 'Email Text' });
        this.setState({ disableEmailTextVisible: true });
    }

    confirmUpdateIssue = (
        <div>
            <Button label='OK' onClick={() => this.updateNotificationStatus()} />
            <Button label='Cancel' onClick={e => this.setState({ disableNotificationVisible: false })} className='p-button-secondary' />
        </div>
    );

    onStatusChange = (status) => {
        //HM-681 
        this.state.availableStatusToUpdate.map(s =>
            s.value === status.value ?
                this.setState({ statusButtonLabelText: s.label, dropdownStatusValue: s.value, updateBtnDisabled: false }) : '')
    }

    jiraBodyTemplate(rowData) {
        return <a href={global.ENV_JIRA_URL + '/browse/' + rowData.JiraId}>{rowData.JiraId}</a>;
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        let showButtons;
        if (this.state.hideSpinner) {
            if (this.state.viewMode) {
                showButtons = <div>
                    <Button label='Back' icon='pi pi-arrow-left' onClick={() => this.props.history.push('/issues')} /> &nbsp;
                    <Button label='History' icon='pi pi-history' onClick={() => this.handleViewHistoryChange()} /> &nbsp;
                    <Button label='View Email Text' icon='pi pi-external-link' onClick={() => this.handleEmailTextChange()} />
                </div>
            }
            else {
                showButtons = <div>
                    <InputTextarea type='text' name='commentbox' rows={5} cols={160} placeholder='Add your comments' onChange={e => this.setState({ commentBoxText: e.target.value })}></InputTextarea><br /><br />
                    <Button label='Back' icon='pi pi-arrow-left' onClick={() => this.props.history.push('/issues')} /> &nbsp;
                    {//HM-681 
                        this.state.IssueDetails[0].StatusName === 'NEW' ?
                            <React.Fragment><Button label={this.state.statusButtonLabelText} icon={this.state.ackbuttonIcon} onClick={() => this.handleServerNotificationInputChange()} /> &nbsp;</React.Fragment> :
                            <React.Fragment>
                                <Dropdown style={{ minwidth: 'auto' }} className="ui-column-filter" placeholder="Status" value={this.state.dropdownStatusValue} options={this.state.availableStatusToUpdate} onChange={this.onStatusChange} /> &nbsp;
                                <Button label="UPDATE" icon="pi pi-pencil" onClick={() => this.handleServerNotificationInputChange()} disabled={this.state.updateBtnDisabled} /> &nbsp;
                            </React.Fragment>
                    }
                    <Button label='History' icon='pi pi-history' onClick={() => this.handleViewHistoryChange()} /> &nbsp;
                    <Button label='View Email Text' icon='pi pi-external-link' onClick={() => this.handleEmailTextChange()} />
                </div>
            }
        }
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className='issuesdetailscontainer'>

                    {this.state.loadBreadCrumbComponent ? <><BreadCrumbComponent {...this.props} /></> : <></>}
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div className='issuedetails'>
                        <header className="page-header">
                            <div className="page-header-title">ISSUE DETAILS -</div>
                        </header>
                        <div id='otherdetails'>
                            <span>{this.state.IssueDetails.length > 0 ? this.state.IssueDetails[0].EmailSubject : ''}</span> <br />
                        </div> <br />
                        <DataTable value={this.state.IssueDetails}
                            selectionMode="single"
                            autoLayout={true}
                            editable="true"
                            editMode="cell"
                            showGridlines={true}
                        >
                            <Column field="ID" header="#ID" style={{ textAlign: 'left', width: '50px' }} />
                            <Column field="JiraId" header="Jira" style={{ textAlign: 'left', width: '100px' }} body={this.jiraBodyTemplate} />
                            <Column field="PriorityID" header="Priority" style={{ textAlign: 'left', width: '50px' }} />
                            <Column field="ServerName" header="Host Name" style={{ textAlign: 'left' }} />
                            <Column field="MetricName" header="Metric Name" style={{ textAlign: 'left' }} />
                            <Column field="IssueLocation" header="Issue Location" style={{ textAlign: 'left', width: '150px' }} />
                            <Column field="CreatedDatetime" header="Created Datetime (EST)" style={{ textAlign: 'left' }} />
                            <Column field="SentDatetime" header="Email Sent Datetime (EST)" style={{ textAlign: 'left' }} />
                            <Column field="StatusName" header="Current Status" style={{ textAlign: 'left' }} />
                            <Column field="LastUpdated" header="Created/Updated By (EST)" style={{ textAlign: 'left' }} />
                        </DataTable>
                        <br /><center>{showButtons}</center>
                    </div>
                    <br />
                    <div className="issuehistorydetails" style={{ display: this.state.showHistoryDetails && this.state.IssueHistoryDetails.length ? "block" : "none" }}>
                        <DataTable value={this.state.IssueHistoryDetails}
                            selectionMode="single"
                            autoLayout={true}
                            rowClassName={this.rowMirrorStyle}
                            paginator={this.state.showpagination}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            editable="true"
                            editMode="cell"
                            showGridlines={true}
                        >
                            <Column field="CreatedDatetime" header="DateTime (EST)" style={{ textAlign: 'left' }} />
                            <Column field="UserId" header="Created/Updated By" style={{ textAlign: 'left' }} />
                            <Column field="Status" header="Status" style={{ textAlign: 'left' }} />
                            <Column field="Comment" header="Comments" style={{ textAlign: 'left' }} />
                        </DataTable>
                    </div>
                    <Dialog header={this.state.dialogNotificationDialogHeaderText} footer={this.confirmUpdateIssue}
                        visible={this.state.disableNotificationVisible} width='400px' modal={true}
                        onHide={e => this.setState({ disableNotificationVisible: false })} maximizable={false}>
                        <div>
                            {this.state.dialogNotificationDialogText}
                        </div>
                    </Dialog>

                    <Dialog header={this.state.dialogEmailTextHeaderText}
                        visible={this.state.disableEmailTextVisible} modal={true}
                        onHide={e => this.setState({ disableEmailTextVisible: false })} maximizable={false} closeOnEscape={true}>
                        <div>
                            {this.state.dialoagEmailTextDialogText}
                        </div>
                    </Dialog>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}
const WrappedIssuesDetailsComponent = withMsal(IssuesDetailsComponent);
export default withAITracking(reactPlugin, WrappedIssuesDetailsComponent);