class HIEMetricService {
    options = {
        headers: {
            'Cache-Control': 'no-cache'
        }
    };

    async getData(url) {
        const headers = {
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'x-northwell-version': '1.0',
            'Ocp-Apim-Subscription-Key': global.ENV_APIM_SUBSCRIPTION_KEY
        };

        const p = await fetch(url, { headers });
        return await p.json();
    }

    async getSystems() {
        /*
        let p = await fetch(this.__getURL('System'));
        let results = await p.json();
        return results;
        */
        return await this.getData(this.__getURL('System') + `&filter=Environment eq 'CACHE'`);
    }

    async getInstances(server) {
        let url = this.__getURL('System') + `&filter=ServerName eq '${server}'AND Environment eq 'CACHE'`;
        /*
        let p = await fetch(this.__getURL('System') + `&filter=ServerName eq '${server}'`);
        let results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getNamespaces(server, instance) {
        let url = this.__getURL('ProductionDetailDistinctNamespace') + `&filter=System eq '${server}:${instance}'`;
        /*
        let p = await fetch(this.__getURL('ProductionDetailDistinctNamespace') + `&filter=System eq '${server}:${instance}'`);
        let results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getSystemsAlerts() {
        const alerts = [];


        // CPU Alerts
        let url = this.__getURL('SystemCPULatest') + '&topCount=5';
        /*        
        let p = await fetch(url);
        let results = await p.json();
        */
        let results = await this.getData(url);
        //console.log(results)
        for (let result of results) {
            alerts.push({
                alert: result.Alert,
                type: 'CPU',
                description: result.Description,
                server: result.Server,
                instance: result.Instance,
                namespace: result.Namespace,
                timestamp: this.__dateTemplate(result.TimeCollected),
            });
        }



        // Storage System Alerts
        url = this.__getURL('SystemStorageLatest') + '&topCount=5';
        //p = await fetch(url);
        //results = await p.json();
        results = await this.getData(url);
        //console.log(results);
        for (let result of results) {
            alerts.push({
                alert: result.Alert,
                type: 'Storage',
                description: result.Description,
                server: result.System.split(':')[0],
                instance: result.Instance,
                namespace: result.Namespace,
                timestamp: this.__dateTemplate(result.TimeCollected),
            });
        }

        // Cache Queue Alerts
        url = this.__getURL('CacheQueueCountLatest') + '&filter=Alert ne 0 &topCount=5 &orderBy=TimeSlot desc';
        //p = await fetch(url);
        //results = await p.json();
        results = await this.getData(url);
        for (let result of results) {
            alerts.push({
                alert: result.Alert,
                type: 'Queue',
                description: result.AlertDescription,
                server: result.System.split(':')[0],
                instance: result.Instance,
                namespace: result.Namespace,
                timestamp: this.__dateTemplate(result.TimeSlot),
            });
        }

        alerts.sort((a, b) => {
            return (a.timestamp > b.timestamp) ? -1 : (a.timestamp < b.timestamp) ? 1 : 0;
        });

        return alerts;
    }

    async getCPU(server, minutes) {
        const url = this.__getURL('SystemCPU') + `&filter=Server eq '${server}'&orderBy=TimeCollected desc&topCount=${6 * minutes + 1}`;
        /*
        console.dir(this.__getURL('SystemCPU'));
        console.dir(`&filter=Server eq '${server}'&orderBy=TimeCollected desc&topCount=${6 * minutes + 1}`);
        console.dir(url);
        console.log(this.options);
        */
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        */
        //console.log(url);
        return await this.getData(url);
    }

    async getMemory(system, minutes) {
        const url = this.__getURL('SystemMemory') + `&filter=System eq '${system}'&orderBy=TimeCollected desc&topCount=${6 * minutes + 1}`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getStorage(system) {
        const url = this.__getURL('SystemStorageSummary') + `&filter=System eq '${system}'&orderBy=AvailPct`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getDatabaseStorageSummary(system) {
        const url = this.__getURL('CacheDatabaseStorageSummary') + `&filter=System eq '${system}'&orderBy=AvailPct`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getDatabaseStorageDetail(system, mounted) {
        const url = this.__getURL('CacheDatabaseStorageDetail') + `&filter=startswith(Directory, '${mounted}') and System eq '${system}'&orderBy=DiskFreeSpaceNum DESC`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getBackups(system) {
        const url = this.__getURL('CacheBackupSummary') + `&filter=System eq '${system}'&orderBy=CompletedDateTime DESC, OrdStatus DESC`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }
    async getEventLogs(system) {
        const url = this.__getURL('CacheEventLog') + `&filter=System eq '${system}'&orderBy=TimeLogged DESC&topCount=100`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getEventLogsByNamespace(system, namespace) {
        const url = this.__getURL('CacheEventLog') + `&filter=System eq '${system}' and Namespace eq '${namespace}'&orderBy=TimeLogged DESC&topCount=100`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getErrorLogs(system) {
        const url = this.__getURL('ErrorLogCountBySystemNamespaceDate') + `&filter=System eq '${system}'&orderBy=Date DESC`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getErrorLogsByNamespace(system, namespace) {
        const url = this.__getURL('ErrorLogCountBySystemNamespaceDate') + `&filter=System eq '${system}' and Namespace eq '${namespace}'&orderBy=Date DESC`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getMessageQueues(system) {
        // Switched from CacheQueueCountSummary to CacheQueueCountLatest per HM-35
        // Changed Order By from orderBy=LastMessageDelay DESC
        // to Name,QCount DESC,LastMessageDelay DESC
        const url = this.__getURL('CacheQueueCountLatest') + `&filter=System eq '${system}'&orderBy=Name,QCount DESC,LastMessageDelay DESC`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getMessageQueuesByNamespace(system, namespace) {
        // Switched from CacheQueueCountSummary to CacheQueueCountLatest per HM-35
        // Changed Order By from orderBy=LastMessageDelay DESC
        // to Name,QCount DESC,LastMessageDelay DESC
        const url = this.__getURL('CacheQueueCountLatest') + `&filter=System eq '${system}' and Namespace eq '${namespace}'&orderBy=Name,QCount DESC,LastMessageDelay DESC`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }



    async getDatabaseConnections(system) {
        const url = this.__getURL('CacheDatabaseConnectionByLatestDateOfInterest') + `&filter=System eq '${system}'&orderBy=TimeCollected DESC&topCount=100`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getDatabaseConnectionsByNamespace(system, namespace) {
        const url = this.__getURL('CacheDatabaseConnectionByLatestDate') + `&filter=System eq '${system}'&orderBy=TimeCollected DESC&topCount=100`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getMessageVolumeByNamespace(server, instance, namespace) {
        const url = this.__getURL('CacheMessageCountByNamespace') + `&filter=SystemName eq '${server}' and Instance eq '${instance}' and Namespace eq '${namespace}'`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getMessageVolumeByType(server, instance, namespace, type) {
        const url = this.__getURL('CacheMessageCountByType') + `&filter=SystemName eq '${server}' and Instance eq '${instance}' and Namespace eq '${namespace}' and HostType eq '${type}'`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getMessageVolumeNames(server, instance, namespace, type) {
        const url = this.__getURL('CacheMessageHostNames') + `&filter=SystemName eq '${server}' and Instance eq '${instance}' and Namespace eq '${namespace}' and HostType eq '${type}'`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    async getMessageVolumeByName(server, instance, namespace, type, name) {
        const url = this.__getURL('CacheMessageCountByDate') + `&filter=SystemName eq '${server}' and Instance eq '${instance}' and Namespace eq '${namespace}' and HostType eq '${type}' and HostName eq '${name}'`;
        /*
        const p = await fetch(url, this.options);
        const results = await p.json();
        return results;
        */
        return await this.getData(url);
    }

    __dateTemplate(timestamp) {
        const timeStr = timestamp.split('T');
        return timeStr[0] + ' ' + timeStr[1];
    }


    __getURL(resource) {
        //return `https://prod-70.eastus.logic.azure.com/workflows/ed0e94b2f6c349d1a9d2188692c959b9/triggers/manual/paths/invoke/${resource}/?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=tgGbGbhZbDXFe8_rN3vyqP0PMD8h5USbn0wKh20e5O0`;
        //"ENV_HIE_METRIC_SERVICE_URl": "https://prod-08.eastus2.logic.azure.com/workflows/c786445fe5054ef0978002466d98c8a7/triggers/manual/paths/invoke/${resource}/?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=TxV6l1LIAbLYROuEdW7whXTgNYnoZ7_CD7Tke8Wlfgo",
        return global.ENV_HIE_METRIC_SERVICE_URL.replace('${resource}', resource);
    }
};

export default HIEMetricService;