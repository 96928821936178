import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import { IACLService } from '../IACLService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import Spinner from "../../Spinner";
import AlertMessage from "../../alertmessage/AlertMessage"
import { MessageSeverity, FilterMatchMode, FilterOperator } from 'primereact/api';
import * as Yup from 'yup';

class SitenameComponent extends Component {
    constructor() {
        super();
        this.WrappedSitenameComponent = React.createRef();
        this._isMounted = false;
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            sitenameList: [],
            sitename: {
                Id: 0,
                SiteName: null,
                ShortName: null,
                IsActive: false
            },
            SiteNameAuditLogs: [],
            hideSpinner: false,
            showpagination: false,
            editSitenameDialogBoxVisible: false,
            createSitenameDialogBoxVisible: false,
            confirmDeleteSitenameDialogBoxVisible: false,
            sitenameAuditLogDialogBoxVisible: false,
            confirmDeleteVisible: false,
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            globalFilterValue: '',
        }
        this.IACLService = new IACLService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getAllSiteName();
    }

    getAllSiteName = () => {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' });
        this.IACLService.getAllSiteName(false, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({ sitenameList: d.body, hideSpinner: true, pageSuccessMessage: '', pageErrorMessage: '' });
                        if (this.state.sitenameList.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getSiteNameOpsAuditLog = (rowData) => {
        this.setState({ sitename: { ...rowData }, hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' });
        this.IACLService.getSiteNameOpsAuditLog(rowData.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({ SiteNameAuditLogs: d.body, hideSpinner: true, sitenameAuditLogDialogBoxVisible: true, pageSuccessMessage: '', pageErrorMessage: '' });
                        if (this.state.SiteNameAuditLogs.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    editSitename = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-book" className="p-button-rounded p-button-outlined" tooltip="Logs" tooltipOptions={{ position: 'top' }} onClick={(e) => this.getSiteNameOpsAuditLog(rowData)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" tooltip="Edit" tooltipOptions={{ position: 'top' }} onClick={() => this.showEditSitenameDialog(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" tooltip="Delete" tooltipOptions={{ position: 'top' }} onClick={e => this.setState({ confirmDeleteSitenameDialogBoxVisible: true, pageErrorMessage: null, pageSuccessMessage: null, sitename: { ...rowData } })} />
            </React.Fragment>
        );
    }

    showEditSitenameDialog = (rowData) => {
        this.setState({ sitename: { ...rowData }, editSitenameDialogBoxVisible: true, pageSuccessMessage: '', pageErrorMessage: '' })
    }

    showSitenameDialogBoxForCreate = () => {
        this.clearRowSelection();
        this.setState({ createSitenameDialogBoxVisible: true, pageSuccessMessage: '', pageErrorMessage: '' })
    }

    clearRowSelection() {
        this.setState({
            sitename: {
                Id: 0,
                SiteName: null,
                ShortName: null,
                IsActive: null
            },
        })
    }

    handleInputChange = (event) => {
        this.setState({
            sitename: {
                ...this.state.sitename, //using spread operator to initialize object with existing props
                [event.target.name]: event.target.value
            },
            pageErrorMessage: null
        });
    }

    validateEditSitenameDialogFormField = async (sitename, onSave = false, isCreate = false) => {

        if (onSave || isCreate) {
            try {
                let sitenameSchema = Yup.object({
                    SiteName: Yup.string().required("Site name is required.")
                        .max(250, "Site name cannot be more than 250 characters.")
                        .matches(/^[^'"]*$/, "Site name should not contain single (') or double (\") quotes."),
                    ShortName: Yup.string().nullable()
                        .max(10, "Short name cannot be more than 10 characters.")
                        .matches(/^[^'"]*$/, "Short name should not contain single (') or double (\") quotes.")
                });
                await sitenameSchema.validate(sitename, { abortEarly: false })

                this.setState({ pageErrorMessage: '' });
                this.IsSiteNameExists(isCreate);

            }
            catch (error) {
                this.setState({ pageErrorMessage: error.inner[0].message });
            }
        }
    }

    IsSiteNameExists(isCreate = false) {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' })
        this.IACLService.IsSiteNameExists(this.state.sitename.SiteName, this.state.sitename.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                } else {
                    if (d.status === 200 && d.body) {
                        this.setState({ pageErrorMessage: 'The Site name provided already exists. Please use a different one.', hideSpinner: true });
                    }
                    else {
                        if (isCreate)
                            this.createSitename();
                        else
                            this.updateSitename();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    createSitename() {
        let body = JSON.stringify(this.state.sitename);
        this.IACLService.createSiteName(body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                } else {
                    if (this._isMounted && d.status === 200) {
                        this.getAllSiteName();
                        this.setState({ pageSuccessMessage: 'Site name created successfully', createSitenameDialogBoxVisible: false, hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    updateSitename() {
        let body = JSON.stringify(this.state.sitename);
        this.IACLService.updateSiteName(this.state.sitename.Id, body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                } else {
                    if (this._isMounted && d.status === 200) {
                        this.getAllSiteName();
                        this.setState({ pageSuccessMessage: 'Changes saved successfully.', editSitenameDialogBoxVisible: false, hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    saveFooter = (
        <div>
            <Button label='Save' onClick={() => this.validateEditSitenameDialogFormField(this.state.sitename, true)} />
            <Button label='Cancel' onClick={e => this.setState({ editSitenameDialogBoxVisible: false, pageErrorMessage: null })} className='p-button-secondary' />
        </div>
    );

    createFooter = (
        <div>
            <Button label='Create' onClick={() => this.validateEditSitenameDialogFormField(this.state.sitename, true, true)} />
            <Button label='Cancel' onClick={e => this.setState({ createSitenameDialogBoxVisible: false, pageErrorMessage: null })} className='p-button-secondary' />
        </div>
    );

    deleteSitename() {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, hideSpinner: false })
        this.IACLService.deleteSiteName(this.state.sitename.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to perform this operation.', confirmDeleteSitenameDialogBoxVisible: false, hideSpinner: true });
                } else if (d.status === 200 && d.body) {
                    this.setState({ pageSuccessMessage: 'Site name deleted successfully.', confirmDeleteSitenameDialogBoxVisible: false, hideSpinner: true });
                    this.getAllSiteName();
                } else if (d.status === 500 && d.body) {
                    this.setState({ pageErrorMessage: d.body.error, confirmDeleteSitenameDialogBoxVisible: false, hideSpinner: true });
                } else {
                    this.setState({ pageErrorMessage: 'Something went wrong', confirmDeleteSitenameDialogBoxVisible: false, hideSpinner: true });
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    confirmDeleteFooter = (
        <div>
            <Button label='Ok' onClick={() => this.deleteSitename()} />
            <Button label='Cancel' onClick={e => this.setState({ confirmDeleteSitenameDialogBoxVisible: false })}
                className='p-button-secondary' />
        </div>
    );

    onGlobalFilterChange = (event, filtersKey) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter = (filtersKey) => {
        return (

            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                    <span>
                        <Button tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.getAllSiteName} icon="pi pi-refresh" />
                    </span>
                </div>
            </div>
        )
    }

    clearDataTableFilters = () => {
        this.setState({
            pageErrorMessage: null,
            pageSuccessMessage: null,
            pageErrorMessage: null,
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            globalFilterValue: '',

        })
        sessionStorage.clear();
    }

    booleanChecker = (rowData) => {
        if (rowData.IsActive) {
            return <span className='masterdata-status-badge status-active'>ACTIVE</span>;
        }
        else {
            return <span className='masterdata-status-badge status-inactive'>INACTIVE</span>;
        }
    };

    onStatusSwitchChange = (e) => {
        this.setState({ sitename: { ...this.state.sitename, IsActive: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    showAlertMessage = (severity, alertMsg) => {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="sitenamecontainer">
                    <BreadCrumbComponent {...this.props} />

                    <AlertMessage ref={instance => { this.alertMsg = instance }} />
                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <div className='flex justify-content-between' style={{ marginBottom: '10px' }}>
                            <div className="page-header-title">SITE NAMES</div>
                            <Button label='' icon='pi pi-plus' tooltip='Create Site Name' tooltipOptions={{ position: 'left' }} onClick={this.showSitenameDialogBoxForCreate} />
                        </div>

                        <DataTable ref={(el) => this.dt = el} value={this.state.sitenameList}
                            selectionMode="single"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            paginator={this.state.showpagination}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            emptyMessage="No Records found."
                            showGridlines={true}
                            onFilter={(e) => this.setState({ filters: e.filters })}
                            stateStorage="session" stateKey="dt-sitename-session"
                        >
                            <Column field="SiteName" header="Site Name" style={{ textAlign: 'left' }} />
                            <Column field="ShortName" header="Short Name" style={{ textAlign: 'left' }} />
                            <Column field="Status" header="Status" style={{ textAlign: 'left' }} body={this.booleanChecker} />
                            <Column field="edit" header="Action" body={this.editSitename} style={{ whiteSpace: 'nowrap', width: '150px', textAlign: 'center' }} alignHeader='center' />
                        </DataTable>
                    </div>

                    <Dialog header='Edit Site Name' footer={this.saveFooter}
                        visible={this.state.editSitenameDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ editSitenameDialogBoxVisible: false, pageErrorMessage: null })} maximizable={false}>
                        <div>
                            {this.state.pageErrorMessage ?
                                this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>
                            /* {this.state.pageErrorMessage ? <div className='text-danger text-center'>{this.state.pageErrorMessage}</div> : <></>} */}
                            <table style={{ width: '400px' }}>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Enable</b></label>
                                        <InputSwitch checked={this.state.sitename.IsActive} onChange={this.onStatusSwitchChange}
                                            tooltip={this.state.sitename.IsActive ? 'YES' : 'NO'} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Site Name</b></label>
                                        <InputText type='text' name='SiteName' value={this.state.sitename.SiteName || ''}
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label><b>Short Name</b></label>
                                        <InputText type='text' name='ShortName' value={this.state.sitename.ShortName || ''}
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Dialog>

                    <Dialog header='Create Site Name' footer={this.createFooter}
                        visible={this.state.createSitenameDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ createSitenameDialogBoxVisible: false, pageErrorMessage: null })} maximizable={false}>
                        <div>
                            {this.state.pageErrorMessage ?
                                this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>
                                /* {this.state.pageErrorMessage ? <div className='text-danger text-center'>{this.state.pageErrorMessage}</div> : <></>} */}
                            <table style={{ width: '400px' }}>
                                <tr>
                                    <td>
                                        <label><b>Enable</b></label>
                                        <InputSwitch checked={this.state.sitename.IsActive} onChange={this.onStatusSwitchChange}
                                            tooltip={this.state.sitename.IsActive ? 'YES' : 'NO'} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Site Name</b></label>
                                        <InputText type='text' name='SiteName' value={this.state.sitename.SiteName || ''} required
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label><b>Short Name</b></label>
                                        <InputText type='text' name='ShortName' value={this.state.sitename.ShortName || ''}
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Dialog>

                    <Dialog header='Delete Site Name' footer={this.confirmDeleteFooter}
                        visible={this.state.confirmDeleteSitenameDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ confirmDeleteSitenameDialogBoxVisible: false })} maximizable={false}>
                        <div>
                            Are you sure you want to delete the site name <b>{this.state.sitename.SiteName}</b>?
                        </div>
                    </Dialog>

                    <Dialog header={this.state.sitename.SiteName + ' - Audit Logs'} style={{ width: '40vw' }}
                        visible={this.state.sitenameAuditLogDialogBoxVisible} modal={true}
                        onHide={e => this.setState({ sitenameAuditLogDialogBoxVisible: false, pageErrorMessage: null })} maximizable={false} closeOnEscape={true}>
                        <div>
                            <DataTable value={this.state.SiteNameAuditLogs}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                filterDisplay="menu"
                                responsiveLayout="scroll"
                                showGridlines removableSort
                                emptyMessage="No Records found."
                                sortField='Timestamp' sortOrder="-1"
                            >
                                <Column field="DisplayTimestamp" header="Timestamp" style={{ textAlign: 'left' }} sortable sortField='Timestamp' />
                                <Column field="ActionType" header="Action" style={{ textAlign: 'left' }} />
                                <Column field="ActionBy" header="Action By" style={{ textAlign: 'left' }} />
                            </DataTable>
                        </div>
                    </Dialog>

                </div>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedSitenameComponent = withMsal(SitenameComponent);
export default withAITracking(reactPlugin, WrappedSitenameComponent);