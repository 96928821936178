import { msalInstance } from "../App";
import Cookies from "universal-cookie";

class autoLogout {
    constructor() {
      this.events = ['mousemove', 'mousedown',
                     'click', 'scroll', 'keypress'];
  
      this.checkCookie = this.checkCookie.bind(this);
      this.logout = this.logout.bind(this);
      this.resetTimeout = this.resetTimeout.bind(this);
  
      this.events.forEach((event) => {
        window.addEventListener(event, this.resetTimeout);
      });
     
      let cookies =  new Cookies();
      let activityCookie = cookies.get('last-user-activity', { path: '/' });

      // Set initial value for  activity Cookie
      if (activityCookie == undefined ) {
          // Set initial activity cookie value
          let current_time = Date.now();
          cookies.set('last-user-activity', current_time, {path: '/'});
          // console.log('Set Initial value for User Activity: ' + cookies.get('last-user-activity', { path: '/' }));
      }

      this.setTimeout();
    }

    checkCookie() {
      // Get last user activity from cookie
      // console.log('checking cookie')
      let cookies = new Cookies();
      let currentCookie = cookies.get('last-user-activity', { path: '/' });

      // check difference in time
      let now = Date.now();
      let diffMS = now - currentCookie;
      let diffMins = Math.round(((diffMS % 86400000) % 3600000) / 60000);
      // console.log('diffMins:' + diffMins);

      if (diffMins >= global.ENV_MSAL_AUTO_LOGOUT_TIME) {
        // If configured time or longer, then logout the user
        this.logout();
      } else {
        // Otherwise reset the timeout so it gets checked again.
        this.clearTimeout();
        this.setTimeout();
      }
    }
  
    clearTimeout() {
      if(this.logoutTimeout)
        clearTimeout(this.logoutTimeout);
    }
  
    setTimeout() {
     // Check cookie for auto logout every minute
      this.logoutTimeout = setTimeout(this.checkCookie, 1 * 60 * 1000);
     }
  
    resetTimeout() {
      // User has triggered event to
      // console.log('Reset Timeout');
      let cookies = new Cookies();
      let current_time = Date.now();
      cookies.set('last-user-activity', current_time, {path: '/'});
      // console.log('Reset value for User Activity: ' + cookies.get('last-user-activity', { path: '/' }));
      this.clearTimeout();
      this.setTimeout();
    }
  
    logout() {
      // console.log('Sending a logout request to the API...');
      msalInstance.logoutRedirect();
      this.destroy();
    }
  
    destroy() {
      this.clearTimeout();
  
      this.events.forEach((event) => {
        window.removeEventListener(event, this.resetTimeout);
      });
    }
  }

  export default autoLogout;