import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { MessageSeverity } from 'primereact/api';
import './AlertMessage.css';

export class AlertMessageComponent extends Component {

    constructor(props) {
        super(props);
    }

    showSuccess(msg) {
        //LIFE IS IN MILLISECONDS
        this.toast.show({ severity: MessageSeverity.SUCCESS, summary: 'Success Message', detail: msg, life: global.ALERT_MSG_LIFE });
    }

    showInfo(msg) {
        this.toast.show({ severity: MessageSeverity.INFO, summary: 'Info Message', detail: msg, life: global.ALERT_MSG_LIFE });
    }

    showWarn(msg) {
        this.toast.show({ severity: MessageSeverity.WARN, summary: 'Warn Message', detail: msg, life: global.ALERT_MSG_LIFE });
    }

    showError(msg) {
        this.toast.show({ severity: MessageSeverity.ERROR, summary: 'Error Message', detail: msg, life: global.ALERT_MSG_LIFE });
    }

    showAlert(severity, msg) {
        switch (severity) {
            case MessageSeverity.SUCCESS:
                this.showSuccess(msg);
                break;
            case MessageSeverity.INFO:
                this.showInfo(msg);
                break;
            case MessageSeverity.WARN:
                this.showWarn(msg);
                break;
            case MessageSeverity.ERROR:
                this.showError(msg);
                break;
        }
    }
    render() {
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
            </div>
        )
    }
}
export default AlertMessageComponent;