import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Panel } from 'primereact/panel';
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import ServerStatsComponent from './server-stats/ServerStatsComponent';
import StorageComponent from './storage/StorageComponent';
import BackupComponent from './backup/BackupComponent';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import "./ServerComponent.css";

class ServerComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this._isForbidden = false;
        this.state = {
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    render() {

        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="serverContainer">
                    <BreadCrumbComponent {...this.props} />
                    <div className="component">
                        <Panel header={'Server: ' + this.props.match.params.server}>
                            <ServerStatsComponent  {...this.props} />
                            {/* <StorageComponent {...this.props} /> */}
                            {/* <BackupComponent {...this.props} /> */}
                        </Panel>
                    </div>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}
const WrappedServerComponent = withMsal(ServerComponent);
export default withAITracking(reactPlugin, WrappedServerComponent);