import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { ProgressSpinner } from 'primereact/progressspinner';
import NoDataFoundImg from '../../../images/NoDataFound.png';
import HIEMetricService from '../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';

import "./NamespacesComponent.css";

class NamespacesComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            namespaces: [],
            loading: true,
        };
        this.hieMetricService = new HIEMetricService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.hieMetricService.getNamespaces(this.props.match.params.server, this.props.match.params.instance).then(namespaces => {
            if (this._isMounted) this.setState({ namespaces, loading: false });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let body;
        if (this.state.loading) {
            body = <ProgressSpinner className="spinner" />;
        } else if (this.state.namespaces.length === 0) {
            body = <div className="noDataFoundContainer"><img src={NoDataFoundImg} alt="No Data Found" /></div>;
        } else {
            body =
                <div className="namespaceList">
                    {
                        this.state.namespaces.map((namespace, index) => {
                            return (
                                <NavLink key={index} to={this.props.match.params.instance + '/' + namespace.Namespace}>
                                    <div className="namespaceEntry ">
                                        <FontAwesomeIcon className="namespaceIcon" size="3x" icon={faDatabase} />
                                        <div className="namespaceName">{namespace.Namespace}</div>
                                    </div>
                                </NavLink>
                            );
                        })
                    }
                </div>;
        }

        return (
            <div className="namespacesContainer">
                <Accordion activeIndex={0}>
                    <AccordionTab header="Namespaces" headerStyle={{ textAlign: 'left' }}>
                        {body}
                    </AccordionTab>
                </Accordion>
            </div>
        );
    }
}

//export default NamespacesComponent;
export default withAITracking(reactPlugin, NamespacesComponent);