import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import HIEMetricService from '../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import './SystemAlertsComponent.css';


class SystemAlertsComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            alerts: [],
            loading: true,
        };
        this.hieMetricService = new HIEMetricService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.hieMetricService.getSystemsAlerts().then(alerts => {
            if (this._isMounted) this.setState({ alerts, loading: false });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div className="systemAlertsContainer">
                <Accordion>
                    <AccordionTab header="Alerts" headerStyle={{ textAlign: 'left' }} headerClassName={this.state.alerts.length > 0 ? 'pulseAlert' : ''}>
                        <DataTable value={this.state.alerts} selectionMode="single" autoLayout={true} loading={this.state.loading} paginator={true} rows={5} rowsPerPageOptions={[5, 10, 20, 50, 100]} showGridlines={true}>
                            <Column field="server" header="Server" />
                            <Column field="namespace" header="Namespace" />
                            <Column field="instance" header="Instance" />
                            <Column field="alert" header="Priority" />
                            <Column field="type" header="Type" />
                            <Column field="description" header="Description" style={{ textAlign: 'left' }} />
                            <Column field="timestamp" header="Timestamp" />
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </div>
        );
    }
}

//export default SystemAlertsComponent;
export default withAITracking(reactPlugin, SystemAlertsComponent);
