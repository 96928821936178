import React, { Component } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chart } from 'primereact/chart';
import NoDataFoundImg from '../../../images/NoDataFound.png';
import HIEMetricService from '../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';

import "./MessageVolumesComponent.css";

class MessageVolumesComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            messageVolumes: [],
            loading: true,
        };
        this.hieMetricService = new HIEMetricService();
        this.msgVolTypes = ['', 'Services', 'Interfaces', 'Operations', 'Processes'];
    }

    componentDidMount() {
        this._isMounted = true;
        let wsCall;
        if (this.props.filter === 'Services') {
            wsCall = this.hieMetricService.getMessageVolumeByType(this.props.match.params.server, this.props.match.params.instance, this.props.match.params.namespace, 1);
        } else if (this.props.filter === 'Interfaces') {
            wsCall = this.hieMetricService.getMessageVolumeByType(this.props.match.params.server, this.props.match.params.instance, this.props.match.params.namespace, 2);
        } else if (this.props.filter === 'Operations') {
            wsCall = this.hieMetricService.getMessageVolumeByType(this.props.match.params.server, this.props.match.params.instance, this.props.match.params.namespace, 3);
        } else if (this.props.filter === 'Processes') {
            wsCall = this.hieMetricService.getMessageVolumeByType(this.props.match.params.server, this.props.match.params.instance, this.props.match.params.namespace, 4);
        } else if (this.props.filter === 'Message Volumes') {
            wsCall = this.hieMetricService.getMessageVolumeByNamespace(this.props.match.params.server, this.props.match.params.instance, this.props.match.params.namespace);
        } else {
            wsCall = this.hieMetricService.getMessageVolumeByName(this.props.match.params.server, this.props.match.params.instance, this.props.match.params.namespace, this.msgVolTypes.indexOf(this.props.match.params.type), this.props.filter);
        }

        wsCall.then(messageVolumes => {
            if ((messageVolumes.length > 0) && (new Date(messageVolumes[0].TimeCollected) > new Date(messageVolumes[messageVolumes.length - 1].TimeCollected))) {
                if (this._isMounted) this.setState({ messageVolumes: messageVolumes.reverse(), loading: false });
            } else {
                if (this._isMounted) this.setState({ messageVolumes, loading: false });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        Date.longDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let body = null;
        if (this.state.loading) {
            body = <ProgressSpinner className="spinner" />;
        } else if (this.state.messageVolumes.length === 0) {
            body = <div className="noDataFoundContainer"><img src={NoDataFoundImg} alt="No Data Found" /></div>;
        } else {
            const dotw = [], weekOne = [], weekTwo = [];
            let j = 0;
            for (let i = 0; i < 7; i++) {
                let day = moment().utc().subtract('days', 14 - i).format('YYYY-MM-DD');
                dotw.push(Date.longDays[new Date(day).getUTCDay()]);
                if (j < this.state.messageVolumes.length) {
                    if (day !== this.state.messageVolumes[j].TimeCollected) {
                        weekOne.push(0);
                    } else {
                        weekOne.push(this.state.messageVolumes[j].AvgCount);
                        j++
                    }
                }
            }

            if (j < this.state.messageVolumes.length) {
                for (let i = 7; i < 14; i++) {
                    let day = moment().utc().subtract('days', 14 - i).format('YYYY-MM-DD');
                    if (j < this.state.messageVolumes.length) {
                        if (day !== this.state.messageVolumes[j].TimeCollected) {
                            weekTwo.push(0);
                        } else {
                            weekTwo.push(this.state.messageVolumes[j].AvgCount);
                            j++
                        }
                    }
                }
            }

            const data = {
                labels: dotw,
                datasets: [
                    {
                        label: 'Week 1',
                        backgroundColor: '#42A5F5',
                        data: weekOne
                    },
                    {
                        label: 'Week 2',
                        backgroundColor: '#9CCC65',
                        data: weekTwo
                    }
                ]
            };

            const options = {
                title: {
                    display: true,
                    fontSize: 16,
                    text: `Two-week Comparison: ${moment().utc().subtract('days', 14).format('YYYY-MM-DD')} through ${moment().utc().subtract('days', 1).format('YYYY-MM-DD')}`,
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Messages/hr'
                        }
                    }]
                },
                legend: {
                    display: true,
                    position: 'bottom',
                },
            };

            if (this.props.filter === 'Message Volumes') {
                body = <NavLink to={`${this.props.match.params.namespace}/mvs`}><Chart type="bar" data={data} options={options} /></NavLink>
            } else if (this.msgVolTypes.includes(this.props.filter)) {
                body = <NavLink to={`mvs/${this.props.filter}`}><Chart type="bar" data={data} options={options} /></NavLink>
            } else {
                body = <Chart type="bar" data={data} options={options} />
            }
        }

        let activeIndex = this.props.closed === '1' ? 1 : 0;

        return (
            <div className="messageVolumesContainer component">
                <Accordion activeIndex={activeIndex}>
                    <AccordionTab header={this.props.filter} headerStyle={{ textAlign: 'left' }}>
                        <div className="messageVolumesGraph">
                            {body}
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        );
    }
}

//export default MessageVolumesComponent;
export default withAITracking(reactPlugin, MessageVolumesComponent);