import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import HIEMetricService from '../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import './StorageComponent.css';

class StorageComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            disks: [],
            loading: true,
        };
        this.hieMetricService = new HIEMetricService();
        this.getTableData = this.getTableData.bind(this);
        this.onSortFunction = this.onSortFunction.bind(this);
    }

    componentDidMount() {
        // console.log('componentDidMount(): ');
        this._isMounted = true;
        this.getTableData();
    }

    componentWillUnmount() {
        // console.log('componentWillUnmount(): ');
        this._isMounted = false;
    }


    getTableData() {
        this.hieMetricService.getDatabaseStorageSummary(this.props.match.params.server + ':' + this.props.match.params.server.slice(0, -1).toUpperCase()).then(disks => {
            //console.log(disks);
            //console.log(this.props.match.params.server + ':' + this.props.match.params.server.slice(0, -1).toUpperCase());

            let i = 0;
            if (disks && disks.length > 0) {
                while (disks[i].AvailPct === 0) {
                    disks.push(disks[i++]);
                    disks.shift();
                }
                if (this._isMounted) this.setState({ disks, loading: false });
            }
        });
    }

    rowStyle(rowData) {
        //console.log(rowData);
        //rowData.Alert = 2;

        if (rowData.Alert == 1) {
            return { warning: true };
        }
        if (rowData.Alert == 2) {
            return { alert: true };
        }

        return { alert: false };

        /*
        if (rowData.AvailPct > 0) {
            //console.log(rowData);
            return {alert: (rowData.AvailPct<35)?true:false};
        } else {
            return {alert: false};
        }
        */
    }

    formatMount(rowData, column) {
        return rowData.MountedOn.substring(rowData.MountedOn.lastIndexOf('/') + 1);
    }

    formatTruncate(rowData, column) {
        const bytes = rowData.TruncAvail;
        if (bytes === 0) {
            return '';
        }
        const decimals = 2;
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return <a href={`${rowData.SystemName}/db/${rowData.MountedOn.substr(1).split('/').join('-')}`}>{parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i]}</a>;
    }

    calcColumnPercentage(rowData, column) {
        return rowData.AvailPct + '%';
    }

    onSortFunction(event) {
        try {
            let data = [...this.state.disks];
            data.sort((data1, data2) => {
                const value1 = this.formatMount(data1); //data1[event.field];
                const value2 = this.formatMount(data2); //data2[event.field];
                let result = null;

                if (value1 == null && value2 != null)
                    result = -1;
                else if (value1 != null && value2 == null)
                    result = 1;
                else if (value1 == null && value2 == null)
                    result = 0;
                else if (typeof value1 === 'string' && typeof value2 === 'string')
                    result = value1.localeCompare(value2, undefined, { numeric: true });
                else
                    result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

                console.log(result);

                return (event.order * result);
            });

            return data;
        } catch (e) {
            console.log(e.message);
        }
    }

    render() {
        return (
            <div className="strorageContainer component">
                <Accordion activeIndex={0}>
                    <AccordionTab header="Storage" headerStyle={{ textAlign: 'left' }}>
                        <DataTable value={this.state.disks} selectionMode="single" autoLayout={true} loading={this.state.loading} paginator={true} rows={10} rowsPerPageOptions={[10, 20, 50, 100]} rowClassName={this.rowStyle} showGridlines={true}>
                            <Column field="System" header="System" style={{ textAlign: 'left' }} sortable />
                            <Column field="Filesystem" header="Filesystem" style={{ textAlign: 'left' }} sortable />
                            <Column field="MountedOn" header="Mounted On" style={{ textAlign: 'left' }} sortable />
                            <Column field="MountedOn" header="Mount" body={this.formatMount} style={{ textAlign: 'left' }} sortable="custom" sortFunction={this.onSortFunction} />
                            <Column field="Allocated" header="Allocated (KB)" sortable />
                            <Column field="Used" header="Used (KB)" sortable />
                            <Column field="Available" header="Available (KB)" sortable />
                            <Column field="TruncAvail" header="Truncate" body={this.formatTruncate} sortable />
                            <Column field="AvailPct" header="Available (%)" body={this.calcColumnPercentage} sortable />
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </div>
        );
    }
}

//export default StorageComponent;
export default withAITracking(reactPlugin, StorageComponent);