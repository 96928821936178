import React, { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Panel } from 'primereact/panel';
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import NamespacesComponent from './namespaces/NamespacesComponent';
import EventLogComponent from './event-log/EventLogComponent';
import MessageQueueComponent from './message-queue/MessageQueueComponent';
import ErrorLogComponent from './error-log/ErrorLogComponent';
import "./InstanceComponent.css";

class InstanceComponent extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this._isForbidden = false;
        this.state = {
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    render() {

        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>

                <div className="instanceContainer">
                    <BreadCrumbComponent {...this.props} />
                    <div className="component">
                        <Panel header={'Instance: ' + this.props.match.params.instance}>
                            <NamespacesComponent {...this.props} />
                            {/* <ErrorLogComponent {...this.props} />
                                <EventLogComponent {...this.props} /> */}
                            <MessageQueueComponent {...this.props} />
                        </Panel>
                    </div>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}

//export default InstanceComponent;
const WrappedInstanceComponent = withMsal(InstanceComponent);
export default withAITracking(reactPlugin, WrappedInstanceComponent);