import React, { Component } from "react";
import { msalConfig, loginRequest } from "./authConfig";
import { Cookies } from 'react-cookie';
import { PageLayout } from "./components/page-layout/page-layout-component";
import { MsalAuthenticationTemplate, MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import WrappedHomeComponent from './components/home/HomeComponent';
import WrappedAdminComponent from './components/admin/AdminComponent';
import WrappedAddThresholdComponent from './components/admin/AddThresholdComponent';
import WrappedEditThresholdComponent from './components/admin/EditThresholdComponent';
import WrappedActionGroupComponent from './components/actionGroup/ActionGroupComponent';
import WrappedCustomHieAlertActionComponent from './components/customHieAlert/CustomHieAlertActionComponent';
import WrappedCreateCustomHieAlertComponent from './components/customHieAlert/CreateCustomHieAlertComponent';
import WrappedEditCustomHieAlertComponent from './components/customHieAlert/EditCustomHieAlertComponent';
import WrappedCustomAzAlertActionComponent from './components/customAzureAlert/CustomAzAlertActionComponent';
import WrappedCreateCustomAzAlertComponent from './components/customAzureAlert/CreateCustomAzAlertComponent';
import WrappedEditCustomAzAlertComponent from './components/customAzureAlert/EditCustomAzAlertComponent';
import WrappedIssuesComponent from './components/Issues/IssuesComponent';
import WrappedIssuesDetailsComponent from './components/Issues/IssuesDetailsComponent';
import WrappedMirrorsMonitorComponent from './components/monitor/mirrors/MirrorsMonitor';
import WrappedInboundActivityComponent from './components/inbound-activity/InboundActivityComponent';
import WrappedInboundDetailsComponent from './components/inbound-activity/InboundDetailsComponent';
import WrappedServerBlackoutComponent from './components/blackout/ServerBlackout';
import WrappedGalileoComponent from './components/home/galileo/GalileoComponent';
import WrappedServerComponent from './components/server/ServerComponent';
import WrappedInstancesComponent from './components/instance/InstanceComponent';
// import CPUComponent from './components/server/server-stats/cpu/CPUComponent';
// import MemoryComponent from './components/server/server-stats/memory/MemoryComponent';
// import SwapComponent from './components/server/server-stats/swap/SwapComponent';
// import DatabaseComponent from './components/server/database/DatabaseComponent';
import WrappedNamespaceComponent from './components/namespace/NamespaceComponent';
import MessageVolumesDetailComponent from './components/namespace/message-volumes-detail/MessageVolumesDetailComponent';
import MessageVolumeTypesDetailComponent from './components/namespace/message-volume-types-detail/MessageVolumeTypesDetailComponent';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from './AppInsights';
import WrappedHIEHealthOverviewComponent from "./components/home/HIEHealthOverview/HIEHealthOverviewComponent";
import HIEHealthOverviewComponent from "./components/home/HIEHealthOverview/HIEHealthOverviewComponent";
import WrappedQueueMonitorComponentComponent from "./components/monitor/queues/QueueMonitor";
import WrappedDatabaseSizeComponent from "./components/monitor/databasesize/DatabaseSize";
import WrappedTaskScheduleMonitorComponent from "./components/monitor/taskschedule/TaskScheduleMonitor";
import WrappedNotificationBlackoutComponent from "./components/blackout/NotificationBlackout";
import WrappedNotificationBlackoutRuleComponent from "./components/blackout/NotificationBlackoutRule";
import WrappedCreateNotificationBlackoutComponent from "./components/blackout/CreateNotificationBlackout";
import WrappedEditNotificationBlackoutComponent from "./components/blackout/EditNotificationBlackout";
import WrappedProdItemSettingComponent from "./components/prodItemSettings/ProdItemSetting";
import WrappedIpPortListComponent from "./components/prodItemSettings/IpPortList";
import WrappedCreateAlertProcessingRule from "./components/alertProcessing/CreateAlertProcessingRule";
import WrappedAlertProcessingRulesComponent from "./components/alertProcessing/AlertProcessingRules";
import WrappedEditAlertProcessingRuleComponent from "./components/alertProcessing/EditAlertProcessingRule";
import WrappedIACLComponent from "./components/IACL/IACLComponent";
import WrappedCreateIACLComponent from "./components/IACL/CreateIACLComponent";
import WrappedEditIACLComponent from "./components/IACL/EditIACLComponent";
import WrappedIACLComponentDetailViewOnly from "./components/IACL/IACLComponentDetailViewOnly";
import WrappedSitenameComponent from "./components/IACL/Sitename/SitenameComponent"
import WrappedConnectionTypeComponent from "./components/IACL/ConnectionType/ConnectionTypeComponent"
import WrappedMessageContentComponent from "./components/IACL/MessageContent/MessageContentComponent"
import WrappedMessageTypeComponent from "./components/IACL/MessageType/MessageTypeComponent"
import WrappedCAGroupNameComponent from "./components/IACL/CAGroupName/CAGroupNameComponent"
import WrappedApplicationComponent from "./components/IACL/Application/ApplicationComponent"
import WrappedLeadershipDashboard from "./components/home/HIEHealthOverview/LeadershipDashboard"
import WrappedRestoreConnection from "./components/recovery/RestoreConnection"
import WrappedRestoreConnectionLogs from "./components/recovery/RestoreConnectionLogs"

import './App.css';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

export const msalInstance = new PublicClientApplication(msalConfig);

class App extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this._isForbidden = false;
        this.state = {
            cookies: new Cookies(),
        };
    }

    componentDidMount() {
        this._isMounted = true;

        //JIRA - HM-425 Bug Fix with Redirect login flow
        let cookieExits = this.state.cookies.getAll('msal.interaction.status', { path: '/' });
        if (cookieExits !== undefined && cookieExits !== null) {
            this.state.cookies.remove('msal.interaction.status', { path: '/' });
            //console.log(cookies.getAll());
        }
    }

    render() {
        let body;
        //this._isForbidden = validateClientIPAddress(this.state.clientIpAddress);

        // if (this._isForbidden) {
        //     body = <ForbiddenComponent></ForbiddenComponent>
        // }
        // else {
        body = <PageLayout>
            <div className="app">
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={HIEHealthOverviewComponent} />
                        <Route exact path="/monitor/servers" component={WrappedHomeComponent} />
                        <Route exact path="/admin/threshold/rules" component={WrappedAdminComponent} />
                        <Route exact path="/admin/threshold/rule/add" component={WrappedAddThresholdComponent} />
                        <Route exact path="/admin/threshold/rule/edit/:id" component={WrappedEditThresholdComponent} />
                        <Route exact path="/admin/alert/actiongroup" component={WrappedActionGroupComponent} />
                        <Route exact path="/admin/custom/hie/alert/rules" component={WrappedCustomHieAlertActionComponent} />
                        <Route exact path="/admin/custom/hie/alert/rule/create" component={WrappedCreateCustomHieAlertComponent} />
                        <Route exact path="/admin/custom/hie/alert/rule/edit/:id" component={WrappedEditCustomHieAlertComponent} />
                        <Route exact path="/admin/custom/azure/alert/rules" component={WrappedCustomAzAlertActionComponent} />
                        <Route exact path="/admin/custom/azure/alert/rule/create" component={WrappedCreateCustomAzAlertComponent} />
                        <Route exact path="/admin/custom/azure/alert/rule/edit/:id" component={WrappedEditCustomAzAlertComponent} />
                        <Route exact path="/admin/alert/processing/rule/create" component={WrappedCreateAlertProcessingRule} />
                        <Route exact path="/admin/alert/processing/rules" component={WrappedAlertProcessingRulesComponent} />
                        <Route exact path="/admin/alert/processing/rule/:id" component={WrappedEditAlertProcessingRuleComponent} />
                        <Route exact path="/admin/alert/processing/rule/:id/:iscreate" component={WrappedEditAlertProcessingRuleComponent} />
                        <Route exact path="/issues" component={WrappedIssuesComponent} />
                        <Route exact path="/issue/:id" component={WrappedIssuesDetailsComponent} />
                        <Route exact path="/monitor/mirrors" component={WrappedMirrorsMonitorComponent} />
                        <Route exact path="/monitor/production/items" component={WrappedProdItemSettingComponent} />
                        <Route exact path="/monitor/queues" component={WrappedQueueMonitorComponentComponent} />
                        <Route exact path="/monitor/databasesize" component={WrappedDatabaseSizeComponent} />
                        <Route exact path="/monitor/inboundmetric" component={WrappedInboundActivityComponent} />
                        <Route exact path="/monitor/taskschedule" component={WrappedTaskScheduleMonitorComponent} />
                        <Route exact path="/component/:id" component={WrappedInboundDetailsComponent} />
                        <Route exact path="/blackout" component={WrappedServerBlackoutComponent} />
                        <Route exact path="/galileo" component={WrappedGalileoComponent} />
                        <Route exact path="/incident/dashboard" component={WrappedHIEHealthOverviewComponent} />
                        <Route exact path="/leader/dashboard" component={WrappedLeadershipDashboard} />
                        <Route exact path="/monitor/:server" component={WrappedServerComponent} />
                        <Route exact path="/notification/blackout/rules" component={WrappedNotificationBlackoutComponent} />
                        <Route exact path="/notification/blackout/rule/detail/:id" component={WrappedNotificationBlackoutRuleComponent} />
                        <Route exact path="/notification/blackout/rule/detail/:id/:iscreate" component={WrappedNotificationBlackoutRuleComponent} />
                        <Route exact path="/notification/blackout/rule/create" component={WrappedCreateNotificationBlackoutComponent} />
                        <Route exact path="/notification/blackout/rule/edit/:id" component={WrappedEditNotificationBlackoutComponent} />
                        <Route exact path="/production/ipport" component={WrappedIpPortListComponent} />
                        <Route exact path="/iacl" component={WrappedIACLComponent} />
                        <Route exact path="/iacl/component/detail/:id" component={WrappedIACLComponentDetailViewOnly} />
                        <Route exact path="/iacl/component/create" component={WrappedCreateIACLComponent} />
                        <Route exact path="/iacl/component/edit/:id" component={WrappedEditIACLComponent} />
                        <Route exact path="/sitenames" component={WrappedSitenameComponent} />
                        <Route exact path="/connectiontypes" component={WrappedConnectionTypeComponent} />
                        <Route exact path="/messagecontent" component={WrappedMessageContentComponent} />
                        <Route exact path="/messagetype" component={WrappedMessageTypeComponent} />
                        <Route exact path="/cagroupname" component={WrappedCAGroupNameComponent} />
                        <Route exact path="/application" component={WrappedApplicationComponent} />
                        <Route exact path="/restore/connection" component={WrappedRestoreConnection} />
                        <Route exact path="/restore/connection/logs" component={WrappedRestoreConnectionLogs} />
                        {/* <Route exact path="/:server/cpu" component={CPUComponent} />
                            <Route exact path="/:server/memory" component={MemoryComponent} />
                            <Route exact path="/:server/swap" component={SwapComponent} />
                            <Route exact path="/:server/db/:dbname" component={DatabaseComponent} /> */}
                        <Route exact path="/monitor/:server/:instance" component={WrappedInstancesComponent} />
                        <Route exact path="/monitor/:server/:instance/:namespace" component={WrappedNamespaceComponent} />
                        <Route exact path="/monitor/:server/:instance/:namespace/mvs" component={MessageVolumesDetailComponent} />
                        <Route exact path="/monitor/:server/:instance/:namespace/mvs/:type" component={MessageVolumeTypesDetailComponent} />
                    </Switch>
                </BrowserRouter>
            </div>
        </PageLayout>
        //}
        return (
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
                    {body}
                </MsalAuthenticationTemplate>
            </MsalProvider>
        );
    }
}
export default withAITracking(reactPlugin, App);

