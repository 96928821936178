import { msalInstance } from '../../App';

export class IssuesDetailsService {

    async invokeWebAPI(url, httpMethod, msalContext, body = "") {

        // check if msalContext exists
        if (msalContext.accounts.length > 0) {
            const tokenRequest = {
                scopes: [global.ENV_MSAL_LOGIN_REQUEST_SCOPES],
                account: msalContext.accounts[0],
            };

            // acquire Bearer token to include in GET request
            const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
            const token = tokenResponse.accessToken;
            const bearer = `Bearer ${token}`;
            const headers = new Headers();
            headers.append('Accept', '*/*');
            headers.append('Content-Type', 'application/json');
            headers.append('x-northwell-version', '1.0');
            headers.append("Authorization", bearer);
            headers.append('User', msalContext.accounts[0].username)

            var options = {}
            if (body) {
                options = {
                    method: httpMethod,
                    headers: headers,
                    body: body
                };
            } else {
                options = {
                    method: httpMethod,
                    headers: headers
                };
            }

            let response = await fetch(url, options);

            return {
                status: response.status,
                body: await response.json()
            };

        } else {
            console.log('user not logged in')
        }
    }

    async getIssueDetailsTableData(fromDate, toDate, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/Notification/ByDateRange?FromDate=" + fromDate + "&ToDate=" + toDate + "&alertTypes=2";
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }


    async getIssueDetailsById(id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/Notification/" + id;
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async updateNotificationStatus(id, body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/Notification/" + id + "/Status";
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    //HM-837 - Allow multi select of alerts for closure
    async updateMultipleNotificationStatus(body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/Notification/Multiple/Status";
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getStatusHistory(id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/Notification/" + id + "/Status/History";
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getIssueCount(msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/Notification/Dashboard/Count";
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }
}