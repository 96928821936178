import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import HIEMetricService from '../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import './BackupComponent.css';

class BackupComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            backups: [],
            loading: true,
        };
        this.hieMetricService = new HIEMetricService();
        this.getTableData = this.getTableData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getTableData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTableData() {
        this.hieMetricService.getBackups(this.props.match.params.server + ':' + this.props.match.params.server.slice(0, -1).toUpperCase()).then(backups => {
            if (this._isMounted) this.setState({ backups, loading: false });
        });
    }

    rowStyle(rowData) {
        if (rowData.OrdStatus === 2) {
            return { alert: true };
        } else if (rowData.OrdStatus === 1) {
            return { warning: true };
        } else {
            return null;
        }
    }

    render() {
        return (
            <div className="backupContainer component">
                <Accordion activeIndex={0}>
                    <AccordionTab header="Backups" headerStyle={{ textAlign: 'left' }}>
                        <DataTable value={this.state.backups} selectionMode="single" autoLayout={true} loading={this.state.loading} paginator={true} rows={10} rowsPerPageOptions={[10, 20, 50, 100]} rowClassName={this.rowStyle} showGridlines={true}>
                            <Column field="Instance" header="Instance" />
                            <Column field="Type" header="Type" />
                            <Column field="StartDateTime" header="Started" />
                            <Column field="CompletedDateTime" header="Completed" />
                            <Column field="File" header="File" style={{ textAlign: 'left' }} />
                            <Column field="Status" header="Status" />
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </div>
        );
    }
}

//export default BackupComponent;
export default withAITracking(reactPlugin, BackupComponent);