import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import ServersComponent from './servers/ServersComponent';
import SystemAlertsComponent from './system-alerts/SystemAlertsComponent';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import './Home.css';

class HomeComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this._isForbidden = false;
        this.state = {
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    render() {
        //console.log(this.state.clientIpAddress)
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="homeContainer">
                    <BreadCrumbComponent {...this.props} />
                    {/* Commented out not useful anymore */}
                    {/* <div className="component"><SystemAlertsComponent /></div> */}
                    <div className="component text-center"><ServersComponent /></div>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
};

// HomeComponent.propTypes = {

// };

//export default HomeComponent;
const WrappedHomeComponent = withMsal(HomeComponent);
export default withAITracking(reactPlugin, WrappedHomeComponent);
