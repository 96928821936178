import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Accordion,AccordionTab} from 'primereact/accordion';
import HIEMetricService from '../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import './ErrorLogComponent.css';

class ErrorLogComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            errorLogs: [],
            loading: true,
        };
        this.hieMetricService = new HIEMetricService();
        this.getTableData=this.getTableData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getTableData();
    }

    getTableData() {
        this.hieMetricService.getErrorLogs(this.props.match.params.server + ':' + this.props.match.params.instance).then(errorLogs => {
            //console.log(errorLogs);
            if (this._isMounted) this.setState({ errorLogs, loading: false });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    formatTimestring(rowData, column) {
        //return rowData.Date.split('T')[0];

        /*
        let date = new Date(rowData.Date);        
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return month + '/' + day + '/' + year;        
        */

        return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(rowData.Date));
        //return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(rowData.Date));
    }

    render() {
        return (
            <div className="ErrorLogContainer component">
                <Accordion activeIndex={0}>
                    <AccordionTab header="Error Trap" headerStyle={{textAlign: 'left'}}>
                        <DataTable value={this.state.errorLogs} selectionMode="single" autoLayout={true} loading={this.state.loading} paginator={true} rows={10} rowsPerPageOptions={[10,20,50,100]} showGridlines={true}>
                            <Column field="System" header="System" style={{textAlign:'left'}} />
                            <Column field="Namespace" header="Namespace" style={{textAlign:'left'}} />
                            <Column field="Errors" header="Errors" style={{textAlign:'left'}}/>
                            <Column field="Date" header="Time Logged" body={this.formatTimestring} />
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </div>
        );
    }
}

//export default ErrorLogComponent;
export default withAITracking(reactPlugin, ErrorLogComponent);