import React, { Component } from 'react';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import HIEMetricService from '../../../../services/HIEMetricService';
import BreadCrumbComponent from '../../../bread-crumb/BreadCrumbComponent';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../../AppInsights';
import './CPUComponent.css';

class CPUComponent extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this._isForbidden = false;
        this.state = {
            data: [],
            dataAll: [],
            lastUpdate: null,
            loading: true,
            frequency: 5,
            frame: 'HostCpu'
        };
        this.chartRef = React.createRef();
        this.hieMetricService = new HIEMetricService();
        this.timerId = null;
        this.fullscreen = props.match.url.includes('cpu');
        this.getChartData = this.getChartData.bind(this);
        this.updateFrequency = this.updateFrequency.bind(this);

        this.freqSelectItems = [
            { label: '5 Minutes', value: 5 },
            { label: '30 Minutes', value: 30 },
            { label: '1 Hour', value: 60 },
        ];
    }

    componentDidMount() {
        // console.log('componentDidMount(): ');
        this._isMounted = true;
        this.getChartData();
        this.timerId = setInterval(this.getChartData, 10000);
    }

    componentDidUpdate() {
        // console.log('componentDidUpdate(): ');
        this.buildChart();
    }

    componentWillUnmount() {
        // console.log('componentWillUnmount(): ');
        this._isMounted = false;
        clearTimeout(this.timerId);
    }

    getChartData() {
        this.hieMetricService.getCPU(this.props.match.params.server, this.state.frequency).then(cpuLog => {
            const data = [];
            //console.log(cpuLog);
            if (Array.isArray(cpuLog) && cpuLog.length > 0) {
                cpuLog.forEach(cpu => data.push(cpu.CPUTotal));
                if (this._isMounted) this.setState({ data, lastUpdate: cpuLog[0].TimeCollected, loading: false });
                if (this._isMounted) this.setState({ dataAll: cpuLog });
            }
        });
    }

    //orig
    buildChart_orig() {
        if (!this.state.loading) {
            // console.log('buildChart(): ');
            let titleFontSize = 16;
            let axisFontSize = 10;
            let titlePosition = 'bottom';
            let xAxisScaleLabel = null;
            let yAxisScaleLabel = null;

            if (this.fullscreen) {
                titleFontSize *= 2;
                axisFontSize *= 2;
                titlePosition = 'top';
                yAxisScaleLabel = 'Percentage';
                if (this.state.frequency < 60) {
                    xAxisScaleLabel = `Last ${this.state.frequency} minutes`;
                } else {
                    //                    xAxisScaleLabel = `Last ${this.state.frequency/60} hour(s)`;
                    xAxisScaleLabel = 'Last hour';
                }
            }

            const labelList = [];

            let min = 0;
            for (let i = 0; i < this.state.frequency * 6 + 1; i++) {
                if (this.state.frequency <= 60 && i % 6 === 0) {
                    labelList.push(min);
                    min++;
                } else {
                    labelList.push('');
                }
            }
            const myChartRef = this.chartRef.current.getContext("2d");
            new Chart(myChartRef, {
                type: 'line',
                data: {
                    labels: labelList,
                    datasets: [{
                        fill: 'origin',
                        borderColor: 'black',
                        data: this.state.data,
                        // showLine: false,
                        pointRadius: 0,
                        borderWidth: '1px',
                    }]
                },
                options: {
                    datasetFill: true,
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: 'CPU',
                        fontSize: titleFontSize,
                        position: titlePosition,
                        padding: 0,
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            display: this.fullscreen,
                            scaleLabel: {
                                display: this.fullscreen,
                                labelString: yAxisScaleLabel,
                                fontSize: axisFontSize,
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100,
                                fontSize: axisFontSize,
                            }
                        }],
                        xAxes: [{
                            display: this.fullscreen,
                            scaleLabel: {
                                display: this.fullscreen,
                                labelString: xAxisScaleLabel,
                                fontSize: axisFontSize,
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 6,
                                fontSize: axisFontSize,
                            }
                        }],
                    },
                    animation: {
                        duration: 0
                    },
                    events: []
                },
                plugins: [{
                    beforeRender: (x, options) => {
                        var c = x.chart
                        var dataset = x.data.datasets[0];
                        var gradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);
                        gradientFill.addColorStop(0, 'red');
                        gradientFill.addColorStop(0.25 - 0.0001, 'red');
                        gradientFill.addColorStop(0.25 + 0.0001, 'green');
                        gradientFill.addColorStop(1, 'green');

                        var model = x.data.datasets[0]._meta[Object.keys(dataset._meta)[0]].dataset._model;
                        model.backgroundColor = gradientFill;
                        //model.backgroundColor = '#ffffff';                        
                    }
                }],
            });
        }
    }

    //newest
    buildChart() {
        if (!this.state.loading) {
            // console.log('buildChart(): ');
            let titleFontSize = 16;
            let axisFontSize = 10;
            let titlePosition = 'bottom';
            let xAxisScaleLabel = null;
            let yAxisScaleLabel = null;

            if (this.fullscreen) {
                titleFontSize *= 2;
                axisFontSize *= 2;
                titlePosition = 'top';
                yAxisScaleLabel = 'Percentage';
                if (this.state.frequency < 60) {
                    xAxisScaleLabel = `Last ${this.state.frequency} minutes`;
                } else {
                    //                    xAxisScaleLabel = `Last ${this.state.frequency/60} hour(s)`;
                    xAxisScaleLabel = 'Last hour';
                }
            }

            const labelList = [];

            let min = 0;
            for (let i = 0; i < this.state.frequency * 6 + 1; i++) {
                if (this.state.frequency <= 60 && i % 6 === 0) {
                    labelList.push(min);
                    min++;
                } else {
                    labelList.push('');
                }
            }

            const myChartRef = this.chartRef.current.getContext("2d");
            new Chart(myChartRef, {
                type: 'line',
                data: {
                    labels: labelList,
                    datasets: [{
                        //fill: 'origin',
                        fill: true,
                        //backgroundColor: 'green',//fillColors, // now we can pass in an array of colors (before it was only 1 color)
                        //borderColor: 'red',// fillColors,
                        //pointBackgroundColor: fillColors,
                        //colors: fillColors,

                        //borderColor: 'black',
                        data: this.state.data,
                        // showLine: false,
                        pointRadius: 0,
                        //borderWidth: '1px',                        
                    }]
                },
                options: {
                    datasetFill: true,
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: 'CPU',
                        fontSize: titleFontSize,
                        position: titlePosition,
                        padding: 0,
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            display: this.fullscreen,
                            scaleLabel: {
                                display: this.fullscreen,
                                labelString: yAxisScaleLabel,
                                fontSize: axisFontSize,
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100,
                                fontSize: axisFontSize,
                            }
                        }],
                        xAxes: [{
                            display: this.fullscreen,
                            scaleLabel: {
                                display: this.fullscreen,
                                labelString: xAxisScaleLabel,
                                fontSize: axisFontSize,
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 6,
                                fontSize: axisFontSize,
                            }
                        }],
                    },
                    animation: {
                        duration: 0
                    },
                    events: []
                },
                plugins: [{
                    afterLayout: chart => {
                        var ctx = chart.chart.ctx;
                        var xAxis = chart.scales['x-axis-0'];
                        var gradientStroke = ctx.createLinearGradient(xAxis.left, 0, xAxis.right, 0);
                        var dataset = chart.data.datasets[0];
                        var lastStop = 0;
                        var lastColor = 'green';
                        var peak = 70; //85
                        dataset.data.forEach((c, i) => {
                            //var stop = 1 / (dataset.colors.length - 1) * i;
                            var stop = 1 / (dataset.data.length - 1) * i;

                            var color = 'green';
                            /*
                            if (dataset.data[i] > peak) {
                                color = 'red';
                            }
                            if (i > 0) {
                                if (dataset.data[i-1] > peak) {
                                    color = 'red';
                                }
                            }                            
                            */
                            /*
                            if (Array.isArray(this.state.dataAll) && this.state.dataAll.length > 0 && this.state.dataAll[i] != undefined) {
                                //console.log(this.state.dataAll[i].Alert);
                                if (this.state.dataAll[i].CPUTotal > peak) {
                                    color = 'red';
                                }
                                if (i > 0) {
                                    if (this.state.dataAll[i - 1].CPUTotal > peak) {
                                        color = 'red';
                                    }
                                }     
                            }
                            */

                            if (Array.isArray(this.state.dataAll) && this.state.dataAll.length > 0 && this.state.dataAll[i] != undefined) {

                                //console.log(this.state.dataAll[i].Alert);
                                //console.log(this.state.dataAll[i]);

                                if (this.state.dataAll[i].Alert == 1) {
                                    color = 'orange';
                                }
                                if (this.state.dataAll[i].Alert == 2) {
                                    color = 'red';
                                }
                                if (i > 0) {
                                    if (this.state.dataAll[i - 1].Alert == 1) {
                                        color = 'orange';
                                    }
                                    if (this.state.dataAll[i - 1].Alert == 2) {
                                        color = 'red';
                                    }
                                }
                            }

                            gradientStroke.addColorStop(stop, color);
                            gradientStroke.addColorStop(lastStop + 0.0001, color);
                            lastStop = stop;
                            lastColor = color;

                            //gradientStroke.addColorStop(stop, dataset.colors[i]);
                        });
                        /*
                        dataset.colors.forEach((c, i) => {
                            var stop = 1 / (dataset.colors.length - 1) * i;

                            var color = 'green';
                            if (chart.data.datasets[i]) {
                                console.log(chart.data.datasets[i].data[i]);
                                if (chart.data.datasets[i].data > 50) {
                                    console.log("found");
                                    color = 'red';
                                }
                            }                            
                            gradientStroke.addColorStop(stop, color);
                            gradientStroke.addColorStop(lastStop + 0.0001, color);
                            lastStop = stop;
                            

                            //gradientStroke.addColorStop(stop, dataset.colors[i]);
                        });
                        */
                        dataset.backgroundColor = gradientStroke;
                        dataset.borderColor = gradientStroke;
                        dataset.pointBorderColor = gradientStroke;
                        dataset.pointBackgroundColor = gradientStroke;
                        dataset.pointHoverBorderColor = gradientStroke;
                        dataset.pointHoverBackgroundColor = gradientStroke;
                    }
                }],
                /*
                plugins: [{
                    beforeRender: (x, options) => {
                        var c = x.chart
                        var dataset = x.data.datasets[0];
                        var gradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);
                        gradientFill.addColorStop(0, 'red');
                        gradientFill.addColorStop(0.25 - 0.0001, 'red');
                        gradientFill.addColorStop(0.25 + 0.0001, 'green');
                        gradientFill.addColorStop(1, 'green');

                        var model = x.data.datasets[0]._meta[Object.keys(dataset._meta)[0]].dataset._model;
                        model.backgroundColor = gradientFill;
                        //model.backgroundColor = '#ffffff';                        
                    }
                }],
                */
            });
        }
    }


    updateFrequency(e) {
        clearTimeout(this.timerId);
        this.setState({ frequency: e.value, loading: true }, () => {
            this.getChartData();
            this.timerId = setInterval(this.getChartData, 10000);
        });
    }

    render() {

        let breadCrumb = null;
        let lastUpdateBody = null;
        let updateFreqBody = null;
        let containerClassName = 'cpuContainer';
        let bodyClassName = 'cpuBody';
        /*
        if (this.fullscreen) {
            containerClassName = 'cpuFullScreenContainer';
            bodyClassName = 'cpuFullScreenBody';
            breadCrumb = <BreadCrumbComponent {...this.props} />
            if (this.state.data.length > 0) {
                if (moment(this.state.lastUpdate).isSame(moment().utc(), 'hour')) {
                    lastUpdateBody = <div className="lastUpdate">Last Update: {this.state.lastUpdate}</div>;
                } else {
                    lastUpdateBody = <div className="lastUpdate outOfDate">Last Update: {this.state.lastUpdate}</div>;
                }
            }
            updateFreqBody = <Dropdown value={this.state.frequency} options={this.freqSelectItems} onChange={this.updateFrequency} className="freqDropdown" placeholder="Update frequency" />
        }
        */
        containerClassName = 'cpuFullScreenContainer';
        bodyClassName = 'cpuFullScreenBody';
        breadCrumb = <BreadCrumbComponent {...this.props} />

        let cpuBody = null;
        /*
        if (this.state.loading) {
            cpuBody = <ProgressSpinner className="spinner" />;
        } else {
            cpuBody = <canvas id="myChart" className="chart" ref={this.chartRef} />;                
        }
        */
        cpuBody = <iframe className='text-left' style={{ width: '100%', height: '100%', border: '0px', }} src={`${global.ENV_GALILEO_DOMAIN_URL}/${this.state.frame}?timezone=US%2FCentral&range_type=last_1440&filters=top_10&asset=1509&t=${global.ENV_GALILEO_TOKEN}`}></iframe>;

        return (
            <div className={containerClassName}>
                {breadCrumb}
                <div className={bodyClassName}>
                    {updateFreqBody}
                    {cpuBody}
                    {lastUpdateBody}
                </div>
            </div>
        );
    }
}

//export default CPUComponent;
export default withAITracking(reactPlugin, CPUComponent);