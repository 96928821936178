import { msalInstance } from '../../App';

export class ProdItemSettingService {

    async invokeWebAPI(url, httpMethod, msalContext, body = "") {

        // check if msalContext exists
        if (msalContext.accounts.length > 0) {
            const tokenRequest = {
                scopes: [global.ENV_MSAL_LOGIN_REQUEST_SCOPES],
                account: msalContext.accounts[0],
            };

            // acquire Bearer token to include in GET request
            const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
            const token = tokenResponse.accessToken;
            const bearer = `Bearer ${token}`;
            const headers = new Headers();
            headers.append('Accept', '*/*');
            headers.append('Content-Type', 'application/json');
            headers.append('x-northwell-version', '1.0');
            headers.append("Authorization", bearer);
            headers.append('User', msalContext.accounts[0].username)

            var options = {}
            if (body) {
                options = {
                    method: httpMethod,
                    headers: headers,
                    body: body
                };
            } else {
                options = {
                    method: httpMethod,
                    headers: headers
                };
            }

            let response = await fetch(url, options);

            return {
                status: response.status,
                body: await response.json()
            };

        } else {
            console.log('user not logged in')
        }
    }

    async getHIEInstances(environment, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/metricsread/HIEInstances?environment=" + environment
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getProductions(instance, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/metricsread/ProductionStatus?instance=" + instance
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getProductionItemSettings(instance, nameSpace, itemName, ipAddress, port, type, msalContext) {

        let url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem/settings?id=0"
        if (instance)
            url = url + "&instance=" + instance
        if (nameSpace)
            url = url + "&nameSpace=" + nameSpace
        if (itemName)
            url = url + "&itemName=" + itemName
        if (ipAddress)
            url = url + "&ipAddress=" + ipAddress
        if (port)
            url = url + "&port=" + port
        if (type)
            url = url + "&type=" + type

        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getCacheProductionItemsHybridStatus(instance, nameSpace, itemName, type, msalContext) {

        let url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem/v1/hybrid/status?id=0"
        if (instance)
            url = url + "&instance=" + instance
        if (nameSpace)
            url = url + "&nameSpace=" + nameSpace
        if (itemName)
            url = url + "&itemName=" + itemName
        if (type)
            url = url + "&type=" + type

        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    //Enhancement - HDO-5993
    async getCacheProductionItemsHybridStatusV2(instance, nameSpace, itemName, type, msalContext) {

        let url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem/v2/hybrid/status?id=0"
        if (instance)
            url = url + "&instance=" + instance
        if (nameSpace)
            url = url + "&nameSpace=" + nameSpace
        if (itemName)
            url = url + "&itemName=" + encodeURIComponent(itemName)
        if (type)
            url = url + "&type=" + type
        url = url + "&lookbackTime=7"

        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getCacheProductionItemsHybridStatusById(Id, viewSettings, msalContext) {

        let url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem/hybrid/status/" + Id + "?viewSettings=" + viewSettings
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getQueueInterfaceDetails(Instance, Namespace, Name, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/queuemetric/" + Instance + "?Namespace=" + Namespace + "&Name=" + encodeURIComponent(Name)
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async updateQueueAlertThresholdValues(Id, body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/queuemetric/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async recycleInterface(Id, body, Action, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem/recycle/" + Id + "?Action=" + Action
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getProdItemLogs(Id, Instance, Namespace, ItemName, StartDate, EndDate, logType, timeZone, msalContext) {
        let url = ""
        if (logType === 1) {
            url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem/status/logs/?Instance=" + Instance + "&Namespace=" + Namespace + "&ItemName=" + encodeURIComponent(ItemName) + "&StartDate=" + StartDate + "&EndDate=" + EndDate
        }
        else {
            url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem/recycle/logs/" + Id + "?StartDate=" + StartDate + "&EndDate=" + EndDate + "&timeZone=" + timeZone
        }

        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }
}