import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import "./BreadCrumbComponent.css";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { Menubar } from 'primereact/menubar';
import { Badge } from 'primereact/badge';
import { IssuesDetailsService } from '../Issues/IssuesDetailsService';

class BreadCrumbComponent extends Component {
    constructor(props) {
        super(props);
        this._isIssueDetailsMounted = false;
        this.state = {
            IssueDashboardCount: {},
            pageErrorMessage: null
        }
        this.issuesDetailsService = new IssuesDetailsService();
    }

    componentDidMount() {
        this._isIssueDetailsMounted = true;
        this.getIssueCount();
    }

    getIssueCount() {
        this.issuesDetailsService.getIssueCount(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isIssueDetailsMounted) {
                        this.setState({ IssueDashboardCount: d.body, pageErrorMessage: null });
                    }
                }
            }
        ).catch(e => {
            this.setState({ pageErrorMessage: 'Something went wrong !!!' })
            console.log(e)
        });
    }

    render() {
        let breadCrumb = [];
        if (this.props.match.params.type) {
            if (breadCrumb.length > 0) {
                const url = `/monitor/${this.props.match.params.server}/${this.props.match.params.instance}/${this.props.match.params.namespace}/mvs/${this.props.match.params.type}`;
                breadCrumb.unshift(<span key='8'> &gt; <NavLink to={url}>{this.props.match.params.type}</NavLink></span>);
            } else {
                breadCrumb.unshift(<span key='8'> &gt; {this.props.match.params.type}</span>);
            }
        }

        if (this.props.match.url.includes('mvs')) {
            if (breadCrumb.length > 0) {
                const url = `/monitor/${this.props.match.params.server}/${this.props.match.params.instance}/${this.props.match.params.namespace}/mvs`;
                breadCrumb.unshift(<span key='7'> &gt; <NavLink to={url}>mvs</NavLink></span>);
            } else {
                breadCrumb.unshift(<span key='7'> &gt; message volumes</span>);
            }
        }

        if (this.props.match.params.namespace) {
            if (breadCrumb.length > 0) {
                const url = `/monitor/${this.props.match.params.server}/${this.props.match.params.instance}/${this.props.match.params.namespace}`;
                breadCrumb.unshift(<span key='6'> &gt; <NavLink to={url}>{this.props.match.params.namespace}</NavLink></span>);
            } else {
                breadCrumb.unshift(<span key='6'> &gt; {this.props.match.params.namespace}</span>);
            }
        }

        if (this.props.match.params.instance) {
            if (breadCrumb.length > 0) {
                const url = `/monitor/${this.props.match.params.server}/${this.props.match.params.instance}`;
                breadCrumb.unshift(<span key='5'> &gt; <NavLink to={url}>{this.props.match.params.instance}</NavLink></span>);
            } else {
                breadCrumb.unshift(<span key='5'> &gt; {this.props.match.params.instance}</span>);
            }
        }

        if (this.props.match.url.includes('cpu')) {
            if (breadCrumb.length > 0) {
                const url = `/monitor/${this.props.match.params.server}/cpu`;
                breadCrumb.unshift(<span key='4'> &gt; <NavLink to={url}>cpu</NavLink></span>);
            } else {
                breadCrumb.unshift(<span key='4'> &gt; cpu</span>);
            }
        }

        if (this.props.match.url.includes('memory')) {
            if (breadCrumb.length > 0) {
                const url = `/monitor/${this.props.match.params.server}/memory`;
                breadCrumb.unshift(<span key='3'> &gt; <NavLink to={url}>memory</NavLink></span>);
            } else {
                breadCrumb.unshift(<span key='3'> &gt; memory</span>);
            }
        }

        if (this.props.match.url.includes('swap')) {
            if (breadCrumb.length > 0) {
                const url = `/monitor/${this.props.match.params.server}/swap`;
                breadCrumb.unshift(<span key='2'> &gt; <NavLink to={url}>swap</NavLink></span>);
            } else {
                breadCrumb.unshift(<span key='2'> &gt; swap</span>);
            }
        }

        // if (this.props.match.url.includes('db')) {
        //     if (breadCrumb.length > 0) {
        //         const url = `/${this.props.match.params.server}/db`;
        //         breadCrumb.unshift(<span key='2'> &gt; <NavLink to={url}>db</NavLink></span>);
        //     } else {
        //         breadCrumb.unshift(<span key='2'> &gt; db &gt; {this.props.match.params.dbname}</span>);
        //     }
        // }

        if (this.props.match.params.server) {
            if (breadCrumb.length > 0) {
                const url = `/monitor/${this.props.match.params.server}`;
                breadCrumb.unshift(<span key='1'> &gt; <NavLink to={url}>{this.props.match.params.server}</NavLink></span>);
            } else {
                breadCrumb.unshift(<span key='1'> &gt; {this.props.match.params.server}</span>);
            }
        }

        if (breadCrumb.length > 0) {
            breadCrumb.unshift(<span key='0'><NavLink to="/monitor/servers">Servers</NavLink></span>);
        }
        //else {
        //     breadCrumb.unshift(<span key='0'><NavLink to="/"><b>Home</b></NavLink></span>);
        // }

        //console.log(`${window.location.protocol}//${window.location.host}/admin`);
        //const adminUrl = `${window.location.protocol}//${window.location.host}/admin`;
        //const adminUrl = "/admin";
        //<div className="adminLink"><a href="#admin">Administration</a></div>
        /*
        const menu = null;
        let items = [
            { label: 'New', icon: 'pi pi-fw pi-plus' },
            { label: 'Delete', icon: 'pi pi-fw pi-trash' }
        ];
        */

        //For Menu Bar
        this.items = [
            {
                label: 'Home', command: () => { window.location = "/incident/dashboard"; }, icon: 'pi pi-fw pi-home',
            },
            {
                label: 'Platform Health', command: () => { window.location = "/leader/dashboard"; }, icon: 'pi pi-heart',
            },
            {
                //label: 'Manage Incident', command: () => { window.location = "/issues"; }, icon: 'pi pi-bell',
                template: <div>
                    <a href="/issues" role="menuitem" class="p-menuitem-link" aria-haspopup="false">
                        <span class="p-menuitem-icon pi pi-fw pi-bell mr-3 p-text-secondary p-overlay-badge" style={{ fontSize: '18px' }}>
                            <Badge value={this.state.IssueDashboardCount.Error || 0} severity="danger"></Badge>
                        </span>
                        <span class="p-menuitem-text">Manage Incident</span>
                    </a>
                </div>
            },
            {
                label: 'Monitor', icon: 'pi pi-fw pi-desktop',
                items: [
                    {
                        label: 'Inbound Inactivity', command: () => { window.location = "/monitor/inboundmetric"; }, icon: 'pi pi-fw pi-angle-double-right'
                    },
                    {
                        label: 'Queues', command: () => { window.location = "/monitor/queues"; }, icon: 'pi pi-fw pi-arrow-right-arrow-left'
                    },
                    {
                        label: 'Scheduled Task', command: () => { window.location = "/monitor/taskschedule"; }, icon: 'pi pi-fw pi-stopwatch'
                    },
                    {
                        label: 'Database Size', command: () => { window.location = "/monitor/databasesize"; }, icon: 'pi pi-database'
                    },
                    {
                        label: 'Mirror Status', command: () => { window.location = "/monitor/mirrors"; }, icon: 'pi pi-fw pi-clone'
                    },
                    {
                        label: 'Galileo', command: () => { window.location = "/galileo"; }, icon: 'pi pi-chart-bar'
                    }
                    // {
                    //     label: 'Servers', command: () => { window.location = "/monitor/servers"; }, icon: 'pi pi-fw pi-server',
                    // }
                ]
            },
            {
                label: 'Production Items', command: () => { window.location = "/monitor/production/items"; }, icon: 'pi pi-fw pi-sliders-h'
            },
            {
                label: 'IACL', command: () => { window.location = "/iacl"; }, icon: 'pi pi-fw pi-bolt'
            },
            {
                label: 'IP Port List', command: () => { window.location = "/production/ipport"; }, icon: 'pi pi-map-marker'
            },
            {
                label: 'Administration', icon: 'pi pi-fw pi-user-edit',
                items: [
                    // {
                    //     label: 'Server Blackout', command: () => { window.location = "/blackout"; }, icon: 'pi pi-fw pi-server'
                    // },
                    {
                        label: 'Do-not-monitor', command: () => { window.location = "/notification/blackout/rules"; }, icon: 'pi pi-eye-slash'
                    },
                    {
                        label: 'Restore Connection', command: () => { window.location = "/restore/connection"; }, icon: 'pi pi-fw pi-replay'
                    },
                    {
                        label: 'Alert Rule', icon: 'pi pi-fw pi-book',
                        items: [
                            {
                                label: 'Custom Alert', command: () => { window.location = "/admin/custom/hie/alert/rules"; }, icon: 'pi pi-fw pi-send'
                            },
                            {
                                label: 'Azure Alert', command: () => { window.location = "/admin/custom/azure/alert/rules"; }, icon: 'pi pi-fw pi-cloud'
                            },
                            {
                                label: 'Threshold Rule', command: () => { window.location = "/admin/threshold/rules"; }, icon: 'pi pi-fw pi-list'
                            },
                            {
                                label: 'Alert Processing', command: () => { window.location = "/admin/alert/processing/rules"; }, icon: 'pi pi-fw pi-cog'
                            },
                        ]
                    },
                    {
                        label: 'IACL Configs', icon: 'pi pi-fw pi-cog',
                        items: [
                            { label: 'Applications', command: () => { window.location = "/application"; }, icon: 'pi pi-fw pi-box' },
                            { label: 'CA Group Names', command: () => { window.location = "/cagroupname"; }, icon: 'pi pi-fw pi-users' },
                            { label: 'Connection Types', command: () => { window.location = "/connectiontypes"; }, icon: 'pi pi-fw pi-wifi' },
                            { label: 'Message Types', command: () => { window.location = "/messagetype"; }, icon: 'pi pi-fw pi-list' },
                            { label: 'Message Content', command: () => { window.location = "/messagecontent"; }, icon: 'pi pi-fw pi-book' },
                            { label: 'Site Names', command: () => { window.location = "/sitenames"; }, icon: 'pi pi-fw pi-building' },
                        ]
                    },
                    {
                        label: 'Action Group', command: () => { window.location = "/admin/alert/actiongroup"; }, icon: 'pi pi-fw pi-users'
                    }
                ]
            }
        ];

        return (
            <div>
                <div className="breadCrumbContainer">
                    <div>
                        {breadCrumb}
                    </div>
                    <div>
                        <Menubar model={this.items} />
                    </div>
                </div>
            </div>
        );
    }
};
//export default BreadCrumbComponent;
export default withAITracking(reactPlugin, BreadCrumbComponent);
