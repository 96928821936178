import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Spinner from '../Spinner'
import AlertMessage from "../alertmessage/AlertMessage";
import { FilterMatchMode, FilterOperator, MessageSeverity } from 'primereact/api';
import { ServerBlackoutService } from '../../components/blackout/ServerBlackoutService';
import './ServerBlackout.css';

class ServerBlackoutComponent extends Component {
    constructor() {
        super();
        this._isServersMounted = false;
        this.state = {
            ServerList: [],
            MappingList: [],
            SelectedServer: [],
            SelectedServerName: "",
            hideSpinner: false,
            pageErrorMessage: '',
            pageSuccessMessage: '',
            disableNotificationVisible: false,
            disableNotificationDialogText: "",
            disableNotificationDialogHeaderText: "",
            invalidateExpDate: false,
            systemId: 0,
            metricTypeId: 0,
            isEnabled: 0,
            jwtToken: '',
            filters: {
                'ServerInstance': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            },
            disableExpiryDate: null,
            calRef: React.createRef()
        };
        this.baseNotificationUrl = global.ENV_HIE_ADMIN_API_AZURE_URL;
        this.serverBlackoutService = new ServerBlackoutService();

        //calender date intialization
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();

        this.invalidDates = [today];
        this.minDate = new Date();
        this.minDate.setHours(0);
        //this.minDate.setDate(today.getDate() + 1)
        this.minDate.setMonth(month);
        this.minDate.setFullYear(year);
        //calender date intialization
    }

    componentDidMount() {
        this._isServersMounted = true;
        this.getNotificationData();
    }

    getNotificationData() {
        this.serverBlackoutService.getNotificationData(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isServersMounted) {
                        this.setState({ ServerList: d.body, hideSpinner: true, pageErrorMessage: null, pageSuccessMessage: null });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });

    }

    handleServerNotificationInputChange(systemId, metricTypeId, isEnabled, serverInstance, metricName) {

        this.setState({ systemId: systemId, pageSuccessMessage: null, pageErrorMessage: null });
        this.setState({ metricTypeId: metricTypeId });
        this.setState({ isEnabled: isEnabled });

        let theText = "Are you sure you wish to ";
        let theAction = "";

        if (isEnabled) {
            theAction = "disable"
        } else {
            theAction = "enable"
        }

        if (metricTypeId == 0) {
            theText = theText + theAction + " all notifications for server: " + serverInstance + " ?";
        } else {
            theText = theText + theAction + " notification: " + metricName + " ?";
        }

        this.setState({ disableNotificationDialogText: theText });
        this.setState({ disableNotificationDialogHeaderText: theAction.charAt(0).toLocaleUpperCase() + theAction.slice(1) + " Notification" });
        this.setState({ disableNotificationVisible: true });

    }

    doServerNotificationInputChange() {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

        //HM-905 - big fix
        if (this.state.invalidateExpDate) {
            this.setState({ pageErrorMessage: "Disabled Expiry Date should be less than current date", hideSpinner: true })
            return;
        }

        let systemId = this.state.systemId;
        let metricTypeId = this.state.metricTypeId;
        let isEnabled = this.state.isEnabled;
        this.serverBlackoutService.editSysMetricNotificationMappings(systemId, metricTypeId, isEnabled, this.state.disableExpiryDate, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else if (d.status === 400) {
                    this.setState({ hideSpinner: true, pageErrorMessage: d.body.title })
                } else if (d.status === 200) {
                    this.setState({ ServerList: d.body, MappingList: [] });

                    if (this.state.SelectedServer.length) {
                        let selectedServer = this.state.SelectedServer[0].systemId;
                        // If the server updated is different from current selected server, 
                        // clear selected server.
                        if (selectedServer != systemId) {
                            this.setState({ SelectedServer: [], SelectedServerName: "" });
                        } else {
                            // Reload selected server to metrics list...
                            for (let i = 0; i < this.state.ServerList.length; i++) {
                                if (this.state.ServerList[i].systemId == selectedServer) {
                                    this.setState({ MappingList: this.state.ServerList[i].mapping, pageSuccessMessage: null });
                                }
                            }
                        }
                    }
                    this.setState({
                        pageSuccessMessage: 'Changes saved successfully !!!', disableNotificationDialogText: "", disableNotificationDialogHeaderText: "",
                        disableNotificationVisible: false, hideSpinner: true, disableExpiryDate: null
                    });
                }
            }
        );
    }

    formatTriggerNotification(rowData, column) {

        let isEnabled = rowData[column.field];
        let systemId = rowData["SystemId"];
        let metricId = rowData["Id"];
        let serverInstance = rowData["ServerInstance"];
        let metricName = rowData["MetricName"];

        if (metricId == undefined) {
            metricId = 0;
            metricName = "";
        }

        return (
            <div>
                <input type="checkbox" value={isEnabled}
                    onChange={() => this.handleServerNotificationInputChange(systemId, metricId, isEnabled, serverInstance, metricName)}
                    checked={isEnabled}
                />
            </div>
        );
    }

    showLastUpdatedDate(rowData) {
        let modifiedBy = rowData["ModifiedBy"];
        let modifiedDateTime = rowData["ModifiedDate"];
        if (modifiedBy && modifiedDateTime) {
            return modifiedBy + " on " + modifiedDateTime
        }
    }

    showServerNotifications(rowData) {

        this.setState({ SelectedServer: [], pageErrorMessage: null, pageSuccessMessage: null });
        this.setState({ SelectedServerName: "" });
        this.setState({ SelectedServer: rowData.Mapping });
        this.setState({ SelectedServerName: rowData.Mapping[0].ServerName });
        this.setState({ MappingList: [] });
        this.setState({ MappingList: rowData.Mapping });

    }

    rowServerStyle(rowData) {
        return { alert: (rowData.TriggerNotification) ? false : true };
    }

    confirmDisableNotification = (
        <div>
            <Button label='OK' onClick={() => this.doServerNotificationInputChange()} />
            <Button label='Cancel' onClick={e => this.setState({ pageErrorMessage: null, pageSuccessMessage: null, disableNotificationVisible: false })} className='p-button-secondary' />
        </div>
    );

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    //calender functions
    setDisableExpiryDate(expiryDate) {
        expiryDate.setSeconds(0)
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, disableExpiryDate: null })
        if (expiryDate) {
            var currentDate = new Date()
            if (expiryDate < currentDate) {
                this.setState({ pageErrorMessage: "Disabled Expiry Date should be less than current date", invalidateExpDate: true, disableExpiryDate: null })
                return;
            }
            else {
                this.setState({
                    disableExpiryDate: expiryDate.toLocaleString('en-US', { timeZone: 'America/New_York', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }),
                    invalidateExpDate: false
                })
            }
        }
    }

    handleClose = () => {
        this.state.calRef.current.hide();
    };
    //calender functions

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="adminContainer">

                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {/* <div className='text-danger text-center'> {this.state.pageErrorMessage}</div> */}
                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">SERVER BLACKOUT</div>
                        </header>
                        <DataTable value={this.state.ServerList}
                            selectionMode="single"
                            autoLayout={true}
                            paginator={true}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            rowClassName={this.rowServerStyle}
                            onSelectionChange={(e) => this.showServerNotifications(e.value)}
                            showGridlines={true}
                            filters={this.state.filters}
                        >
                            <Column field="ServerInstance" header="Server Names" filter filterMatchMode="contains" showFilterOperator={false} filterPlaceholder="Search" style={{ textAlign: 'left', width: '80%' }} sortable />
                            <Column field="DisableExpiryDate" header="Disable Expiry Date (EST)" style={{ textAlign: 'left', width: '99%' }} />
                            <Column field="TriggerNotification" header="Action" body={this.formatTriggerNotification.bind(this)} />
                        </DataTable>

                        <div id="metricsList" style={{ display: this.state.SelectedServer.length ? "block" : "none" }}>

                            <p>&nbsp;</p>
                            Selected Server Notifications: <b>{this.state.SelectedServerName}</b>

                            <DataTable value={this.state.MappingList}
                                selectionMode="single"
                                autoLayout={true}
                                paginator={false}  // Disabled due to bug with page and changing the data
                                rows={this.state.MappingList.length}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                rowClassName={this.rowServerStyle}
                                showGridlines={true}
                            >
                                <Column field="MetricName" header="Notifications Metric" style={{ textAlign: 'left', width: '40%' }} sortable />
                                <Column field="DisableExpiryDate" header="Disable Expiry Date (EST)" style={{ textAlign: 'left', width: '30%' }} />
                                <Column field="LastUpdated" header="Last Updated By (EST)" body={this.showLastUpdatedDate.bind(this)} style={{ textAlign: 'left', width: '40%' }} />
                                <Column field="TriggerNotification" header="Action" body={this.formatTriggerNotification.bind(this)} />
                            </DataTable>
                        </div>
                    </div>

                    <Dialog header={this.state.disableNotificationDialogHeaderText} footer={this.confirmDisableNotification}
                        visible={this.state.disableNotificationVisible} width='400px' modal={true}
                        onHide={e => this.setState({ disableNotificationVisible: false })} maximizable={false}>
                        <div>
                            {this.state.disableNotificationDialogText}
                            {this.state.isEnabled ? <span><br />
                                <label><b>Disable Expiry Date</b></label>
                                <Calendar ref={this.state.calRef} id="disableExpiryDate"
                                    value={this.state.disableExpiryDate}
                                    onChange={(e) => this.setDisableExpiryDate(e.value)}
                                    showTime
                                    //disabledDates={this.invalidDates} 
                                    //disabledDays={[0, 6]}
                                    minDate={this.minDate} placeholder="Expiry Date" readOnlyInput
                                    footerTemplate={(e) => (
                                        <div style={{ textAlign: 'center' }}>
                                            <Button label='OK' onClick={this.handleClose} />
                                        </div>
                                    )}></Calendar></span>
                                : <></>}
                        </div>
                    </Dialog>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
};
const WrappedServerBlackoutComponent = withMsal(ServerBlackoutComponent);
//export default WrappedServerBlackoutComponent;
export default withAITracking(reactPlugin, WrappedServerBlackoutComponent);


