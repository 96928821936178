import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import { InteractionType } from "@azure/msal-browser";
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import { MonitorService } from '../MonitorService'
import AlertMessage from "../../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import Spinner from '../../Spinner'
import './DatabaseSize.css';

//HM-1070 - PI 2023 Q2 - UI Page for Database sizes
class DatabaseSizeComponent extends Component {
    constructor() {
        super();
        this._isDbSizeMounted = false;
        this.state = {
            DatabaseSizeList: [],
            InstanceList: [],
            hideSpinner: false,
            pageErrorMessage: '',
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'SystemName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'Instance': { value: null, matchMode: FilterMatchMode.IN },
                'DatabaseName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            },
            selectedAutoRefresh: 0,
            intervalId: null,
            autoRefreshOptions: [
                { label: 'Auto-Refresh Off', value: 0 },
                { label: '5 minute', value: 300000 },
                { label: '10 minute', value: 600000 },
                { label: '15 minute', value: 900000 },
                { label: '30 minute', value: 1800000 }
            ]
        };

        this.baseAPIUrl = global.ENV_HIE_ADMIN_API_AZURE_URL;
        this.monitorService = new MonitorService();
    }

    componentDidMount() {
        this._isDbSizeMounted = true;
        this.getDatabaseSizeDetails();
    }

    getDatabaseSizeDetails = () => {
        this.setState({ hideSpinner: false })
        this.monitorService.getDatabaseSizeDetails(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isDbSizeMounted) {
                        this.setState({
                            DatabaseSizeList: d.body,
                            InstanceList: [...new Set(d.body.map(x => x.Instance))],
                            hideSpinner: true
                        });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    clearDataTableFilters = () => {
        this.setState({
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'SystemName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'Instance': { value: null, matchMode: FilterMatchMode.IN },
                'DatabaseName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            }
        });
        sessionStorage.clear();
    }

    //HM-935 - change to save the filter
    onGlobalFilterChange = (event, filtersKey) => {
        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter = (filtersKey) => {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)}
                            placeholder="Global Search" style={{ width: '100%' }} size="100" />
                    </span>
                    <span>
                        <Dropdown value={this.state.selectedAutoRefresh} options={this.state.autoRefreshOptions} onChange={this.onAutoRefresh} placeholder="Auto-Refresh"
                            tooltip='Auto-Refresh' tooltipOptions={{ position: 'top' }} className="ui-column-filter" />
                        <Button hidden={this.state.selectedAutoRefresh !== 0} tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.getDatabaseSizeDetails} icon="pi pi-refresh" />
                    </span>
                </div>
            </div>
        )
    }

    onAutoRefresh = (e) => {
        let interval = {}
        let selectedAutoRefresh = e.value;
        clearInterval(this.state.intervalId);

        if (e.value && e.value > 0) {
            interval = setInterval(() => {
                //console.log('Logs every - ' + selectedAutoRefresh + ' ms - ' + new Date());
                this.getDatabaseSizeDetails();
            }, selectedAutoRefresh);
        }
        this.setState({ selectedAutoRefresh: e.value, intervalId: interval })
    }

    instanceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.InstanceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="Instance" className="multiselect-custom"
        />;
    }

    rowClassStyle = (rowData) => {
        if (rowData.BeyondThreshold) {
            return 'p-highlight'
        };
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="adminContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}

                    <div hidden={this.state.hideSpinner}><Spinner /></div>

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">DATABASE SIZE
                                {/* &nbsp;<Tooltip target=".custom-target-icon" />
                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                    data-pr-tooltip="Highlighted records represents database size above threshold."
                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i> */}
                            </div>
                        </header>
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.DatabaseSizeList}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            dataKey="Id"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            showGridlines removableSort
                            emptyMessage="No Records found."
                            onFilter={(e) => this.setState({ filters: e.filters })} //HM-935 - change to save the filter
                            stateStorage="session" stateKey="dt-database-size-session"
                            //rowClassName={this.rowClassStyle}
                            sortField='SizeInt' sortOrder="-1"
                        >
                            <Column field="SystemName" header="HostName" filter filterPlaceholder='Host Name' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            <Column field="Instance" header="Instance" filter filterPlaceholder='Instance' filterElement={this.instanceFilterTemplate} showFilterMatchModes={false} style={{ textAlign: 'left' }} />
                            <Column field="DatabaseName" header="Database Name" filter filterPlaceholder='Database Name' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            {/* <Column field="MaxSize" header="Max Size" style={{ textAlign: 'left' }} /> */}
                            <Column field="Size" header="Size" style={{ textAlign: 'left' }} sortable sortField='SizeInt' />
                            <Column field="Available" header="Available" style={{ textAlign: 'left' }} sortable sortField='AvailableNum' />
                            <Column field="Free" header="Free (%)" style={{ textAlign: 'left' }} sortable />
                            <Column field="DiskFreeSpace" header="Disk Free Space" style={{ textAlign: 'left' }} sortable sortField='DiskFreeSpaceNum' />
                            {/* <Column field="ExpansionSize" header="Expansion Size" style={{ textAlign: 'left' }} /> */}
                            {/* <Column field="Status" header="Status" style={{ textAlign: 'left' }} /> */}
                            <Column field="DisplayLastExpansionTime" header="Last Expansion Time" style={{ textAlign: 'left' }} />
                            <Column field="DisplayTimeCollected" header="Last Updated" style={{ textAlign: 'left' }} sortable sortField='TimeCollected' />
                        </DataTable>
                    </div>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
};
const WrappedDatabaseSizeComponent = withMsal(DatabaseSizeComponent);
export default withAITracking(reactPlugin, WrappedDatabaseSizeComponent);