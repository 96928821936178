export const msalConfig = {
    auth: {
        clientId: global.ENV_MSAL_CLIENT_ID,
        authority: global.ENV_MSAL_AUTHORITY,
        redirectUri: global.ENV_MSAL_REDIRECT_URI
    },
    cache: {
        cacheLocation: global.ENV_MSAL_CACHE_LOCATION, // This configures where your cache will be stored
        storeAuthStateInCookie: global.ENV_MSAL_STORE_AUTH_IN_COOKIE, // Set this to "true" if you are having issues on IE11 or Edge,
        secureCookies: true
    },
    system: {
        tokenRenewalOffsetSeconds: 10
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [global.ENV_MSAL_LOGIN_REQUEST_SCOPES]
};

export const logoutRequest = {
    mainWindowRedirectUri: global.ENV_MSAL_REDIRECT_URI,
    postLogoutRedirectUri: global.ENV_MSAL_REDIRECT_URI
};
