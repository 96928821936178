import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { Button } from 'primereact/button';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { CustomAzureAlertService } from './CustomAzAlertService';
import { AdminService } from '../admin/AdminService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity, FilterMatchMode, FilterOperator } from 'primereact/api';
import Spinner from '../Spinner'

//import '../AdminComponent.css';

class CustomAzAlertActionComponent extends Component {
    constructor() {
        super();
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            hasReadRights: true,
            showpagination: false,
            customAzureAlerts: [],
            customAzureAlert: {},
            confirmDeleteVisible: false,
            hideSpinner: false,
            adminRoles: [],
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            globalFilterValue: ''
        }
        this.customAzureAlertService = new CustomAzureAlertService();
        this.adminService = new AdminService();
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.globalSearchFilter = this.globalSearchFilter.bind(this);
        this.editCustomAzAlertRule = this.editCustomAzAlertRule.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getAdminRoles()
        this.getCustomHieAlertRule()
    }

    getAdminRoles() {
        this.adminService.getAdminRoles(this.props.msalContext).then(
            (d) => {
                this.setState({ adminRoles: d, pageErrorMessage: null });
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getRoleId() {
        var adminRoles = this.state.adminRoles;
        if (adminRoles && adminRoles.length > 0) {
            if (adminRoles.includes('Admin.Write'))
                return 2;
            if (adminRoles.includes('Admin.Read'))
                return 1;
        }
        return 0;
    }

    getCustomHieAlertRule() {
        this.customAzureAlertService.getCustomAzureAlertRule(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', pageSuccessMessage: null, hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({ customAzureAlerts: d.body, hideSpinner: true, pageErrorMessage: null, pageSuccessMessage: null });
                        if (this.state.customAzureAlerts.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    deleteRecord() {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' })
        this.customAzureAlertService.deleteCustomAzureAlertRule(this.state.customAzureAlert.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to perform this operation.', hideSpinner: true });
                } else if (d.status === 200) {
                    this.getCustomHieAlertRule();
                    this.setState({ confirmDeleteVisible: false, hideSpinner: true, pageSuccessMessage: 'Rule deleted successfully !!!' });
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong !!!', hideSpinner: true });
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    globalSearchFilter(filtersKey) {
        return (
            <div>
                <div style={{ 'textAlign': 'center' }} >
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                </div>
            </div>
        )
    }

    onGlobalFilterChange(event, filtersKey) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    booleanChecker = (rowData, item) => {
        if (rowData[item.field]) {
            return "Active"
        } else {
            return "Not Active"
        }
    };

    editCustomAzAlertRule(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" tooltip="Edit" tooltipOptions={{ position: 'top' }} onClick={() =>  window.open('/admin/custom/azure/alert/rule/edit/' + rowData.Id, "_blank")} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" tooltip="Delete" tooltipOptions={{ position: 'top' }} onClick={e => this.setState({ confirmDeleteVisible: true, customAzureAlert: { ...rowData } })} />
            </React.Fragment>
        );
    }

    confirmDeleteFooter = (
        <div>
            <Button label='Ok' onClick={() => this.deleteRecord()} />
            <Button label='Cancel' onClick={e => this.setState({ confirmDeleteVisible: false, pageErrorMessage: '', pageSuccessMessage: '' })}
                className='p-button-secondary' />
        </div>
    );

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="actiongroupContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <div className='flex justify-content-between' style={{ marginBottom: '10px' }}>
                            <div className="page-header-title">AZURE ALERT RULE</div>
                            <Button label='' icon='pi pi-plus' tooltip='Create Custom Rule' tooltipOptions={{ position: 'left' }} disabled={this.getRoleId() !== 2}
                                onClick={() => window.open('/admin/custom/azure/alert/rule/create', '_blank')} />
                        </div>

                        <DataTable
                            value={this.state.customAzureAlerts}
                            selectionMode="single"
                            paginator={this.state.showpagination}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            responsiveLayout="scroll"
                            filters={this.state.filters}
                            header={this.globalSearchFilter('filters')}
                            emptyMessage="No Records found."
                            showGridlines={true}
                            onFilter={(e) => this.setState({ filters: e.filters })}
                            stateStorage="session" stateKey="dt-custom-azurealert-session"
                        >
                            <Column field="ResourceType" header="Resource Type" style={{ textAlign: 'left' }} />
                            <Column field="Name" header="Alert Name" style={{ textAlign: 'left' }} />
                            <Column field="Priority" header="Priority" style={{ textAlign: 'left', width: '150px' }} sortable />
                            <Column field="ResourceName" header="Resource Name" style={{ textAlign: 'left' }} />
                            <Column field="Status" header="Status" style={{ textAlign: 'left', width: '150px' }} body={this.booleanChecker} />
                            <Column field="TriggerEmail" header="Trigger Email" style={{ textAlign: 'left', width: '150px' }} body={this.booleanChecker} />
                            <Column field="edit" header="Action" body={this.editCustomAzAlertRule}
                                style={{ whiteSpace: 'nowrap', width: '150px', textAlign: 'center' }} />
                        </DataTable>
                    </div>

                    <Dialog header='Delete threshold rule' footer={this.confirmDeleteFooter}
                        visible={this.state.confirmDeleteVisible} width='400px' modal={true}
                        onHide={e => this.setState({ confirmDeleteVisible: false })} maximizable={false}>
                        <div>
                            Are you sure you want to delete the rule <b>{this.state.customAzureAlert.Name}</b>?
                        </div>
                    </Dialog>
                </div>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedCustomAzAlertActionComponent = withMsal(CustomAzAlertActionComponent);
export default withAITracking(reactPlugin, WrappedCustomAzAlertActionComponent);