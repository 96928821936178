import { msalInstance } from '../../../App';

export class HIEHealthService {

    async invokeWebAPI(url, httpMethod, msalContext, body = "") {

        // check if msalContext exists
        if (msalContext.accounts.length > 0) {
            const tokenRequest = {
                scopes: [global.ENV_MSAL_LOGIN_REQUEST_SCOPES],
                account: msalContext.accounts[0],
            };

            // acquire Bearer token to include in GET request
            const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
            const token = tokenResponse.accessToken;
            const bearer = `Bearer ${token}`;
            const headers = new Headers();
            headers.append('Accept', '*/*');
            headers.append('Content-Type', 'application/json');
            headers.append('x-northwell-version', '1.0');
            headers.append("Authorization", bearer);
            headers.append('User', msalContext.accounts[0].username)

            var options = {}
            if (body) {
                options = {
                    method: httpMethod,
                    headers: headers,
                    body: body
                };
            } else {
                options = {
                    method: httpMethod,
                    headers: headers
                };
            }

            let response = await fetch(url, options);

            return {
                status: response.status,
                body: await response.json()
            };

        } else {
            console.log('user not logged in')
        }
    }

    async loadDashboardData(PageNo, IsNew, PageSize, Category, msalContext) {

        const url = `${global.ENV_HIE_ADMIN_API_AZURE_URL}/Notification/Dashboard?pageNum=${PageNo}&IsNew=${IsNew}&pageSize=${PageSize}&alertTypes=2&priorityTypes=${Category}`
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async loadHealthStatus(msalContext) {

        const url = `${global.ENV_HIE_ADMIN_API_AZURE_URL}/Notification/Dashboard/healthstatus`
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async updateHealthStatus(healthStatusData, msalContext) {

        const url = `${global.ENV_HIE_ADMIN_API_AZURE_URL}/Notification/Dashboard/healthstatus/${healthStatusData.Id}`
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, JSON.stringify(healthStatusData));
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getHealthStatusAuditLog(id, msalContext) {

        const url = `${global.ENV_HIE_ADMIN_API_AZURE_URL}/Notification/Dashboard/healthstatus/logs/${id}`
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }
}