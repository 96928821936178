import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import HIEMetricService from '../../../services/HIEMetricService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import './DatabaseConnectionComponent.css';

class DatabaseConnectionComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            databaseConnections: [],
            loading: true,
        };
        this.hieMetricService = new HIEMetricService();
        this.getTableData = this.getTableData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getTableData();
    }

    getTableData() {
        this.hieMetricService.getDatabaseConnectionsByNamespace(this.props.match.params.server + ':' + this.props.match.params.instance, this.props.match.params.namespace).then(databaseConnections => {
            if (this._isMounted) this.setState({ databaseConnections, loading: false });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div className="databaseConnectionContainer component">
                <Accordion activeIndex={0}>
                    <AccordionTab header="Database Connections" headerStyle={{ textAlign: 'left' }}>
                        <DataTable value={this.state.databaseConnections} selectionMode="single" autoLayout={true} loading={this.state.loading} paginator={true} rows={10} rowsPerPageOptions={[10, 20, 50, 100]} showGridlines={true}>
                            <Column field="DSN" header="Data Source" style={{ textAlign: 'left' }} />
                            <Column field="ConnectionName" header="Connection Name" style={{ textAlign: 'left' }} />
                            <Column field="URL" header="Connection String" style={{ textAlign: 'left' }} />
                            <Column field="Usr" header="User" style={{ textAlign: 'left' }} />
                            <Column field="ConnectionStatus" header="Status" />
                            <Column field="Message" header="Message" style={{ textAlign: 'left' }} />
                            <Column field="TimeCollected" header="Timestamp" />
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </div>
        );
    }
}

//export default DatabaseConnectionComponent;
export default withAITracking(reactPlugin, DatabaseConnectionComponent);