import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import { InteractionType } from "@azure/msal-browser";
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import { MonitorService } from '../MonitorService'
import AlertMessage from "../../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import Spinner from '../../Spinner'
import { Tooltip } from 'primereact/tooltip';
import './QueueMonitor.css';

class QueueMonitorComponent extends Component {
    constructor() {
        super();
        this._isMirrorsMounted = false;
        this.state = {
            QueuesList: [],
            SystemList: [],
            InstanceList: [],
            NamespaceList: [],
            hideSpinner: false,
            pageErrorMessage: '',
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'System': { value: null, matchMode: FilterMatchMode.IN },
                'Instance': { value: null, matchMode: FilterMatchMode.IN },
                'Namespace': { value: null, matchMode: FilterMatchMode.IN },
                'Name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            },
            selectedAutoRefresh: 0,
            intervalId: null,
            autoRefreshOptions: [
                { label: 'Auto-Refresh Off', value: 0 },
                { label: '1 minute', value: 60000 },
                { label: '5 minute', value: 300000 },
                { label: '15 minute', value: 900000 },
                { label: '30 minute', value: 1800000 }
            ]
        };

        this.baseAPIUrl = global.ENV_HIE_ADMIN_API_AZURE_URL;
        this.getQueueMonitorData = this.getQueueMonitorData.bind(this)
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.globalSearchFilter = this.globalSearchFilter.bind(this);
        this.monitorService = new MonitorService();
    }

    componentDidMount() {
        this._isMirrorsMounted = true;
        this.getQueueMonitorData();
    }

    getQueueMonitorData() {
        this.setState({ hideSpinner: false })
        this.monitorService.getQueueMonitorData(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMirrorsMounted) {
                        this.setState({
                            QueuesList: d.body,
                            SystemList: [...new Set(d.body.map(x => x.System))],
                            InstanceList: [...new Set(d.body.map(x => x.Instance))],
                            NamespaceList: [...new Set(d.body.map(x => x.Namespace))].sort((a, b) => { return a > b ? 1 : -1 }),
                            hideSpinner: true
                        });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    clearDataTableFilters = () => {
        this.setState({
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'System': { value: null, matchMode: FilterMatchMode.IN },
                'Instance': { value: null, matchMode: FilterMatchMode.IN },
                'Namespace': { value: null, matchMode: FilterMatchMode.IN },
                'Name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            }
        });
        sessionStorage.clear();
    }

    //HM-935 - change to save the filter
    onGlobalFilterChange(event, filtersKey) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter(filtersKey) {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)}
                            placeholder="Global Search" style={{ width: '100%' }} size="100" />
                    </span>
                    <span>
                        <Dropdown value={this.state.selectedAutoRefresh} options={this.state.autoRefreshOptions} onChange={this.onAutoRefresh} placeholder="Auto-Refresh"
                            tooltip='Auto-Refresh' tooltipOptions={{ position: 'top' }} className="ui-column-filter" />
                        <Button hidden={this.state.selectedAutoRefresh !== 0} tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.getQueueMonitorData} icon="pi pi-refresh" />
                    </span>
                </div>
            </div>
        )
    }

    systemFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.SystemList}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            placeholder="System" className="multiselect-custom"
        />;
    }

    instanceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.InstanceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="Instance"
        />;
    }

    namespaceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.NamespaceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="Namespace" className="multiselect-custom"
        />;
    }

    formatSeconds(rowData, column) {

        let totalseconds = rowData[column.field];

        if (rowData.MetricType === 106)
            return totalseconds > 0 ? "DLQ - " + totalseconds : totalseconds

        if (totalseconds >= 0) {
            var day = 86400;
            var hour = 3600;
            var minute = 60;
            var daysout = Math.floor(totalseconds / day);
            var hoursout = Math.floor((totalseconds - daysout * day) / hour);
            var minutesout = Math.floor((totalseconds - daysout * day - hoursout * hour) / minute);
            var secondsout = Math.floor(totalseconds - daysout * day - hoursout * hour - minutesout * minute);
            return daysout + "d " + hoursout + "h " + minutesout + "m " + secondsout + "s"
        }
        else
            return "0d 0h 0m 0s"
    }

    getUrlSessionId(rowData) {
        let url = `https://${rowData.Instance.toLowerCase()}.nshs.edu/csp/healthshare/${rowData.Namespace}/EnsPortal.VisualTrace.zen?SESSIONID=${rowData.SessionId}`;
        return <a href={url} target="_blank">{rowData.SessionId}</a>;
    }

    getQueueItemStatus = (rowData) => {
        if (rowData.Enabled && rowData.ItemStatus === "ENABLED") {
            return <span className={`issue-status-badge status-enabled`}>{rowData.ItemStatus}</span>;
        }
        else if (!rowData.Enabled) {
            return <span className={`issue-status-badge status-disabled`}>{rowData.ItemStatus}</span>;
        }
        else if (rowData.ItemStatus === "RETRY") {
            return <span className={`issue-status-badge status-retry`}>{rowData.ItemStatus}</span>;
        }
        else if (rowData.ItemStatus)
            return <span className={`issue-status-badge status-disabled`}>{rowData.ItemStatus}</span>;
        else
            return "N.A"
    }

    getLastErrorLogged(rowData) {
        let url = `https://${rowData.Instance.toLowerCase()}.nshs.edu/csp/healthshare/${rowData.Namespace}/EnsPortal.VisualTrace.zen?SESSIONID=${rowData.SessionId}`;
        if (rowData.TimeLogged || rowData.ErrorText || rowData.SessionId) {
            return <div>
                <span>{rowData.ErrorText} - <a href={url} target="_blank">{rowData.SessionId}</a><br /><br /><p>TimeLogged - {rowData.TimeLogged}</p>
                </span>
            </div>
        }
        else
            return ""
    }

    getCacheProductionUrl = (rowData) => {
        let url = null
        if (rowData.OSVersion === "AIX") {
            if (rowData.Platform === "ENSEMBLE" || rowData.Platform === "HEALTHSHARE")
                url = `https://${rowData.Instance.toLocaleLowerCase()}.nshs.edu/csp/healthshare/${rowData.Namespace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
            else
                url = `https://${rowData.Server.toLocaleLowerCase()}.nshs.edu/${rowData.Instance.toLocaleLowerCase()}/csp/${rowData.Namespace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
        }
        else if (rowData.OSVersion === "LINUX") { //HM-1149 - fix
            url = `https://${rowData.Instance.toLocaleLowerCase()}.mid.northwell.edu/csp/healthshare/${rowData.Namespace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
        }
        return <a href={url} target="_blank">{rowData.Namespace}</a>;
    }

    onAutoRefresh = (e) => {
        let interval = {}
        let selectedAutoRefresh = e.value;
        clearInterval(this.state.intervalId);

        if (e.value && e.value > 0) {
            interval = setInterval(() => {
                //console.log('Logs every - ' + selectedAutoRefresh + ' ms - ' + new Date());
                this.getQueueMonitorData();
            }, selectedAutoRefresh);
        }
        this.setState({ selectedAutoRefresh: e.value, intervalId: interval })
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="adminContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}

                    <div hidden={this.state.hideSpinner}><Spinner /></div>

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">QUEUES
                                &nbsp;<Tooltip target=".custom-target-icon" />
                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                    data-pr-tooltip="DLQ - Dead-letter queue in azure service bus."
                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                            </div>
                        </header>
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.QueuesList}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            dataKey="Id"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            showGridlines removableSort
                            emptyMessage="No Records found."
                            onFilter={(e) => this.setState({ filters: e.filters })} //HM-935 - change to save the filter
                            sortField='Qcount' sortOrder="-1"
                            stateStorage="session" stateKey="dt-monitor-queues-session"
                        >
                            {/* <Column field="System" header="System" filter filterElement={this.systemFilterTemplate} showFilterMatchModes={false} filterPlaceholder='System' showFilterOperator={false} style={{ textAlign: 'left' }} /> */}
                            <Column field="Instance" header="Instance" filter filterElement={this.instanceFilterTemplate} showFilterMatchModes={false} filterPlaceholder='Instance' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            <Column field="Namespace" header="NameSpace" body={this.getCacheProductionUrl} filter filterElement={this.namespaceFilterTemplate} showFilterMatchModes={false} filterPlaceholder='NameSpace' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            <Column field="Name" header="Queue Name" filter filterPlaceholder='Queue Name' showFilterOperator={false} style={{ textAlign: 'left' }} />
                            <Column field="Qcount" header="Count" style={{ textAlign: 'left' }} sortable />
                            <Column field="LastMessageDelay" header="Delay" style={{ textAlign: 'left', minWidth: '100px' }} body={this.formatSeconds} sortable sortField='LastMessageDelay' />
                            <Column field="LastMessageDelayDateTimeDisplay" header="First Queued Message" style={{ textAlign: 'left' }} sortable sortField='LastMessageDelayDateTime' />
                            <Column field="ErrTimeLoggedDisplay" header="Latest Error" style={{ textAlign: 'left' }} sortable sortField='TimeLogged' />
                            <Column field="ItemEnabled" header="Status" body={this.getQueueItemStatus} style={{ textAlign: 'left' }} />
                            <Column field="TimeCollectedDisplay" header="Time Collected" style={{ textAlign: 'left' }} sortable sortField='TimeCollected' />
                        </DataTable>
                    </div>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
};
const WrappedQueueMonitorComponentComponent = withMsal(QueueMonitorComponent);
export default withAITracking(reactPlugin, WrappedQueueMonitorComponentComponent);