import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { InteractionType } from "@azure/msal-browser";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { ProdItemSettingService } from './ProdItemSettingSerivce'
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import Spinner from '../Spinner'
import './ProdItemSetting.css'

//HM-920 - UI show prod item settings and there ip port info
class IpPortList extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            ProdItemSettingsList: [],
            SelectedInterface: {},
            InstanceList: [],
            NamespaceList: [],
            hideSpinner: false,
            pageErrorMessage: '',
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            settingInHtml: null,
            visibleInterfaceDetails: false,
            disableSearchButton: false,

            IPPortListSelectedInstance: sessionStorage.getItem("IPPortListSelectedInstance") ?? '',
            IPPortListSelectedNameSpace: sessionStorage.getItem("IPPortListSelectedNameSpace") ?? '',
            ItemNameInputIPPortList: sessionStorage.getItem("ItemNameInputIPPortList") ?? '',
            IpAddressInput: sessionStorage.getItem("IpAddressInput") ?? '',
            PortNumberInput: sessionStorage.getItem("PortNumberInput") ?? '',
            SelectedItemType: '',

            itemTypes: [
                { label: 'OPERATION', value: 'OPERATION' },
                { label: 'PROCESS', value: 'PROCESS' },
                { label: 'SERVICE', value: 'SERVICE' }
            ]
        };

        this.baseAPIUrl = global.ENV_HIE_ADMIN_API_AZURE_URL;
        this.getProductionItemSettings = this.getProductionItemSettings.bind(this)
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.globalSearchFilter = this.globalSearchFilter.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.prodItemSettingService = new ProdItemSettingService();
    }

    componentDidMount() {
        this._isMounted = true;
        //this.getHIEInstances();
        this.getProductionItemSettings();
    }

    getHIEInstances() {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.prodItemSettingService.getHIEInstances("CACHE", this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.InstanceList.push({ label: item.ServerInstance, value: item.ServerInstance })));
                        this.setState({ hideSpinner: true });
                        this.getProductions(['ALL'])
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getProductions(instance) {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

        this.prodItemSettingService.getProductions(instance, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.NamespaceList.push({ label: item.Namespace, value: item.Namespace })));
                        this.setState({ hideSpinner: true, disableNameSpaceDropDown: false });
                        this.getProductionItemSettings();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getProductionItemSettings() {
        this.setState({ hideSpinner: false })
        this.prodItemSettingService.getProductionItemSettings(this.state.IPPortListSelectedInstance, this.state.IPPortListSelectedNameSpace, this.state.ItemNameInputIPPortList,
            this.state.IpAddressInput, this.state.PortNumberInput, this.state.SelectedItemType, this.props.msalContext).then(
                (d) => {
                    if (d.status === 403) {
                        this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                    } else {
                        if (this._isMounted) {
                            this.setState({
                                ProdItemSettingsList: d.body,
                                hideSpinner: true
                            });
                        }
                    }
                }
            ).catch(e => {
                console.log(e)
            });
    }

    handleInputChange = (event) => {
        sessionStorage.setItem([event.target.name], event.target.value);
        this.setState({ [event.target.name]: event.target.value, pageErrorMessage: null, pageSuccessMessage: null })
    }

    validateInputFields = () => {
        this.setState({ pageErrorMessage: '', pageSuccessMessage: '' })

        var itemNameInputCheck = /^[^'"]*$/.test(this.state.ItemNameInputIPPortList);
        var ipAddressInputCheck = /^[^'"]*$/.test(this.state.IpAddressInput);
        var portNumberInputCheck = /^[^'"]*$/.test(this.state.PortNumberInput);

        if (this.state.ItemNameInputIPPortList.trim().length > 0 && this.state.ItemNameInputIPPortList.trim().length < 5) {
            this.setState({ pageErrorMessage: 'Interface Name should be greater than 5 characters.' })
            return false;
        }
        else if (this.state.ItemNameInputIPPortList.trim().length > 500) {
            this.setState({ pageErrorMessage: 'Interface Name cannot be more than 500 characters.' })
            return false;
        }
        else if (this.state.IpAddressInput.trim().length > 500) {
            this.setState({ pageErrorMessage: 'IP Address cannot be more than 500 characters.' })
            return false;
        }
        else if (this.state.PortNumberInput.trim().length > 500) {
            this.setState({ pageErrorMessage: 'Port cannot be more than 500 characters.' })
            return false;
        }
        else if (!itemNameInputCheck) {
            this.setState({ pageErrorMessage: "Interface Name should not contain single (') or double (\") quotes." })
            return false;
        }
        else if (!ipAddressInputCheck) {
            this.setState({ pageErrorMessage: "IP Address should not contain single (') or double (\") quotes." })
            return false;
        }
        else if (!portNumberInputCheck) {
            this.setState({ pageErrorMessage: "Port should not contain single (') or double (\") quotes." })
            return false;
        }
        else
            this.getProductionItemSettings()
        //this.setState({ disableSearchButton: false })
        return true;
    }

    getProdItemStatus(rowData) {
        if (rowData.Enabled && rowData.ItemStatus === "ENABLED") {
            return <span className={`issue-status-badge status-enabled`}>{rowData.ItemStatus}</span>;
        }
        else if (!rowData.Enabled) {
            return <span className={`issue-status-badge status-disabled`}>{rowData.ItemStatus}</span>;
        }
        else if (rowData.ItemStatus === "RETRY") {
            return <span className={`issue-status-badge status-retry`}>{rowData.ItemStatus}</span>;
        }
        else if (rowData.ItemStatus)
            return <span className={`issue-status-badge status-disabled`}>{rowData.ItemStatus}</span>;
        else
            return "N.A"
    }

    rowClassStyle = (rowData) => {
        return rowData.ItemStatus === "ERROR" ? 'error-highlight' : ''
    }

    clearSearchParams = () => {
        this.setState({
            IPPortListSelectedInstance: '',
            IPPortListSelectedNameSpace: '',
            ItemNameInputIPPortList: '',
            IpAddressInput: '',
            PortNumberInput: '',
            SelectedItemType: '',
        });
        sessionStorage.clear();
    }

    // clearDataTableFilters = () => {
    //     this.setState({
    //         filters: {
    //             'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    //         }
    //     });
    //     sessionStorage.clear();
    // }

    //HM-935 - change to save the filter
    onGlobalFilterChange(event, filtersKey) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter(filtersKey) {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <div></div>
                    {/* <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} /> */}
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                    <Button tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.getProductionItemSettings} icon="pi pi-refresh" />
                </div>
            </div>
        )
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    onRowClick(e) {
        if (e.data.Settings) {
            try {
                let jsonSetting = []
                let parsedJson = JSON.parse(e.data.Settings)
                jsonSetting.push(parsedJson)
                var convertSettingsToHtml = jsonSetting.map((Member) => {
                    let keys = Object.keys(Member)
                    let divs = keys.map(k => <><tr><td class="fluidcell">{k}</td><td class="fluidcell">{Member[k]}</td></tr></>)
                    return (
                        <>{divs}</>
                    )
                });
            } catch (error) { }
        }
        this.setState({ SelectedInterface: e.data, visibleInterfaceDetails: true, settingInHtml: convertSettingsToHtml });
    }


    getCacheProductionUrl = (rowData) => {
        let url = null
        if (rowData.OSVersion === "AIX") {
            if (rowData.Platform === "ENSEMBLE" || rowData.Platform === "HEALTHSHARE")
                url = `https://${rowData.Instance.toLocaleLowerCase()}.nshs.edu/csp/healthshare/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
            else
                url = `https://${rowData.Server.toLocaleLowerCase()}.nshs.edu/${rowData.Instance.toLocaleLowerCase()}/csp/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
        }
        else if (rowData.OSVersion === "LINUX") { //HM-1149 - fix
            url = `https://${rowData.Instance.toLocaleLowerCase()}.mid.northwell.edu/csp/healthshare/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
        }
        return <a href={url} target="_blank">{rowData.NameSpace}</a>;
    }

    showNAIfNoData = (rowData, column) => {
        let value = rowData[column.field];

        if (value)
            return rowData[column.field];
        else
            return "N.A"
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="adminContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}

                    <div hidden={this.state.hideSpinner}><Spinner /></div>

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">IP PORT LIST
                                &nbsp;<Tooltip target=".custom-target-icon" />
                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                    data-pr-tooltip="Below data get's refreshed in 15 minutes. Double click on a record to view full settings."
                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i></div>
                        </header>


                        <div className="card">
                            <div className="float-container">
                                {/* <div className="float-child">
                                    <Dropdown className="ui-column-filter" placeholder="Select Instance" value={this.state.IPPortListSelectedInstance}
                                        options={this.state.InstanceList} filter onChange={(e) => this.setState({ IPPortListSelectedInstance: e.value })} />
                                </div>
                                <div className="float-child">
                                    <Dropdown className="ui-column-filter" placeholder="Select NameSpace" value={this.state.IPPortListSelectedNameSpace}
                                        options={this.state.NamespaceList} filter onChange={(e) => this.setState({ IPPortListSelectedNameSpace: e.value })} />
                                </div> */}
                                <div className="float-child">
                                    <InputText type='text' placeholder='Interface Name' name='ItemNameInputIPPortList' value={this.state.ItemNameInputIPPortList} onChange={this.handleInputChange} />
                                </div>
                                <div className="float-child">
                                    <InputText type='text' placeholder='IP Address' name='IpAddressInput' value={this.state.IpAddressInput} onChange={this.handleInputChange} />
                                </div>
                                <div className="float-child">
                                    <InputText type='text' placeholder='Port' name='PortNumberInput' value={this.state.PortNumberInput} onChange={this.handleInputChange} />
                                </div>
                                {/* <div className="float-child">
                                    <Dropdown className="ui-column-filter" placeholder="Select Type" value={this.state.SelectedItemType} options={this.state.itemTypes} onChange={(e) => this.setState({ SelectedItemType: e.value })} />
                                </div> */}
                                <div className="float-child">
                                    <Button disabled={this.state.disableSearchButton} label='Search' onClick={() => this.validateInputFields()} />
                                </div>
                                <div className="float-child">
                                    <Button label='Clear' onClick={this.clearSearchParams} />
                                </div>
                            </div>
                        </div>
                        <br />
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.ProdItemSettingsList}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            dataKey="Id"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            //header={this.globalSearchFilter('filters')}
                            showGridlines removableSort
                            rowClassName={this.rowClassStyle}
                            emptyMessage="No Records found."
                            onFilter={(e) => this.setState({ filters: e.filters })} //HM-935 - change to save the filter
                            stateStorage="session" stateKey="dt-ipport-session"
                            onRowDoubleClick={this.onRowClick}
                        >
                            <Column field="Server" header="Server" style={{ textAlign: 'left' }} />
                            <Column field="Instance" header="Instance" style={{ textAlign: 'left' }} />
                            <Column field="NameSpace" body={this.getCacheProductionUrl} header="NameSpace" style={{ textAlign: 'left' }} />
                            <Column field="Name" header="Name" style={{ textAlign: 'left' }} />
                            <Column field="ComponentType" header="Type" style={{ textAlign: 'left' }} />
                            <Column field="Ipaddress" header="IP Address" style={{ textAlign: 'left' }} body={this.showNAIfNoData} />
                            <Column field="Port" header="Port" style={{ textAlign: 'left' }} body={this.showNAIfNoData} />
                            <Column field="Enabled" header="Status" body={this.getProdItemStatus} style={{ textAlign: 'left' }} />
                        </DataTable>

                        <Dialog header={'Interface - ' + this.state.SelectedInterface.Name} style={{ width: '50vw' }}
                            visible={this.state.visibleInterfaceDetails} modal={true}
                            onHide={e => this.setState({ visibleInterfaceDetails: false })} maximizable={true} closeOnEscape={true}>
                            <div>
                                <table class="fixedtablelayout" border={1}>
                                    <th colSpan={2} class="th-details">General Information</th>
                                    <tr><td>Type:</td><td>{this.state.SelectedInterface.ComponentType || "N.A"}</td></tr>
                                    <tr><td>Description:</td><td class="fluidcell">{this.state.SelectedInterface.Description || "N.A"}</td></tr>
                                    <tr><td>Production:</td><td class="fluidcell">{this.state.SelectedInterface.ProductionName || "N.A"}</td></tr>
                                    <tr><td>Class Name:</td><td class="fluidcell">{this.state.SelectedInterface.ClassName || "N.A"}</td></tr>
                                    <tr><td>PoolSize:</td><td>{this.state.SelectedInterface.PoolSize || "N.A"}</td></tr>
                                    <tr><td>Schedule:</td><td>{this.state.SelectedInterface.Schedule || "N.A"}</td></tr>
                                    <tr><td>TimeCollected:</td><td>{this.state.SelectedInterface.DisplayTimeCollected || "N.A"}</td></tr>
                                </table>
                            </div>
                            {this.state.settingInHtml ? <><br></br><table class="fixedtablelayout" border={1}>
                                <th colSpan={2} class="th-details">Settings</th>{this.state.settingInHtml}</table></> : <></>}
                        </Dialog>
                    </div>
                </div >
            </MsalAuthenticationTemplate >
        );
    }
};
const WrappedIpPortListComponent = withMsal(IpPortList);
export default withAITracking(reactPlugin, WrappedIpPortListComponent);