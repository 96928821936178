import { msalInstance } from '../../App';

export class IACLService {

    async invokeWebAPI(url, httpMethod, msalContext, body = "", rawResponse = false) {

        // check if msalContext exists
        if (msalContext.accounts.length > 0) {
            const tokenRequest = {
                scopes: [global.ENV_MSAL_LOGIN_REQUEST_SCOPES],
                account: msalContext.accounts[0],
            };

            // acquire Bearer token to include in GET request
            const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
            const token = tokenResponse.accessToken;
            const bearer = `Bearer ${token}`;
            const headers = new Headers();
            headers.append('Accept', '*/*');
            headers.append('Content-Type', 'application/json');
            headers.append('x-northwell-version', '1.0');
            headers.append("Authorization", bearer);
            headers.append('User', msalContext.accounts[0].username)

            var options = {}
            if (body) {
                options = {
                    method: httpMethod,
                    headers: headers,
                    body: body
                };
            }
            else {
                options = {
                    method: httpMethod,
                    headers: headers
                };
            }

            let response = await fetch(url, options);

            if (rawResponse)
                return response;
            else
                return {
                    status: response.status,
                    body: await response.json()
                };

        } else {
            console.log('user not logged in')
        }
    }

    //#region IACL operations
    async getIACLHomeData(body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/iacl/home"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async exportIACLHomeData(body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/iacl/home/export"

        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body, true);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getIaclSearchParameters(msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/iacl/home/search/parameters"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getIaclMasterData(msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/iacl/masterdata"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getIaclRecordById(Id, isEdit, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/iacl/" + Id + "/" + isEdit
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async createIACLRecord(body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/iacl/create"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async editIACLRecord(id, body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/iacl/update/" + id
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async deleteIACLRecord(id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/iacl/" + id
        try {
            const response = await this.invokeWebAPI(url, 'DELETE', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getIACLAuditHistory(Id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/iacl/auditlogs/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    //#region Site names operations
    async IsSiteNameExists(siteName, id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/sitename/exists?siteName=" + siteName + "&id=" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getAllSiteName(onlyActive, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/sitename?onlyActive=" + onlyActive
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getSiteNameOpsAuditLog(id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/sitename/logs/" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async createSiteName(body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/sitename"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async updateSiteName(Id, body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/sitename/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async deleteSiteName(Id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/sitename/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'DELETE', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    //#region Connection type operations
    async IsConnTypeExists(connType, id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/connectiontype/exists?connType=" + connType + "&id=" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getAllConnectionType(onlyActive, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/connectiontype?onlyActive=" + onlyActive
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getConnTypeOpsAuditLog(id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/connectiontype/logs/" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async createConnectionType(body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/connectiontype"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async updateConnectionType(Id, body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/connectiontype/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async deleteConnectionType(Id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/connectiontype/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'DELETE', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    //#region Message content operations
    async IsMessageContentExists(msgContent, id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagecontent/exists?msgContent=" + msgContent + "&id=" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getAllMessageContent(onlyActive, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagecontent?onlyActive=" + onlyActive
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getMsgContentOpsAuditLog(id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagecontent/logs/" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async createMessageContent(body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagecontent"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async updateMessageContent(Id, body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagecontent/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async deleteMessageContent(Id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagecontent/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'DELETE', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    //#region Message type operations
    async IsMessageTypeExists(msgType, id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagetype/exists?msgType=" + msgType + "&id=" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getAllMessageType(onlyActive, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagetype?onlyActive=" + onlyActive
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getMsgTypeOpsAuditLog(id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagetype/logs/" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async createMessageType(body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagetype"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async updateMessageType(Id, body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagetype/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async deleteMessageType(Id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/messagetype/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'DELETE', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    //#region CA Group name operations
    async IsCaGroupNameExists(caGrpName, id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/cagroupname/exists?caGrpName=" + caGrpName + "&id=" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getAllCaGroupName(onlyActive, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/cagroupname?onlyActive=" + onlyActive
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getCaGrpNameOpsAuditLog(id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/cagroupname/logs/" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async createCaGroupName(body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/cagroupname"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async updateCaGroupName(Id, body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/cagroupname/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async deleteCaGroupName(Id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/cagroupname/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'DELETE', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    //#region Application operations
    async IsApplicationExists(applicationName, id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/applicaion/exists?applicationName=" + applicationName + "&id=" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getAllApplication(onlyActive, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/applicaion?onlyActive=" + onlyActive
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async getApplicationOpsAuditLog(id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/applicaion/logs/" + id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async createApplication(body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/applicaion"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async updateApplication(Id, body, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/applicaion/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }

    async deleteApplication(Id, msalContext) {
        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/applicaion/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'DELETE', msalContext);
            return response;
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

}