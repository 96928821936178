import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import { IACLService } from '../IACLService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import Spinner from "../../Spinner";
import AlertMessage from "../../alertmessage/AlertMessage"
import { MessageSeverity, FilterMatchMode, FilterOperator } from 'primereact/api';
import * as Yup from 'yup';

class ConnectionTypeComponent extends Component {
    constructor() {
        super();
        this.WrappedConnectionTypeComponent = React.createRef();
        this._isMounted = false;
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            connectionTypeList: [],
            connectionType: {
                Id: 0,
                ConnectionType: null,
                IsActive: false
            },
            ConnectionTypeAuditLogs: [],
            hideSpinner: false,
            showpagination: false,
            editConnTypeDialogBoxVisible: false,
            createConnTypeDialogBoxVisible: false,
            confirmDeleteConnTypeDialogBoxVisible: false,
            connTypeAuditLogDialogBoxVisible: false,
            confirmDeleteVisible: false,
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            globalFilterValue: '',
        }
        this.IACLService = new IACLService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getAllConnectionType();
    }

    getAllConnectionType = () => {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' });
        this.IACLService.getAllConnectionType(false, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({ connectionTypeList: d.body, hideSpinner: true, pageSuccessMessage: '', pageErrorMessage: '' });
                        if (this.state.connectionTypeList.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getConnTypeOpsAuditLog = (rowData) => {
        this.setState({ connectionType: { ...rowData }, hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' });
        this.IACLService.getConnTypeOpsAuditLog(rowData.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({ ConnectionTypeAuditLogs: d.body, hideSpinner: true, connTypeAuditLogDialogBoxVisible: true, pageSuccessMessage: '', pageErrorMessage: '' });
                        if (this.state.ConnectionTypeAuditLogs.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    editConnectionType = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-book" className="p-button-rounded p-button-outlined" tooltip="Logs" tooltipOptions={{ position: 'top' }} onClick={(e) => this.getConnTypeOpsAuditLog(rowData)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" tooltip="Edit" tooltipOptions={{ position: 'top' }} onClick={() => this.showEditConnTypeDialog(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" tooltip="Delete" tooltipOptions={{ position: 'top' }} onClick={e => this.setState({ confirmDeleteConnTypeDialogBoxVisible: true, pageErrorMessage: null, pageSuccessMessage: null, connectionType: { ...rowData } })} />
            </React.Fragment>
        );
    }

    showEditConnTypeDialog = (rowData) => {
        this.setState({ connectionType: { ...rowData }, editConnTypeDialogBoxVisible: true, pageSuccessMessage: '', pageErrorMessage: '' })
    }

    showConnTypeDialogBoxForCreate = () => {
        this.clearRowSelection();
        this.setState({ createConnTypeDialogBoxVisible: true, pageSuccessMessage: '', pageErrorMessage: '' })
    }

    clearRowSelection() {
        this.setState({
            connectionType: {
                Id: 0,
                ConnectionType: null,
                IsActive: null
            },
        })
    }

    handleInputChange = (event) => {
        this.setState({
            connectionType: {
                ...this.state.connectionType, //using spread operator to initialize object with existing props
                [event.target.name]: event.target.value
            },
            pageErrorMessage: null
        });
    }

    validateEditConnTypeDialogFormField = async (connectionType, onSave = false, isCreate = false) => {

        if (onSave || isCreate) {
            try {
                let connTypeSchema = Yup.object({
                    ConnectionType: Yup.string().required("Connection type is required.")
                        .max(50, "Connection type cannot be more than 50 characters.")
                        .matches(/^[^'"]*$/, "Connection type should not contain single (') or double (\") quotes.")
                });
                await connTypeSchema.validate(connectionType, { abortEarly: false })

                this.setState({ pageErrorMessage: '' });
                this.IsConnTypeExists(isCreate);
            }
            catch (error) {
                this.setState({ pageErrorMessage: error.inner[0].message });
            }
        }
    }

    IsConnTypeExists(isCreate = false) {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' })
        this.IACLService.IsConnTypeExists(this.state.connectionType.ConnectionType, this.state.connectionType.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (d.status === 200 && d.body) {
                        this.setState({ pageErrorMessage: 'The connection type provided already exists. Please use a different one.', hideSpinner: true });
                    }
                    else {
                        if (isCreate)
                            this.createConnectionType();
                        else
                            this.updateConnectionType();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    createConnectionType() {
        let body = JSON.stringify(this.state.connectionType);
        this.IACLService.createConnectionType(body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted && d.status === 200) {
                        this.getAllConnectionType();
                        this.setState({ pageSuccessMessage: 'Connection type created successfully', createConnTypeDialogBoxVisible: false, hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    updateConnectionType() {
        let body = JSON.stringify(this.state.connectionType);
        this.IACLService.updateConnectionType(this.state.connectionType.Id, body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                } else {
                    if (this._isMounted && d.status === 200) {
                        this.getAllConnectionType();
                        this.setState({ pageSuccessMessage: 'Changes saved successfully.', editConnTypeDialogBoxVisible: false, hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    saveFooter = (
        <div>
            <Button label='Save' onClick={() => this.validateEditConnTypeDialogFormField(this.state.connectionType, true)} />
            <Button label='Cancel' onClick={e => this.setState({ editConnTypeDialogBoxVisible: false, pageErrorMessage: null })} className='p-button-secondary' />
        </div>
    );

    createFooter = (
        <div>
            <Button label='Create' onClick={() => this.validateEditConnTypeDialogFormField(this.state.connectionType, true, true)} />
            <Button label='Cancel' onClick={e => this.setState({ createConnTypeDialogBoxVisible: false, pageErrorMessage: null })} className='p-button-secondary' />
        </div>
    );

    deleteConnectionType() {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, hideSpinner: false })
        this.IACLService.deleteConnectionType(this.state.connectionType.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to perform this operation.', confirmDeleteConnTypeDialogBoxVisible: false, hideSpinner: true });
                } else if (d.status === 200 && d.body) {
                    this.setState({ pageSuccessMessage: 'Connection type deleted successfully.', confirmDeleteConnTypeDialogBoxVisible: false, hideSpinner: true });
                    this.getAllConnectionType();
                } else if (d.status === 500 && d.body) {
                    this.setState({ pageErrorMessage: d.body.error, confirmDeleteConnTypeDialogBoxVisible: false, hideSpinner: true });
                } else {
                    this.setState({ pageErrorMessage: 'Something went wrong', confirmDeleteConnTypeDialogBoxVisible: false, hideSpinner: true });
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    confirmDeleteFooter = (
        <div>
            <Button label='Ok' onClick={() => this.deleteConnectionType()} />
            <Button label='Cancel' onClick={e => this.setState({ confirmDeleteConnTypeDialogBoxVisible: false })}
                className='p-button-secondary' />
        </div>
    );

    onGlobalFilterChange = (event, filtersKey) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter = (filtersKey) => {
        return (

            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                    <span>
                        <Button tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.getAllConnectionType} icon="pi pi-refresh" />
                    </span>
                </div>
            </div>
        )
    }

    clearDataTableFilters = () => {
        this.setState({
            pageErrorMessage: null,
            pageSuccessMessage: null,
            pageErrorMessage: null,
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            globalFilterValue: '',

        })
        sessionStorage.clear();
    }

    booleanChecker = (rowData) => {
        if (rowData.IsActive) {
            return <span className='masterdata-status-badge status-active'>ACTIVE</span>;
        }
        else {
            return <span className='masterdata-status-badge status-inactive'>INACTIVE</span>;
        }
    };

    onStatusSwitchChange = (e) => {
        this.setState({ connectionType: { ...this.state.connectionType, IsActive: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    showAlertMessage = (severity, alertMsg) => {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="connTypeContainer">
                    <BreadCrumbComponent {...this.props} />

                    <AlertMessage ref={instance => { this.alertMsg = instance }} />
                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <div className='flex justify-content-between' style={{ marginBottom: '10px' }}>
                            <div className="page-header-title">CONNECTION TYPES</div>
                            <Button label='' icon='pi pi-plus' tooltip='Create Connection Type' tooltipOptions={{ position: 'left' }} onClick={this.showConnTypeDialogBoxForCreate} />
                        </div>

                        <DataTable ref={(el) => this.dt = el} value={this.state.connectionTypeList}
                            selectionMode="single"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            paginator={this.state.showpagination}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            emptyMessage="No Records found."
                            showGridlines={true}
                            onFilter={(e) => this.setState({ filters: e.filters })}
                            stateStorage="session" stateKey="dt-connectionType-session"
                        >
                            <Column field="ConnectionType" header="Connection Type" style={{ textAlign: 'left' }} />
                            <Column field="Status" header="Status" style={{ textAlign: 'left' }} body={this.booleanChecker} />
                            <Column field="edit" header="Action" body={this.editConnectionType} style={{ whiteSpace: 'nowrap', width: '150px', textAlign: 'center' }} alignHeader='center' />
                        </DataTable>
                    </div>

                    <Dialog header='Edit Connection Type' footer={this.saveFooter}
                        visible={this.state.editConnTypeDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ editConnTypeDialogBoxVisible: false, pageErrorMessage: null })} maximizable={false}>
                        <div>
                            {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                            <table style={{ width: '400px' }}>
                                <tr>
                                    <td>
                                        <label><b>Enable</b></label>
                                        <InputSwitch checked={this.state.connectionType.IsActive} onChange={this.onStatusSwitchChange}
                                            tooltip={this.state.connectionType.IsActive ? 'YES' : 'NO'} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Connection Type</b></label>
                                        <InputText type='text' name='ConnectionType' value={this.state.connectionType.ConnectionType || ''}
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Dialog>

                    <Dialog header='Create Connection Type' footer={this.createFooter}
                        visible={this.state.createConnTypeDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ createConnTypeDialogBoxVisible: false, pageErrorMessage: null })} maximizable={false}>
                        <div>
                            {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                            <table style={{ width: '400px' }}>
                                <tr>
                                    <td>
                                        <label><b>Enable</b></label>
                                        <InputSwitch checked={this.state.connectionType.IsActive} onChange={this.onStatusSwitchChange}
                                            tooltip={this.state.connectionType.IsActive ? 'YES' : 'NO'} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Connection Type</b></label>
                                        <InputText type='text' name='ConnectionType' value={this.state.connectionType.ConnectionType || ''} required
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Dialog>

                    <Dialog header='Delete Connection Type' footer={this.confirmDeleteFooter}
                        visible={this.state.confirmDeleteConnTypeDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ confirmDeleteConnTypeDialogBoxVisible: false })} maximizable={false}>
                        <div>
                            Are you sure you want to delete the connection type <b>{this.state.connectionType.ConnectionType}</b>?
                        </div>
                    </Dialog>

                    <Dialog header={this.state.connectionType.ConnectionType + ' - Audit Logs'} style={{ width: '40vw' }}
                        visible={this.state.connTypeAuditLogDialogBoxVisible} modal={true}
                        onHide={e => this.setState({ connTypeAuditLogDialogBoxVisible: false, pageErrorMessage: null })} maximizable={false} closeOnEscape={true}>
                        <div>
                            <DataTable value={this.state.ConnectionTypeAuditLogs}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                filterDisplay="menu"
                                responsiveLayout="scroll"
                                showGridlines removableSort
                                emptyMessage="No Records found."
                                sortField='Timestamp' sortOrder="-1"
                            >
                                <Column field="DisplayTimestamp" header="Timestamp" style={{ textAlign: 'left' }} sortable sortField='Timestamp' />
                                <Column field="ActionType" header="Action" style={{ textAlign: 'left' }} />
                                <Column field="ActionBy" header="Action By" style={{ textAlign: 'left' }} />
                            </DataTable>
                        </div>
                    </Dialog>

                </div>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedConnectionTypeComponent = withMsal(ConnectionTypeComponent);
export default withAITracking(reactPlugin, WrappedConnectionTypeComponent);