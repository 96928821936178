import React, { Component } from 'react';
import "./IssuessComponent.css";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { NavLink } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { MessageSeverity, FilterMatchMode, FilterOperator } from 'primereact/api';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { IssuesDetailsService } from './IssuesDetailsService';
import AlertMessage from "../alertmessage/AlertMessage";
import Spinner from '../Spinner'

class IssuesComponent extends Component {
    constructor(props) {
        super(props);
        this._isIssueMounted = false;
        this.state = {
            IssueDetailsList: [],
            MetricNameList: [],
            PlatformList: ['ENSEMBLE', 'HIE', 'SAHI', 'OPENLINK', 'INFRASTRUCTURE SERVICES', 'GCP', 'AZURE'],
            hideSpinner: false,
            pageErrorMessage: null,
            pageSuccessMessage: null,
            showpagination: false,
            StatusName: '',
            commentBoxText: "",
            fromDate: sessionStorage.getItem("issue_page_fromDate") ? new Date(sessionStorage.getItem("issue_page_fromDate")) : null,
            toDate: sessionStorage.getItem("issue_page_toDate") ? new Date(sessionStorage.getItem("issue_page_toDate")) : new Date(),
            filterBtnDisable: false,
            dt: React.createRef(),
            selectedIssues: [],
            IssueDashboardCount: {},
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'Id': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'PriorityId': { value: null, matchMode: FilterMatchMode.IN },
                'ServerName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'MetricName': { value: null, matchMode: FilterMatchMode.IN },
                'Platform': { value: new URLSearchParams(window.location.search).get('Platform') ? [new URLSearchParams(window.location.search).get('Platform')] : null, matchMode: FilterMatchMode.IN },
                'StatusName': { value: ['NEW', 'ACKNOWLEDGED', 'ON-HOLD', 'ESCALATED'], matchMode: FilterMatchMode.IN }
            },
            loadBreadCrumbComponent: true,

            //state props for bulk update
            updateMultipleIssueDialog: false,
            updateMultipleIssueStatusId: 0,
            availableStatusForMultipleUpdate: [],
        };

        this.incidentStatuses = ['NEW', 'ACKNOWLEDGED', 'ON-HOLD', 'ESCALATED', 'RESOLVED'];
        this.priorityIdList = [1, 2, 3, 4];

        this.issuesDetailsService = new IssuesDetailsService();
    }

    componentDidMount() {
        this._isIssueMounted = true;
        this.onRefreshIssueDetailsData()
    }

    getIssueDetailsTableData(fromDate, toDate) {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })
        fromDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(fromDate)
        toDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(toDate)
        this.issuesDetailsService.getIssueDetailsTableData(fromDate, toDate, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isIssueMounted) {
                        this.setState({
                            loadBreadCrumbComponent: true,
                            IssueDetailsList: d.body,
                            MetricNameList: [...new Set(d.body.map(x => x.MetricName))].sort((a, b) => { return a > b ? 1 : -1 }),
                            //PlatformList: [...new Set(d.body.filter(x => x.Platform != null).map(x => x.Platform))].sort((a, b) => { return a > b ? 1 : -1 }), //Comemted to fix HDO-6692 and have initialized the data in constructor
                            hideSpinner: true,
                            pageErrorMessage: null, pageSuccessMessage: null, selectedIssues: []
                        });
                        if (this.state.IssueDetailsList.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            this.setState({ hideSpinner: true, pageErrorMessage: 'Something went wrong !!!', pageSuccessMessage: null })
            console.log(e)
        });
    }

    onRefreshIssueDetailsData = (e) => {
        this.setState({ loadBreadCrumbComponent: false, pageErrorMessage: "", pageSuccessMessage: "" })
        if (e) {
            var toDateLocal = new Date();
            var fromDateLocal = new Date(new Date().setDate(toDateLocal.getDate() - global.INCIDENT_MAX_DATE_RANGE + 1));
            this.setState({ toDate: toDateLocal, fromDate: fromDateLocal })
            this.getIssueDetailsTableData(fromDateLocal, toDateLocal);
        } else {
            if (sessionStorage.getItem("issue_page_fromDate")) {
                this.getIssueDetailsTableData(new Date(sessionStorage.getItem("issue_page_fromDate")), this.state.toDate);
            }
            else {
                var fromDateLocal = new Date(this.state.toDate);
                this.setState({ fromDate: new Date(fromDateLocal.setDate(fromDateLocal.getDate() - global.INCIDENT_MAX_DATE_RANGE + 1)) }) //+1 to include the same day - HM-693 fix
                this.getIssueDetailsTableData(fromDateLocal, this.state.toDate);
            }
        }
    }

    updateIssueDetails = (rowData) => {
        let redirectUrl = '/issue/' + rowData.Id
        if (rowData.StatusName === 'NEW') {
            return (
                <React.Fragment>
                    <NavLink key='Acknowledge' to={redirectUrl} target="_blank"><strong>ACKNOWLEDGE</strong></NavLink>
                </React.Fragment>
            );
        }
        else if (rowData.StatusName === 'RESOLVED') {
            return (
                <React.Fragment>
                    <NavLink key='View' to={redirectUrl} target="_blank"><strong>VIEW</strong></NavLink>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <NavLink key='Edit' to={redirectUrl} target="_blank"><strong>EDIT</strong></NavLink>
                </React.Fragment>
            );
        }
    }

    //#region HM-837/HM-939 - Bulk update code start
    updateMultipleIssueStatus = () => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        var onlyValidCharacters = /^[^\'"]*$/.test(this.state.commentBoxText); //HM-708 bug fix

        if (this.state.commentBoxText === '' || this.state.commentBoxText.trim().length == 0) {
            this.setState({ pageErrorMessage: 'Please provide your comments.' }); return;
        }
        else if (this.state.commentBoxText.trim().length < global.COMMENT_BOX_MIN_LENGTH) {
            this.setState({ pageErrorMessage: 'Comments should be atleast of ' + global.COMMENT_BOX_MIN_LENGTH + ' characters.' }); return;
        }
        // if (this.state.commentBoxText.trim().length > global.COMMENT_BOX_MAX_LENGTH) {
        //     this.setState({ pageErrorMessage: 'Please provide your comments within ' + global.COMMENT_BOX_MAX_LENGTH + ' characters.' }); return;
        // }
        // else 
        if (!onlyValidCharacters) { //HM-708 bug fix
            this.setState({ pageErrorMessage: "Comments should not contain single (') or double (\") quotes." }); return;
        }
        else if (this.state.updateMultipleIssueStatusId === 0 || this.state.updateMultipleIssueStatusId === '' || this.state.updateMultipleIssueStatusId === null) {
            this.setState({ pageErrorMessage: "Please select the required status." }); return;
        }
        else {
            this.setState({ hideSpinner: false, loadBreadCrumbComponent: false })
            let requestBody = {
                Ids: this.state.selectedIssues.map(x => x.Id),
                UserID: this.props.msalContext.accounts[0].username,
                Comments: this.state.commentBoxText.trim(),
                Status: this.state.updateMultipleIssueStatusId
            }
            let changedStatusLabel = this.state.availableStatusForMultipleUpdate.filter(x => x.value === this.state.updateMultipleIssueStatusId).map(k => k.label)[0];
            this.issuesDetailsService.updateMultipleNotificationStatus(JSON.stringify(requestBody), this.props.msalContext).then(
                (d) => {
                    if (d.status === 403) {
                        this.setState({ pageErrorMessage: 'You do not have permission to perform this operation.', hideSpinner: true });
                    } else if (d.status === 400) {
                        this.setState({ hideSpinner: true, pageErrorMessage: d.body.error })
                    } else if (d.status === 200) {
                        this.setState({ pageErrorMessage: null, pageSuccessMessage: 'Issue status changed to ' + changedStatusLabel + '.', selectedIssues: [], commentBoxText: "", updateMultipleIssueDialog: false, updateMultipleIssueStatusId: 0 })
                        this.getIssueDetailsTableData(this.state.fromDate, this.state.toDate) //updating the UI with latest update details
                    }
                }
            ).catch(e => {
                this.setState({ hideSpinner: true, pageErrorMessage: 'Something went wrong !!!', pageSuccessMessage: null })
                console.log(e)
            });
        }
    }

    isSelectable(value, field) {
        let isSelectable = true;
        switch (field) {
            case 'StatusName':
                isSelectable = value !== "RESOLVED";
                break;
            default:
                break;
        }
        return isSelectable;
    }

    onSelectionChange = (event) => {
        this.setState({ pageErrorMessage: null, pageErrorMessage: null, selectedIssues: event.value })
    }

    isRowSelectable = (event) => {
        const data = event.data;
        return this.isSelectable(data.StatusName, 'StatusName');
    }

    updateSelectedIssues = () => {
        let filteredStatus = this.state.filters.StatusName.value;
        if (!filteredStatus || filteredStatus.length > 1) {
            this.setState({ availableStatusForMultipleUpdate: [{ label: 'RESOLVED', value: 3 }] })
        } else {
            if (filteredStatus.includes('NEW')) {
                this.setState({ availableStatusForMultipleUpdate: [{ label: 'ACKNOWLEDGE', value: 2 }] })
            } else if (filteredStatus.includes('ACKNOWLEDGED')) {
                this.setState({
                    availableStatusForMultipleUpdate: [
                        { label: 'ON-HOLD', value: 4 },
                        { label: 'ESCALATE', value: 5 },
                        { label: 'RESOLVED', value: 3 }
                    ]
                })
            }
            else if (filteredStatus.includes('ON-HOLD')) {
                this.setState({
                    availableStatusForMultipleUpdate: [
                        { label: 'ESCALATE', value: 5 },
                        { label: 'RESOLVED', value: 3 }
                    ]
                })
            }
            else if (filteredStatus.includes('ESCALATED')) {
                this.setState({
                    availableStatusForMultipleUpdate: [
                        { label: 'ON-HOLD', value: 4 },
                        { label: 'RESOLVED', value: 3 }
                    ]
                })
            }
        }

        if (this.state.selectedIssues && this.state.selectedIssues.length <= global.INCIDENT_MAX_SELECTION) {
            this.setState({ updateMultipleIssueDialog: true, pageErrorMessage: null, pageSuccessMessage: null })
        }
        else {
            this.setState({ pageErrorMessage: "Maximum " + global.INCIDENT_MAX_SELECTION + " issues can be selected at a once" })
        }
    }

    hideUpdateMultipleIssueDialog = () => {
        this.setState({ updateMultipleIssueDialog: false, pageErrorMessage: null, pageSuccessMessage: null })
    }
    //#endregion Bulk update code end

    statusBodyTemplate = (rowData) => {
        return <span className={`issue-status-badge status-${rowData.StatusName.toLowerCase()}`}>{rowData.StatusName}</span>
    }

    statusItemTemplate = (option) => {
        return <span className={`issue-status-badge status-${option.toLowerCase()}`}>{option}</span>

    }

    statusFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.incidentStatuses}
            itemTemplate={this.statusItemTemplate}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            placeholder="Status" className="multiselect-custom"
        />
    }

    platformFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.PlatformList}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            placeholder="Platform" className="multiselect-custom"
        />
    }

    priorityFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.priorityIdList}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            placeholder="Priority" className="multiselect-custom"
        />
    }

    metricNameFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.MetricNameList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="MetricName" className="multiselect-custom"
        />
    }

    globalSearchFilter = (filtersKey) => {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                    <Button tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.onRefreshIssueDetailsData} icon="pi pi-refresh" />
                </div>
            </div>
        )
    }

    clearDataTableFilters = () => {
        this.setState({
            pageErrorMessage: null, pageSuccessMessage: null,
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'Id': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'PriorityId': { value: null, matchMode: FilterMatchMode.IN },
                'ServerName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                'MetricName': { value: null, matchMode: FilterMatchMode.IN },
                'Platform': { value: null, matchMode: FilterMatchMode.IN },
                'StatusName': { value: null, matchMode: FilterMatchMode.IN }
            },
            selectedIssues: [],
        });
        sessionStorage.clear();
        //change the base url, by removing the query params
        if (new URLSearchParams(window.location.search).get('Platform'))
            this.props.history.push('/issues')
        this.onRefreshIssueDetailsData("Refresh On Clear")
    }

    onGlobalFilterChange = (event, filtersKey) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters, pageErrorMessage: null, pageSuccessMessage: null });
    }

    setToDate(date) {
        //HM-707 bug fix - if same date, then use the current datetime, else set hours to max of that day.
        if (date.getDate() === new Date().getDate()) {
            this.setState({ toDate: new Date() })
            sessionStorage.setItem("issue_page_toDate", new Date());
            this.validateFilterDates(this.state.fromDate, new Date())
        } else {
            this.setState({ toDate: new Date(date.setHours(23, 59, 59, 999)) })
            sessionStorage.setItem("issue_page_toDate", new Date(date.setHours(23, 59, 59, 999)));
            this.validateFilterDates(this.state.fromDate, date)
        }
    }

    setFromDate(date) {
        this.setState({ fromDate: date })
        sessionStorage.setItem("issue_page_fromDate", date);
        this.validateFilterDates(date, this.state.toDate)
    }

    getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2.getTime() - date1.getTime());
        return Math.floor(diffInMs / (1000 * 3600 * 24)); //Math.floor() - HM-693 fix

    }
    validateFilterDates(fromDate, toDate) {
        var currentDate = new Date();
        if (fromDate > toDate) {
            this.setState({ pageErrorMessage: "From Date should be less than To Date", filterBtnDisable: true })
            return false;
        }
        else if (fromDate > currentDate) {
            this.setState({ pageErrorMessage: "From Date should be less than current date", filterBtnDisable: true })
            return false;
        }
        else if (toDate > currentDate) {
            this.setState({ pageErrorMessage: "To Date should be less than current date", filterBtnDisable: true })
            return false;
        }
        else if (this.getDifferenceInDays(fromDate, toDate) > global.INCIDENT_MAX_DATE_RANGE - 1) { //-1 - to exclude the extra day, after diff - HM-693 fix
            this.setState({ pageErrorMessage: "Date range cannot exceed more than " + global.INCIDENT_MAX_DATE_RANGE + " days", filterBtnDisable: true })
            return false;
        }
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, filterBtnDisable: false })
        return true;
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        // console.log(this.props.match.params.Platform)
        // console.log(this.state.filters)
        const updateMultipleIssuesDialogFooter = (
            <React.Fragment>
                <Button label="Yes" onClick={() => this.updateMultipleIssueStatus()} />
                <Button label="No" className="p-button-secondary" onClick={this.hideUpdateMultipleIssueDialog} />
            </React.Fragment>
        );
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className='issuescontainer'>

                    {this.state.loadBreadCrumbComponent ? <><BreadCrumbComponent {...this.props} /></> : <></>}
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div className='issuedetails'>
                        <header className="page-header">
                            <div className="page-header-title">INCIDENT MANAGEMENT
                                &nbsp;<Tooltip target=".custom-target-icon" />
                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                    data-pr-tooltip="1.By default, only 90 days records are shown from current date.&#013; 2.Double click on a row to view delails/take action."
                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                            </div>
                        </header>
                        <div className="card">
                            <div className="float-container">
                                <div className="float-child">
                                    <label htmlFor="basic">From Date:</label>
                                    <Calendar id="basic" value={this.state.fromDate} onChange={(e) => this.setFromDate(e.value)}></Calendar>
                                </div>
                                <div className="float-child">
                                    <label htmlFor="basic">To Date:</label>
                                    <Calendar id="basic" value={this.state.toDate} onChange={(e) => this.setToDate(e.value)}></Calendar>
                                </div>
                                <div className="float-child">
                                    <label htmlFor="basic"><br></br></label>
                                    <Button label='Filter' icon='pi pi-filter' disabled={this.state.filterBtnDisable} onClick={() => this.getIssueDetailsTableData(this.state.fromDate, this.state.toDate)} />
                                </div>
                                <div className="float-child">
                                    <label htmlFor="basic"><br></br></label>
                                    <Button label="Update" icon="pi pi-save" className="p-button-success mr-2" onClick={this.updateSelectedIssues} disabled={!this.state.selectedIssues || !this.state.selectedIssues.length} />
                                </div>
                            </div>
                        </div>
                        <br />
                        <DataTable
                            value={this.state.IssueDetailsList}
                            paginator={this.state.showpagination}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            dataKey="Id"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            emptyMessage="No Records found."
                            selection={this.state.selectedIssues}
                            onSelectionChange={this.onSelectionChange}
                            isDataSelectable={this.isRowSelectable}
                            selectionPageOnly showGridlines removableSort
                            onFilter={(e) => this.setState({ filters: e.filters, selectedIssues: [] })}
                            stateStorage="session" stateKey="dt-manage-issues-session"
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }} frozen></Column>
                            <Column hidden={true} field="Id" header="ID" style={{ textAlign: 'left', minWidth: '80px' }} sortable />
                            <Column field="Platform" header="Platform" filter filterElement={this.platformFilterTemplate} showFilterMatchModes={false} filterPlaceholder='Platform' style={{ textAlign: 'left', minWidth: '90px' }} />
                            <Column field="MetricName" header="Metric Name" filter filterElement={this.metricNameFilterTemplate} filterPlaceholder='Metric Name' showFilterMatchModes={false} style={{ textAlign: 'left', minWidth: '200px' }} />
                            <Column field="ServerName" header="Server" filter filterPlaceholder='Server Name' showFilterOperator={false} style={{ textAlign: 'left', minWidth: '200px' }} />
                            <Column field="Namespace" header="Namespace" style={{ textAlign: 'left', width: '150px' }} />
                            <Column field="Itemname" header="Interface" style={{ textAlign: 'left', width: '150px' }} />
                            <Column field="PriorityId" header="Priority" filter filterElement={this.priorityFilterTemplate} showFilterMatchModes={false} filterPlaceholder='Priority' style={{ textAlign: 'left', minWidth: '100px' }} />
                            <Column filterField="StatusName" header="Status" filter filterElement={this.statusFilterTemplate} showFilterMatchModes={false} body={this.statusBodyTemplate} style={{ textAlign: 'left', minWidth: '100px' }} />
                            <Column field="LastUpdated" header="Created/Updated By (EST)" style={{ textAlign: 'left', width: '300px' }} />
                            <Column field="edit" header="Action" body={this.updateIssueDetails} style={{ whiteSpace: 'nowrap', textAlign: 'center' }} />
                        </DataTable>
                    </div>

                    <Dialog visible={this.state.updateMultipleIssueDialog} style={{ width: '650px' }} header="CONFIRM" modal footer={updateMultipleIssuesDialogFooter} onHide={this.hideUpdateMultipleIssueDialog}>
                        <div className="confirmation-content">
                            <table>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Status</b></label>
                                        <Dropdown value={this.state.updateMultipleIssueStatusId} options={this.state.availableStatusForMultipleUpdate}
                                            onChange={(e) => this.setState({ updateMultipleIssueStatusId: e.value, pageErrorMessage: null, pageSuccessMessage: null })} placeholder="Status" className="ui-column-filter" style={{ width: '30%' }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Closure comments</b></label>
                                        <InputTextarea type='text' name='commentbox' onChange={e => this.setState({ pageErrorMessage: null, pageSuccessMessage: null, commentBoxText: e.target.value })} placeholder="Add your comments" rows={3} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {this.state.selectedIssues && <span>Are you sure you want to update <b>{this.state.selectedIssues.length}</b> selected issues ?</span>}
                    </Dialog>

                </div>
            </MsalAuthenticationTemplate >
        );
    }
}
const WrappedIssuesComponent = withMsal(IssuesComponent);
export default withAITracking(reactPlugin, WrappedIssuesComponent);