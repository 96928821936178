import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import moment from 'moment';
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import Spinner from '../Spinner'
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { PickList } from 'primereact/picklist';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { IACLService } from './IACLService';
import { ProdItemSettingService } from '../prodItemSettings/ProdItemSettingSerivce'
import * as Yup from 'yup';
import { uuid } from 'uuidv4';
import "./IACL.css"

//HDO-5469 - PI 2024 Q3 - Integrate IACL UI features into the Monitoring tool
class EditIACLComponent extends Component {
    constructor() {
        super();
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            hideSpinner: false,
            IACLRecord: {
                Platform: null,
                InterfaceName: null,
                Priority: null,
                AfterHoursPriority: null,
                ApplicationId: null,
                InterfaceType: null,
                Namespace: null,
                ConnectionTypeId: null,
                MessageContentId: null,
                RoutineMaintenanceTimeStart: null,
                RoutineMaintenanceTimeEnd: null,
                RoutineMaintenanceFrequency: null,
                RoutineMaintenanceMonthDays: null,
                RoutineMaintenanceWeekDays: null,
                RoutineMaintenanceComments: null,
                OneTimeMaintenaceStart: null,
                OneTimeMaintenaceEnd: null,
                OneTimeComments: null,
                GOAJobName: null,
                Comments: null,
                Documents: [],
                MessageTypeList: [],
                SiteNameList: []
            },
            ImpactAnalysis: {
                AffectedApps: null,
                Notify: null,
                Comments: null
            },
            PlatformList: [],
            NamespaceList: [],
            InterfaceTypeList: [],
            SiteNameList: [],
            SelectedSiteName: [],
            ApplicationNameList: [],
            MessageContentList: [],
            ConnectionTypeList: [],
            MessageTypeList: [],
            SelectedMessageType: [],
            Priority: [1, 2, 3, 4],
            RoutineMaintenance: {
                Frequency: ['Daily', 'Weekly', 'Monthly'],
                MonthDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                WeekDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            AddDocumentObject: { Id: null, Name: null, Url: null, DocType: null },
            DeleteDocumentObject: { Id: null, Name: null, Url: null, DocType: null },
            DocType: ['VISIO', 'OPS MANUAL', 'MAPPING'],
            addDocDialogBoxVisible: false
        }
        this.IACLService = new IACLService();
        this.prodItemSettingService = new ProdItemSettingService();

        //calender date intialization
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        this.minDate = new Date();
        this.minDate.setMonth(month);
        this.minDate.setFullYear(year);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getIaclMasterData()
    }

    getIaclMasterData = () => {
        this.IACLService.getIaclMasterData(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.NamespaceList.map((item) => (this.state.NamespaceList.push({ label: item.Namespace, value: item.Namespace })));
                        d.body.PlatformList.map((item) => (this.state.PlatformList.push({ label: item.Name, value: item.Id })));
                        d.body.SiteNameList.map((item) => (this.state.SiteNameList.push({ label: item.Name, value: item.Id })));
                        d.body.ApplicationNameList.map((item) => (this.state.ApplicationNameList.push({ label: item.Name, value: item.Id })));
                        d.body.InterfaceTypeList.map((item) => (this.state.InterfaceTypeList.push({ label: item.Name, value: item.Id })));
                        d.body.MessageContentList.map((item) => (this.state.MessageContentList.push({ label: item.Name, value: item.Id })));
                        d.body.ConnectionTypeList.map((item) => (this.state.ConnectionTypeList.push({ label: item.Name, value: item.Id })));
                        d.body.MessageTypeList.map((item) => (this.state.MessageTypeList.push({ label: item.Name, value: item.Id })));
                        this.setState({ pageSuccessMessage: '', pageErrorMessage: '' });
                    }
                    this.getIaclRecordById()
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getIaclRecordById = () => {
        this.IACLService.getIaclRecordById(this.props.match.params.id, true, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        if (d.body.MessageType) {
                            d.body.MessageType.split(',').map((x) => {
                                this.state.MessageTypeList.map((y) => {
                                    if (y.value === parseInt(x)) {
                                        this.state.SelectedMessageType.push(y)
                                    }
                                })
                            });
                            this.setState({
                                MessageTypeList: this.state.MessageTypeList.filter((x) => !d.body.MessageType.split(',').includes(x.value.toString())),
                            });
                        }
                        if (d.body.SiteName) {
                            d.body.SiteName.split(',').map((x) => {
                                this.state.SiteNameList.map((y) => {
                                    if (y.value === parseInt(x)) {
                                        this.state.SelectedSiteName.push(y)
                                    }
                                })
                            });
                            this.setState({
                                SiteNameList: this.state.SiteNameList.filter((x) => !d.body.SiteName.split(',').includes(x.value.toString())),
                            });
                        }
                        d.body.OneTimeMaintenaceStart = d.body.OneTimeMaintenaceStart ? new Date(d.body.OneTimeMaintenaceStart) : d.body.OneTimeMaintenaceStart
                        d.body.OneTimeMaintenaceEnd = d.body.OneTimeMaintenaceEnd ? new Date(d.body.OneTimeMaintenaceEnd) : d.body.OneTimeMaintenaceEnd
                        d.body.RoutineMaintenanceMonthDays = d.body.RoutineMaintenanceMonthDays === 0 ? null : d.body.RoutineMaintenanceMonthDays
                        this.setState({
                            IACLRecord: d.body,
                            ImpactAnalysis: d.body.ImpactAnalysis,
                            hideSpinner: true, pageSuccessMessage: '', pageErrorMessage: ''
                        });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    handleInputChange = (event) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (event.target.name === "ConnectionTypeId") {
            this.setState({
                IACLRecord: {
                    ...this.state.IACLRecord, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value === "" ? null : event.target.value,
                    GOAJobName: null
                }
            })
        }
        else {
            this.setState({
                IACLRecord: {
                    ...this.state.IACLRecord, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value === "" ? null : event.target.value
                }
            })
        }
    }

    handleAddDocInputChange = (event) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.setState({
            AddDocumentObject: {
                ...this.state.AddDocumentObject, //using spread operator to initialize object with existing props
                [event.target.name]: event.target.value === "" ? null : event.target.value
            }
        })
    }

    handleImpactAnalysisInputChange = (event) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.setState({
            ImpactAnalysis: {
                ...this.state.ImpactAnalysis, //using spread operator to initialize object with existing props
                [event.target.name]: event.target.value === "" ? null : event.target.value
            }
        })
    }

    //HDO-6591 - bug fix
    validateDates = (startDate, endDate) => {
        if (startDate > endDate) {
            this.setState({ pageErrorMessage: "Start date should be less than end date" })
            return false;
        }
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        return true;
    }

    panelHeaderTemplate = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
                <span className={titleClassName}>
                    {options.props.id}
                </span>
            </div>
        )
    }

    pickListItemTemplate = (item) => {
        return (
            <div>
                {item.label}
            </div>
        );
    }

    onMessageTypeListChange = (event) => {
        this.setState({ MessageTypeList: event.source, SelectedMessageType: event.target });
    }

    onSiteNameListChange = (event) => {
        this.setState({ SiteNameList: event.source, SelectedSiteName: event.target });
    }

    onSaveIaclNewInterfaceRequest = async (IACLRecord) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (!this.validateDates(IACLRecord.OneTimeMaintenaceStart, IACLRecord.OneTimeMaintenaceEnd))
            return
        IACLRecord.MessageTypeList = this.state.SelectedMessageType.map((item) => { return item.value })
        IACLRecord.SiteNameList = this.state.SelectedSiteName.map((item) => { return item.value })
        IACLRecord.OneTimeMaintenaceStart = IACLRecord.OneTimeMaintenaceStart ? moment(new Date(IACLRecord.OneTimeMaintenaceStart).setSeconds(0)).format('YYYY-MM-DDTHH:mm:ss.SSS') : null
        IACLRecord.OneTimeMaintenaceEnd = IACLRecord.OneTimeMaintenaceEnd ? moment(new Date(IACLRecord.OneTimeMaintenaceEnd).setSeconds(0)).format('YYYY-MM-DDTHH:mm:ss.SSS') : null

        // validating the input data
        try {
            let IACLRecordSchema = Yup.object({
                InterfaceName: Yup.string().required("Interface Name is required.")
                    .max(1000, "Interface Name cannot be more than 1000 characters.")
                    .matches(/^[^'"]*$/, "Interface Name should not contain single (') or double (\") quotes."),
                Platform: Yup.string().required("Platform is required."),
                Priority: Yup.string().required("Priority is required."),
                ApplicationId: Yup.string().required('Application team is required.'),
                Namespace: Yup.string().required('Namespace is required.'),
                InterfaceType: Yup.string().required('Interface Type is required.'),
                GOAJobName: Yup.string().nullable()
                    .max(1000, "GOA job name cannot be more than 1000 characters.")
                    .matches(/^[^'"]*$/, "GOA job name should not contain single (') or double (\") quotes."),
                RoutineMaintenanceTimeStart: Yup.string().nullable()
                    .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, "Recurring maintenance start time should be in HH:MM format."),
                RoutineMaintenanceTimeEnd: Yup.string().nullable()
                    .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$|^\w$/, "Recurring maintenance end time should be in HH:MM format.")
            });
            await IACLRecordSchema.validate(IACLRecord, { abortEarly: false })

            if (this.state.ImpactAnalysis && (this.state.ImpactAnalysis.AffectedApps || this.state.ImpactAnalysis.Notify || this.state.ImpactAnalysis.Comments)) {
                IACLRecord.ImpactAnalysis = this.state.ImpactAnalysis

                let ImpactAnalysisSchema = Yup.object({
                    Notify: Yup.string().nullable().matches(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(;[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/, 'Email list is not valid. Refer info icon.')
                });
                await ImpactAnalysisSchema.validate(IACLRecord.ImpactAnalysis, { abortEarly: false })
            }
            else {
                IACLRecord.ImpactAnalysis = null
            }

            this.setState({ hideSpinner: false })
            let body = JSON.stringify(IACLRecord);

            //invoke the post api to edit iacl record
            this.IACLService.editIACLRecord(this.props.match.params.id, body, this.props.msalContext).then(
                (d) => {
                    if (d.status === 403) {
                        this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                    } else {
                        if (d.status === 200) {
                            if (d.body > 0) {
                                this.setState({ hideSpinner: true, pageSuccessMessage: 'Record saved successfully', pageErrorMessage: '' });
                            }
                        }
                        else {
                            this.setState({ hideSpinner: true, pageErrorMessage: "Something went wrong." })
                        }
                    }
                }
            ).catch(e => {
                this.setState({ hideSpinner: true, pageErrorMessage: "Something went wrong." })
                console.log(e)
            });
        }
        catch (error) { //set exception in the state and return
            //console.log(error.inner)
            this.setState({ pageErrorMessage: error.inner[0].message });
        }
    }

    createIACLDocLink = (rowData) => {
        let redirectUrl = rowData.Url
        return (
            <React.Fragment>
                <a href={redirectUrl}><strong>{rowData.Name}</strong></a>
            </React.Fragment>
        );
    }

    onDocActionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" tooltip="Delete" onClick={e => this.setState({ confirmDeleteDocActionDialogBoxVisible: true, DeleteDocumentObject: { ...rowData } })} />
            </React.Fragment>
        );
    }

    addDocModalFooter = (
        <div>
            <Button label='Add' onClick={() => this.onAddDocAction()} />
            <Button label='Cancel' onClick={e => this.setState({
                addDocDialogBoxVisible: false,
                AddDocumentObject: { Name: null, Url: null, DocType: null },
                DeleteDocumentObject: { Name: null, Url: null, DocType: null }, pageErrorMessage: null, pageSuccessMessage: null
            })} className='p-button-secondary' />
        </div>
    );

    onAddDocAction = async () => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: '' })
        let tempDocArray = Array.from(this.state.IACLRecord.Documents)
        tempDocArray.push(this.state.AddDocumentObject)

        //validating the input data
        try {
            let AddDocumentObjectchema = Yup.object({
                Name: Yup.string().required("Document Name is required.")
                    .max(1000, "Interface Name cannot be more than 1000 characters.")
                    .matches(/^[^'"]*$/, "Interface Name should not contain single (') or double (\") quotes."),
                Url: Yup.string().required("Document Url is required.")
                    .matches(/^[^'"]*$/, "Document Url should not contain single (') or double (\") quotes."),
                DocType: Yup.string().required('Document Type is required.')
            });
            await AddDocumentObjectchema.validate(this.state.AddDocumentObject, { abortEarly: false })
        }
        catch (error) { //set exception in the state and return
            //console.log(error.inner)
            this.setState({ pageErrorMessage: error.inner[0].message });
            return;
        }

        //If all input validations are passed, push the doc record into main array of IACLRecord object
        this.setState({
            IACLRecord: {
                ...this.state.IACLRecord,
                Documents: tempDocArray
            },
            addDocDialogBoxVisible: false
        })
    }

    confirmDeleteFooter = (
        <div>
            <Button label='Ok' onClick={() => this.onDocDeleteAction()} />
            <Button label='Cancel' onClick={e => this.setState({ confirmDeleteDocActionDialogBoxVisible: false })} className='p-button-secondary' />
        </div>
    );

    onDocDeleteAction = () => {
        let tempDocArray = Array.from(this.state.IACLRecord.Documents)
        //filter the array and remove the deleted item
        tempDocArray = tempDocArray.filter((item) => {
            if (item.Id != this.state.DeleteDocumentObject.Id) {
                return true;
            }
            return false;
        })

        //update the state
        this.setState({
            IACLRecord: {
                ...this.state.IACLRecord,
                Documents: tempDocArray
            },
            confirmDeleteDocActionDialogBoxVisible: false
        })
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="iaclcontainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">EDIT INTERFACE</div>
                        </header>

                        <div className="">
                            <div id='Information'>
                                <Panel id='Information' toggleable headerTemplate={this.panelHeaderTemplate}>
                                    <table className='iacl-table'>
                                        <tr>
                                            <td className='iacl-td'><span className='required-field iacl-label'>Interface Name:</span></td>
                                            <td className='iacl-td'><span className='required-field iacl-label'>Platform:</span></td>
                                            <td className='iacl-td'><span className='required-field iacl-label'>Priority:</span>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip="During business hours."
                                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i></td>
                                            <td className='iacl-td'><span className='iacl-label'>After Hours Priority:</span>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip="During non-business hours."
                                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i></td>
                                            <td className='iacl-td'><span className='required-field iacl-label'>Application Team:</span>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip="Application team who supports this interface if it goes down."
                                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><InputText state='IACLRecord' type='text' name='InterfaceName' value={this.state.IACLRecord.InterfaceName || ''} onChange={this.handleInputChange} placeholder='Name' /></td>
                                            <td className='iacl-td'><Dropdown name='Platform' style={{ width: '100%' }} className="ui-column-filter" placeholder="Platform" value={this.state.IACLRecord.Platform}
                                                options={this.state.PlatformList} onChange={this.handleInputChange} showClear /></td>
                                            <td className='iacl-td'><Dropdown name='Priority' style={{ width: '100%' }} className="ui-column-filter" placeholder="Priority" value={this.state.IACLRecord.Priority}
                                                options={this.state.Priority} onChange={this.handleInputChange} showClear /></td>
                                            <td className='iacl-td'><Dropdown name='AfterHoursPriority' style={{ width: '100%' }} className="ui-column-filter" placeholder="After Hours Priority" value={this.state.IACLRecord.AfterHoursPriority}
                                                options={this.state.Priority} onChange={this.handleInputChange} showClear /></td>
                                            <td className='iacl-td'><Dropdown name='ApplicationId' style={{ width: '100%' }} className="ui-column-filter" placeholder="Application Team" value={this.state.IACLRecord.ApplicationId}
                                                options={this.state.ApplicationNameList} onChange={this.handleInputChange} filter showClear /></td>
                                        </tr>
                                    </table>
                                </Panel> <Divider layout="horizontal"></Divider>
                            </div>

                            <div id='Interface Properties'>
                                <Panel id='Interface Properties' toggleable headerTemplate={this.panelHeaderTemplate}>
                                    <table className='iacl-table' border='0'>
                                        <tr>
                                            <td className='iacl-td'><span className='required-field iacl-label'>{this.state.IACLRecord.Platform === 5 ? 'Design Name:' : 'Namespace:'}</span></td>
                                            <td colSpan={2} className='iacl-td'><span className='iacl-label'>Message Types:</span></td>
                                            <td colSpan={2} className='iacl-td'><span className='iacl-label'>Site Names:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><Dropdown name='Namespace' style={{ width: '100%' }} className="ui-column-filter" placeholder={this.state.IACLRecord.Platform === 5 ? 'Design Name' : 'Namespace'} value={this.state.IACLRecord.Namespace}
                                                options={this.state.NamespaceList} onChange={this.handleInputChange} filter showClear /></td>
                                            <td colSpan={2} rowSpan={this.state.IACLRecord.ConnectionTypeId === 1 ? 9 : 7} className='iacl-td'>
                                                <PickList source={this.state.MessageTypeList} target={this.state.SelectedMessageType} itemTemplate={this.pickListItemTemplate} sourceHeader="Message Types" targetHeader="Selected Message types"
                                                    sourceStyle={{ height: '200px' }} targetStyle={{ height: '200px' }} onChange={this.onMessageTypeListChange} showSourceControls={false} showTargetControls={false}
                                                    filterBy="label" sourceFilterPlaceholder="Search by name" targetFilterPlaceholder="Search by name" /> </td>
                                            <td colSpan={2} rowSpan={this.state.IACLRecord.ConnectionTypeId === 1 ? 9 : 7} className='iacl-td'>
                                                <PickList source={this.state.SiteNameList} target={this.state.SelectedSiteName} itemTemplate={this.pickListItemTemplate} sourceHeader="Site List" targetHeader="Selected Sites"
                                                    sourceStyle={{ height: '200px' }} targetStyle={{ height: '200px' }} onChange={this.onSiteNameListChange} showSourceControls={false} showTargetControls={false}
                                                    filterBy="label" sourceFilterPlaceholder="Search by name" targetFilterPlaceholder="Search by name" />
                                            </td>
                                        </tr>
                                        <tr><td className='iacl-td'><span className='required-field  iacl-label'>Interface Type:</span></td> </tr>
                                        <tr><td className='iacl-td'><Dropdown name='InterfaceType' style={{ width: '100%' }} className="ui-column-filter" placeholder="Interface Type" value={this.state.IACLRecord.InterfaceType}
                                            options={this.state.InterfaceTypeList} onChange={this.handleInputChange} showClear /></td> </tr>
                                        <tr>  <td className='iacl-td'><span className='iacl-label'>Connection Type:</span></td></tr>
                                        <tr>  <td className='iacl-td'><Dropdown name='ConnectionTypeId' style={{ width: '100%' }} className="ui-column-filter" placeholder="Connection Type" value={this.state.IACLRecord.ConnectionTypeId} options={this.state.ConnectionTypeList} onChange={this.handleInputChange} showClear /></td></tr>
                                        {this.state.IACLRecord.ConnectionTypeId === 1 ? <>
                                            <tr>  <td className='iacl-td'><span className='iacl-label'>GOA JobName:</span></td></tr>
                                            <tr><td className='iacl-td'><InputText state='IACLRecord' type='text' name='GOAJobName' value={this.state.IACLRecord.GOAJobName || ''} onChange={this.handleInputChange} placeholder='GOA JobName' /></td></tr></>
                                            : <></>
                                        }
                                        <tr>  <td className='iacl-td'><span className='iacl-label'>Message Content:</span></td></tr>
                                        <tr> <td className='iacl-td'><Dropdown name='MessageContentId' style={{ width: '100%' }} className="ui-column-filter" placeholder="Message Content" value={this.state.IACLRecord.MessageContentId} options={this.state.MessageContentList} onChange={this.handleInputChange} showClear /></td></tr>
                                    </table>
                                </Panel>
                            </div>

                            <div id='Impact Analysis'>
                                <Divider layout="horizontal"></Divider>
                                <Panel id='Impact Analysis' toggleable headerTemplate={this.panelHeaderTemplate} collapsed={true}>
                                    <table>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Affected Application:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Notify:</span>&nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip='Email not in correct format or if multiple emails not seperated by semicolon ( ; ) as a delimiter. e.g. abc@email.com;xyz@email.com'
                                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i></td>
                                            <td className='iacl-td'><span className='iacl-label'>Comments:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><InputTextarea type='text' name='AffectedApps' value={this.state.ImpactAnalysis && this.state.ImpactAnalysis.AffectedApps || null} onChange={this.handleImpactAnalysisInputChange} rows={3} /></td>
                                            <td className='iacl-td'><InputTextarea type='text' name='Notify' value={this.state.ImpactAnalysis && this.state.ImpactAnalysis.Notify || null} onChange={this.handleImpactAnalysisInputChange} rows={3} /></td>
                                            <td className='iacl-td'><InputTextarea type='text' name='Comments' value={this.state.ImpactAnalysis && this.state.ImpactAnalysis.Comments || null} onChange={this.handleImpactAnalysisInputChange} rows={3} /></td>
                                        </tr>
                                    </table>
                                </Panel>
                            </div>

                            <div id='Documents'>
                                <Divider layout="horizontal"></Divider>
                                <Panel id='Documents' toggleable headerTemplate={this.panelHeaderTemplate} collapsed={this.state.IACLRecord.Documents && this.state.IACLRecord.Documents.length > 0 ? false : true}>
                                    <Button icon="pi pi-plus" tooltip='Add Document' tooltipOptions={{ position: 'top' }} onClick={e => this.setState({
                                        addDocDialogBoxVisible: true,
                                        AddDocumentObject: { Id: uuid(), Name: null, Url: null, DocType: null },
                                        DeleteDocumentObject: { Id: null, Name: null, Url: null, DocType: null },
                                        pageErrorMessage: null, pageErrorMessage: null
                                    })} />
                                    {this.state.IACLRecord.Documents && this.state.IACLRecord.Documents.length > 0 ? <div style={{ padding: '5px' }}>
                                        <DataTable
                                            value={this.state.IACLRecord.Documents}
                                            selectionMode="single"
                                            paginator={true}
                                            rows={3}
                                            filterDisplay="menu"
                                            responsiveLayout="scroll"
                                            showGridlines removableSort
                                            emptyMessage="No Records found."
                                        >
                                            <Column field="Name" header="Name" style={{ textAlign: 'left' }} body={this.createIACLDocLink} />
                                            <Column field="DocType" header="Type" style={{ textAlign: 'left' }} />
                                            <Column field="Delete" header="Action" body={this.onDocActionTemplate} style={{ whiteSpace: 'nowrap', width: '150px', textAlign: 'center' }} />
                                        </DataTable>
                                    </div> : <>Add Document</>}
                                </Panel>
                            </div>

                            <div id='Maintenance Activity'>
                                <Divider layout="horizontal"></Divider>
                                <Panel id='Maintenance Activity' toggleable headerTemplate={this.panelHeaderTemplate} collapsed={true}>
                                    <h4>Recurring Maintenance Window:</h4><hr />
                                    <table className='iacl-table'>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Frequency:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Start Time(hh:mm) / 24h:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>End Time(hh:mm)  / 24h:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Days:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><Dropdown name='RoutineMaintenanceFrequency' style={{ width: '100%' }} className="ui-column-filter" placeholder="Frequency" value={this.state.IACLRecord.RoutineMaintenanceFrequency}
                                                onChange={this.handleInputChange} options={this.state.RoutineMaintenance.Frequency} showClear /></td>
                                            <td className='iacl-td'><InputText type='text' name='RoutineMaintenanceTimeStart' value={this.state.IACLRecord.RoutineMaintenanceTimeStart || ''} onChange={this.handleInputChange} /></td>
                                            <td className='iacl-td'><InputText type='text' name='RoutineMaintenanceTimeEnd' value={this.state.IACLRecord.RoutineMaintenanceTimeEnd || ''} onChange={this.handleInputChange} /></td>
                                            <td className='iacl-td'><Dropdown name='RoutineMaintenanceWeekDays' style={{ width: '100%' }} className="ui-column-filter" placeholder="Week Day" value={this.state.IACLRecord.RoutineMaintenanceWeekDays}
                                                onChange={this.handleInputChange} options={this.state.RoutineMaintenance.WeekDays} showClear /></td>

                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Day of the Month:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Comments:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><Dropdown name='RoutineMaintenanceMonthDays' style={{ width: '100%' }} className="ui-column-filter" placeholder="Month Day" value={this.state.IACLRecord.RoutineMaintenanceMonthDays}
                                                onChange={this.handleInputChange} options={this.state.RoutineMaintenance.MonthDays} showClear /></td>
                                            <td colSpan={3} className='iacl-td'><InputText type='text' name='RoutineMaintenanceComments' value={this.state.IACLRecord.RoutineMaintenanceComments || ''} onChange={this.handleInputChange} /></td>
                                        </tr>
                                    </table>


                                    <h4>One Time Maintenance Window:</h4>
                                    <table style={{ width: 'fit-content' }}>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Start Date/Time:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>End Date/Time:</span></td>
                                            <td className='iacl-td'><span className='iacl-label'>Comments:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><Calendar name='OneTimeMaintenaceStart' id="basic" placeholder='Start Date/Time'
                                                value={this.state.IACLRecord.OneTimeMaintenaceStart ? new Date(this.state.IACLRecord.OneTimeMaintenaceStart) : this.state.IACLRecord.OneTimeMaintenaceStart} minDate={this.minDate}
                                                onChange={this.handleInputChange} showTime showIcon readOnlyInput></Calendar></td>
                                            <td className='iacl-td'><Calendar name='OneTimeMaintenaceEnd' id="basic" placeholder='End Date/Time'
                                                value={this.state.IACLRecord.OneTimeMaintenaceEnd ? new Date(this.state.IACLRecord.OneTimeMaintenaceEnd) : this.state.IACLRecord.OneTimeMaintenaceEnd} minDate={this.minDate}
                                                onChange={this.handleInputChange} showTime showIcon readOnlyInput></Calendar></td>
                                            <td className='iacl-td'><InputText type='text' name='OneTimeComments' value={this.state.IACLRecord.OneTimeComments || ''} onChange={this.handleInputChange} style={{ width: '24em' }} /></td>
                                        </tr>
                                        <tr>
                                        </tr>
                                    </table>
                                    <table>
                                        <tr>
                                            <td className='iacl-td'><span className='iacl-label'>Support Notes:</span></td>
                                        </tr>
                                        <tr>
                                            <td className='iacl-td'><InputTextarea type='text' name='Comments' value={this.state.IACLRecord.Comments} onChange={this.handleInputChange} rows={3} /></td>
                                        </tr>
                                    </table>
                                </Panel>
                            </div>

                        </div> <br />
                        <div>
                            <Button label='Save' onClick={() => this.onSaveIaclNewInterfaceRequest(this.state.IACLRecord)} /> &nbsp;
                            <Button label='Cancel' onClick={() => this.props.history.push('/iacl')} />
                        </div>
                    </div>

                    <Dialog header='Add Document' footer={this.addDocModalFooter}
                        visible={this.state.addDocDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ addDocDialogBoxVisible: false, pageErrorMessage: '' })} maximizable={false}>
                        <div>
                            <table className='w-100'>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Document Name</b></label>
                                        <InputText type='text' name='Name' value={this.state.AddDocumentObject.Name || ''} style={{ width: '30em' }}
                                            onChange={this.handleAddDocInputChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Document Url</b></label>
                                        <InputText type='text' name='Url' value={this.state.AddDocumentObject.Url || ''}
                                            onChange={this.handleAddDocInputChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>Document Type</b></label>
                                        <Dropdown name='DocType' style={{ width: '100%' }} className="ui-column-filter" placeholder="DocType" value={this.state.AddDocumentObject.DocType}
                                            onChange={this.handleAddDocInputChange} options={this.state.DocType} showClear />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Dialog>

                    <Dialog header='Delete Document' footer={this.confirmDeleteFooter}
                        visible={this.state.confirmDeleteDocActionDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ confirmDeleteDocActionDialogBoxVisible: false })} maximizable={false}>
                        <div>
                            Are you sure you want to delete the document - <b>{this.state.DeleteDocumentObject.Name}</b> ?
                        </div>
                    </Dialog>
                </div>
            </MsalAuthenticationTemplate >
        )
    }
}

const WrappedEditIACLComponent = withMsal(EditIACLComponent);
export default withAITracking(reactPlugin, WrappedEditIACLComponent);