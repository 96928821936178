import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { Button } from 'primereact/button';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { CustomHieAlertService } from './CustomHieAlertService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity, FilterMatchMode, FilterOperator } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
import Spinner from '../Spinner'
import "./CustomHieAlert.css"

class CustomHieAlertActionComponent extends Component {
    constructor() {
        super();
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            hasReadRights: true,
            customHieAlerts: [],
            customHieAlert: {},
            selectedCustomHieAlertView: {},
            confirmDeleteVisible: false,
            showCustomAlertView: false,
            hideSpinner: false,
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'Priority': { value: null, matchMode: FilterMatchMode.IN },
                'Trigger': { value: null, matchMode: FilterMatchMode.IN },
                'Status': { value: null, matchMode: FilterMatchMode.IN },
            },
            alertTypeList: [
                { label: 'Warning', value: 1 },
                { label: 'Error', value: 2 },
                { label: 'Information', value: 3 }
            ],
            timePeriodOptions: [
                { label: '5 minutes', value: 5 },
                { label: '15 minutes', value: 15 },
                { label: '30 minutes', value: 30 },
                { label: '1 hour', value: 60 },
                { label: '6 hours', value: 360 },
                { label: '12 hours', value: 720 },
                { label: '1 day', value: 1440 },
                { label: '7 days', value: 10080 },
                { label: '15 days', value: 21600 },
                { label: '30 days', value: 43200 },
            ],
            globalFilterValue: ''
        }
        this.priorityIdList = [0, 1, 2, 3, 4];
        this.triggerOptions = ['EMAIL', 'REPORT', 'N.A'];
        this.statusOptions = ['ACTIVE', 'INACTIVE'];
        this.customAlertService = new CustomHieAlertService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getCustomHieAlertRule()
    }

    getCustomHieAlertRule = () => {
        this.setState({ hideSpinner: false })
        this.customAlertService.getCustomHieAlertRule(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', pageSuccessMessage: null, hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({
                            customHieAlerts: d.body.map((item) => {
                                let updatedItem = {}
                                if (item.TriggerEmail) {
                                    updatedItem = { ...item, Trigger: 'EMAIL', Status: item.Status ? 'ACTIVE' : 'INACTIVE' }
                                }
                                else if (item.TriggerReport) {
                                    updatedItem = { ...item, Trigger: 'REPORT', Status: item.Status ? 'ACTIVE' : 'INACTIVE' }
                                }
                                else {
                                    updatedItem = { ...item, Trigger: 'N.A', Status: item.Status ? 'ACTIVE' : 'INACTIVE' }
                                }
                                return updatedItem;

                            }),
                            hideSpinner: true, pageErrorMessage: null, pageSuccessMessage: null
                        });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getCustomHieAlertRuleById = (Id) => {
        this.setState({ hideSpinner: false })
        this.customAlertService.getCustomHieAlertRuleById(Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted && d.status === 200) {
                        this.setState({
                            selectedCustomHieAlertView: d.body,
                            showCustomAlertView: true,
                            hideSpinner: true
                        });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    deleteRecord() {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' })
        this.customAlertService.deleteCustomHieAlertRule(this.state.customHieAlert.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to perform this operation.', hideSpinner: true });
                } else if (d.status === 200) {
                    this.getCustomHieAlertRule();
                    this.setState({ confirmDeleteVisible: false, hideSpinner: true, pageSuccessMessage: 'Rule deleted successfully !!!' });
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong !!!', hideSpinner: true });
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    globalSearchFilter = (filtersKey) => {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                    <Button tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.getCustomHieAlertRule} icon="pi pi-refresh" />
                </div>
            </div>
        )
    }

    clearDataTableFilters = () => {
        this.setState({
            pageErrorMessage: null, pageSuccessMessage: null,
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'Priority': { value: null, matchMode: FilterMatchMode.IN },
                'Trigger': { value: null, matchMode: FilterMatchMode.IN },
                'Status': { value: null, matchMode: FilterMatchMode.IN },
            },
        });
        sessionStorage.clear();
    }

    onGlobalFilterChange = (event, filtersKey) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    booleanChecker = (rowData) => {
        return <span className={`customalert-status-badge status-${rowData.Status.toLowerCase()}`}>{rowData.Status}</span>;
    };

    priorityFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.priorityIdList}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            placeholder="Priority" className="multiselect-custom"
        />;
    }

    triggerOptionFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.triggerOptions}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            placeholder="Trigger" className="multiselect-custom"
        />;
    }

    statusOptionFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.statusOptions}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            placeholder="Status" className="multiselect-custom"
        />;
    }

    customHieAlertRuleAction = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-eye" className="p-button-rounded p-button-secondary p-mr-2" tooltip="View" tooltipOptions={{ position: 'top' }} onClick={() => this.getCustomHieAlertRuleById(rowData.Id)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" tooltip="Edit" tooltipOptions={{ position: 'top' }} onClick={() => window.open('/admin/custom/hie/alert/rule/edit/' + rowData.Id, "_blank")} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" tooltip="Delete" tooltipOptions={{ position: 'top' }} onClick={e => this.setState({ confirmDeleteVisible: true, customHieAlert: { ...rowData } })} />
            </React.Fragment>
        );
    }

    confirmDeleteFooter = (
        <div>
            <Button label='Ok' onClick={() => this.deleteRecord()} />
            <Button label='Cancel' onClick={e => this.setState({ confirmDeleteVisible: false })}
                className='p-button-secondary' />
        </div>
    );

    padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    formatDate = (date) => {
        return (
            [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/') +
            ' ' +
            [
                this.padTo2Digits(date.getHours()),
                this.padTo2Digits(date.getMinutes()),
                this.padTo2Digits(date.getSeconds()),
            ].join(':')
        );
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="actiongroupContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <div className='flex justify-content-between' style={{ marginBottom: '10px' }}>
                            <div className="page-header-title">CUSTOM ALERT RULE</div>
                            <Button label='' icon='pi pi-plus' tooltip='Create Custom Rule' tooltipOptions={{ position: 'left' }}
                                onClick={() => window.open('/admin/custom/hie/alert/rule/create', '_blank')} />
                        </div>

                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.customHieAlerts}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            responsiveLayout="scroll"
                            filters={this.state.filters}
                            header={this.globalSearchFilter('filters')}
                            emptyMessage="No Records found."
                            showGridlines={true} removableSort
                            onFilter={(e) => this.setState({ filters: e.filters })}
                            stateStorage="session" stateKey="dt-custom-hiealert-session"
                        >
                            <Column field="Name" header="Alert Name" style={{ textAlign: 'left' }} />
                            <Column field="Priority" header="Priority" filter filterElement={this.priorityFilterTemplate} showFilterMatchModes={false} filterPlaceholder='Priority' style={{ textAlign: 'left', width: '150px' }} sortable />
                            <Column field="MatchFilter" header="MatchFilter" style={{ textAlign: 'left' }} />
                            <Column field="Status" header="Status" filter filterElement={this.statusOptionFilterTemplate} showFilterMatchModes={false} filterPlaceholder='Priority' style={{ textAlign: 'left', width: '150px' }} body={this.booleanChecker} />
                            <Column field="Trigger" header="Trigger" filter filterElement={this.triggerOptionFilterTemplate} showFilterMatchModes={false} filterPlaceholder='Trigger' style={{ textAlign: 'left', width: '150px' }} />
                            <Column field="edit" header="Action" alignHeader='center' body={this.customHieAlertRuleAction} style={{ whiteSpace: 'nowrap', width: '150px', textAlign: 'center' }} />
                        </DataTable>
                    </div>

                    <Dialog header='Delete threshold rule' footer={this.confirmDeleteFooter}
                        visible={this.state.confirmDeleteVisible} width='400px' modal={true}
                        onHide={e => this.setState({ confirmDeleteVisible: false })} maximizable={false}>
                        <div>
                            Are you sure you want to delete the rule <b>{this.state.customHieAlert.Name}</b>?
                        </div>
                    </Dialog>

                    <Dialog header={this.state.selectedCustomHieAlertView.Name} style={{ width: '50vw' }}
                        visible={this.state.showCustomAlertView} modal={true}
                        onHide={e => this.setState({ showCustomAlertView: false })} maximizable={true} closeOnEscape={true}>
                        <div>
                            <table class="fixedtablelayout" border={1}>
                                <th colSpan={2} class="th-details">General Information</th>
                                <tr><td>Instance:</td><td class="fluidcell">{this.state.selectedCustomHieAlertView.Instance ? this.state.selectedCustomHieAlertView.Instance.map(item => item).join(', ') : ''}</td></tr>
                                <tr><td>Namespace:</td><td class="fluidcell">{this.state.selectedCustomHieAlertView.NameSpace ? this.state.selectedCustomHieAlertView.NameSpace.map(item => item).join(', ') : ''}</td></tr>
                                <tr><td>Interface name:</td><td class="fluidcell">{this.state.selectedCustomHieAlertView.ConfigName ? this.state.selectedCustomHieAlertView.ConfigName.map(item => item).join(', ') : ''}</td></tr>
                                <tr><td>Matchfilter:</td><td>{this.state.selectedCustomHieAlertView.MatchFilter || "N.A"}</td></tr>
                            </table>
                        </div>
                        <div>
                            <br />
                            <table class="fixedtablelayout" border={1}>
                                <th colSpan={2} class="th-details">Alert Configuration</th>
                                <tr><td>Alert type:</td><td class="fluidcell">{this.state.alertTypeList.filter(item => item.value === this.state.selectedCustomHieAlertView.AlertType).map(type => type.label) || "N.A"}</td></tr>
                                {this.state.selectedCustomHieAlertView.AlertType === 2 ? <tr><td>Priority:</td><td>{this.state.selectedCustomHieAlertView.Priority}</td></tr> : <></>}
                                <tr><td>Notification subject:</td><td>{this.state.selectedCustomHieAlertView.NotificationSubject || "N.A"}</td></tr>
                                <tr><td>Action group's:</td><td class="fluidcell">{this.state.selectedCustomHieAlertView.ActionGroupList ? this.state.selectedCustomHieAlertView.ActionGroupList.map(item => item.Name).join(', ') : ''}</td></tr>
                                {this.state.selectedCustomHieAlertView.CreateServiceNow ? <tr><td>Servicenow group:</td><td>{this.state.selectedCustomHieAlertView.ServiceNowGroup || "N.A"}</td></tr> : <></>}
                                <tr><td>Alert Frequency:</td><td class="fluidcell">{this.state.timePeriodOptions.filter(item => item.value === this.state.selectedCustomHieAlertView.ResendFrequency).map(type => type.label) || "N.A"}</td></tr>
                                <tr><td>Lookback period:</td><td class="fluidcell">{this.state.timePeriodOptions.filter(item => item.value === this.state.selectedCustomHieAlertView.LookBackTime).map(type => type.label) || "N.A"}</td></tr>
                            </table>
                        </div>
                        <div>
                            <br />
                            <table class="fixedtablelayout" border={1}>
                                <th colSpan={2} class="th-details">Actions</th>
                                <tr><td>Created by:</td><td>{this.state.selectedCustomHieAlertView.CreatedBy || "N.A"}</td></tr>
                                <tr><td>Created date:</td><td class="fluidcell">{this.state.selectedCustomHieAlertView.CreatedDate ? this.formatDate(new Date(this.state.selectedCustomHieAlertView.CreatedDate)) : "N.A"}</td></tr>
                                <tr><td>Modified by:</td><td class="fluidcell">{this.state.selectedCustomHieAlertView.ModifiedBy || "N.A"}</td></tr>
                                <tr><td>Modified date:</td><td class="fluidcell">{this.state.selectedCustomHieAlertView.ModifiedDate ? this.formatDate(new Date(this.state.selectedCustomHieAlertView.ModifiedDate)) : "N.A"}</td></tr>
                            </table>
                        </div>
                    </Dialog>
                </div>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedCustomHieAlertActionComponent = withMsal(CustomHieAlertActionComponent);
export default withAITracking(reactPlugin, WrappedCustomHieAlertActionComponent);