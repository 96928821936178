import { msalInstance } from '../../App';

export class CustomHieAlertService {

    async invokeWebAPI(url, httpMethod, msalContext, body = "") {

        // check if msalContext exists
        if (msalContext.accounts.length > 0) {
            const tokenRequest = {
                scopes: [global.ENV_MSAL_LOGIN_REQUEST_SCOPES],
                account: msalContext.accounts[0],
            };

            // acquire Bearer token to include in GET request
            const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
            const token = tokenResponse.accessToken;
            const bearer = `Bearer ${token}`;
            const headers = new Headers();
            headers.append('Accept', '*/*');
            headers.append('Content-Type', 'application/json');
            headers.append('x-northwell-version', '1.0');
            headers.append("Authorization", bearer);
            headers.append('User', msalContext.accounts[0].username)

            var options = {}
            if (body) {
                options = {
                    method: httpMethod,
                    headers: headers,
                    body: body
                };
            } else {
                options = {
                    method: httpMethod,
                    headers: headers
                };
            }

            let response = await fetch(url, options);

            return {
                status: response.status,
                body: await response.json()
            };

        } else {
            console.log('user not logged in')
        }
    }

    //Custom Hie Rule API calls
    async createCustomHieAlertRule(body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert/hie/create"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async editCustomHieAlertRule(id, body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert/hie/" + id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async isAlertActionRuleNameExists(Name, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert/" + Name + "/exists"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getCustomHieAlertRule(msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert/hie"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getCustomHieAlertRuleById(Id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert/hie/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async deleteCustomHieAlertRule(Id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert/hie/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'DELETE', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getHIEInstances(environment, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/metricsread/HIEInstances?environment=" + environment
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getProductions(instance, environment, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/metricsread/ProductionStatus?instance=" + instance + "&environment=" + environment
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getProductionItems(instance, nameSpace, environment, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/productionitem?instance=" + instance + "&nameSpace=" + nameSpace + "&environment=" + environment
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }
}