import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../../bread-crumb/BreadCrumbComponent';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../../AppInsights';
import { IACLService } from '../IACLService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import Spinner from "../../Spinner";
import AlertMessage from "../../alertmessage/AlertMessage"
import { MessageSeverity, FilterMatchMode, FilterOperator } from 'primereact/api';
import * as Yup from 'yup';

//HDO-5469 - PI 2024 Q3 - Integrate IACL UI features into the Monitoring tool
class CAGroupNameComponent extends Component {
    constructor() {
        super();
        this.WrappedCAGroupNameComponent = React.createRef();
        this._isMounted = false;
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            caGroupNameList: [],
            caGroupName: {
                Id: 0,
                CAGroupName: null,
                IsActive: false
            },
            CAGroupNameAuditLogs: [],
            hideSpinner: false,
            showpagination: false,
            editCaGrpNameDialogBoxVisible: false,
            createCaGrpNameDialogBoxVisible: false,
            confirmDeleteCaGrpNameDialogBoxVisible: false,
            caGrpNameAuditLogDialogBoxVisible: false,
            confirmDeleteVisible: false,
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            globalFilterValue: '',
        }
        this.IACLService = new IACLService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getAllCaGroupName();
    }

    getAllCaGroupName = () => {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' });
        this.IACLService.getAllCaGroupName(false, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({ caGroupNameList: d.body, hideSpinner: true, pageSuccessMessage: '', pageErrorMessage: '' });
                        if (this.state.caGroupNameList.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getCaGrpNameOpsAuditLog = (rowData) => {
        this.setState({ caGroupName: { ...rowData }, hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' });
        this.IACLService.getCaGrpNameOpsAuditLog(rowData.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({ CAGroupNameAuditLogs: d.body, hideSpinner: true, caGrpNameAuditLogDialogBoxVisible: true, pageSuccessMessage: '', pageErrorMessage: '' });
                        if (this.state.CAGroupNameAuditLogs.length > 10) {
                            this.setState({ showpagination: true })
                        }
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    editCaGrpName = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-book" className="p-button-rounded p-button-outlined" tooltip="Logs" tooltipOptions={{ position: 'top' }} onClick={(e) => this.getCaGrpNameOpsAuditLog(rowData)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" tooltip="Edit" tooltipOptions={{ position: 'top' }} onClick={() => this.showEditCaGrpNameDialog(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" tooltip="Delete" tooltipOptions={{ position: 'top' }} onClick={e => this.setState({ confirmDeleteCaGrpNameDialogBoxVisible: true, pageErrorMessage: null, pageSuccessMessage: null, caGroupName: { ...rowData } })} />
            </React.Fragment>
        );
    }

    showEditCaGrpNameDialog = (rowData) => {
        this.setState({ caGroupName: { ...rowData }, editCaGrpNameDialogBoxVisible: true, pageSuccessMessage: '', pageErrorMessage: '' })
    }

    showCaGrpNameDialogBoxForCreate = () => {
        this.clearRowSelection();
        this.setState({ createCaGrpNameDialogBoxVisible: true, pageSuccessMessage: '', pageErrorMessage: '' })
    }

    clearRowSelection() {
        this.setState({
            caGroupName: {
                Id: 0,
                CAGroupName: null,
                IsActive: null
            },
        })
    }

    handleInputChange = (event) => {
        this.setState({
            caGroupName: {
                ...this.state.caGroupName, //using spread operator to initialize object with existing props
                [event.target.name]: event.target.value
            },
            pageErrorMessage: null
        });
    }

    validateEditCaGrpNameDialogFormField = async (caGroupName, onSave = false, isCreate = false) => {
        if (onSave || isCreate) {
            try {
                let msgContentSchema = Yup.object({
                    CAGroupName: Yup.string().required("CA Group name is required.")
                        .max(200, "CA Group name cannot be more than 200 characters.")
                        .matches(/^[^'"]*$/, "CA Group name should not contain single (') or double (\") quotes.")
                });
                await msgContentSchema.validate(caGroupName, { abortEarly: false })

                this.setState({ pageErrorMessage: '' });
                this.IsCaGroupNameExists(isCreate);
            }
            catch (error) {
                this.setState({ pageErrorMessage: error.inner[0].message });
            }
        }
    }

    IsCaGroupNameExists(isCreate = false) {
        this.setState({ hideSpinner: false, pageSuccessMessage: '', pageErrorMessage: '' })
        this.IACLService.IsCaGroupNameExists(this.state.caGroupName.CAGroupName, this.state.caGroupName.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (d.status === 200 && d.body) {
                        this.setState({ pageErrorMessage: 'The CA Group name provided already exists. Please use a different one.', hideSpinner: true });
                    }
                    else {
                        if (isCreate)
                            this.createCaGroupName();
                        else
                            this.updateCaGroupName();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    createCaGroupName() {
        let body = JSON.stringify(this.state.caGroupName);
        this.IACLService.createCaGroupName(body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted && d.status === 200) {
                        this.getAllCaGroupName();
                        this.setState({ pageSuccessMessage: 'CA Group name created successfully', createCaGrpNameDialogBoxVisible: false, hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    updateCaGroupName() {
        let body = JSON.stringify(this.state.caGroupName);
        this.IACLService.updateCaGroupName(this.state.caGroupName.Id, body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.' });
                } else {
                    if (this._isMounted && d.status === 200) {
                        this.getAllCaGroupName();
                        this.setState({ pageSuccessMessage: 'Changes saved successfully.', editCaGrpNameDialogBoxVisible: false, hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    saveFooter = (
        <div>
            <Button label='Save' onClick={() => this.validateEditCaGrpNameDialogFormField(this.state.caGroupName, true)} />
            <Button label='Cancel' onClick={e => this.setState({ editCaGrpNameDialogBoxVisible: false, pageErrorMessage: null })} className='p-button-secondary' />
        </div>
    );

    createFooter = (
        <div>
            <Button label='Create' onClick={() => this.validateEditCaGrpNameDialogFormField(this.state.caGroupName, true, true)} />
            <Button label='Cancel' onClick={e => this.setState({ createCaGrpNameDialogBoxVisible: false, pageErrorMessage: null })} className='p-button-secondary' />
        </div>
    );

    deleteCaGroupName() {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, hideSpinner: false })
        this.IACLService.deleteCaGroupName(this.state.caGroupName.Id, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to perform this operation.', confirmDeleteCaGrpNameDialogBoxVisible: false, hideSpinner: true });
                } else if (d.status === 200 && d.body) {
                    this.setState({ pageSuccessMessage: 'CA Group name deleted successfully.', confirmDeleteCaGrpNameDialogBoxVisible: false, hideSpinner: true });
                    this.getAllCaGroupName();
                } else if (d.status === 500 && d.body) {
                    this.setState({ pageErrorMessage: d.body.error, confirmDeleteCaGrpNameDialogBoxVisible: false, hideSpinner: true });
                } else {
                    this.setState({ pageErrorMessage: 'Something went wrong', confirmDeleteCaGrpNameDialogBoxVisible: false, hideSpinner: true });
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    confirmDeleteFooter = (
        <div>
            <Button label='Ok' onClick={() => this.deleteCaGroupName()} />
            <Button label='Cancel' onClick={e => this.setState({ confirmDeleteCaGrpNameDialogBoxVisible: false })}
                className='p-button-secondary' />
        </div>
    );

    onGlobalFilterChange = (event, filtersKey) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter = (filtersKey) => {
        return (

            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" size="100" />
                    </span>
                    <span>
                        <Button tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.getAllCaGroupName} icon="pi pi-refresh" />
                    </span>
                </div>
            </div>
        )
    }

    clearDataTableFilters = () => {
        this.setState({
            pageErrorMessage: null,
            pageSuccessMessage: null,
            pageErrorMessage: null,
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            globalFilterValue: '',

        })
        sessionStorage.clear();
    }

    booleanChecker = (rowData) => {
        if (rowData.IsActive) {
            return <span className='masterdata-status-badge status-active'>ACTIVE</span>;
        }
        else {
            return <span className='masterdata-status-badge status-inactive'>INACTIVE</span>;
        }
    };

    onStatusSwitchChange = (e) => {
        this.setState({ caGroupName: { ...this.state.caGroupName, IsActive: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    showAlertMessage = (severity, alertMsg) => {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="caGroupNameContainer">
                    <BreadCrumbComponent {...this.props} />

                    <AlertMessage ref={instance => { this.alertMsg = instance }} />
                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div>
                        <div className='flex justify-content-between' style={{ marginBottom: '10px' }}>
                            <div className="page-header-title">CA GROUP NAMES</div>
                            <Button label='' icon='pi pi-plus' tooltip='Create CA Group Name' tooltipOptions={{ position: 'left' }} onClick={this.showCaGrpNameDialogBoxForCreate} />
                        </div>

                        <DataTable ref={(el) => this.dt = el} value={this.state.caGroupNameList}
                            selectionMode="single"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            paginator={this.state.showpagination}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            emptyMessage="No Records found."
                            showGridlines={true}
                            onFilter={(e) => this.setState({ filters: e.filters })}
                            stateStorage="session" stateKey="dt-caGroupName-session"
                        >
                            <Column field="CAGroupName" header="CA Group Name" style={{ textAlign: 'left' }} />
                            <Column field="Status" header="Status" style={{ textAlign: 'left' }} body={this.booleanChecker} />
                            <Column field="edit" header="Action" body={this.editCaGrpName} style={{ whiteSpace: 'nowrap', width: '150px', textAlign: 'center' }} alignHeader='center' />
                        </DataTable>
                    </div>

                    <Dialog header='Edit CA Group Name' footer={this.saveFooter}
                        visible={this.state.editCaGrpNameDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ editCaGrpNameDialogBoxVisible: false, pageErrorMessage: null })} maximizable={false}>
                        <div>
                            {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                            <table style={{ width: '400px' }}>
                                <tr>
                                    <td>
                                        <label><b>Enable</b></label>
                                        <InputSwitch checked={this.state.caGroupName.IsActive} onChange={this.onStatusSwitchChange}
                                            tooltip={this.state.caGroupName.IsActive ? 'YES' : 'NO'} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>CA Group Name</b></label>
                                        <InputText type='text' name='CAGroupName' value={this.state.caGroupName.CAGroupName || ''}
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Dialog>

                    <Dialog header='Create CA Group Name' footer={this.createFooter}
                        visible={this.state.createCaGrpNameDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ createCaGrpNameDialogBoxVisible: false, pageErrorMessage: null })} maximizable={false}>
                        <div>
                            {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                            <table style={{ width: '400px' }}>
                                <tr>
                                    <td>
                                        <label><b>Enable</b></label>
                                        <InputSwitch checked={this.state.caGroupName.IsActive} onChange={this.onStatusSwitchChange}
                                            tooltip={this.state.caGroupName.IsActive ? 'YES' : 'NO'} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className='required-field'><b>CA Group Name</b></label>
                                        <InputText type='text' name='CAGroupName' value={this.state.caGroupName.CAGroupName || ''} required
                                            onChange={this.handleInputChange} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Dialog>

                    <Dialog header='Delete CA Group Name' footer={this.confirmDeleteFooter}
                        visible={this.state.confirmDeleteCaGrpNameDialogBoxVisible} width='400px' modal={true}
                        onHide={e => this.setState({ confirmDeleteCaGrpNameDialogBoxVisible: false })} maximizable={false}>
                        <div>
                            Are you sure you want to delete the ca group name <b>{this.state.caGroupName.CAGroupName}</b>?
                        </div>
                    </Dialog>

                    <Dialog header={this.state.caGroupName.CAGroupName + ' - Audit Logs'} style={{ width: '40vw' }}
                        visible={this.state.caGrpNameAuditLogDialogBoxVisible} modal={true}
                        onHide={e => this.setState({ caGrpNameAuditLogDialogBoxVisible: false, pageErrorMessage: null })} maximizable={false} closeOnEscape={true}>
                        <div>
                            <DataTable value={this.state.CAGroupNameAuditLogs}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                filterDisplay="menu"
                                responsiveLayout="scroll"
                                showGridlines removableSort
                                emptyMessage="No Records found."
                                sortField='Timestamp' sortOrder="-1"
                            >
                                <Column field="DisplayTimestamp" header="Timestamp" style={{ textAlign: 'left' }} sortable sortField='Timestamp' />
                                <Column field="ActionType" header="Action" style={{ textAlign: 'left' }} />
                                <Column field="ActionBy" header="Action By" style={{ textAlign: 'left' }} />
                            </DataTable>
                        </div>
                    </Dialog>

                </div>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedCAGroupNameComponent = withMsal(CAGroupNameComponent);
export default withAITracking(reactPlugin, WrappedCAGroupNameComponent);