import { msalInstance } from '../../App';

export class AdminService {

    async invokeWebAPI(url, httpMethod, msalContext, body = "") {

        // check if msalContext exists
        if (msalContext.accounts.length > 0) {
            const tokenRequest = {
                scopes: [global.ENV_MSAL_LOGIN_REQUEST_SCOPES],
                account: msalContext.accounts[0],
            };

            // acquire Bearer token to include in GET request
            const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
            const token = tokenResponse.accessToken;
            const bearer = `Bearer ${token}`;
            const headers = new Headers();
            headers.append('Accept', '*/*');
            headers.append('Content-Type', 'application/json');
            headers.append('x-northwell-version', '1.0');
            headers.append("Authorization", bearer);
            headers.append('User', msalContext.accounts[0].username)

            var options = {}
            if (body) {
                options = {
                    method: httpMethod,
                    headers: headers,
                    body: body
                };
            } else {
                options = {
                    method: httpMethod,
                    headers: headers
                };
            }

            let response = await fetch(url, options);

            return {
                status: response.status,
                body: await response.json()
            };

        } else {
            console.log('user not logged in')
        }
    }

    async getAdminRoles(msalContext) {
        try {
            if (msalContext.accounts.length > 0) {
                const tokenRequest = {
                    scopes: [global.ENV_MSAL_LOGIN_REQUEST_SCOPES],
                    account: msalContext.accounts[0],
                };
                // acquire Bearer token to include in GET request
                const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
                const token = tokenResponse.accessToken;

                if (token != '') {
                    var base64Url = token.split('.')[1];
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    }).join(''));
                    var result = JSON.parse(jsonPayload);
                    if (result.roles === undefined) {
                    } else {
                        var roles = result.roles;
                        return roles;
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getMetricType(msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/MetricsRead/MetricTypes"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getThresholdRules(msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/Notification/ThresholdReference/Rules"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getThresholdRulesById(Id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/Notification/ThresholdReference/Rules/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async saveNewThresholdRule(body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/Notification/ThresholdReference/Rules/create"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async editThresholdRule(Id, body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/Notification/ThresholdReference/Rules/Edit/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async deleteThresholdRule(id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/delete/" + id
        try {
            const response = await this.invokeWebAPI(url, 'DELETE', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    //Action Group API calls
    async getActionGroups(msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/actiongroup/list"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async updateActionGroup(Id, body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/actiongroup/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async createActionGroup(body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/actiongroup/create"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async isActionGroupNameExist(Name, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/actiongroup/" + Name + "/exists"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async isActionGroupMappingExists(Id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/actiongroup/" + Id + "/mapping/exists"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async deleteActionGroup(Id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/actiongroup/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'DELETE', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    //Custom Rule API calls
    async createCustomHieAlertRule(body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert/create"
        try {
            const response = await this.invokeWebAPI(url, 'POST', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async editCustomHieAlertRule(id, body, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert/" + id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async isAlertActionRuleNameExists(Name, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert/" + Name + "/exists"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getCustomHieAlertRule(msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getCustomHieAlertRuleById(Id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async deleteCustomHieAlertRule(Id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/customalert/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'DELETE', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }
}