import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { CustomHieAlertService } from './CustomHieAlertService';
import { ActionGroupService } from '../actionGroup/ActionGroupService';
import { AutoComplete } from 'primereact/autocomplete';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { InputSwitch } from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import Spinner from '../Spinner'
import { ListBox } from 'primereact/listbox';

class CreateCustomHieAlertComponent extends Component {
    constructor() {
        super();
        this.state = {
            pageErrorMessage: '',
            pageSuccessMessage: '',
            actiongroups: [],
            servicenowgroups: [],
            selectedActionGroups: null,
            filteredActionGroups: null,
            autoCompleteReadOnly: false,
            disableNameSpaceDropDown: true,
            disableInterfaceDropDown: true,
            hideSpinner: false,
            customAlertRequest: {
                MetricType: 9, //HM-947 - this is set based upon the instance selection, default is 9
                AlertType: 0,
                Instance: [],
                NameSpace: [],
                ConfigName: [],
                MatchFilter: '',
                Status: false,
                GroupBy: false,
                PHI: false,
                Name: '',
                Priority: 0,
                LogType: [],
                NotificationBody: '',
                TriggerEmail: false,
                TriggerReport: false,
                NotificationSubject: '',
                ActionGroupIds: '',
                ServiceNowGroup: null,
                LookBackTime: 60,
                ResendFrequency: 0
            },
            alertTypeList: [
                { label: 'Warning', value: 1 },
                { label: 'Error', value: 2 },
                { label: 'Information', value: 3 }
            ],
            priorityList: [
                { label: 'P1', value: 1 },
                { label: 'P2', value: 2 },
                { label: 'P3', value: 3 },
                { label: 'P4', value: 4 }
            ],
            eventLogType: [
                { label: 'Error', value: '2' },
                { label: 'Warning', value: '3' },
                { label: 'Info', value: '4' },
                { label: 'Alert', value: '6' },
            ],
            timePeriodOptions: [
                { label: '5 minutes', value: 5 },
                { label: '15 minutes', value: 15 },
                { label: '30 minutes', value: 30 },
                { label: '1 hour', value: 60 },
                { label: '6 hours', value: 360 },
                { label: '12 hours', value: 720 },
                { label: '1 day', value: 1440 },
                { label: '7 days', value: 10080 },
                { label: '15 days', value: 21600 },
                { label: '30 days', value: 43200 },
            ],
            hieInstances: [],
            productions: [],
            productionItems: []
        }
        this.INTERFACE_DROPDOWN_LIMIT = global.INTERFACE_DROPDOWN_LIMIT
        this.customAlertService = new CustomHieAlertService();
        this.actionGrpService = new ActionGroupService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateCreateCustomAlertField = this.validateCreateCustomAlertField.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getActionGroupsData();
    }

    getActionGroupsData() {
        this.setState({ servicenowgroups: [{ label: 'NONE', value: '' }], pageErrorMessage: null, pageSuccessMessage: null })
        this.actionGrpService.getActionGroups(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({ actiongroups: d.body.ActionGroups });
                        d.body.ServiceNowGroups.map((item) => (this.state.servicenowgroups.push({ label: item.Name, value: item.Name })))
                        this.getHIEInstances();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getHIEInstances() {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.customAlertService.getHIEInstances("CACHE;GCP", this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.hieInstances.push({ label: item.ServerInstance, value: item.ServerInstance })));
                        this.setState({ hideSpinner: true });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getProductions(instance) {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

        this.customAlertService.getProductions(instance, "CACHE;GCP", this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.productions.push({ label: item.Namespace, value: item.Namespace })));
                        this.setState({ hideSpinner: true, disableNameSpaceDropDown: false });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getProductionItems(instance, nameSpace) {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })
        this.customAlertService.getProductionItems(instance, nameSpace, "CACHE;GCP", this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.productionItems.push({ label: item.Name, value: item.Name })));
                        this.setState({ hideSpinner: true, disableInterfaceDropDown: false });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    arIntVals = ['AlertType', 'MetricType', 'Priority', 'ResendFrequency'];
    arBoolVals = ['Status', 'TriggerEmail'];

    handleInputChange(event) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        let found = false;
        for (let i = 0; i < this.arBoolVals.length; i++) {
            if (event.target.name === this.arBoolVals[i]) {
                found = true;
                this.setState({
                    customAlertRequest: {
                        ...this.state.customAlertRequest, //using spread operator to initialize object with existing props
                        [event.target.name]: event.target.checked
                    }
                })
                break;
            }
        }
        for (let i = 0; i < this.arIntVals.length; i++) {
            if (event.target.name === this.arIntVals[i]) {
                found = true;
                this.setState({
                    customAlertRequest: {
                        ...this.state.customAlertRequest, //using spread operator to initialize object with existing props
                        [event.target.name]: parseInt(event.target.value, 10)
                    }
                })
                break;
            }
        }

        if (!found) {
            this.setState({
                customAlertRequest: {
                    ...this.state.customAlertRequest, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    clearCusterAlertRequest() {
        this.setState({
            customAlertRequest: {
                MetricType: 0,
                AlertType: 0,
                Instance: [],
                NameSpace: [],
                ConfigName: [],
                MatchFilter: '',
                Status: false,
                PHI: false,
                Name: '',
                Priority: 0,
                LogType: [],
                NotificationBody: '',
                TriggerEmail: false,
                TriggerReport: false,
                NotificationSubject: '',
                ActionGroupIds: '',
                LookBackTime: 60,
                ResendFrequency: 0
            },
            selectedActionGroups: null,
            productions: [],
            productionItems: [],
            disableInterfaceDropDown: true,
            disableNameSpaceDropDown: true,
            pageErrorMessage: null,
            pageSuccessMessage: null
        });
    }

    async validateCreateCustomAlertField(customAlertRequest, onSave = false, isCreate = false) {
        this.setState({ pageErrorMessage: '', pageSuccessMessage: '' })
        if (this.state.selectedActionGroups && this.state.selectedActionGroups.length > 0) {
            let alertGroupId = this.state.selectedActionGroups.map(a => a.Id)
            customAlertRequest.ActionGroupIds = alertGroupId.join(",")
            this.setState({ customAlertRequest: { ...this.state.customAlertRequest, ActionGroupIds: alertGroupId.join(",") } })
        }
        else {
            customAlertRequest.ActionGroupIds = ''
        }
        if (onSave) {
            var alertNameText = /^[^'"]*$/.test(customAlertRequest.Name);
            var notificationSubjectText = /^[^'"]*$/.test(customAlertRequest.NotificationSubject);
            var matchFilterText = /^[^"]*$/.test(customAlertRequest.MatchFilter);

            if (customAlertRequest.Name === null || customAlertRequest.Name === '') {
                this.setState({ pageErrorMessage: 'Alert Rule Name is required.' });
                return false;
            }
            else if (customAlertRequest.Name.trim().length > 100) {
                this.setState({ pageErrorMessage: 'Alert Rule Name cannot be more than 100 characters.' })
                return false;
            }
            else if (!alertNameText) {
                this.setState({ pageErrorMessage: "Alert Rule Name should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (customAlertRequest.AlertType === null || customAlertRequest.AlertType === 0) {
                this.setState({ pageErrorMessage: 'Alert Type is required' })
                return false;
            }
            else if ((customAlertRequest.Priority === null || customAlertRequest.Priority === 0)
                && customAlertRequest.AlertType === 2) {
                this.setState({ pageErrorMessage: 'Priority is required' })
                return false;
            }
            else if (customAlertRequest.NotificationSubject === null || customAlertRequest.NotificationSubject === '') {
                this.setState({ pageErrorMessage: 'Notification Subject is required.' });
                return false;
            }
            else if (customAlertRequest.NotificationSubject.trim().length > 100) {
                this.setState({ pageErrorMessage: 'Notification Subject cannot be more than 100 characters.' })
                return false;
            }
            else if (!notificationSubjectText) {
                this.setState({ pageErrorMessage: "Notification Subject should not contain single (') or double (\") quotes." })
                return false;
            }
            else if (customAlertRequest.MatchFilter === null || customAlertRequest.MatchFilter === '') {
                this.setState({ pageErrorMessage: 'Match Filter is required.' });
                return false;
            }
            else if (!matchFilterText) {
                this.setState({ pageErrorMessage: "Match Filter should not contain double (\") quotes." })
                return false;
            }
            else if (customAlertRequest.ActionGroupIds === null || customAlertRequest.ActionGroupIds === '') {
                this.setState({ pageErrorMessage: 'Atleast one action group is required.' });
                return false;
            }
            else if (customAlertRequest.ResendFrequency === null || customAlertRequest.ResendFrequency <= 0) {
                this.setState({ pageErrorMessage: 'Alert Frequency is required.' });
                return false;
            }
            else if (customAlertRequest.LookBackTime === null || customAlertRequest.LookBackTime <= 0) {
                this.setState({ pageErrorMessage: 'LookBack Period is required.' });
                return false;
            }
            else if (customAlertRequest.Status && !customAlertRequest.TriggerEmail && !customAlertRequest.TriggerReport) {
                this.setState({ pageErrorMessage: 'Trigger type is required.' });
                return false;
            }
            else if (isCreate) {
                this.setState({ pageErrorMessage: "" })
                this.IsAlertActionRuleNameExists(customAlertRequest, isCreate)
            }
        }
        else {
            const propName = customAlertRequest.target.name;
            const value = customAlertRequest.target.value;
            switch (propName) {
                case 'Name':
                    if (value === '' || value === null || value <= 0) {
                        this.setState({ pageErrorMessage: 'Alert Rule Name is required.' });
                        return false;
                    }
                    break;
                case 'NotificationSubject':
                case 'MatchFilter':
                    if (value === '' || value === null || value <= 0) {
                        this.setState({ pageErrorMessage: propName + ' is required.' });
                        return false;
                    }
                default:
                    break;
            }
        }
    }

    IsAlertActionRuleNameExists(customAlertRequest, isCreate = false) {
        this.setState({ hideSpinner: false })
        this.customAlertService.isAlertActionRuleNameExists(customAlertRequest.Name, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (d.status === 200 && d.body) {
                        this.setState({ pageErrorMessage: 'Alert rule name provided already exists. Please use a different one.', hideSpinner: true });
                    }
                    else {
                        if (isCreate)
                            this.CreateCustomHieAlertRule(customAlertRequest);
                    }
                }
            }
        ).catch(e => {
            console.log(e)
        });
    }

    CreateCustomHieAlertRule(customAlertRequest) {
        this.setState({ pageErrorMessage: '', pageSuccessMessage: '' })

        //set ['ALL'] if more than 1 & all instances and namespaces are selected 
        customAlertRequest.LogType = customAlertRequest && customAlertRequest.LogType.length === this.state.eventLogType.length ? ['ALL'] : customAlertRequest.LogType
        customAlertRequest.Instance = customAlertRequest && customAlertRequest.Instance.length === this.state.hieInstances.length ? ['ALL'] : customAlertRequest.Instance
        customAlertRequest.NameSpace = customAlertRequest && customAlertRequest.NameSpace.length > 1 && customAlertRequest.NameSpace.length === this.state.productions.length ? ['ALL'] : customAlertRequest.NameSpace
        customAlertRequest.NotificationSubject = customAlertRequest.PHI ? `PHI - ${customAlertRequest.NotificationSubject}` : customAlertRequest.NotificationSubject
        let body = JSON.stringify(customAlertRequest);

        this.customAlertService.createCustomHieAlertRule(body, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else if (this._isMounted && d.status === 200) {
                    if (d.body.Id > 0 && d.body.AlertActionRuleId > 0) {
                        this.setState({ pageSuccessMessage: 'Rule created successfully.', hideSpinner: true })
                    }
                    else {
                        this.setState({ pageErrorMessage: 'Rule save failed.', hideSpinner: true });
                    }
                }
                else {
                    this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
                }
            }
        ).catch(e => {
            this.setState({ pageErrorMessage: 'Something went wrong', hideSpinner: true });
            console.log(e)
        });
    }

    onAlertTypeChange = (alertType) => {
        if (alertType !== null && alertType !== undefined) {
            this.setState({
                customAlertRequest: {
                    ...this.state.customAlertRequest, AlertType: alertType.value, Priority: 0, ServiceNowGroup: null
                }, pageErrorMessage: null, pageSuccessMessage: null
            })
        }
    }

    onPriorityChange = (priority) => {
        if (priority !== null && priority !== undefined) {
            this.setState({ customAlertRequest: { ...this.state.customAlertRequest, Priority: priority.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    //HM-1093
    onLogTypeChange = (logType) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (logType !== null && logType !== undefined) {
            this.setState({
                customAlertRequest: { ...this.state.customAlertRequest, LogType: logType.value }
            })
        }
    }

    onInstanceChange = (instance) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        if (instance !== null && instance !== undefined) {

            let metrictype = 9 //HM-947 - this is set based upon the instance selection
            if (instance.value && instance.value.length === 1) {
                metrictype = instance.value[0].includes('GCP') ? 201 : 9
            }

            this.setState({
                customAlertRequest: { ...this.state.customAlertRequest, Instance: instance.value, NameSpace: [], ConfigName: [], MetricType: metrictype },
                productions: [], productionItems: [], disableNameSpaceDropDown: true, disableInterfaceDropDown: true
            })

            if (instance.value !== null && instance.value !== undefined && instance.value !== "" && instance.value.length > 0) {
                //get all namespaces if all instances are selected
                if (instance.value.length === this.state.hieInstances.length)
                    this.getProductions(['ALL'])
                else
                    this.getProductions(instance.value);
            }
            else
                this.setState({ productions: [], productionItems: [], disableNameSpaceDropDown: true, disableInterfaceDropDown: true });
        }
    }

    onNameSpaceChange = (nameSpace) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        if (nameSpace !== null && nameSpace !== undefined) {

            this.setState({
                customAlertRequest: { ...this.state.customAlertRequest, NameSpace: nameSpace.value, ConfigName: [] },
                productionItems: [], disableInterfaceDropDown: true
            })
            if (nameSpace.value !== null && nameSpace.value !== undefined && nameSpace.value !== "" && nameSpace.value.length > 0) {
                //get all interfaces if all instances and namespaces are selected
                if (this.state.customAlertRequest.Instance.length === this.state.hieInstances.length && nameSpace.value.length === this.state.productions.length)
                    this.getProductionItems(['ALL'], ['ALL'])
                else
                    this.getProductionItems(this.state.customAlertRequest.Instance, nameSpace.value);
            }
            else
                this.setState({ productionItems: [], disableInterfaceDropDown: true });
        }
    }

    onInterfaceNameChange = (interfaceName) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        if (interfaceName.value && interfaceName.value.length > this.INTERFACE_DROPDOWN_LIMIT) {
            this.setState({ pageErrorMessage: "Max " + this.INTERFACE_DROPDOWN_LIMIT + " can be selected" })
            return;
        }

        if (interfaceName !== null && interfaceName !== undefined) {
            this.setState({ customAlertRequest: { ...this.state.customAlertRequest, ConfigName: interfaceName.value } })
        }
    }

    onServiceNowGrpChange = (group) => {
        if (group !== null && group !== undefined) {
            this.setState({ customAlertRequest: { ...this.state.customAlertRequest, ServiceNowGroup: group.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    onLookBackPeriodChange = (lookBackPeriod) => {
        if (lookBackPeriod !== null && lookBackPeriod !== undefined) {
            if (this.state.customAlertRequest.TriggerReport && lookBackPeriod.value < 60)
                this.setState({ customAlertRequest: { ...this.state.customAlertRequest, LookBackTime: 60 }, pageErrorMessage: "The minimun value for 'LookBackTime' is 1 hour in case of 'Trigger Report'.", pageSuccessMessage: null })
            else
                this.setState({ customAlertRequest: { ...this.state.customAlertRequest, LookBackTime: lookBackPeriod.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    onResendFrequencyChange = (e) => {
        if (e !== null && e !== undefined) {

            if (e.value < this.state.customAlertRequest.LookBackTime)
                this.setState({ pageErrorMessage: "The value for 'Alert Frequency' should be equal to or greater than the Lookback period. ", pageSuccessMessage: null })
            else
                this.setState({ customAlertRequest: { ...this.state.customAlertRequest, ResendFrequency: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    onStatusSwitchChange = (e) => {
        this.setState({ customAlertRequest: { ...this.state.customAlertRequest, Status: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    onGroupBySwitchChange = (e) => {
        this.setState({ customAlertRequest: { ...this.state.customAlertRequest, GroupBy: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    onPHISwitchChange = (e) => {
        this.setState({ customAlertRequest: { ...this.state.customAlertRequest, PHI: e.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    onTriggerOptionChange = (e) => {
        if (e.value === "email")
            this.setState({ customAlertRequest: { ...this.state.customAlertRequest, TriggerEmail: e.checked, TriggerReport: false }, pageErrorMessage: null, pageSuccessMessage: null })
        else
            this.setState({ customAlertRequest: { ...this.state.customAlertRequest, Priority: 0, ResendFrequency: 0, AlertType: 3, ServiceNowGroup: null, TriggerEmail: false, TriggerReport: e.checked, LookBackTime: this.state.customAlertRequest.LookBackTime < 60 ? 60 : this.state.customAlertRequest.LookBackTime }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    searchActionGroup = (event) => {
        if (this.state.selectedActionGroups && this.state.selectedActionGroups.length >= global.ACTION_GROUP_LIMIT) {
            this.setState({ autoCompleteReadOnly: true, filteredActionGroups: null, pageErrorMessage: "You can add up to " + global.ACTION_GROUP_LIMIT + " action groups to each alert rule." })
        }
        else {
            setTimeout(() => {
                let filteredActionGroups;
                if (!event.query.trim().length) {
                    filteredActionGroups = [...this.state.actiongroups];
                }
                else {
                    filteredActionGroups = this.state.actiongroups.filter((actiongrp) => {
                        return actiongrp.Name.toLowerCase().match(event.query.toLowerCase());
                    });
                }
                this.setState({ filteredActionGroups });
            }, 250);
            this.setState({ pageErrorMessage: null, pageSuccessMessage: null, autoCompleteReadOnly: false })
        }
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="actiongroupContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}

                    {this.state.pageSuccessMessage ?
                        <div>
                            {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}
                            <header className="page-header">
                                <div className="page-header-title">CREATE CUSTOM ALERT RULE</div>
                            </header>
                            <div className="card">
                                <table className='w-100'>
                                    <tr>
                                        <td>
                                            <label><b>Alert Rule Name</b></label>
                                            <label>{this.state.customAlertRequest.Name}</label>
                                        </td>
                                        <td>
                                            {this.state.customAlertRequest.TriggerReport ?
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td style={{ width: '33%' }}>
                                                                <label><b>Status</b></label>
                                                                <label>{this.state.customAlertRequest.Status ? 'Active' : 'Not Active'}</label>
                                                            </td>
                                                            <td style={{ width: '33%' }}>
                                                                <label><b>Contains PHI ?</b></label>
                                                                <label>{this.state.customAlertRequest.PHI ? 'Yes' : 'No'}</label>
                                                            </td>
                                                            <td style={{ width: '33%' }}>
                                                                <label><b>Distinct Records</b></label>
                                                                <label>{this.state.customAlertRequest.GroupBy ? 'Yes' : 'No'}</label>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div> :
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td style={{ width: '50%' }}>
                                                                <label><b>Status</b></label>
                                                                <label>{this.state.customAlertRequest.Status ? 'Active' : 'Not Active'}</label>
                                                            </td>
                                                            <td style={{ width: '50%' }}>
                                                                <label><b>Contains PHI ?</b></label>
                                                                <label>{this.state.customAlertRequest.PHI ? 'Yes' : 'No'}</label>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <label><b>Trigger</b></label>
                                            <label>{this.state.customAlertRequest.TriggerEmail ? "Email" : ""}</label>
                                            <label>{this.state.customAlertRequest.TriggerReport ? "Report" : ""}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label><b>Alert Type</b></label>
                                            <label>{this.state.customAlertRequest.AlertType}</label>
                                        </td>
                                        <td>
                                            <label><b>Priority</b></label>
                                            <label>{this.state.customAlertRequest.Priority === 0 ? 'N.A' : 'P' + this.state.customAlertRequest.Priority}</label>
                                        </td>
                                        <td>
                                            <label><b>Log Type</b></label>
                                            <label>{this.state.eventLogType.filter(({ value }) => this.state.customAlertRequest.LogType.includes(value)).map(x => x.label).join(", ")}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label><b>Instance</b></label>
                                            <label>{this.state.customAlertRequest.Instance.length > 0 ?
                                                this.state.customAlertRequest.Instance.length > 1 ?
                                                    <ListBox options={this.state.customAlertRequest.Instance} filter
                                                        virtualScrollerOptions={{ itemSize: 38 }}
                                                        style={{ width: '15rem' }} listStyle={{ height: '250px' }} />
                                                    : this.state.customAlertRequest.Instance
                                                : 'N.A.'}</label>
                                        </td>
                                        <td>
                                            <label><b>NameSpace</b></label>
                                            <label>{this.state.customAlertRequest.NameSpace.length > 0 ?
                                                this.state.customAlertRequest.NameSpace.length > 1 ?
                                                    <ListBox options={this.state.customAlertRequest.NameSpace} filter
                                                        virtualScrollerOptions={{ itemSize: 38 }}
                                                        style={{ width: '15rem' }} listStyle={{ height: '250px' }} />
                                                    : this.state.customAlertRequest.NameSpace
                                                : 'N.A.'}</label>
                                        </td>
                                        <td>
                                            <label><b>Interface Name</b></label>
                                            <label>{this.state.customAlertRequest.ConfigName.length > 0 ?
                                                this.state.customAlertRequest.ConfigName.length > 1 ?
                                                    <ListBox options={this.state.customAlertRequest.ConfigName} filter
                                                        virtualScrollerOptions={{ itemSize: 38 }}
                                                        listStyle={{ height: '250px' }} />
                                                    : this.state.customAlertRequest.ConfigName
                                                : 'N.A.'}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan>
                                            <label><b>Match Filter</b></label>
                                            <label>{this.state.customAlertRequest.MatchFilter}</label>
                                        </td>
                                        <td>
                                            <label><b>Notification Subject</b></label>
                                            <label>{this.state.customAlertRequest.NotificationSubject}</label>
                                        </td>
                                        <td>
                                            <label><b>Alert Frequency</b></label>
                                            <label>{this.state.customAlertRequest.ResendFrequency}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label><b>Action Groups</b></label>
                                            <label>{this.state.selectedActionGroups && this.state.selectedActionGroups.length > 0 ?
                                                this.state.selectedActionGroups.map(a => a.Name).join(", ") : ''}</label>
                                        </td>
                                        <td>
                                            <label><b>ServiceNow Group</b></label>
                                            <label>{this.state.customAlertRequest.ServiceNowGroup || "N.A"}</label>
                                        </td>
                                        <td>
                                            <label><b>LookBack Period</b></label>
                                            <label>{this.state.customAlertRequest.LookBackTime || "N.A"}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Button label='OK' onClick={() => this.props.history.push('/admin/custom/hie/alert/rules')} /> &nbsp;
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        :
                        <div>
                            <header className="page-header">
                                <div className="page-header-title">CREATE CUSTOM ALERT RULE</div>
                            </header>
                            {this.state.customAlertRequest.TriggerReport ?
                                <div><Message severity="info" text="The default value for 'Alert Type' is set to Information for 'Trigger Report' option." /><br /><br /></div> : <></>}
                            <div className="card">
                                <table className='w-100' border="0">
                                    <tr>
                                        <td>
                                            <label className='required-field'><b>Alert Rule Name</b></label>
                                            <InputText type='text' name='Name' value={this.state.customAlertRequest.Name || ''}
                                                onChange={this.handleInputChange} onBlur={this.validateCreateCustomAlertField} />
                                        </td>
                                        <td>
                                            {this.state.customAlertRequest.TriggerReport ?
                                                <div>
                                                    <table border="0">
                                                        <tr>
                                                            <td style={{ width: '33%' }}>
                                                                <label><b>Enable</b></label>
                                                                <InputSwitch checked={this.state.customAlertRequest.Status} onChange={this.onStatusSwitchChange}
                                                                    tooltip={this.state.customAlertRequest.Status ? 'YES' : 'NO'} />
                                                            </td>
                                                            <td style={{ width: '33%' }}>
                                                                <label><b>Contains PHI ?</b>
                                                                    &nbsp;<Tooltip target=".custom-target-icon" />
                                                                    <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                                        data-pr-tooltip={"Mark this as YES, if your error text contain any PHI data."} data-pr-position="top"
                                                                        style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                                                <InputSwitch checked={this.state.customAlertRequest.PHI} onChange={this.onPHISwitchChange}
                                                                    tooltip={this.state.customAlertRequest.PHI ? 'YES' : 'NO'} />
                                                            </td>
                                                            <td style={{ width: '33%' }}>
                                                                <label><b>Distinct Records</b>
                                                                    &nbsp;<Tooltip target=".custom-target-icon" />
                                                                    <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                                        data-pr-tooltip={"All the event logs will be grouped by Instance, Namespace & Session id to get distinct records."} data-pr-position="top"
                                                                        style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                                                <InputSwitch checked={this.state.customAlertRequest.GroupBy} onChange={this.onGroupBySwitchChange}
                                                                    tooltip={this.state.customAlertRequest.GroupBy ? 'YES' : 'NO'} />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div> :
                                                <div>
                                                    <table border="0">
                                                        <tr>
                                                            <td style={{ width: '50%' }}>
                                                                <label><b>Enable</b></label>
                                                                <InputSwitch checked={this.state.customAlertRequest.Status} onChange={this.onStatusSwitchChange}
                                                                    tooltip={this.state.customAlertRequest.Status ? 'YES' : 'NO'} />
                                                            </td>
                                                            <td style={{ width: '50%' }}>
                                                                <label><b>Contains PHI ?</b>
                                                                    &nbsp;<Tooltip target=".custom-target-icon" />
                                                                    <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                                        data-pr-tooltip={"Mark this as YES, if your error text contain any PHI data."} data-pr-position="top"
                                                                        style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                                                <InputSwitch checked={this.state.customAlertRequest.PHI} onChange={this.onPHISwitchChange}
                                                                    tooltip={this.state.customAlertRequest.PHI ? 'YES' : 'NO'} />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <label><b>Trigger</b></label>
                                            <span className="flex flex-wrap gap-3">
                                                <label><RadioButton inputId="triggeremail" name="triggeremail" value="email" onChange={this.onTriggerOptionChange} checked={this.state.customAlertRequest.TriggerEmail} /> Email</label>
                                                <label><RadioButton inputId="triggerreport" name="triggerreport" value="report" onChange={this.onTriggerOptionChange} checked={this.state.customAlertRequest.TriggerReport} /> Report</label>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='required-field'><b>Alert Type</b>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip={"This represents monitoring tool alert type."} data-pr-position="right" data-pr-at="right+5 top"
                                                    data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                            <Dropdown disabled={this.state.customAlertRequest.TriggerReport} style={{ width: '100%' }} className="ui-column-filter" placeholder="Alert Type" value={this.state.customAlertRequest.AlertType}
                                                options={this.state.alertTypeList} onChange={this.onAlertTypeChange} />
                                        </td>
                                        <td>
                                            <label className='required-field'><b>Priority</b></label>
                                            <Dropdown disabled={this.state.customAlertRequest.AlertType !== 2 || this.state.customAlertRequest.TriggerReport} style={{ width: '100%' }} className="ui-column-filter" placeholder="Priority" value={this.state.customAlertRequest.Priority}
                                                options={this.state.priorityList} onChange={this.onPriorityChange} />
                                        </td>
                                        <td>
                                            <label><b>Log Type</b>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip={"This represents cache event log types. If none selected, by default all options are considered."} data-pr-position="right" data-pr-at="right+5 top"
                                                    data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                            <MultiSelect style={{ width: '100%' }} className="ui-column-filter" placeholder="Log Type" value={this.state.customAlertRequest.LogType}
                                                options={this.state.eventLogType} onChange={this.onLogTypeChange} maxSelectedLabels={0} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label><b>Instance</b></label>
                                            <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.customAlertRequest.Instance}
                                                options={this.state.hieInstances} onChange={this.onInstanceChange} maxSelectedLabels={3}
                                                placeholder="Select Instance" />
                                        </td>
                                        <td>
                                            <label><b>NameSpace</b></label>
                                            <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.customAlertRequest.NameSpace}
                                                options={this.state.productions} onChange={this.onNameSpaceChange} disabled={this.state.disableNameSpaceDropDown}
                                                maxSelectedLabels={1} placeholder="Select NameSpace" />
                                        </td>
                                        <td>
                                            <label><b>Interface Name</b>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip={"Max " + this.INTERFACE_DROPDOWN_LIMIT + " can be selected"} data-pr-position="right" data-pr-at="right+5 top"
                                                    data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i></label>
                                            <MultiSelect style={{ width: '100%' }} className="ui-column-filter" filter value={this.state.customAlertRequest.ConfigName}
                                                options={this.state.productionItems} onChange={this.onInterfaceNameChange} disabled={this.state.disableInterfaceDropDown}
                                                maxSelectedLabels={1} placeholder="Select Interface" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='required-field'><b>Match Filter</b></label>
                                            <InputTextarea type='text' name='MatchFilter' value={this.state.customAlertRequest.MatchFilter}
                                                onChange={this.handleInputChange} onBlur={this.validateCreateCustomAlertField} rows={5} cols={30} />
                                        </td>
                                        <td>
                                            <label className='required-field'><b>Notification Subject</b></label>
                                            <InputText type='text' name='NotificationSubject' value={this.state.customAlertRequest.NotificationSubject || ''}
                                                onChange={this.handleInputChange} onBlur={this.validateCreateCustomAlertField} />
                                            <div><br /></div>
                                            <label className='required-field'><b>Alert Frequency</b>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip="Denotes the frequency(in minutes) of the reminder alert. Mentioned value is system default." data-pr-position="right" data-pr-at="right+5 top"
                                                    data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                            </label>
                                            <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Select Frequency" value={this.state.customAlertRequest.ResendFrequency}
                                                options={this.state.timePeriodOptions} onChange={this.onResendFrequencyChange} />
                                        </td>
                                        <td>
                                            <label htmlFor="ServiceNowGroup" name='ServiceNowGroup'><b>ServiceNow Group</b>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip="If provided, an incident will be created in servicenow & assigned to selected group. Use 'NONE' option to not create an incident in servicenow." data-pr-position="right" data-pr-at="right+5 top"
                                                    data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                            </label>
                                            <Dropdown disabled={this.state.customAlertRequest.AlertType !== 2 || this.state.customAlertRequest.TriggerReport} style={{ width: '100%' }} className="ui-column-filter" placeholder="Select Group" value={this.state.customAlertRequest.ServiceNowGroup}
                                                options={this.state.servicenowgroups} onChange={this.onServiceNowGrpChange} />
                                            <div><br /></div>
                                            <label className='required-field'><b>Lookback period</b>
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-tooltip="This is the lookback period, or the time period to look back at each time the data is checked. For example, every 5 minute you’ll be looking at the past 1 hour." data-pr-position="right" data-pr-at="right+5 top"
                                                    data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                            </label>
                                            <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Select Timeperiod" value={this.state.customAlertRequest.LookBackTime}
                                                options={this.state.timePeriodOptions} onChange={this.onLookBackPeriodChange} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='3'>
                                            <span className='required-field'><b>Action Groups</b></span>
                                            <Button label='' icon='pi pi-plus' tooltip='Create Action Group' onClick={() => window.open('/admin/alert/actiongroup', '_blank')} />
                                            <span className="p-fluid">
                                                <AutoComplete tooltip='Search for available action group' tooltipOptions={{ position: 'top' }} value={this.state.selectedActionGroups} suggestions={this.state.filteredActionGroups} completeMethod={this.searchActionGroup} field="Name" multiple
                                                    onChange={(e) => this.setState({ pageErrorMessage: '', pageSuccessMessage: '', selectedActionGroups: e.value })} readonly={this.state.autoCompleteReadOnly} aria-label="actiongroups" />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Button label='Save' onClick={() => this.validateCreateCustomAlertField(this.state.customAlertRequest, true, true)} /> &nbsp;
                                            <Button label='Clear' onClick={() => this.clearCusterAlertRequest()} /> &nbsp;
                                            <Button label='Cancel' onClick={() => this.props.history.push('/admin/custom/hie/alert/rules')} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedCreateCustomHieAlertComponent = withMsal(CreateCustomHieAlertComponent);
export default withAITracking(reactPlugin, WrappedCreateCustomHieAlertComponent);