import React, { Component } from 'react';
import { MsalProvider, withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { msalInstance } from '../../App';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { AdminService } from './AdminService';

import './AdminComponent.css';

class AddThresholdComponent extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            thresholdRule: {
                id: "",
                alertActionRuleId: 0,
                alertType: null,
                alertTypeName: null,
                alertDescription: null,
                developerSupportSOPText: null,
                emailList: null,
                highThresholdValue: null,
                lowThresholdValue: null,
                metricType: null,
                metricName: null,
                name: null,
                notificationBody: null,
                notificationSubject: null,
                priority: null,
                scenarioType: null,
                status: null,
                triggerEmail: null,
                adminRoles: []
            },
            metricTypeList: [],
            alertTypeList: [
                { label: 'Warning', value: 1 },
                { label: 'Error', value: 2 },
                { label: 'Information', value: 3 }
            ],
            createRuleErrorMessage: '',
            pageErrorMessage: '',
            createThresholdRule: false,
            hasWritePermission: false,
            arIntVals: ['alertType', 'metricType', 'scenarioType', 'highThresholdValue', 'lowThresholdValue', 'priority'],
            arBoolVals: ['status', 'triggerEmail']
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.saveRecord = this.saveRecord.bind(this);
        this.validateThresholdRuleDialogFormField = this.validateThresholdRuleDialogFormField.bind(this);

        this.adminService = new AdminService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getMetricType();
        this.getAdminRoles();
    }

    getAdminRoles() {
        this.adminService.getAdminRoles(this.props.msalContext).then(
            (d) => {
                if (d && d.includes('Admin.Write'))
                    this.setState({ hasWritePermission: true });
                else
                    this.setState({ createRuleErrorMessage: 'You do not have permission to view this page.' });
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getMetricType() {
        this.adminService.getMetricType(this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'Failed to load Metric Types' });
                    this.setState({ hasReadRights: false });
                } else {
                    if (this._isMounted) {
                        this.setState({ metricTypeList: d.body });
                    }
                }
            }
        ).catch(e => {
            //console.log(e)
        });
    }

    saveRecord() {
        if (this.state.thresholdRule.id === "" || this.state.thresholdRule.id === undefined) {
            console.log("new record");
            let tempTR = this.state.thresholdRule;
            tempTR.id = undefined; //clear id property to avoid web service error

            let body = JSON.stringify(tempTR);
            this.adminService.saveNewThresholdRule(body, this.props.msalContext).then( // /Create
                (d) => {
                    if (d.status === 200) {
                        this.setState({ thresholdRuleDialogVisible: false });
                    } else {
                        this.setState({ createRuleErrorMessage: d.body.error });
                    }
                }
            );
        }
    }

    handleInputChange(event) {
        let found = false;
        for (let i = 0; i < this.state.arBoolVals.length; i++) {
            if (event.target.name === this.state.arBoolVals[i]) {
                found = true;
                this.setState({
                    thresholdRule: {
                        ...this.state.thresholdRule, //using spread operator to initialize object with existing props
                        [event.target.name]: event.target.checked
                    }
                })
                break;
            }
        }
        for (let i = 0; i < this.state.arIntVals.length; i++) {
            if (event.target.name === this.state.arIntVals[i]) {
                found = true;
                this.setState({
                    thresholdRule: {
                        ...this.state.thresholdRule, //using spread operator to initialize object with existing props
                        [event.target.name]: parseInt(event.target.value, 10)
                    }
                })
                break;
            }
        }
        if (!found) {
            this.setState({
                thresholdRule: {
                    ...this.state.thresholdRule, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    async validateThresholdRuleDialogFormField(thresholdRule, onSave = false) {
        console.log(thresholdRule);
        this.setState({ createRuleErrorMessage: '' });
        if (onSave) {
            if (thresholdRule.metricType === null || thresholdRule.metricType === '') {
                this.setState({ createRuleErrorMessage: 'metricType is required' });
                return false;
            }
            else if (thresholdRule.alertType === null || thresholdRule.alertType === '') {
                this.setState({ createRuleErrorMessage: 'alertType is required' });
                return false;
            }
            else if (thresholdRule.scenarioType === null || thresholdRule.scenarioType === '' || thresholdRule.scenarioType <= 0) {
                this.setState({ createRuleErrorMessage: 'scenarioType is required' });
                return false;
            }
            else if (thresholdRule.lowThresholdValue === null || thresholdRule.lowThresholdValue === '' || thresholdRule.lowThresholdValue < 0) {
                this.setState({ createRuleErrorMessage: 'lowThresholdValue is required' });
                return false;
            }
            else if (thresholdRule.highThresholdValue === null || thresholdRule.highThresholdValue === '' || thresholdRule.highThresholdValue < 0) {
                this.setState({ createRuleErrorMessage: 'highThresholdValue is required' });
                return false;
            }
            else if ((thresholdRule.priority === null || thresholdRule.priority === '' || thresholdRule.priority <= 0) && thresholdRule.alertType === 2) {
                this.setState({ createRuleErrorMessage: 'priority is required' });
                return false;
            }
            else if (thresholdRule.emailList === null || thresholdRule.emailList === '') {
                this.setState({ createRuleErrorMessage: 'emailList is required' });
                return false;
            }
            else if (thresholdRule.name === null || thresholdRule.name === '') {
                this.setState({ createRuleErrorMessage: 'name is required' });
                return false;
            }
            else if (thresholdRule.alertDescription === null || thresholdRule.alertDescription === '') {
                this.setState({ createRuleErrorMessage: 'alertDescription is required' });
                return false;
            }
            else if (thresholdRule.notificationSubject === null || thresholdRule.notificationSubject === '') {
                this.setState({ createRuleErrorMessage: 'notificationSubject is required' });
                return false;
            }
            else {
                this.setState({ createRuleErrorMessage: '' });
                this.saveRecord();
            }
        }
        else {
            const propName = thresholdRule.target.name;
            const value = thresholdRule.target.value;
            switch (propName) {
                case 'scenarioType':
                case 'priority':
                case 'emailList':
                case 'name':
                case 'alertDescription':
                case 'notificationSubject':
                    if (value === '' || value === null || value <= 0) {
                        this.setState({ createRuleErrorMessage: propName + ' is required' });
                        return false;
                    }
                case 'lowThresholdValue':
                case 'highThresholdValue':
                    if (value === '' || value === null || value < 0) {
                        this.setState({ createRuleErrorMessage: propName + ' is required' });
                        return false;
                    }
                default:
                    break;
            }
        }
    }

    onAlertTypeChange = (alertType) => {
        if (alertType !== null && alertType !== undefined) {
            this.setState({ thresholdRule: { ...this.state.thresholdRule, alertType: alertType.value } })
        }
    }
    onMetricTypeChange = (metricType) => {
        let metricName = this.state.metricTypeList.find((m) => m.value === metricType.value).label
        if (metricType !== null && metricType !== undefined) {
            this.setState({ thresholdRule: { ...this.state.thresholdRule, metricType: metricType.value, metricName: metricName } })
        }
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="adminContainer">
                    <BreadCrumbComponent {...this.props} />
                    {this.state.createRuleErrorMessage ? <div className='text-danger text-center'>{this.state.createRuleErrorMessage}</div> : <></>}
                    {this.state.hasWritePermission ? <React.Fragment><div>
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td colSpan='4'>
                                        <Checkbox name='status' inputId="cb1" value="Active" onChange={this.handleInputChange} checked={this.state.thresholdRule.status}></Checkbox>
                                        <label htmlFor="cb1" className="p-checkbox-label d-inline">Active</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Metric Type <Button className='tooltip-button'
                                            tooltip='Type of metric from source system.'
                                            tooltipOptions={{ position: 'top' }} /></label>
                                        <span>{this.state.thresholdRule.metricType}</span>
                                    </td>
                                    <td>
                                        <label>Metric Name</label>
                                        <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Metric Name" id={this.state.thresholdRule.metricName}
                                            value={this.state.thresholdRule.metricType} options={this.state.metricTypeList} onChange={this.onMetricTypeChange} filter />
                                    </td>
                                    <td>
                                        <label>Scenario Type <Button className='tooltip-button'
                                            tooltip='If multiple alerts are needed, use an scenario type.'
                                            tooltipOptions={{ position: 'top' }} /></label>
                                        <input type='text' name='scenarioType'
                                            value={this.state.thresholdRule.scenarioType || 0} required
                                            onChange={this.handleInputChange} onBlur={this.validateThresholdRuleDialogFormField} />
                                    </td>
                                    <td>
                                        <label>Alert Type <Button className='tooltip-button'
                                            tooltip='Warning - 1, Error - 2, Info - 3'
                                            tooltipOptions={{ position: 'top' }} /></label>
                                        <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Alert Type"
                                            value={this.state.thresholdRule.alertType} options={this.state.alertTypeList} onChange={this.onAlertTypeChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Alert Name</label>
                                        <input type='text' name='name'
                                            value={this.state.thresholdRule.name} required
                                            onChange={this.handleInputChange} onBlur={this.validateThresholdRuleDialogFormField} />
                                    </td>
                                    <td colSpan={2}>
                                        <label>Alert Description</label>
                                        <input type='text' name='alertDescription'
                                            value={this.state.thresholdRule.alertDescription} required
                                            onChange={this.handleInputChange} onBlur={this.validateThresholdRuleDialogFormField} />
                                    </td>
                                    <td>
                                        <label>Notification Subject</label>
                                        <input type='text' name='notificationSubject'
                                            value={this.state.thresholdRule.notificationSubject || ''} required
                                            onChange={this.handleInputChange} onBlur={this.validateThresholdRuleDialogFormField} />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label>Low Threshold Value</label>
                                        <input type='text' name='lowThresholdValue'
                                            value={this.state.thresholdRule.lowThresholdValue || 0} required
                                            onChange={this.handleInputChange} onBlur={this.validateThresholdRuleDialogFormField} />
                                    </td>
                                    <td>
                                        <label>High Threshold Value</label>
                                        <input type='text' name='highThresholdValue'
                                            value={this.state.thresholdRule.highThresholdValue || 0} required
                                            onChange={this.handleInputChange} onBlur={this.validateThresholdRuleDialogFormField} />
                                    </td>
                                    <td>
                                        <label>Priority</label>
                                        <input type='text' name='priority'
                                            value={this.state.thresholdRule.priority || ''} required
                                            onChange={this.handleInputChange} onBlur={this.validateThresholdRuleDialogFormField} />
                                    </td>
                                    <td>
                                        <Checkbox name='triggerEmail' inputId="cb2" value="triggerEmail" onChange={this.handleInputChange} checked={this.state.thresholdRule.triggerEmail}></Checkbox>
                                        <label htmlFor="cb2" className="p-checkbox-label d-inline" name='triggerEmail'>Trigger Email</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        <label>Email List <Button className='tooltip-button'
                                            tooltip='Comma delimited field.'
                                            tooltipOptions={{ position: 'top' }} /></label>
                                        <textarea type='text' name='emailList' rows="5" cols="160" value={this.state.thresholdRule.emailList}
                                            onChange={this.handleInputChange} onBlur={this.validateThresholdRuleDialogFormField} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Button label='Save' onClick={() => this.validateThresholdRuleDialogFormField(this.state.thresholdRule, true)} />

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div></React.Fragment> : ""}
                </div>
            </MsalAuthenticationTemplate>
        )
    }
}

const WrappedAddThresholdComponent = withMsal(AddThresholdComponent);
export default withAITracking(reactPlugin, WrappedAddThresholdComponent);
